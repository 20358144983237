export default {
  sidebar: {
    fleet: 'Flota',
    vehicles: 'Vozila',
    vehiclesClasses: 'Klase vozila',
    administration: 'Administracija',
    currencies: 'Valute',
    currenciesAdministration: 'Administracija valuta',
    paymentTypes: 'Načini plaćanja',
    paymentTypesAdministration: 'Administracija načina plaćanja',
    users: 'Korisnici',
    usersAdministration: 'Administracija korisnika',
    clients: 'Klijenti',
    clientsAdministration: 'Administracija klijenata',
    organizations: 'Organizacije',
    organizationsAdministration: 'Administracija organizacija',
    saasAdmin: 'Saas admin',
    administrators: 'Administratori',
    saasAdminAdministration: 'Administracija Saas admina',
    statistics: 'Statistika',
    generalOverview: 'Opći pregled',
    payment: 'Plaćanje',

    pricingRegions: 'Cijene područja',
    pricingRegionsAdministration: 'Upravljanje cijenama područja',
    help: 'Pomoć',
    drivings: 'Vožnje',
    calendar: 'Kalendar',
    tabularView: 'Tablični prikaz',
    drivingTemplates: 'Predlošci rezervacija',
    expenses: 'Troškovi',
    vehicleExpensesAdministration: 'Administracija troškova vozila',
    vehicleMaintenance: 'Održavanje vozila',
    vehicleMaintenanceAdministration: 'Administracija održavanja vozila',
    organization: 'Organizacija',
    organizationAdminstration: 'Administracija organizacije',
    packages: 'Paketi',
    packagesChange: 'Promjena paketa',
    logout: 'Odjava',
    logoutText: 'Jeste li sigurni da se želite odjaviti?',
    unaccessibleRoutes: 'Ne možete pristupiti ovoj stranici. Nadogradite paket za potpuni pristup.',
    transactions: 'Transakcije',
    subscription: 'Pretplata',
    gpsMap: 'GPS karta',
    team: 'Tim',
    teamAdministration: 'Administracija tima',
    invoices: 'Računi',
    invoicesAdministration: 'Administracija računa',
    drivingOffers: 'Ponude',
    drivingOffersAdministration: 'Administracija ponuda',
    dashboard: 'Dashboard',
    dashboardAdministration: 'Administracija dashboard-a',
    resourceOverview: 'Pregled resursa',
    resourceOverviewAdministration: 'Administracija pregleda resursa',
    rides: 'Vožnje',
    passengerReviews: 'Recenzije putnika',
    passengerReviewsAdministration: 'Administracija recenzija putnika',
    pricingAndRates: 'Cijene i stope',
    pricingZones: 'Zone i područja',
    pricingZonesAdministration: 'Administracija zona i područja',
    pricingPerZones: 'Cijene po zonama',
    pricingPerZonesAdministration: 'Administracija cijena po zonama',
    pricingPerHour: 'Cijene po satu',
    pricingPerHourAdministration: 'Administracija cijena po satu',
    availability: 'Dostupnost',
    availabilityAdministration: 'Dostupnost vozača i vozila',
    distanceScales: 'Cjenovne skale po udaljenosti',
    distanceScalesAdministration: 'Administracija cjenovnih skala po udaljenosti',
    passengers: 'Putnici',
    passengersAdministration: 'Administracija putnika',
    workLogs: 'Evidencija aktivnosti',
    workLogsAdministration: 'Administracija evidencije aktivnosti',
    advancedSettings: 'Napredne postavke',
    advancedSettingsAdministration: 'Administracija naprednih postavki',
  },
  header: {
    organization: 'Organizacija',
    newDriving: 'Vožnja',
    dailyRent: 'Dnevni najam',
    transfer: 'Prijenos',
    notifications: 'Obavijesti',
    changeLanguage: 'Promijeni jezik',
    help: 'Pomoć',
    fromTemplate: 'Iz predloška',
    back: 'Natrag',
  },
  footer: {
    poweredBy: 'Pokreće',
    subscriptionTo: 'Vaša pretplata na',
    trialEndsAt: 'Probni period za',
    packageExpiresIn: 'paketa ističe za',
    packagePastDue: 'je istekao.',
    clickToPay: 'Kliknite za plaćanje odmah.',
    affiliateUser: 'Ako želite otključati sve funkcionalnosti platforme, potrebno je da se pretplatite ',
    here: 'OVDJE',
  },
  buttons: {
    ok: 'U redu',
    yes: 'Da',
    no: 'Ne',
    save: 'Spremi',
    send: 'Pošalji',
    sendMessage: 'Pošalji poruku',
    edit: 'Uredi',
    add: 'Dodaj',
    confirm: 'Potvrdi',
    cancel: 'Otkaži',
    cancelDriving: 'Otkaži vožnju',
    markAsDone: 'Označi kao završeno',
    markAsNoShow: 'Označi kao nepojavljivanje',
    deleteDriving: 'Izbriši nalog',
    newOrganisation: 'Nova organizacija',
    changePackage: 'Promjena paketa',
    downloadStats: 'Preuzimanje statistike',
    downloadPDF: 'Preuzmi PDF',
    today: 'Danas',
    choose: 'Odaberi',
    login: 'Prijava',
    generateFile: 'Generiraj ugovor',
    generateDrivingOrder: 'Ispis naručivanja vožnje',
    downloadFile: 'Preuzmi datoteku',
    activate: 'Aktiviraj',
    deactivate: 'Deaktiviraj',
    showImage: 'Prikaži sliku',
    showDetails: 'Prikaži detalje',
    actions: 'Radnje',
    delete: 'Obriši',
    open: 'Otvori',
    show: 'Prikaži',
    options: 'Opcije',
    prev: 'Prethodno',
    next: 'Sljedeće',
    skip: 'Preskoči',
    finish: 'Završi',
    settings: 'Postavke',
    cancelSubscription: 'Otkaži pretplatu',
    backToEditOrganization: 'Natrag na uređivanje organizacije',
    payNow: 'Platite sada',
    back: 'Idi natrag',
    integrationRoutes: 'Rute integracije',
    transactions: 'Transakcije',
    articlesOfLaw: 'Članci zakona',
    export: 'Izvoz',
    duplicate: 'Napravi kopiju',
    makeReturnTrip: 'Napravite povratno putovanje',
    selectOrganisation: 'Odaberite organizaciju',
    advancedSettings: 'Napredne postavke',
    accept: 'Prihvati',
    reject: 'Odbij',
    sendViaGnet: 'Pošalji putem GNET-a',
    printInvoice: 'Ispis fakture',
    enable: 'Omogući',
    disable: 'Onemogući',
    generatePaymentLink: 'Generiraj link za plaćanje',
    addItem: '+ Dodaj stavku',
    visitWebsite: 'Posjetite naš web sajt',
    buyMoreSms: 'Kupite više SMS-ova',
    forward: 'Proslijedi',
    sendViaAffiliate: 'Pošalji putem Affiliate',
    markInvoiceAsPaid: 'Označi račun kao plaćen',
    markDrivingOfferAsPaid: 'Označi ponudu vožnje kao plaćenu',
    selectAll: 'Odaberi sve',
    viaAffiliateId: 'Dodajte putem affiliate ID-a',
    registerPartner: 'Registrirajte partnera',
    createNew: 'Kreiraj novo',
    clone: 'Kloniraj',
    cloneExisting: 'Kloniraj postojeće',
    copyLink: 'Kopiraj poveznicu',
    markAsStarted: 'Označi kao započeto',
    copyReferenceLink: 'Kopiraj referentni link',
  },
  tooltips: {
    save: 'Spremi',
    edit: 'Uredi',
    delete: 'Obriši',
    showImage: 'Prikaži sliku',
    open: 'Otvori',
    show: 'Prikaži',
    showDetails: 'Prikaži detalje ',
    downloadFile: 'Preuzmi datoteku',
    generateFile: 'Generiraj ugovor',
    generateDrivingOrder: 'Ispis naručivanja vožnje',
    activate: 'Aktiviraj',
    deactivate: 'Deaktiviraj',
    cancel: 'Otkaži',
    cancelDriving: 'Otkaži nalog',
    cannotMarkAsCanceled: 'Nalog ne može biti označen kao otkazan jer je već prihvaćen',
    markAsDone: 'Označi kao završeno',
    cannotMarkAsDone: 'Nalog ne može biti označen kao završen, jer se još nije dogodio',
    markAsNoShow: 'Označi kao nepojavljivanje',
    cannotMarkAsNoShow: 'Narudžbina se ne može označiti kao nepojavljivanje jer se još nije dogodila',
    deleteDriving: 'Izbriši nalog',
    cannotDelete: 'Žao nam je, ali iz sigurnosnih razloga korisnici se ne mogu brisati dok su trenutno prijavljeni.',
    cannotDeactivate: 'Žao nam je, ali iz sigurnosnih razloga korisnici ne mogu biti deaktivirani dok su prijavljeni.',
    duplicate: 'Napravi kopiju',
    makeReturnTrip: 'Napravite povratno putovanje',
    refresh: 'Osvježi',
    selectOrganisation: 'Odaberite organizaciju',
    sendViaGnet: 'Pošalji putem GNET-a',
    receivedViaGnet: 'Primljeno putem GNET-a',
    sentViaGnet: 'Poslano putem GNET-a',
    printInvoice: 'Ispis fakture',
    optionsDisabled: 'Morate odbiti ili prihvatiti nalog da biste pristupili izborniku opcija',
    cannotMakeReturnTrip: 'Ne možete napraviti povratno putovanje za dnevni najam',
    cannotSendGnetOrderViaGnet: 'Ne možete poslati Gnet nalog putem Gnet-a',
    cannotSendViaGnetWithoutGnetId: 'Ne možete poslati nalog putem Gnet-a bez Gnet ID-a',
    cannotPrintInvoiceWithoutClient: 'Ne možete ispisati fakturu bez odabira klijenta',
    cannotMarkAsDoneStatusRejected: 'Nalog ne može biti označen kao završen jer je već označen kao odbijen',
    cannotMarkAsDoneStatusCanceled: 'Nalog ne može biti označen kao završen jer je već označen kao otkazan',
    cannotMarkAsDoneStatusDone: 'Nalog ne može biti označen kao završen jer je već označen kao završen',
    cannotMarkAsDoneStatusNoShow: 'Nalog ne može biti označen kao završen jer je već označen kao nedolazak',
    cannotMarkAsNoShowStatusRejected: 'Nalog ne može biti označen kao nedolazak jer je već označen kao odbijen',
    cannotMarkAsNoShowStatusCanceled: 'Nalog ne može biti označen kao nedolazak jer je već označen kao otkazan',
    cannotMarkAsNoShowStatusDone: 'Nalog ne može biti označen kao nedolazak jer je već označen kao završen',
    cannotMarkAsNoShowStatusNoShow: 'Nalog ne može biti označen kao nedolazak jer je već označen kao nedolazak',
    cannotMarkAsCanceledGnet: 'Nalog ne može biti označen kao otkazan jer je primljen putem Gnet-a',
    cannotMarkAsCanceledStatusRejected: 'Nalog ne može biti označen kao otkazan jer je već označen kao odbijen',
    cannotMarkAsCanceledStatusExpired: 'Nalog ne može biti označen kao otkazan jer je već označen kao istekao',
    cannotMarkAsCanceledStatusCanceled: 'Nalog ne može biti označen kao otkazan jer je već označen kao otkazan',
    cannotMarkAsCanceledStatusDraft: 'Nalog ne može biti označen kao otkazan jer je već označen kao nacrt',
    cannotMarkAsCanceledStatusCompleted: 'Nalog ne može biti označen kao otkazan jer je već označen kao završen',
    cannotMarkAsCanceledStatusNoShow: 'Nalog ne može biti označen kao otkazan jer je već označen kao nedolazak',
    cannotDeleteDrivingSentViaGnet: 'Nalog ne može biti obrisan jer je poslan putem Gnet-a',
    cannotDeleteDrivingReceivedViaGnet: 'Nalog ne može biti obrisan jer je primljen putem Gnet-a i prihvaćen',
    generatePaymentLink: 'Generiraj link za plaćanje',
    cannotGeneratePaymentLInk: 'Link za plaćanje ne može biti generiran jer niste aktivirali Stripe',
    disabledExport: 'Možete exportovati podatke jednom u 24 sata',
    sendSms: 'Pošalji SMS',
    accept: 'Prihvati',
    reject: 'Odbij',
    sendViaAffiliate: 'Pošalji putem Affiliate',
    receivedViaAffiliate: 'Primljeno putem Affiliate',
    sentViaAffiliate: 'Poslano putem Affiliate',
    cannotSendViaAffiliate: 'Ne možete poslati affiliate nalog za vožnju putem affiliate-a.',
    cannotForwardDrivingOrder: 'Ne možete proslijediti nalog za vožnju koji je u prošlosti.',
    cannotMarkAsCanceledAffiliate:
      'Nalog za vožnju ne može biti označen kao otkazan jer je primljen putem affiliate-a.',
    cannotMarkAsCanceled12Hours: 'Nalog ne može biti označen kao otkazan jer je prošlo 12 sati od vremena preuzimanja.',
    cannotDeleteActiveDriving: 'Aktivni nalog za vožnju ne može biti obrisan.',
    noImageToShow: 'Nema slike za prikaz',
    disabledForAffiliateUser:
      "Vaš račun je tipa 'Affiliate'. Ova akcija je dostupna za pretplaćene [Redovne] korisnike. Idite na poveznicu u podnožju kako biste se pretplatili.",
    markInvoiceAsPaid: 'Označi račun kao plaćen',
    markDrivingOfferAsPaid: 'Označi ponudu vožnje kao plaćenu',
    invoiceIsPaid: 'Račun je već označen kao plaćen',
    drivingOfferIsPaid: 'Ponuda za vožnju je već označena kao plaćena',
    selectAreaToDelete: 'Odaberite područje koje želite obrisati',
    deleteSelectedArea: 'Obrišite odabrano područje',
    markAsStarted: 'Označi kao započeto',
    cannotMarkAsStarted: 'Nalog ne može biti označen kao započet jer se još nije dogodio',
    cannotMarkAsStartedStatusStarted: 'Nalog ne može biti označen kao započet jer je već označen kao započet',
    cannotMarkAsStartedStatusRejected: 'Nalog ne može biti označen kao započet jer je već označen kao odbijen',
    cannotMarkAsStartedStatusCanceled: 'Nalog ne može biti označen kao započet jer je već označen kao otkazan',
    cannotMarkAsStartedStatusDone: 'Nalog ne može biti označen kao započet jer je već označen kao završen',
    cannotMarkAsStartedStatusNoShow: 'Nalog ne može biti označen kao započet jer je već označen kao nedolazak',
    copyReferenceLink: 'Kopiraj referentni link',
    noTemplatesAvailable: 'Nema dostupnih predložaka rezervacija',
  },
  snackbar: {
    close: 'Zatvori',
    savedSuccessfully: 'Uspješno spremljeno',
    excelDownloadedSuccessfully: 'Uspješno preuzeto',
    deletedSuccessfully: 'Uspješno izbrisano',
    sentSuccessfully: 'Uspješno poslano',
    statusChanged: 'Status uspješno promijenjen',
    activeStatusChanged: 'Aktivni status uspješno promijenjen',
    defaultStatusChanged: 'Status zadane vrijednosti uspješno promijenjen',
    primaryStatusChanged: 'Primarni status uspješno promijenjen',
    anErrorOccured: 'Došlo je do pogreške, javite se podršci',
    loadingCommentsFailed: 'Nije uspjelo učitavanje komentara',
    drivingCanceled: 'Naručivanje vožnje uspješno otkazano',
    drivingFinished: 'Naručivanje vožnje uspješno završeno',
    drivingStarted: 'Nalog za vožnju je uspješno označen kao započet',
    drivingNoShow: 'Nalog za vožnju je uspješno označen kao nepojavljivanje.',
    drivingDeleted: 'vozačka dozvola je uspješno izbrisana',
    drivingAccepted: 'Vožnja je uspješno prihvaćena',
    drivingRejected: 'Vožnja je uspješno odbijena',
    planChanged: 'Plan uspješno promijenjen',
    planChangeFailed: 'Nije moguće promijeniti plan',
    saveChangesFirst: 'Prvo spremiti promjene koje ste napravili ranije',
    makeRoutesPublic: 'Rute su javno dostupne',
    makeRoutesNotPublic: 'Rute više nisu javno dostupne',
    makePublicLinkActive: 'Javna poveznica je sada dostupna',
    makePublicLinkInactive: 'Javna poveznica više nije dostupna',
    integrationKeyCopied: 'Ključ integracije kopiran',
    copyToken: 'Ručno kopiranje tokena',
    publicLinkCopied: 'Javna poveznica je kopirana',
    copyPublicLink: 'Ručno kopirajte javnu poveznicu',
    referenceLinkCopied: 'Referentni link kopiran',
    copyReferenceLink: 'Ručno kopirajte referentni link',
    organisationChanged: 'Organizacija uspješno promijenjena',
    changeFailed: 'Promjena nije uspjela, molimo pokušajte ponovno',
    loginFailed: 'Korisničko ime ili lozinka nisu ispravni',
    organisationSuspended: 'Vaša organizacija je suspendirana',
    userEmailDoesNotExists: 'Korisnik s ovom email adresom ne postoji',
    canceledSubscrSuccessfully: 'Uspješno ste otkazali pretplatu',
    toManyRequests: 'Previše zahtjeva. Pokušajte ponovo za minutu.',
    visibleStatusChanged: 'Vidljivi status uspješno promijenjen',
    noInternetConnection: 'Nema interneta. Molimo provjerite vašu internetsku vezu.',
    backOnline: 'Ponovo ste online.',
    driverNotifiedToTurnGpsOn: 'Vozač obaviješten da uključi GPS',
    savedSuccessfullyWithGnetError: 'GNET sinhronizacija nije uspjela, nalog za vožnju je uspješno spremljen.',
    logoutFromAnotherDevice: 'Odjavljeni ste s drugog uređaja.',
    stripeEnabled: 'Stripe uspješno aktiviran',
    stripeDisabled: 'Stripe uspješno deaktiviran',
    finishStripeConnect: 'Molimo završite postavljanje Stripe-a',
    requestSentSuccessfully: 'Zahtjev uspješno poslan',
    pricingZoneNoMatch: 'Ne možemo pronaći odgovarajuće cijenovne zone za izračun cijene.',
    areaAlreadyExists: 'Ovo područje već postoji',
    noDataForThisPostalCode: 'Nema podataka za ovaj poštanski broj',
    affiliateRequestSentSuccessfully: 'Organizacija već postoji, zahtjev za partnerstvo je poslan',
    organizationRegisteredSuccessfully: 'Organizacija je uspješno registrirana',
    organizationIsAlreadyAPartner: 'Organizacija je već partner',
    recordAlreadyExists: 'Ovaj zapis već postoji.',
    tooManyUsers: 'Imate previše korisnika za ovaj paket',
    priceCalculationFailed: 'Proračun cijene nije uspio',
    workLogTimeRequired: 'Morate unijeti vrijeme početka i završetka ili minute',
    zapierEnabled: 'Zapier je uspješno aktiviran',
    zapierDisabled: 'Zapier je uspješno deaktiviran',
    lastUserRoleChange: 'Ne možete promijeniti ulogu ovog korisnika jer biste ostali bez operatera ili dispečera.',
  },
  filters: {
    filter: 'Filtriraj',
    currentYear: 'Trenutna godina',
    lastYear: 'Prošla godina',
    lastQuarter: 'Posljednji kvartal',
    lastThreeYears: 'Posljednje tri godine',
    filterByDriver: 'Filtriraj po vozaču',
    filterByVehicle: 'Filtriraj po vozilu',
    filterByClient: 'Filtriraj po klijentu',
    day: 'Dan',
    week: 'Tjedan',
    month: 'Mjesec',
    vehicle: 'Vozilo',
    driving: 'Vožnja',
    user: 'Korisnik',
    fromDate: 'Od datuma',
    toDate: 'Do datuma',
    threeMonths: 'Tri mjeseca',
    halfYear: 'Pola godine',
    year: 'Godina',
  },
  months: {
    jan: 'siječanj',
    feb: 'veljača',
    mar: 'ožujak',
    apr: 'travanj',
    may: 'svibanj',
    jun: 'lipanj',
    jul: 'srpanj',
    aug: 'kolovoz',
    sep: 'rujan',
    oct: 'listopad',
    nov: 'studeni',
    dec: 'prosinac',
    January: 'siječanj',
    February: 'veljača',
    March: 'ožujak',
    April: 'travanj',
    May: 'svibanj',
    June: 'lipanj',
    July: 'srpanj',
    August: 'kolovoz',
    September: 'rujan',
    October: 'listopad',
    November: 'studeni',
    December: 'prosinac',
  },
  usersPageHeaders: {
    firstName: 'Ime',
    lastName: 'Prezime',
    username: 'Korisničko ime',
    email: 'Email',
    phoneNumber: 'Broj telefona',
    role: 'Uloga',
    device: 'Uređaj',
    operatingSystem: 'Operativni sustav',
    browser: 'Preglednik',
    lastActive: 'Zadnja aktivnost',
    active: 'Aktivan',
    actions: 'Radnje',
  },
  usersRoles: {
    masterAdmin: 'Glavni administrator',
    manager: 'Upravitelj',
    driver: 'Vozač',
    operator: 'Operator',
    externalAssociate: 'Vanjski suradnik',
    sales: 'Prodaja',
    salesLead: 'Vođa prodaje',
    gnet: 'GNET',
    customer: 'Korisnik portala',
    publicUser: 'Javni korisnik',
    zapier: 'Zapier',
  },
  masterUsersPageHeaders: {
    firstName: 'Ime',
    lastName: 'Prezime',
    username: 'Korisničko ime',
    email: 'Email',
    phoneNumber: 'Broj telefona',
    role: 'Uloga',
    organisation: 'Organizacija',
    active: 'Aktivan',
    actions: 'Radnje',
  },
  usersPage: {
    newItem: 'Novi unos korisnika',
    editItem: 'Uredi korisnika',
    id: 'ID',
    firstName: 'Ime',
    lastName: 'Prezime',
    username: 'Korisničko ime',
    password: 'Lozinka',
    phoneNumber: 'Broj telefona',
    role: 'Uloga',
    client: 'Klijent',
    email: 'Email',
    organisation: 'Organizacija',
    percentage: 'Postotak',
    externalAssociate: 'Vanjski suradnik',
    image: 'Slika',
    deleteUser: 'Izbriši korisnika',
    deleteMessage: 'Jeste li sigurni da želite izbrisati korisnika ',
    emailChanged: 'Promjena email adrese',
    verificationEmailSent:
      'Zahtjev za promjenom email adrese je poslan. Da biste dovršili postupak i osigurali sigurnost korisničkog naloga, novu email adresu treba verificirati klikom na verifikacijsku poveznicu poslanu na novu email adresu. Email adresa neće biti promijenjena dok se uspješno ne verificira.',
    passengersInfoAvailableToDriver: 'Informacije o putnicima dostupne vozaču',
    colorPicker:
      'Možete odabrati jedinstvenu boju za svakog korisnika. Koristit će se na stranici dostupnosti radi lakšeg prepoznavanja.',
  },
  clientsPageHeaders: {
    name: 'Ime',
    phoneNumber: 'Broj telefona',
    address: 'Adresa',
    email: 'Email',
    companyId: 'OIB/Osobna iskaznica',
    clientType: 'Vrsta klijenta',
    percentage: 'Postotak',
    active: 'Aktivan',
    actions: 'Radnje',
  },
  clientsPage: {
    newItem: 'Novi unos klijenta',
    editItem: 'Uredi klijenta',
    id: 'ID',
    name: 'Ime',
    address: 'Adresa',
    phoneNumber: 'Broj telefona',
    email: 'Email',
    companyId: 'OIB/Osobna iskaznica',
    clientType: 'Vrsta klijenta',
    individual: 'Fizička osoba',
    business: 'Pravna osoba',
    percentage: 'Postotak',
    deleteClient: 'Izbriši klijenta',
    deleteMessage: 'Jeste li sigurni da želite izbrisati klijenta ',
    tabs: {
      general: 'Općenito',
      transfers: 'Transferi',
      users: 'Korisnici',
      invoices: 'Računi',
      cards: 'Kartice',
    },
    publicId: 'Javni ID',
    referenceLink: 'Referentni link',
    publicFormNotEnabled:
      'Ako želite pristupiti referentnom linku, idite na napredne postavke i omogućite javni obrazac',
  },
  drivingsHeaders: {
    drivingNumber: 'Broj nalog',
    drivingType: 'Tip rezervacije',
    drivingStatus: 'Status',
    locationFrom: 'Polazište',
    locationTo: 'Odredište',
    vehicleClass: 'Klasa vozila',
    vehicle: 'Vozilo',
    driversName: 'Vozač',
    startTime: 'Vrijeme polaska',
    actions: 'Radnje',
    exportExcel: 'Izvoz u Excel',
    price: 'Cijena',
    totalPrice: 'Ukupna cijena',
    confirmed: 'Potvrđeno',
    paid: 'Plaćeno',
    numberOfDrivings: 'Broj vožnji',
    templateName: 'Naziv predloška',
    visibleToClients: 'Vidljivo klijentima',
  },
  drivingTemplatesPage: {
    newItem: 'Novi unos predloška rezervacije',
    editItem: 'Izmjena predloška rezervacije',
    deleteDrivingTemplate: 'Obriši predložak rezervacije',
    deleteMessage: 'Jeste li sigurni da želite obrisati ovaj predložak rezervacije?',
  },
  vehiclesHeaders: {
    plateNumber: 'Registracija vozila',
    brand: 'Marka vozila',
    vehicleClass: 'Klasa vozila',
    passengersNumber: 'Broj putnika',
    fuelConsumption: 'Potrošnja goriva na 100 km',
    yearOfManufacture: 'Godina proizvodnje',
    pricePerKm: 'Cijena po km',
    pricePerMi: 'Cijena po milji',
    pricePerHour: 'Cijena po satu',
    pricePerWaitingHour: 'Cijena čekanja po satu',
    active: 'Aktivan',
    actions: 'Radnje',
  },
  vehiclesPage: {
    id: 'ID',
    newItem: 'Novi unos vozila',
    editItem: 'Uredi vozilo',
    vehiclePlates: 'Registracijske oznake',
    vehicleBrand: 'Marka vozila',
    vehicleClass: 'Klasa vozila',
    passengersNumber: 'Broj putnika',
    fuelConsumption: 'Potrošnja goriva (100km)',
    fuelConsumptionMi: 'Potrošnja goriva automobila (100 milja)',
    manufactureYear: 'Godina proizvodnje',
    price: 'Cijena po km',
    priceMi: 'Cijena po milji',
    hourPrice: 'Cijena po satu',
    image: 'Slika',
    hourWaitingPrice: 'Cijena čekanja po satu',
    deleteMessage: 'Jeste li sigurni da želite izbrisati vozilo?',
    tabs: {
      general: 'Općenito',
      transfers: 'Prijenosi',
      costs: 'Troškovi',
      damages: 'Oštećenja',
    },
    licence: 'Broj licence',
    color: 'Boja',
    colorPicker:
      'Možete odabrati jedinstvenu boju za svako vozilo. Koristit će se na stranici dostupnosti radi lakšeg prepoznavanja.',
  },
  vehicleClassesHeaders: {
    vehicleClass: 'Klasa vozila',
    numberOfPassengers: 'Broj putnika',
    numberOfSuitcases: 'Broj kofera',
    pricePerKm: 'Cijena po km',
    pricePerMi: 'Cijena po milji',
    pricePerHour: 'Cijena po satu',
    pricePerWaitingHour: 'Cijena čekanja po satu',
    availableForPublicBookingForm: 'Dostupno za javnu booking rezervaciju',
    active: 'Aktivan',
    actions: 'Radnje',
  },
  vehicleClassesPage: {
    newItem: 'Nova klasa vozila',
    editItem: 'Uredi klasu vozila',
    id: 'ID',
    vehicleClass: 'Klasa vozila',
    numberOfPassengers: 'Broj putnika',
    numberOfSuitcases: 'Broj kofera',
    availableForPublicBookingForm: 'Dostupno za javnu booking rezervaciju',
    price: 'Cijena po km',
    priceMi: 'Cijena po milji',
    hourPrice: 'Cijena po satu',
    image: 'Slika',
    hourWaitingPrice: 'Cijena čekanja po satu',
    deleteMessage: 'Jeste li sigurni da želite izbrisati klasu vozila?',
  },
  expensesHeaders: {
    name: 'Naziv',
    vehicle: 'Vozilo',
    type: 'Vrsta',
    description: 'Opis',
    price: 'Cijena',
    expenseDate: 'Datum',
    actions: 'Radnje',
    driving: 'Nalog',
    exportExcel: 'Izvoz u Excel',
  },
  vehicleExpensesTypes: {
    minorService: 'Manji servis',
    majorService: 'Veći servis',
    fuel: 'Gorivo',
    tires: 'Gume',
    parking: 'Parking',
    other: 'Ostalo',
  },
  expensesPage: {
    newItem: 'Novi unos troškova ',
    editItem: 'Promjena troškova',
    id: 'ID',
    name: 'Naziv',
    vehicle: 'Vozilo',
    costType: 'Vrsta troška',
    price: 'Cijena',
    currency: 'Valuta',
    costTime: 'Vrijeme troška',
    description: 'Opis',
    deleteMessage: 'Jeste li sigurni da želite izbrisati ovaj trošak?',
    costTypes: {
      'Mali servis': 'Manji servis',
      'Veliki servis': 'Veći servis',
      Gorivo: 'Gorivo',
      Gume: 'Gume',
      Parking: 'Parking',
      Ostalo: 'Ostalo',
    },
  },
  vehicleMaintenanceHeaders: {
    name: 'Vozilo',
    details: 'Detalji',
    dateFrom: 'Datum početka',
    dateTo: 'Datum završetka',
    actions: 'Radnje',
  },
  vehicleMaintenancePage: {
    newItem: 'Novi unos kvara na vozilu ',
    editItem: 'Promjena kvara na vozilu',
    id: 'ID',
    vehicle: 'Vozilo',
    description: 'Opis',
    dateFrom: 'Vrijeme početka',
    dateTo: 'Vrijeme završetka',
    deleteMessage: 'Jeste li sigurni da želite izbrisati ovaj kvar na vozilu?',
  },
  customerCardsHeaders: {
    cardholderName: 'Ime vlasnika kartice',
    cardNumber: 'Broj kartice',
    expiryDate: 'Datum isteka',
    cvv: 'CVV',
    actions: 'Akcije',
  },
  customerCardsPage: {
    newItem: 'Novi unos kartice',
    editItem: 'Izmjena kartice',
    cardholderName: 'Ime vlasnika kartice',
    cardNumber: 'Broj kartice',
    expiryDate: 'Datum isteka',
    cvv: 'CVV',
    deleteCard: 'Obriši karticu',
    deleteMessage: 'Jeste li sigurni da želite obrisati ovu karticu?',
  },
  pricingRegionsHeaders: {
    name: 'Ime',
    actions: 'Radnje',
  },
  pricingRegionsPage: {
    newItem: 'Nova cjenovna regije',
    editItem: 'Uređivanje cjenovne regije',
    name: 'Ime',
    chooseCurrency: 'Odaberi valutu',
    soloPrice: 'Solo cijena - Godina',
    soloPaddlePriceId: 'Solo ID cijene paddle - Godina',
    team5Price: 'Cijena za tim 5 - Godina',
    team5PaddlePriceId: 'ID paddle cijene za tim 5 - Godina',
    team10Price: 'Cijena za tim 10 - Godina',
    team10PaddlePriceId: 'ID paddle cijene za tim 10 - Godina',
    team25Price: 'Cijena za tim 25 - Godina',
    team25PaddlePriceId: 'ID paddle cijene za tim 25 - Godina',
    team50Price: 'Cijena za tim 50 - Godina',
    team50PaddlePriceId: 'ID paddle cijene za tim 50 - Godina',
    soloPriceMonthly: 'Solo cijena - Mjesec',
    soloPaddlePriceIdMonthly: 'Solo ID cijene paddle - Mjesec',
    team5PriceMonthly: 'Cijena za tim 5 Mjesec',
    team5PaddlePriceIdMonthly: 'ID paddle cijene za tim 5 - Mjesec',
    team10PriceMonthly: 'Cijena za tim 10 - Mjesec',
    team10PaddlePriceIdMonthly: 'ID paddle cijene za tim 10 - Mjesec',
    team25PriceMonthly: 'Cijena za tim 25 - Mjesec',
    team25PaddlePriceIdMonthly: 'ID paddle cijene za tim 25 - Mjesec',
    team50PriceMonthly: 'Cijena za tim 50 - Mjesec',
    team50PaddlePriceIdMonthly: 'ID paddle cijene za tim 50 - Mjesec',
    chooseCountries: 'Odaberi zemlje',
    description: 'Opis',
    deletePricingRegion: 'Izbriši cjenovnu regiju',
    deleteMessage: 'Jeste li sigurni da želite izbrisati ovu cjenovnu regiju?',
  },
  notificationsHeaders: {
    time: 'Vrijeme',
    notification: 'Obavijest',
  },
  notificationsPage: {
    showUnseenOnly: 'Prikaži samo nepročitano',
    markAllAsRead: 'Označi sve kao pročitano',
  },
  organisationsHeaders: {
    name: 'Organizacija',
    phoneNumber: 'Broj telefona',
    email: 'Email',
    address: 'Adresa',
    companyId: 'OIB',
    vat: 'PDV',
    active: 'Aktivan',
    createdAt: 'Registriran',
    lastActivityAt: 'Posljednja aktivnost',
    actions: 'Radnje',
    billingDate: 'Datum naplate',
    referral: 'Preporučitelj',
    subscriptionType: 'Vrsta pretplate',
    subscriptionStatus: 'Status pretplate',
    country: 'Zemlja',
  },
  organisationsPage: {
    newItem: 'Novi unos organizacije',
    editItem: 'Uredi organizaciju',
    id: 'ID',
    name: 'Organizacija',
    address: 'Adresa',
    phoneNumber: 'Broj telefona',
    email: 'Email',
    vat: 'PDV',
    companyId: 'OIB',
    warrantNumber: 'Broj rješenja',
    image: 'Slika',
    signature: 'Potpis',
    seal: 'Pečat',
    deactivateMessage: 'Jeste li sigurni da želite ',
    deactivate: 'DEAKTIVIRATI',
    activate: 'AKTIVIRATI',
    organisation: 'organizaciju',
    deleteOrganisation: 'Izbriši organizaciju',
    cancelSubscrMessage:
      'Jeste li sigurni da želite otkazati pretplatu? Imajte na umu da potvrdom ove radnje: 1. Račun vaše organizacije i svi povezani podaci bit će trajno izbrisani. Ova radnja se ne može poništiti. 2. Bit ćete automatski odjavljeni iz sustava odmah nakon otkazivanja. 3. Više nećete imati pristup za prijavu ili dohvat bilo kojih podataka povezanih s vašom organizacijom u budućnosti. Preporučujemo da pregledate sve potrebne informacije ili izvezete ključne podatke prije nastavka s ovom nepovratnom radnjom. Ako imate pitanja ili trebate pomoć, ne oklijevajte kontaktirati naš tim za podršku. Želite li nastaviti s otkazivanjem?',
    deleteMessage: 'Jeste li sigurni da želite izbrisati ovu organizaciju?',
    allDrivings: 'Ukupan broj narudžbi vožnje',
    allDrivingsAccepted: 'Ukupno prihvaćenih narudžbi vožnje',
    allDrivingsCanceled: 'Ukupno odbijenih narudžbi vožnje',
    allVehicles: 'Ukupan broj vozila',
    allUsers: 'Ukupan broj korisnika',
    areYouSure: 'Jeste li sigurni?',
    makeRoutesAvailable: 'Omogući javni pristup rutama',
    percentage: 'Postotak',
    deleteImageMessage: 'Jeste li sigurni da želite izbrisati ovu sliku?',
    deleteImageTitle: 'Brisanje slike',
    cancelSubscriptionTitle: 'Otkazivanje pretplate',
    subscriptionType: 'Vrsta pretplate',
    billingDate: 'Datum naplate',
    articlesOfLaw: 'Članci zakona',
    country: 'Zemlja',
    referent: 'Referent',
    language: 'Jezik',
    gnetId: 'GNET ID',
    affiliateId: 'Affiliate ID',
    trialEndsAt: 'Probni period završava',
    languageTooltip:
      'Zadani jezik za nove korisnike i jezik na kojem će biti poslani svi e-mailovi kompanije (ne individualni korisnički e-mailovi).',
    organisationType: 'Tip organizacije',
    regular: 'Redovni',
    affiliateSender: 'Affiliate pošiljalac',
    affiliateReceiver: 'Affiliate primalac',
    dispatcher: 'Dispečer',
    referral: 'Preporučitelj',
    selectLocation: 'Odaberite lokaciju',
    changeLocation: 'Promijenite lokaciju',
    timeZone: 'Vremenska zona',
    onlyActive: 'Samo aktivni',
  },
  subscriptionsHeaders: {
    invoiceNumber: 'Broj računa',
    status: 'Status',
    total: 'Ukupno',
    tax: 'Porez',
    currency: 'Valuta',
    billedAt: 'Datum izdavanja računa',
    actions: 'Radnje',
  },
  subscriptionsPage: {
    newItem: 'Novi unos transakcije',
    editItem: 'Uredi transakciju',
    id: 'Id',
    invoiceNumber: 'Broj računa',
    status: 'Status',
    sent: 'Poslano',
    completed: 'Dovršeno',
    total: 'Ukupno',
    currency: 'Valuta',
    billedAt: 'Naplaćeno',
    actions: 'Akcije',
    deleteSubscription: 'Obriši transakciju',
    deleteMessage: 'Jeste li sigurni da želite obrisati ovu transakciju?',
    currentSubscription: 'Trenutni paket',
    nextBillingDate: 'Sljedeći datum naplate',
    numberOfSeatsTaken: 'Broj zauzetih mjesta',
    numberOfSeatsAvailable: 'Broj slobodnih mjesta',
    pastDue: 'Isteklo',
  },
  currenciesHeaders: {
    name: 'Ime',
    code: 'Kod',
    symbol: 'Simbol',
    active: 'Aktivan',
    default: 'Osnovna',
    actions: 'Radnje',
  },
  currenciesPage: {
    newItem: 'Novi unos valute ',
    editItem: 'Promjena valute',
    id: 'ID',
    currency: 'Valuta',
    code: 'Kod',
    symbol: 'Simbol',
    deleteMessage: 'Jeste li sigurni da želite izbrisati ovu valutu?',
  },
  paymentMethodsHeaders: {
    name: 'Ime',
    active: 'Aktivan',
    default: 'Osnovna',
    priceVisible: 'Cijena vidljiva',
    actions: 'Radnje',
  },
  paymentMethodsPage: {
    newItem: 'Novi unos načina plaćanja',
    editItem: 'Promjena načina plaćanja',
    id: 'ID',
    paymentMethod: 'Način plaćanja',
    deleteMessage: 'Jeste li sigurni da želite izbrisati ovaj način plaćanja?',
  },
  feedbacksHeaders: {
    subject: 'Naslov',
    organisation: 'Organizacija',
    user: 'Korisnik',
    actions: 'Radnje',
  },
  checkpointsHeaders: {
    checkpointLocation: 'Lokacija putne točke',
    arrivalTime: 'Vrijeme dolaska',
    actions: 'Radnje',
  },
  tables: {
    noDataText: 'Nema podataka',
    search: 'Pretraga',
    drivings: 'Narudžbe vožnje',
    drivingsByPage: 'Narudžbe vožnje po stranici',
    drivingTemplates: 'Predlošci rezervacija',
    drivingTemplatesByPage: 'Predlošci po stranici',
    vehicles: 'Vozila',
    vehiclesByPage: 'Vozila po stranici',
    vehiclesClasses: 'Klase vozila',
    vehiclesClassesByPage: 'Klase vozila po stranici',
    expenses: 'Troškovi',
    expensesByPage: 'Troškova po stranici',
    vehiclesMaintenance: 'Kvarovi vozila',
    vehiclesMaintenanceByPage: 'Kvarovi vozila po stranici',
    reportsByPage: 'Izvješća po stranici',
    currencies: 'Valute',
    currencieseByPage: 'Valute po stranici',
    payments: 'Načini plaćanja',
    paymentsByPage: 'Načini plaćanja po stranici',
    saasAdministrators: 'Svi administratori',
    users: 'Svi korisnici',
    usersByPage: 'Korisnici po stranici',
    clients: 'Svi klijenti',
    clientsByPage: 'Klijenti po stranici',
    organisations: 'Sve organizacije',
    organisationsByPage: 'Organizacije po stranici',
    cards: 'Kartice',
    cardsByPage: 'Kartice po stranici',
    transactions: 'Transakcije',
    transactionsByPage: 'Transakcije po stranici',
    subscriptions: 'Pretplate',
    subscriptionsByPage: 'Pretplate po stranici',
    pricingRegions: 'Cijene područja',
    pricingRegionsByPage: 'Cijene područja po stranici',
    feedback: 'Povratne informacije',
    feedbacksByPage: 'Povratne informacije po stranici',
    notifications: 'Obavijesti',
    notificationsByPage: 'Obavijesti po stranici',
    all: 'Sve',
    passengers: 'Putnici',
    passengersByPage: 'Putnici po stranici',
    of: 'od',
    checkpoints: 'Putne točke',
    checkpointsByPage: 'Putne točke po stranici',
    invoices: 'Računi',
    invoicesByPage: 'Računi po stranici',
    drivingOffers: 'Ponude za vožnju',
    drivingOffersByPage: 'Ponude za vožnju po stranici',
    smsMessages: 'SMS poruke',
    smsMessagesByPage: 'SMS poruke po stranici',
    resourcesUsed: 'Iskorišteni resursi',
    resourcesUsedByPage: 'Resursi po stranici',
    inNext3Days: 'U sljedeća 3 dana',
    notConfirmedOrPaid: 'Nije potvrđeno ili plaćeno',
    inNext5Days: 'U sljedećih 5 dana',
    latestReviews: 'Najnovije recenzije',
    passengerReviews: 'Recenzije putnika',
    driversByPage: 'Vozači po stranici',
    reviewsByPage: 'Recenzije po stranici',
    partners: 'Partneri',
    partnersByPage: 'Partneri po stranici',
    sentRequests: 'Poslani zahtjevi',
    receivedRequests: 'Primljeni zahtjevi',
    requestsByPage: 'Zahtjevi po stranici',
    pricingZones: 'Zone i područja',
    pricingZonesByPage: 'Zone i područja po stranici',
    pricingPerZones: 'Cijene po zonama',
    pricingPerZonesByPage: 'Cijene po stranici',
    pricingPerHour: 'Cijene po satu',
    pricingPerHourByPage: 'Cijene po stranici',
    workLogs: 'Evidencija aktivnosti',
    workLogsByPage: 'Evidencija aktivnosti po stranici',
    loading: 'Učitavanje',
  },
  statistics: {
    numberOfAcceptedDrivings: 'Broj prihvaćenih vožnji',
    numberOfRejectedDrivings: 'Broj odbijenih vožnji po vozaču',
    vehicleDrivingsNumber: 'Broj vožnji po vozilu',
    vehicleProfit: 'Zarada po vozilu',
    monthDrivingsNumber: 'Broj vožnji po mjesecima',
    driverProfit: 'Zarada po vozaču',
  },
  reportsHeaders: {
    vehicle: 'Vozilo',
    expenses: 'Troškovi',
    profit: 'Profit',
    earnings: 'Zarada',
    daysWorking: 'Radni dani',
    usageIndex: 'Indeks korištenja',
    client: 'Klijent',
    numberOfDrivings: 'Broj Vožnji',
    percentForClient: 'Cijena za Klijenta',
    sumOfPrice: 'Iznos',
  },
  packages: {
    active: 'Aktivan',
    starterTitle: 'Početnik',
    monthlyPackagesMessage: 'Mjesečna pretplata po organizaciji.',
    starterText: 'Idealno za pojedince ili male timove koji trebaju samo osnovne funkcije.',
    starterListItemOne: 'Mobilne aplikacije',
    starterListItemTwo: 'Obavijesti putem pusha',
    starterListItemThree: 'Pristup administraciji',
    starterListItemFour: 'Integracija na web stranicu',
    standardTitle: 'Standard',
    standardText: 'Savršeno za organizacije s više od 5 vozača.',
    standardListItemOne: 'PAKET POČETNIK uključen',
    standardListItemTwo: 'Nema ograničenja broja vozača',
    standardListItemThree: 'Dostupnost 99,9%',
    standardListItemFour: 'Izvješća i upravljanje vozilima',
    premiumTitle: 'Premium',
    premiumText: 'Idealno za organizacije limuzinskih usluga koje djeluju na temelju preciznih podataka.',
    premiumListItemOne: 'PAKET STANDARD uključen',
    premiumListItemTwo: 'BI i Prognoza troškova',
    premiumListItemThree: 'Izvoz podataka',
    premiumListItemFour: 'Tjedna i mjesečna izvješća',
    warningOne: '* Prikazane cijene su mjesečna pretplata po korisniku',
    warningTwo: '* Nakon prelaska na novi paket, nije moguće prebaciti se na manji paket tijekom sljedećih 6 mjeseci',
    warningThree: '* Korisnici demo plana imaju uvid u funkcionalnosti koje pruža premium paket',
    warningOneAnnualPackage: 'Trebate više? Imate veću organizaciju? Imamo za vas PAKET PODUZEĆA.',
    warningTwoAnnualPackage: 'i naš tim za prodaju će vas uskoro kontaktirati.',
    packageChange: 'Promjena paketa',
    changePackage: 'Jeste li sigurni da želite prebaciti se na',
    package: 'paketa',
    annualPricing: 'Godišnje cijene',
    monthlyPricing: 'Mjesečne cijene',
    packageType5: 'Tim 5',
    packageType10: 'Tim 10',
    packageType25: 'Tim 25',
    packageType50: 'Tim 50',
    'Solo - Yearly': 'Solo - Godišnje',
    'Team 5 - Yearly': 'Tim 5 - Godišnje',
    'Team 10 - Yearly': 'Tim 10 - Godišnje',
    'Team 25 - Yearly': 'Tim 25 - Godišnje',
    'Team 50 - Yearly': 'Tim 50 - Godišnje',
    'Solo - Monthly': 'Solo - Mjesečno',
    'Team 5 - Monthly': 'Tim 5 - Mjesečno',
    'Team 10 - Monthly': 'Tim 10 - Mjesečno',
    'Team 25 - Monthly': 'Tim 25 - Mjesečno',
    'Team 50 - Monthly': 'Tim 50 - Mjesečno',
    annualPackagesMessage: 'Godišnja pretplata po organizaciji.',
    packageTypeSoloMainMessage: 'Samo 1 korisnički naloga.',
    packageType5MainMessage: 'Do 5 korisničkih naloga.',
    packageType10MainMessage: 'Do 10 korisničkih naloga.',
    packageType25MainMessage: 'Do 25 korisničkih naloga.',
    packageType50MainMessage: 'Do 50 korisničkih naloga.',
    allFeaturesIncluded: 'Sve funkcionalnosti uključene.',
    unlimitedRides: 'Neograničen broj vožnji i vozila.',
    trialPeriod: 'Probno razdoblje uključeno.',
    onboardingFeeOptional: 'Naknada za instalaciju opcionalna.',
    noHiddenCosts: 'Bez skrivenih troškova.',
    freeSmsFlights10: 'Besplatno 15 SMS-ova i 50 provjera letova mjesečno.',
    freeSmsFlights25: 'Besplatno 35 SMS-ova i 100 provjera letova mjesečno.',
    freeSmsFlights50: 'Besplatno 50 SMS-ova i 200 provjera letova mjesečno.',
  },
  successPaymentPage: {
    message: 'Plaćanje uspješno. Hvala vam.',
  },
  demoExpiring: {
    notification: 'Obavijest',
    expiredSubscriptionMessage:
      'Obavještavamo vas da je vaša pretplata na ovoj platformi istekla. Kako biste nastavili koristiti naše usluge, molimo vas da obnovite svoju pretplatu.',
  },
  login: {
    username: 'Korisničko ime',
    password: 'Lozinka',
    rememberMe: 'Zapamti me',
    login: 'Prijava',
    noAccount: 'Još nemate nalog? Možete se registrirati',
    forgetPassword: 'Zaboravili ste lozinku? Možete je resetirati',
    registerHere: 'ovdje',
    email: 'Email',
    enterEmail: 'Molimo unesite svoj email',
    enterPassword: 'Molimo unesite svoju novu lozinku',
    signInWithGoogle: 'Prijavite se putem Googlea',
  },
  registration: {
    register: 'Registracija',
    name: 'Naziv organizacije',
    address: 'Adresa',
    phoneNumber: 'Broj telefona',
    email: 'E-mail',
    companyId: 'OIB tvrtke',
    vat: 'PDV',
    language: 'Jezik',
    package: 'Paket',
    accept: 'Prihvaćam',
    terms: 'uvjete korištenja',
    successfullyRegistered: 'Korisnik je uspješno registriran. Primit ćete e-mail s poveznicom za verifikaciju.',
    haveAccount: 'Već imate korisnički nalog? Možete se prijaviti',
    loginHere: 'Ovdje',
    trialPeriodInfo:
      'Uključen je besplatni probni period od 14 dana. Naplata će uslijediti nakon završetka probnog perioda.',
  },
  driving: {
    drivingType: 'Tip rezervacije',
    general: 'Opće informacije',
    newDriving: 'Zakaži transfer',
    newDailyRent: 'Zakaži dnevni najam',
    areYouSure: 'Jeste li sigurni?',
    vehicleIsUnavailable: 'Vozilo je zauzeto',
    vehicleIsDamaged: 'Vozilo je oštećeno',
    sureAboutThisVehicle: 'Jeste li sigurni da želite odabrati ovo vozilo?',
    driverUnavaliable: 'Vozač je zauzet',
    sureAboutThisDriver: 'Jeste li sigurni da želite odabrati ovog vozača?',
    checkVehicleSeatAvailability: 'Ovo vozilo ne može primiti toliko putnika',
    cancelDriving: 'Jeste li sigurni da želite otkazati nalog za vožnju?',
    finishDriving: 'Jeste li sigurni da želite završiti nalog za vožnju?',
    noShowDriving: 'Jeste li sigurni da želite označiti narudžbu vožnje kao nepojavljivanje?',
    markDrivingAsStarted: 'Jeste li sigurni da želite označiti nalog za vožnju kao započet?',
    deleteDriving: 'Jeste li sigurni da želite izbrisati nalog za vožnju?',
    newTransfer: 'Novi transfer',
    driving: 'Narudžba vožnje',
    comments: 'Komentari',
    id: 'ID',
    locationFrom: 'Polazište',
    locationTo: 'Odredište',
    vehicleClass: 'Klasa vozila',
    vehicle: 'Vozilo',
    driver: 'Vozač',
    pickupTime: 'Vrijeme preuzimanja',
    clientConfirmed: 'Klijent potvrdio',
    roundTrip: 'Povratno putovanje',
    description: 'Opis',
    extraInfo: 'Dodatne informacije',
    drivingNotAccepted: 'Nisu prihvatili nalog za vožnju:',
    currency: 'Valuta',
    paymentMethod: 'Način plaćanja',
    price: 'Cijena',
    charged: 'Naplaćeno',
    paid: 'Plaćeno',
    distance: 'Udaljenost (km)',
    distanceMi: 'Udaljenost (milja)',
    expectedTime: 'Očekivano trajanje',
    waitingTime: 'Vrijeme čekanja (h)',
    EnterTheNumberOfHoursWaiting: 'Unesite broj sati čekanja',
    dropOffTime: 'Vrijeme dolaska',
    comebackTime: 'Vrijeme povratka',
    acceptUntil: 'Prihvatite do',
    drivingRequired: 'Je li narudžba vožnje obavezna',
    comment: 'Komentar',
    attachments: 'Privici',
    addFiles: '+ Dodajte datoteke',
    deleteFiles: 'Jeste li sigurni da želite izbrisati ovu datoteku?',
    passengers: 'Putnici',
    addPassenger: '+ Dodaj putnika',
    deletePassenger: 'Jeste li sigurni da želite izbrisati ovog putnika?',
    chooseClient: 'Odaberite klijenta',
    costs: 'Troškovi',
    checkpoints: 'Putne točke',
    addCheckpoint: 'Dodaj putnu točku',
    deleteCheckpoint: 'Jeste li sigurni da želite izbrisati ovu putnu točku?',
    waitingBoardText: 'Tekst na čekanju na ploči',
    flightNumber: 'Broj leta',
    flightInfo: 'Informacije o letu',
    createdBy: 'Nalog za vožnju kreiran <b>{time}</b> od <b>{user}</b>',
    client: 'Klijent',
    gnet: 'GNET',
    note: 'Napomena',
    noteDriver: 'Napomena za vozača',
    noteDispatcher: 'Napomena za dispečera',
    passenger: 'Putnik',
    fullName: 'Ime i prezime',
    phoneNumber: 'Broj telefona',
    email: 'Email',
    passengersNumber: 'Broj putnika',
    babySeatsNumber: 'Broj dječjih sjedala',
    suitcasesNumber: 'Broj kofera',
    stopsNumber: 'Broj zaustavljanja',
    steps: {
      mainInfo: 'Osnovne informacije',
      additionalInfo: 'Dodatne informacije',
      review: 'Pregled',
    },
    commissionAmount: 'Provizija',
    sendSms: 'Pošalji SMS',
    message: 'Poruka',
    acceptDriving: 'Jeste li sigurni da želite prihvatiti vožnju?',
    rejectDriving: 'Jeste li sigurni da želite odbiti vožnju?',
    calculatePriceViaZonePricing: 'Izračunaj cijenu preko cjenovne zone',
    disableCalculatePriceWithoutHours: 'Ne možete izračunati cijenu bez broja sati čekanja',
    disableCalculatePriceViaZonePricing:
      'Nije moguće izračunati cijenu preko cjenovne zone bez lokacije, valute i klase vozila',
    calculatePriceViaDistanceScales: 'Izračunajte cijenu prema udaljenosti',
    disableCalculatePriceViaDistanceScales:
      'Nije moguće izračunati cijenu po udaljenosti bez udaljenosti, valute i klase vozila',
    numberOfPassengers: 'Broj putnika',
    numberOfSuitcases: 'Broj kofera',
    vat: 'PDV',
    vatPercentage: 'PDV postotak',
    priceType: 'Tip cijene',
    accessibleForWheelchair: 'Pristupačno za invalidska kolica',
    priceForWaiting: 'Cijena čekanja',
    numberOfHours: 'Broj sati',
    getAQuote: 'Cijena na upit',
    selectPassenger: 'Izaberite putnika',
    clientId: 'ID klijenta',
    perDistance: '(po udaljenosti)',
    perHour: '(po satu)',
    templateName: 'Naziv predloška',
    visibleToClients: 'Vidljivo klijentima',
  },
  drivingStatus: {
    pending: 'Na čekanju',
    accepted: 'Prihvaćeno',
    rejected: 'Odbijeno',
    expired: 'Isteklo',
    canceled: 'Otkazano',
    draft: 'U obradi',
    done: 'Dovršeno',
    noShow: 'Nepojavljivanje',
    ongoing: 'Započeto',
  },
  drivingOrderType: {
    dailyRent: 'Dnevni najam',
    transfer: 'Prijenos',
    hourlyDaily: 'Po satu/danu',
    oneWayTransfer: 'Jednosmjerni transfer',
    fromAirport: 'Od aerodroma',
    toAirport: 'Do aerodroma',
  },
  flightInfo: {
    airlineName: 'Naziv zrakoplovne kompanije',
    flightNumber: 'Broj leta',
    departureAirport: 'Zračna luka polaska',
    departureCountry: 'Zemlja polaska',
    departureTime: 'Vrijeme polaska',
    arrivalAirport: 'Zračna luka dolaska',
    arrivalCountry: 'Zemlja dolaska',
    arrivalTime: 'Vrijeme dolaska',
    departureDelayed: 'Kašnjenje polaska',
    flightStatus: 'Status leta',
    percentageOfFlight: 'Postotak leta',
  },
  customValidations: {
    fileType: 'Vrsta datoteke .{extension} nije podržana',
    fileSize: 'Datoteke ne smiju biti veće od {size}',
    fieldRequired: 'Polje {field} je obavezno',
    fieldType: 'Polje {field} mora biti tipa {type}',
    fieldLengthMin: 'Polje {field} mora biti dulje od {length}',
    fieldLengthMax: 'Polje {field} mora biti kraće od {length}',
    fieldUnique: 'Polje {field} mora biti jedinstveno',
    fieldValid: 'Polje {field} mora biti valjano',
    noActiveSubscription: 'Nema aktivne pretplate',
    usersLimitExceeded: 'Dostignuto ograničenje korisnika',
    fieldBefore: '{field1} mora biti prije {field2}',
    flightNotExist: 'Let {field} ne postoji',
    gnetKeyNotValid: 'Gnet ključ koji ste unijeli nije važeći.',
    gnetPassengersRequired: 'Nalog za vožnju ne može biti poslan GNET-u bez putnika.',
    priceAndCurrencyRequired: 'Link za plaćanje ne može biti generiran bez cijene i valute',
    fieldValueNotSupported: 'Vrijednost polja {field} nije podržana',
    noCountryCode: 'Niste odabrali državu',
    noStripeForYourCountry: 'Stripe nije dostupan u vašoj zemlji',
    stripeAlreadyEnabled: 'Stripe je već aktiviran',
    fieldValueMin: 'Polje {field} mora biti {min} ili više',
    fieldValueMax: 'Polje {field} mora biti {max} ili manje',
    markAsDoneDate: 'Vrijeme polaska ne može biti u budućnosti',
    phoneNumberNotValidForSms:
      'Broj telefona nije valjan za slanje SMS-a. Valjan broj mora uključivati pozivni broj zemlje.',
    affiliateIdDoesNotExist: 'Organizacija s danim affiliate ID-em ne postoji.',
    numericDecimal: 'Polje {field} smije sadržavati samo brojčane znakove (dozvoljeni decimalni brojevi)',
  },
  fieldTypes: {
    string: 'tekst',
    integer: 'broj',
    date: 'datum',
  },
  names: {
    username: 'korisničko ime',
    password: 'lozinka',
    choose_client: 'izaberite klijenta',
    choose_month: 'izaberite mjesec',
    currency: 'valuta',
    payment_method: 'način plaćanja',
    vehicle_class: 'klasa vozila',
    first_name: 'ime',
    last_name: 'prezime',
    passport: 'putovnica',
    nationality: 'nacionalnost',
    plate_number: 'registracija',
    brand: 'marka automobila',
    number_of_passengers: 'broj putnika',
    fuel_consumption: 'potrošnja goriva',
    year_of_manufacture: 'godina proizvodnje',
    price_per_km: 'cijena po kilometru',
    price_per_mi: 'cijena po milji',
    price_per_hour: 'cijena po satu',
    price_per_waiting_hour: 'cijena po čekanju po satu',
    vehicle: 'vozilo',
    description: 'opis',
    time_from: 'vrijeme od',
    time_to: 'vrijeme do',
    name: 'ime',
    cost_type: 'tip troška',
    cost_time: 'vrijeme troška',
    price: 'cijena',
    phone_number: 'broj telefona',
    organisation: 'organizacija',
    role: 'uloga',
    image: 'slika',
    location_from: 'polazište',
    location_to: 'odredište',
    pickup_time: 'vrijeme preuzimanja',
    distance: 'udaljenost',
    waiting_time: 'vrijeme čekanja',
    comment: 'komentar',
    address: 'adresa',
    vat: 'PDV',
    company_id: 'ID tvrtke',
    company_personal_id: 'id tvrtke/osobna iskaznica',
    licence: 'licence',
    warrant_number: 'broj dozvole',
    seal: 'pečat',
    signature: 'potpis',
    email: 'email',
    percentage: 'postotak',
    organizationName: 'ime organizacije',
    package: 'paket',
    choose_currency: 'odaberi valutu',
    team_10_price: 'cijena za tim 10',
    team_10_paddle_price_id: 'ID cijene za tim 10 Paddle',
    team_25_price: 'cijena za tim 25',
    team_25_paddle_price_id: 'ID cijene za tim 25 Paddle',
    team_50_price: 'cijena za tim 50',
    team_50_paddle_price_id: 'ID cijene za tim 50 Paddle',
    team_10_price_monthly: 'cijena za tim 10',
    team_10_paddle_price_id_monthly: 'ID cijene za tim 10 Paddle',
    team_25_price_monthly: 'cijena za tim 25',
    team_25_paddle_price_id_monthly: 'ID cijene za tim 25 Paddle',
    team_50_price_monthly: 'cijena za tim 50',
    team_50_paddle_price_id_monthly: 'ID cijene za tim 50 Paddle',
    countries: 'zemlje',
    invoice_number: 'broj računa',
    status: 'status',
    total: 'ukupno',
    billed_at: 'datum izdavanja računa',
    articles_of_law: 'članci zakona',
    country: 'zemlja',
    referent: 'referent',
    language: 'jezik',
    location: 'lokacija',
    arrival_time: 'vrijeme dolaska',
    waiting_board_text: 'tekst na čekanju na ploči',
    flight_number: 'broj leta',
    gnet_id: 'GNET ID',
    sendViaGnet: 'Pošalji putem GNET-a',
    receiver_id: 'ID primatelja',
    preferred_vehicle_type: 'preferirani tip vozila',
    reservation_type: 'tip rezervacije',
    trip_duration_minutes: 'trajanje putovanja',
    client: 'klijent',
    client_type: 'vrsta klijenta',
    card_holder_name: 'ime vlasnika kartice',
    card_number: 'broj kartice',
    expiry_date: 'datum isteka',
    cvv: 'cvv',
    code: 'kod',
    symbol: 'simbol',
    quantity: 'količina',
    discount: 'popust',
    driving_type: 'tip rezervacije',
    full_name: 'puno ime',
    suitcases_number: 'broj kofera',
    passengers_number: 'broj putnika',
    baby_seats_number: 'broj dječjih sjedalica',
    stops_number: 'broj zaustavljanja',
    commission_amount: 'provizija',
    message: 'poruka',
    affiliate_id: 'affiliate id',
    coordinates: 'koordinate',
    zone_one: 'zona jedan',
    zone_two: 'zona dva',
    price_type: 'tip cijene',
    price_for_waiting: 'cijena za čekanje',
    number_of_hours: 'broj sati',
    zones: 'Zone',
    client_id: 'id klijenta',
    minutes: 'minute',
    started_at: 'počelo u',
    finished_at: 'završilo u',
    driving: 'vožnja',
    user: 'korisnik',
    time_zone: 'vremenska zona',
    charged: 'naplaćeno',
    offer_number: 'broj ponude za vožnju',
    drivings: 'vožnje',
  },
  weekDays: {
    monday: 'Ponedjeljak',
    tuesday: 'Utorak',
    wednesday: 'Srijeda',
    thursday: 'Četvrtak',
    friday: 'Petak',
    saturday: 'Subota',
    sunday: 'Nedjelja',
  },
  select: {
    noDataAvailable: 'Nema dostupnih podataka',
    selected: 'odabrano',
  },
  excelExport: {
    to_date_range: 'Izvješće ne može biti generirano za buduće datume. Molimo unesite valjane datume',
    overlap_range: 'Vrijeme završetka ne može biti veće od vremena početka.',
  },
  setPassword: {
    success: 'Email poslan',
    checkEmail: 'Provjerite svoj email za daljnje upute.',
    already_changed: 'Već promijenjeno',
    send_new_reset_request: 'Već ste promijenili lozinku s ovim linkom. Pošaljite novi zahtjev za resetiranje lozinke.',
  },
  statisticsPage: {
    reportType: 'Tip izvješća',
    reportTime: 'Vrijeme izvješća',
    vehicleUtilization: 'Iskorištenje vozila',
    costEffectiveness: 'Isplativost troškova',
    generalReport: 'Opće izvješće',
    clientsReport: 'Izvještaj po Klijentima',
  },
  profile: {
    yourProfile: 'Vaš profil',
    name: 'Ime',
    role: 'Uloga',
    username: 'Korisničko ime',
    password: 'Lozinka',
    phoneNumber: 'Broj telefona',
    email: 'Email',
    percentage: 'Postotak',
  },
  gpsMaps: {
    noTransfers: 'Nema tekućih ili nadolazećih transfera.',
    map: 'Karta',
    transfers: 'Prijenosi',
    drivers: 'Vozači',
    ongoing: 'U tijeku',
    upcoming: 'Nadolazeći',
    driving: 'Vožnja',
    driver: 'Vozač',
    vehicle: 'Vozilo',
    requestDriverLocation: 'Zatraži lokaciju vozača',
    lastRecordedLocation: 'Zadnja zabilježena lokacija',
  },
  driverStatus: {
    available: 'Dostupan',
    offline: 'Van mreže',
    inTransfer: 'U prijenosu',
  },
  onboardingTutorialMain: {
    languageStep: 'Ovdje možete postaviti željeni jezik za platformu.',
    notificationsStep: 'Ovdje možete pronaći svoje obavijesti.',
    addTransferStep: 'Kliknite ovo dugme za kreiranje nove rezervacije, jednosmjernih transfera i dnevnih renti.',
    sideMenuButtonStep: 'Kliknite ovu ikonu da prikažete ili sakrijete traku s izbornikom.',
    sideMenuExpStep:
      'Odavde možete navigirati glavnim dijelovima platforme: Rezervacije, Vozni park, Troškovi, Administracija, Računi, Statistika, itd.',
    sideMenuButtonCloseStep: 'Kliknite istu ikonu da sakrijete traku s izbornikom.',
    supportStep: 'Ako imate bilo kakva pitanja ili poteškoće, slobodno kontaktirajte korisničku podršku klikom ovdje.',
  },
  onboardingTutorialVehicles: {
    tableStep: 'Vozila će biti prikazana u ovoj tablici nakon što ih dodate.',
    searchStep: 'Možete lako pronaći vozila pretragom po marki, registarskoj oznaci, godini itd.',
    addStep: 'Dodajte nova vozila klikom na gumb „Dodaj“. Prvo dodajte sve „Klase vozila“ prije unosa vozila.',
  },
  onboardingTutorialStatistics: {
    typeStep:
      'U ovom dijelu možete pronaći sve statističke podatke prikazane u grafikonima. Ovdje odaberite tip statistike.',
    intervalStep: 'Izaberite interval za koji želite pregled statistike.',
    downloadStep: 'Također možete preuzeti i ispisati ove tablice.',
  },
  onboardingTutorialGps: {
    tabOneStep:
      'U GPS modulu možete pratiti vozače i transfere na jednom mjestu. Izaberite ovu karticu za prikaz trenutnih i nadolazećih transfera (sljedećih 24h).',
    tabTwoStep: 'Izaberite vozila da ih vidite na karti. Dostupna vozila možete dodijeliti transferima izravno odavde.',
    mapStep: 'Odaberite ili uklonite vozila klikom na njihov pin na karti.',
  },
  onboardingTutorialInvoices: {
    tableStep: 'Sve fakture bit će prikazane u ovoj tablici, od najnovijih do najstarijih.',
    searchStep: 'Jednostavno pronađite fakture pretragom po broju, iznosu, imenu klijenta, datumu kreiranja itd.',
    addStep: 'Kreirajte nove fakture klikom na gumb „Dodaj“.',
  },
  onboardingTutorialOffers: {
    tableStep: 'Ponude će biti prikazane u ovoj tablici nakon dodavanja, od najnovijih do najstarijih.',
    searchStep: 'Jednostavno pronađite ponude pretragom po broju, iznosu ili opisu.',
    addStep: 'Kreirajte nove ponude klikom na gumb „Dodaj“.',
  },
  onboardingTutorialResources: {
    infoStep: 'Prikazuje se koliko vam resursa preostaje, a možete kupiti dodatne SMS poruke po potrebi.',
    tableStep:
      'U ovoj tablici možete pronaći evidenciju i povijest svih korištenih resursa (poslane SMS poruke i provjereni letovi).',
    searchStep: 'Jednostavno pronađite resurse pretragom po sadržaju, korisniku ili datumu kreiranja.',
  },
  onboardingTutorialPassengerReviews: {
    tableStep: 'Recenzije putnika bit će prikazane u ovoj tablici, od najnovijih do najstarijih.',
    searchStep: 'Jednostavno pronađite recenzije putnika pretragom po broju ili komentaru putnika.',
  },
  onboardingTutorialPricingZones: {
    tableStep: 'Zone cijena bit će prikazane u ovoj tablici nakon dodavanja.',
    searchStep: 'Jednostavno pronađite zone cijena pretragom.',
    addStep: 'Kreirajte nove zone cijena klikom na gumb „Dodaj“.',
  },
  onboardingTutorialPricingZoneRelations: {
    tableStep: 'Relacije zona cijena (cijene) bit će prikazane u ovoj tablici nakon dodavanja.',
    searchStep: 'Jednostavno pronađite relacije zona cijena pretragom.',
    addStep:
      'Kreirajte nove relacije zona cijena (cijene) klikom na gumb „Dodaj“. Prvo dodajte „Zone cijena“ prije korištenja ove funkcije.',
  },
  onboardingTutorialPricingZoneHourlyRelations: {
    tableStep:
      'Sve cjenovne zone su navedene u ovoj tablici. Otvaranjem određene zone možete postaviti cijene za tu zonu.',
    searchStep: 'Jednostavno pronađite cjenovne zone pretraživanjem izravno.',
  },
  onboardingTutorialDashboard: {
    infoStep: 'Prikazuje sažetak statistike vaše organizacije za posljednjih 30 dana.',
    next3DaysStep: 'Rezervacije za sljedeća 3 dana bit će prikazane u ovoj tablici.',
    notPaidOrConfirmedStep:
      'Rezervacije za sljedećih 7 dana koje nisu potvrđene ili plaćene bit će prikazane u ovoj tablici.',
    next5DaysStep: 'Zauzeti vozači za sljedećih 5 dana bit će prikazani u ovoj tablici.',
    latestReviewsStep: 'Najnovije recenzije vožnji bit će prikazane u ovoj tablici.',
  },
  onboardingTutorialAffiliate: {
    tabStep: 'Promjenom kartica možete vidjeti svoje partnere, poslane zahtjeve ili primljene zahtjeve.',
    idStep: 'Ovdje možete pronaći svoj affiliate ID koji možete podijeliti s partnerima.',
    addStep: 'Kreirajte novi zahtjev za affiliate partnera klikom na gumb „Dodaj“.',
  },
  onboardingTutorialAdvancedSettings: {
    advancedSettings:
      'Klikom ovdje možete pristupiti Naprednim postavkama vaše organizacije i postaviti sve atribute i integracije.',
  },
  onboardingTutorialDistanceScales: {
    distanceScales:
      'Ovdje možete postaviti i upravljati cjenovnim skalama udaljenosti za svaku klasu vozila i valutu. Jednostavno definirajte cijene za određene udaljenosti (npr. 0-5 km, 5-15 km) za prilagodbu obračuna tarifa.',
  },
  advancedSettings: {
    advancedSettings: 'Napredne postavke',
    distanceUnit: 'Jedinica za udaljenost',
    distanceUnitDescription: 'Odaberite jedinicu za mjerenje udaljenosti.',
    kilometers: 'Kilometri',
    miles: 'Milje',
    dateFormat: 'Format datuma',
    dateFormatDescription: 'Odaberite format datuma koji želite koristiti.',
    ddmmyyyy: 'DD-MM-YYYY',
    mmddyyyy: 'MM-DD-YYYY',
    mandatoryDrivings: 'Obavezne vožnje',
    mandatoryDrivingsDescription: 'Odaberite ako želite da sve vožnje budu obavezne prema zadanim postavkama.',
    mandatoryDrivingsDefault: 'Obavezne Vožnje (Zadano)',
    paidDrivings: 'Plaćene vožnje',
    paidDrivingsDescription: 'Označite kako bi sve vožnje bile plaćene prema zadanim postavkama.',
    paidDrivingsDefault: 'Plaćene vožnje (Zadano)',
    notificationsEmail: 'Email za obavijesti',
    notificationsEmailDescription:
      'Promijenite zadanu email adresu za primanje obavijesti (ako ne želite koristiti email adresu svoje organizacije).',
    email: 'Email',
    articlesOfLaw: 'Članci zakona',
    integrations: 'Integracije',
    gnetId: 'GNET ID',
    gnetDescription:
      'GNET je sveobuhvatna platforma osmišljena za optimizaciju praćenja u stvarnom vremenu, upravljanja flotom i dispečerskih operacija za transportnu industriju.',
    makeRoutesAvailable: 'Omogući javni pristup rutama',
    makeRoutesAvailableDescription:
      'Aktivirajte svoj API ključ da integrirate LimoExpress s vašom web stranicom, ERP sustavom i još mnogo toga.',
    makePublicFormAvailable: 'Omogući javni obrazac',
    makePublicFormAvailableDescription:
      'Aktivirajte ovu opciju kako biste omogućili pristup javnoj stranici za rezervaciju, tako da vaši klijenti mogu pristupiti naprednom obrascu za rezervaciju i lako kreirati naloge. Link možete koristiti u biltenima, na web stranici, u letcima; to je jedinstveni link samo za vašu kompaniju. Alternativno, možete dobiti i dio koda za embedovanje obrasca za rezervaciju direktno na svojoj web stranici.',
    emailsEnabled: 'Omogućeni emailovi',
    emailsEnabledDefault: 'Omogućeni emailovi',
    emailsEnabledDescription: 'Označite da biste primali obavijesti putem email-a.',
    passengerReviewsEnabled: 'Omogućene ocjene putnika',
    passengerReviewsEnabledDefault: 'Omogućene ocjene putnika',
    passengerReviewsEnabledDescription:
      'Aktivacijom ove funkcionalnosti vaši putnici (ako je unesen email) dobit će email potvrdu nakon završene vožnje (označene kao Završeno) i imat će priliku dati ocjene (feedback) za vaše usluge.',
    vat: 'PDV',
    vatPercentage: 'Postotak PDV-a',
    vatPercentageDescription: 'Unesite postotak PDV-a.',
    invoicePaymentInstructions: 'Upute za plaćanje fakture',
    stripe: 'Stripe',
    stripeDescription:
      'Aktiviraj Stripe kako bi mogao naplaćivati svoje usluge klijentima. LimoExpress ne uzima proviziju.',
    dataExport: 'Izvoz podataka',
    dataExportDescription: 'Možete izvesti sve svoje podatke, i oni će vam biti poslati putem e-pošte.',
    sureAboutDataExport:
      'Jeste li sigurni da želite izvesti sve svoje podatke? Ako jeste, svi podaci će vam biti poslani putem e-pošte u naredna 2 sata.',
    defaultPriceType: 'Zadani tip cijene',
    defaultPriceTypeDescription: 'Postavite zadani tip cijene kako bi se odredio prikaz cijena.',
    NET: 'NETO',
    GROSS: 'BRUTO',
    link: 'Link',
    code: 'Kod',
    language: 'Jezik',
    chooseSize: 'Odaberite veličinu',
    small: 'Mala',
    medium: 'Srednja',
    large: 'Velika',
    zapier: 'Zapier',
    zapierDescription:
      'Aktivirajte Zapier i ostvarite pristup integracijama s više od 7.000 najpopularnijih sustava širom svijeta. Ova integracija vam omogućava kreiranje workflow-ova koji u potpunosti podržavaju vaše poslovne procese. Možete automatizirati poruke, fakture, kao i pokretati lančane akcije na temelju određenih aktivnosti na LimoExpress platformi.',
    zapierId: 'Zapier ID',
    publicBookingColor: 'Boja javnog obrasca',
    useSystemColors: 'Koristi podrazumijevanu boju',
    choseCustomColor: 'Odaberi prilagođenu boju',
    location: 'Lokacija',
    selectLocation: 'Odaberite lokaciju',
    changeLocation: 'Promijenite lokaciju',
    mapRadius: 'Radijus mape',
    mapRadiusDescription:
      'Postavite lokaciju i definirajte radijus kako biste ograničili dostupne lokacije prilikom kreiranja nove rezervacije. Samo adrese unutar ovog radijusa bit će dostupne za odabir.',
    publicMapRadius: 'Radijus mape javnog obrasca za rezervacije',
    publicMapRadiusDescription:
      'Postavite lokaciju i definirajte radijus za javni obrazac za rezervacije. Korisnici koji rezerviraju vožnju mogu birati samo lokacije za preuzimanje i odredište unutar ovog radijusa.',
    radiusDistance: 'Udaljenost radijusa (km)',
    radiusDistanceMi: 'Udaljenost radijusa (milje)',
  },
  drivingExcelCheckboxes: {
    drivingNumber: 'Broj naloga',
    drivingType: 'Tip rezervacije',
    drivingStatus: 'Status',
    locationFrom: 'Polazište',
    locationTo: 'Odredište',
    driver: 'Vozač',
    client: 'Klijent',
    plateNumber: 'Registarska oznaka',
    note: 'Opis',
    fuelConsumption: 'Potrošnja goriva',
    pickupTime: 'Vrijeme polaska',
    numberOfPassengers: 'Broj putnika',
    passengers: 'Putnici',
    price: 'Cijena',
    paid: 'Plaćeno',
    paymentMethod: 'Način plaćanja',
    waitingTime: 'Vrijeme čekanja (h)',
    distance: 'Razdaljina (km)',
    distanceMi: 'Udaljenost (milja)',
    selectAll: 'Odaberi sve',
    roundTrip: 'Povratno putovanje',
    babySeatsNumber: 'Broj dječjih sjedala',
    suitcasesNumber: 'Broj kofera',
    charged: 'Naplaćeno',
  },
  gnet: {
    gnetInfo: 'GNET informacije',
    requesterId: 'ID podnosioca zahtjeva',
    receiverId: 'ID primatelja',
    preferredVehicleType: 'Preporučeni tip vozila',
    reservationType: 'Tip rezervacije',
    runType: 'Tip vožnje',
    price: 'Cijena',
    currency: 'Valuta',
    noteDriver: 'Napomena za vozača',
    noteDispatcher: 'Napomena za dispečera',
    tripDurationMinutes: 'Trajanje putovanja (minuti)',
  },
  invoicesHeaders: {
    invoiceNumber: 'Broj računa',
    client: 'Klijent',
    totalPrice: 'Ukupna cijena',
    drivings: 'Vožnje',
    paid: 'Plaćeno',
    currency: 'Valuta',
    createdAt: 'Kreirano',
    actions: 'Akcije',
  },
  invoicesPage: {
    newItem: 'Novi unos računa',
    chooseClient: 'Odaberite klijenta',
    chooseDrivings: 'Odaberite vožnje',
    allDrivings: 'Svi nalozi',
    invoiceNumber: 'Broj Fakture',
    payUntil: 'Rok za Plaćanje',
    deleteMessage: 'Jeste li sigurni da želite izbrisati ovaj račun?',
    markInvoiceAsPaid: 'Jeste li sigurni da želite označiti ovaj račun kao plaćen?',
  },
  drivingOffersHeaders: {
    number: 'Broj ponude za vožnju',
    totalPrice: 'Ukupna cijena',
    description: 'Opis',
    paid: 'Plaćeno',
    currency: 'Valuta',
    createdAt: 'Kreirano',
    actions: 'Akcije',
  },
  drivingOffersPage: {
    newItem: 'Novi unos ponude za vožnju',
    item: 'Stavka',
    description: 'Opis',
    title: 'Naslov',
    vehicleClass: 'Klasa vozila',
    quantity: 'Količina',
    price: 'Cijena',
    discount: 'Popust',
    number: 'Broj ponude za vožnju',
    vat: 'PDV',
    deleteMessage: 'Jeste li sigurni da želite obrisati ovu ponudu za vožnju?',
    markDrivingOfferAsPaid: 'Jeste li sigurni da želite označiti ovu ponudu za vožnju kao plaćenu?',
  },
  modal: {
    reservationSuccessTitle: 'Uspjeh',
    reservationSuccessMessage: 'Zahtjev za rezervaciju je uspješno poslan. Netko će vas uskoro kontaktirati.',
    reservationErrorTitle: 'Obrazac onemogućen',
    reservationErrorMessage:
      'Obrazac je trenutno onemogućen od strane organizacije. Molimo kontaktirajte podršku ili pokušajte ponovno kasnije.',
  },
  dashboardPage: {
    drivingCountToday: 'Broj vožnji (danas)',
    drivingCountThisMonth: 'Broj vožnji (zadnjih 30 dana)',
    dailyRentsAndTransfers: 'Broj vožnji (dnevni najam/transferi)',
    drivingTotalAmount: 'Ukupan iznos',
    driversCount: 'Aktivni vozači (±7 dana)',
    passengerCount: 'Broj putnika (zadnjih 30 dana)',
  },
  reviewsHeaders: {
    rating: 'Ocjena',
    comment: 'Komentar',
    createdAt: 'Kreirano',
  },
  resourceOverviewHeaders: {
    actionType: 'Vrsta akcije',
    user: 'Korisnik',
    action: 'Akcija',
    createdAt: 'Kreirano',
  },
  resourceOverviewPage: {
    smsMessagesLeft: 'Broj preostalih SMS poruka',
    flightsCheckLeft: 'Broj preostalih provjera letova',
    sms: 'SMS',
    flightCheck: 'Provjera leta',
  },
  affiliateHeaders: {
    organisation: 'Organizacija',
    createdAt: 'Kreirano',
    actions: 'Akcije',
  },
  affiliatePage: {
    partners: 'Partneri',
    sentRequests: 'Poslani zahtjevi',
    receivedRequests: 'Primljeni zahtjevi',
    acceptRequest: 'Jeste li sigurni da želite prihvatiti ovaj zahtjev?',
    rejectRequest: 'Jeste li sigurni da želite odbiti ovaj zahtjev?',
    deletePartner: 'Jeste li sigurni da želite obrisati ovog partnera?',
    deleteRequest: 'Jeste li sigurni da želite obrisati zahtjev?',
    newItem: 'Novi zahtjev',
    affiliateId: 'Affiliate ID',
    registerNewPartner: 'Registrirajte novog partnera',
    registrationSuccessfull: 'Registracija uspješna',
    registrationSuccessfullDescription:
      'Affiliate partner je uspješno registriran! Obavijestite partnera da će dobiti email za verifikaciju računa i unos lozinke. Nakon toga, mogu početi koristiti platformu.',
  },
  affiliate: {
    affiliateInfo: 'Affiliate informacije',
    affiliate: 'Affiliate',
    partner: 'Partner',
    preferredVehicleType: 'Preferirani tip vozila',
    driverInfo: 'Informacije o vozaču',
    vehicleInfo: 'Informacije o vozilu',
    price: 'Cijena',
    currency: 'Valuta',
  },
  pricingZonesHeaders: {
    name: 'Naziv zone',
    code: 'Šifra',
    postalCode: 'Poštanski broj',
    actions: 'Akcije',
  },
  pricingZonesPage: {
    newItem: 'Novi unos cjenovne zone',
    editItem: 'Izmjena cjenovne zone',
    id: 'ID',
    name: 'Naziv zone',
    code: 'Šifra',
    deleteMessage: 'Jeste li sigurni da želite izbrisati ovu cjenovnu zonu?',
    postalCode: 'Poštanski broj',
    postalCodeDescription: 'Definirajte zonu na karti pomoću poštanskog broja',
    postalCodeDisabled: 'Molimo unesite poštanski broj',
  },
  pricingZoneRelationsHeaders: {
    zoneOne: 'Zona jedan',
    zoneTwo: 'Zona dva',
    client: 'Klijent',
    actions: 'Akcije',
  },
  pricingZoneRelationsPage: {
    newItem: 'Unos nove veze cjenovnih zona',
    editItem: 'Izmjena veze cjenovnih zona',
    zone: 'Zona',
    zoneOne: 'Zona jedan',
    zoneTwo: 'Zona dva',
    chooseClient: 'Odaberite klijenta',
    chooseClientFrom: 'Kloniraj od klijenta',
    chooseClientTo: 'Kloniraj za klijenta',
    cloneItem: 'Kloniranje veze cjenovnih zona',
    zoneRelations: 'Veze cjenovnih zona',
    cloneItemHourly: 'Kloniranje cijena po satu',
    zones: 'Zone',
    fromTo: 'Od - Do',
    discountPercentage: 'Postotak popusta (%)',
    deleteMessage: 'Jeste li sigurni da želite obrisati ovu vezu cjenovnih zona?',
  },
  referralPrize: {
    referToAFriend: 'Preporučite prijatelju',
    referToAFriendDescription:
      'Pozovite svoje prijatelje da se pridruže platformi unosom njihove email adrese ispod. Oni će primiti personalizovanu pozivnicu za registraciju i početak korištenja platforme. Alternativno, možete podijeliti vaš jedinstveni link za registraciju direktno s drugima! Kada se neko registrira putem vašeg linka ili pozivnice, dobit ćete nagradu za preporuku kao znak zahvalnosti. Počnite pozivati i dijeliti već danas!',
  },
  distanceScalesPage: {
    distanceScales: 'Cjenovne skale po udaljenosti',
  },
  passengersPage: {
    newItem: 'Unos novog putnika',
    deletePassenger: 'Obriši putnika',
    deleteMessage: 'Jeste li sigurni da želite obrisati putnika ',
  },
  formsAndTables: {
    actions: 'Akcije',
    firstName: 'Ime',
    lastName: 'Prezime',
    email: 'Email',
    phoneNumber: 'Broj telefona',
    passport: 'Putovnica',
    country: 'Država',
    nationality: 'Nacionalnost',

    logType: 'Tip',
    minutes: 'Minute',
    startedAt: 'Počelo u',
    finishedAt: 'Završeno u',
    driving: 'Vožnja',
    user: 'Korisnik',
    createdAt: 'Kreirano u',
    exportExcel: 'Izvezi u Excel',
    description: 'Opis',
  },

  workLogsPage: {
    newItem: 'Novi unos evidencije aktivnosti',
    deleteMessage: 'Jeste li sigurni da želite obrisati ovu evidenciju aktivnosti?',
    regular: 'Redovna',
  },
};
