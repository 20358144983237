import { getHttpClient } from './client';

export const getAllWorkLogs = async (params) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/work-log';
  let queryString = '?';
  if (params.from_date) {
    queryString += `from=${params.from_date}`;
  }
  if (params.to_date) {
    queryString += `&to=${params.to_date}`;
  }
  if (params.user_id) {
    queryString += `&user_id=${params.user_id}`;
  }
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const saveWorkLog = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/work-log', 'POST', data).then((response) => response.data);
};

export const updateWorkLog = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/work-log', 'POST', data).then((response) => response.data);
};

export const deleteWorkLog = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/work-log/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const exportExcel = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/export-work-logs';
  return await getHttpClient(baseUrl, 'POST', data).then((response) => response.data);
};
