export default {
  sidebar: {
    fleet: 'Fleet',
    vehicles: 'Vehicles',
    vehiclesClasses: 'Vehicle classes',
    administration: 'Administration',
    currencies: 'Currencies',
    currenciesAdministration: 'Currency administration',
    paymentTypes: 'Payment methods',
    paymentTypesAdministration: 'Payment methods administration',
    users: 'Users',
    usersAdministration: 'Users administration',
    clients: 'Clients',
    clientsAdministration: 'Clients administration',
    organizations: 'Organizations',
    organizationsAdministration: 'Administration of organizations',
    saasAdmin: 'Saas admin',
    administrators: 'Administrators',
    saasAdminAdministration: 'Saas admin administration',
    statistics: 'Statistics',
    generalOverview: 'General report',
    payment: 'Payment',
    pricingRegions: 'Pricing regions',
    pricingRegionsAdministration: 'Pricing regions administration',
    help: 'Help',
    drivings: 'Driving orders',
    calendar: 'Calendar',
    tabularView: 'Tabular view',
    drivingTemplates: 'Reservation templates',
    expenses: 'Costs',
    vehicleExpensesAdministration: 'Administration of vehicle costs',
    vehicleMaintenance: 'Vehicle malfunctions',
    vehicleMaintenanceAdministration: 'Vehicle malfunctions administration',
    organization: 'Organization',
    organizationAdminstration: 'Administration of organization',
    packages: 'Packages',
    packagesChange: 'Packages change',
    logout: 'Logout',
    logoutText: 'Are you sure you want to log out?',
    unaccessibleRoutes: "You can't access this page. Upgrade the package for full access.",
    transactions: 'Transactions',
    subscription: 'Subscription',
    gpsMap: 'GPS map',
    team: 'Team',
    teamAdministration: 'Team administration',
    invoices: 'Invoices',
    invoicesAdministration: 'Invoice administration',
    drivingOffers: 'Offers',
    drivingOffersAdministration: 'Offers administration',
    dashboard: 'Dashboard',
    dashboardAdministration: 'Dashboard administration',
    resourceOverview: 'Resource overview',
    resourceOverviewAdministration: 'Resource overview administration',
    rides: 'Rides',
    passengerReviews: 'Passenger reviews',
    passengerReviewsAdministration: 'Passenger reviews administration',
    affiliate: 'Affiliate',
    affiliateAdministration: 'Affiliate administration',
    pricingAndRates: 'Pricing and rates',
    pricingZones: 'Zones and areas',
    pricingZonesAdministration: 'Zones and areas administration',
    pricingPerZones: 'Pricing per zones',
    pricingPerZonesAdministration: 'Pricing per zones administration',
    pricingPerHour: 'Pricing per hour',
    pricingPerHourAdministration: 'Pricing per hour administration',
    availability: 'Availability',
    availabilityAdministration: 'Availability of drivers and vehicles',
    distanceScales: 'Distance scales',
    distanceScalesAdministration: 'Distance scales administration',
    passengers: 'Passengers',
    passengersAdministration: 'Passengers administration',
    workLogs: 'Activity logs',
    workLogsAdministration: 'Activity logs administration',
    advancedSettings: 'Advanced settings',
    advancedSettingsAdministration: 'Advanced settings administration',
  },
  header: {
    organization: 'Organization',
    // newDriving: 'Driving order',
    newDriving: 'Reservation',
    dailyRent: 'Daily rent',
    transfer: 'Transfer',
    notifications: 'Notifications',
    changeLanguage: 'Change language',
    help: 'Help',
    fromTemplate: 'From template',
    back: 'Back',
  },
  footer: {
    poweredBy: 'Powered by',
    subscriptionTo: 'Your subscription to',
    trialEndsAt: 'Your trial period for',
    packageExpiresIn: 'package expires in',
    packagePastDue: 'is past due.',
    clickToPay: 'Click to pay now.',
    affiliateUser: 'If you want to unlock all platform features, you need to subscribe ',
    here: 'HERE',
  },
  buttons: {
    ok: 'Ok',
    yes: 'Yes',
    no: 'No',
    save: 'Save',
    send: 'Send',
    sendMessage: 'Send message',
    edit: 'Edit',
    add: 'Add',
    confirm: 'Confirm',
    cancel: 'Cancel',
    cancelDriving: 'Cancel driving',
    markAsDone: 'Mark as done',
    markAsNoShow: 'Mark as no show',
    deleteDriving: 'Delete driving',
    newOrganisation: 'New organization',
    changePackage: 'Packages change',
    downloadStats: 'Download statistics',
    downloadPDF: 'Download PDF',
    today: 'Today',
    choose: 'Choose',
    login: 'Log in',
    generateFile: 'Generate contract',
    generateDrivingOrder: 'Print driving order',
    downloadFile: 'Download file',
    activate: 'Activate',
    deactivate: 'Deactivate',
    showImage: 'Show image',
    showDetails: 'Show details',
    actions: 'Actions',
    delete: 'Delete',
    open: 'Open',
    show: 'Show',
    options: 'Options',
    prev: 'Previous',
    next: 'Next',
    skip: 'Skip',
    finish: 'Finish',
    settings: 'Settings',
    cancelSubscription: 'Cancel subscription',
    backToEditOrganization: 'Back to edit organization',
    payNow: 'Pay now',
    back: 'Go back',
    integrationRoutes: 'Integration routes',
    transactions: 'Transactions',
    articlesOfLaw: 'Articles of law',
    export: 'Export',
    duplicate: 'Make a copy',
    makeReturnTrip: 'Make a return trip',
    selectOrganisation: 'Select organisation',
    advancedSettings: 'Advanced settings',
    accept: 'Accept',
    reject: 'Reject',
    sendViaGnet: 'Send via GNET',
    printInvoice: 'Print invoice',
    enable: 'Enable',
    disable: 'Disable',
    generatePaymentLink: 'Generate payment link',
    addItem: '+ Add item',
    visitWebsite: 'Visit our website',
    buyMoreSms: 'Buy more SMS',
    forward: 'Forward',
    sendViaAffiliate: 'Send via Affiliate',
    markInvoiceAsPaid: 'Mark invoice as paid',
    markDrivingOfferAsPaid: 'Mark driving offer as paid',
    selectAll: 'Select all',
    viaAffiliateId: 'Add via affiliate id',
    registerPartner: 'Register partner',
    createNew: 'Create new',
    clone: 'Clone',
    cloneExisting: 'Clone existing',
    copyLink: 'Copy link',
    markAsStarted: 'Mark as started',
    copyReferenceLink: 'Copy reference link',
  },
  tooltips: {
    save: 'Save',
    edit: 'Edit',
    delete: 'Delete',
    showImage: 'Show image',
    open: 'Open',
    show: 'Show',
    showDetails: 'Show details ',
    downloadFile: 'Download file',
    generateFile: 'Generate contract',
    generateDrivingOrder: 'Print driving order',
    activate: 'Activate',
    deactivate: 'Deactivate',
    cancel: 'Cancel',
    cancelDriving: 'Cancel driving',
    cannotMarkAsCanceled: 'The order cannot be marked as canceled because it has already been accepted',
    markAsDone: 'Mark as done',
    cannotMarkAsDone: 'The order cannot be marked as completed because it has not yet occurred',
    markAsNoShow: 'Mark as no show',
    cannotMarkAsNoShow: 'The order cannot be marked as no show because it has not yet occurred',
    deleteDriving: 'Delete driving',
    cannotDelete: 'We are sorry, but for security reasons, users cannot be deleted while they are currently logged in.',
    cannotDeactivate:
      'We are sorry, but for security reasons, users cannot be deactivated while they are currently logged in.',
    duplicate: 'Make a copy',
    makeReturnTrip: 'Make a return trip',
    refresh: 'Refresh',
    selectOrganisation: 'Select organisation',
    sendViaGnet: 'Send via GNET',
    receivedViaGnet: 'Received via GNET',
    sentViaGnet: 'Sent via GNET',
    printInvoice: 'Print invoice',
    optionsDisabled: 'You have to reject or accept the order to access the option menu',
    cannotMakeReturnTrip: 'You cannot make a return trip for daily rent',
    cannotSendGnetOrderViaGnet: 'You cannot send the gnet order via gnet',
    cannotSendViaGnetWithoutGnetId: 'You cannot send the order via gnet without gnet id',
    cannotPrintInvoiceWithoutClient: 'You cannot print invoice without choosing client',
    cannotMarkAsDoneStatusRejected:
      'The order cannot be marked as completed because it has already been marked as rejected',
    cannotMarkAsDoneStatusCanceled:
      'The order cannot be marked as completed because it has already been marked as canceled',
    cannotMarkAsDoneStatusDone:
      'The order cannot be marked as completed because it has already been marked as completed',
    cannotMarkAsDoneStatusNoShow:
      'The order cannot be marked as completed because it has already been marked as no show',
    cannotMarkAsNoShowStatusRejected:
      'The order cannot be marked as no show because it has already been marked as rejected',
    cannotMarkAsNoShowStatusCanceled:
      'The order cannot be marked as no show because it has already been marked as canceled',
    cannotMarkAsNoShowStatusDone:
      'The order cannot be marked as no show because it has already been marked as completed',
    cannotMarkAsNoShowStatusNoShow:
      'The order cannot be marked as no show because it has already been marked as no show',
    cannotMarkAsCanceledGnet: 'The order cannot be marked as canceled because it is recieved via gnet',
    cannotMarkAsCanceledStatusRejected:
      'The order cannot be marked as canceled because it has already been marked as rejected',
    cannotMarkAsCanceledStatusExpired:
      'The order cannot be marked as canceled because it has already been marked as expired',
    cannotMarkAsCanceledStatusCanceled:
      'The order cannot be marked as canceled because it has already been marked as canceled',
    cannotMarkAsCanceledStatusDraft:
      'The order cannot be marked as canceled because it has already been marked as draft',
    cannotMarkAsCanceledStatusCompleted:
      'The order cannot be marked as canceled because it has already been marked as completed',
    cannotMarkAsCanceledStatusNoShow:
      'The order cannot be marked as canceled because it has already been marked as no show',
    cannotDeleteDrivingSentViaGnet: 'The order cannot be deleted because it is sent via gnet',
    cannotDeleteDrivingReceivedViaGnet: 'The order cannot be deleted because it is received via gnet and accepted',
    generatePaymentLink: 'Generate payment link',
    cannotGeneratePaymentLInk: 'Payment link cannot be generated because you have not activated Stripe',
    disabledExport: 'You can export data once in 24 hours',
    sendSms: 'Send SMS',
    accept: 'Accept',
    reject: 'Reject',
    sendViaAffiliate: 'Send via affiliate',
    receivedViaAffiliate: 'Received via affiliate',
    sentViaAffiliate: 'Sent via affiliate',
    cannotSendViaAffiliate: 'You cannot send the affiliate driving order through the affiliate.',
    cannotForwardDrivingOrder: 'You cannot forward a driving order that is in the past.',
    cannotMarkAsCanceledAffiliate: 'The order cannot be marked as canceled because it is recieved via affiliate',
    cannotMarkAsCanceled12Hours:
      'The order cannot be marked as canceled because 12 hours have passed since the pickup time.',
    cannotDeleteActiveDriving: 'The active driving order cannnot be deleted',
    noImageToShow: 'No image to show',
    disabledForAffiliateUser:
      "Your account is of type 'Affiliate'. This action is available to subscribed [Regular] accounts. Go to the link in footer to subscribe.",
    markInvoiceAsPaid: 'Mark invoice as paid',
    markDrivingOfferAsPaid: 'Mark driving offer as paid',
    invoiceIsPaid: 'Invoice is already marked as paid',
    drivingOfferIsPaid: 'Driving offer is already marked as paid',
    selectAreaToDelete: 'Select area that you want to delete',
    deleteSelectedArea: 'Delete selected area',
    markAsStarted: 'Mark as started',
    cannotMarkAsStarted: 'The order cannot be marked as started because it has not yet occurred',
    cannotMarkAsStartedStatusStarted:
      'The order cannot be marked as started because it has already been marked as started',
    cannotMarkAsStartedStatusRejected:
      'The order cannot be marked as started because it has already been marked as rejected',
    cannotMarkAsStartedStatusCanceled:
      'The order cannot be marked as started because it has already been marked as canceled',
    cannotMarkAsStartedStatusDone:
      'The order cannot be marked as started because it has already been marked as completed',
    cannotMarkAsStartedStatusNoShow:
      'The order cannot be marked as started because it has already been marked as no show',
    copyReferenceLink: 'Copy reference link',
    noTemplatesAvailable: 'No reservation templates available',
  },
  snackbar: {
    close: 'Close',
    savedSuccessfully: 'Saved successfully',
    excelDownloadedSuccessfully: 'Downloaded successfully',
    deletedSuccessfully: 'Deleted successfully',
    sentSuccessfully: 'Sent successfully',
    statusChanged: 'Status changed successfully',
    activeStatusChanged: 'Active status changed successfully',
    defaultStatusChanged: 'Default status changed successfully',
    primaryStatusChanged: 'Primary status changed successfully',
    anErrorOccured: 'An error occurred, report to support',
    loadingCommentsFailed: 'Loading comments failed',
    drivingCanceled: 'Driving order is successfully canceled',
    drivingFinished: 'Driving order is successfully completed',
    drivingStarted: 'Driving order is successfully marked as started',
    drivingNoShow: 'Driving order is successfully marked as no show',
    drivingDeleted: 'Driving order is successfully deleted',
    drivingAccepted: 'Driving order is successfully accepted',
    drivingRejected: 'Driving order is successfully rejected',
    planChanged: 'Plan successfully changed',
    planChangeFailed: 'It is not possible to change plan',
    saveChangesFirst: 'First save the changes you made earlier',
    makeRoutesPublic: 'Routes are publicly accessible',
    makeRoutesNotPublic: 'Routes are no longer publicly accessible',
    makePublicLinkActive: 'Public link is now available',
    makePublicLinkInactive: 'Public link is no longer available',
    integrationKeyCopied: 'Integration key copied',
    copyToken: 'Manually copy the token',
    publicLinkCopied: 'Public link copied',
    copyPublicLink: 'Manually copy the public link',
    referenceLinkCopied: 'Reference link copied',
    copyReferenceLink: 'Manually copy the reference link',
    organisationChanged: 'Company changed successfully',
    changeFailed: 'The change failed, please try again',
    loginFailed: 'Username or password is incorrect',
    organisationSuspended: 'Your organization is suspended',
    userEmailDoesNotExists: 'User with this email does not exists',
    canceledSubscrSuccessfully: 'Subscription successfully canceled',
    toManyRequests: 'To many requests. Try again in a minute.',
    visibleStatusChanged: 'Visible status changed successfully',
    noInternetConnection: 'No internet. Please check your internet connection.',
    backOnline: 'You are back online.',
    driverNotifiedToTurnGpsOn: 'Driver notified to turn GPS on',
    savedSuccessfullyWithGnetError: 'GNET synchronization failed, driving order saved sucessfully.',
    logoutFromAnotherDevice: 'You have been logged out from another device.',
    stripeEnabled: 'Stripe successfully activated',
    stripeDisabled: 'Stripe successfully deactivated',
    finishStripeConnect: 'Please finish your Stripe Setup',
    requestSentSuccessfully: 'Request sent successfully',
    pricingZoneNoMatch: 'We cannot find corresponding pricing zones to calculate the price.',
    areaAlreadyExists: 'This area already exists',
    noDataForThisPostalCode: 'No data available for this postal code',
    affiliateRequestSentSuccessfully: 'Organization already exists, affiliate request was sent',
    organizationRegisteredSuccessfully: 'Organization registered succesfully',
    organizationIsAlreadyAPartner: 'Organization is already a partner',
    linkCopied: 'Link copied',
    recordAlreadyExists: 'This record already exists.',
    tooManyUsers: 'You have too many users for this package',
    priceCalculationFailed: 'Failed to calculate the price',
    workLogTimeRequired: 'You must enter start and finish time, or minutes',
    zapierEnabled: 'Zapier successfully activated',
    zapierDisabled: 'Zapier successfully deactivated',
    lastUserRoleChange:
      "You cannot change this user's role because you would be left without any operators or dispatchers.",
  },
  filters: {
    filter: 'Filter',
    currentYear: 'Current year',
    lastYear: 'Last year',
    lastQuarter: 'Last quarter',
    lastThreeYears: 'Last three years',
    filterByDriver: 'Filter by driver',
    filterByVehicle: 'Filter by vehicle',
    filterByClient: 'Filter by clients',
    day: 'Day',
    week: 'Week',
    month: 'Month',
    vehicle: 'Vehicle',
    driving: 'Driving',
    user: 'User',
    fromDate: 'From date',
    toDate: 'To date',
    threeMonths: 'Three months',
    halfYear: 'Half year',
    year: 'Year',
  },
  months: {
    jan: 'January',
    feb: 'February',
    mar: 'March',
    apr: 'April',
    may: 'May',
    jun: 'June',
    jul: 'July',
    aug: 'August',
    sep: 'September',
    oct: 'October',
    nov: 'November',
    dec: 'December',
    January: 'January',
    February: 'February',
    March: 'March',
    April: 'April',
    May: 'May',
    June: 'June',
    July: 'July',
    August: 'August',
    September: 'September',
    October: 'October',
    November: 'November',
    December: 'December',
  },
  usersPageHeaders: {
    firstName: 'First name',
    lastName: 'Last name',
    username: 'User name',
    email: 'Email',
    phoneNumber: 'Phone number',
    role: 'Role',
    device: 'Device',
    operatingSystem: 'Operating system',
    browser: 'Browser',
    lastActive: 'Last active',
    active: 'Active',
    actions: 'Actions',
  },
  usersRoles: {
    masterAdmin: 'Master administrator',
    manager: 'Manager',
    driver: 'Driver',
    operator: 'Operator',
    externalAssociate: 'External associate',
    sales: 'Sales',
    salesLead: 'Sales lead',
    gnet: 'GNET',
    customer: 'Portal user',
    publicUser: 'Public user',
    zapier: 'Zapier',
  },
  masterUsersPageHeaders: {
    firstName: 'First name',
    lastName: 'Last name',
    username: 'User name',
    email: 'Email',
    phoneNumber: 'Phone number',
    role: 'Role',
    organisation: 'Organization',
    active: 'Active',
    actions: 'Actions',
  },
  usersPage: {
    newItem: 'New user entry',
    editItem: 'Edit User',
    id: 'Id',
    firstName: 'First name',
    lastName: 'Last name',
    username: 'User name',
    password: 'Password',
    phoneNumber: 'Phone number',
    role: 'Role',
    client: 'Client',
    email: 'Email',
    organisation: 'Organization',
    percentage: 'Percentage',
    externalAssociate: 'External associate',
    image: 'Image',
    deleteUser: 'Delete user',
    deleteMessage: 'Are you sure you want to delete the user ',
    emailChanged: 'Email change',
    verificationEmailSent:
      'The request for changing the email address has been sent. To complete the process and ensure the security of the user account, the new email address must be verified by clicking on the verification link sent to the new email. The email address will not be changed until it is successfully verified.',
    passengersInfoAvailableToDriver: 'Passengers informations available to driver',
    colorPicker:
      'You can select a unique color for each user. It will be used on the availability page for easier identification.',
  },
  clientsPageHeaders: {
    name: 'Name',
    phoneNumber: 'Phone number',
    address: 'Address',
    email: 'Email',
    companyId: 'Company ID/Personal ID',
    clientType: 'Client type',
    percentage: 'Percentage',
    active: 'Active',
    actions: 'Actions',
  },
  clientsPage: {
    newItem: 'New client entry',
    editItem: 'Edit User',
    id: 'Id',
    name: 'Name',
    address: 'Address',
    phoneNumber: 'Phone number',
    email: 'Email',
    companyId: 'Company ID/Personal ID',
    clientType: 'Client type',
    individual: 'Individual',
    business: 'Business entity',
    percentage: 'Percentage',
    deleteClient: 'Delete client',
    deleteMessage: 'Are you sure you want to delete the client ',
    tabs: {
      general: 'General',
      transfers: 'Transfers',
      users: 'Users',
      invoices: 'Invoices',
      cards: 'Cards',
    },
    publicId: 'Public ID',
    referenceLink: 'Reference link',
    publicFormNotEnabled:
      'If you want to access the reference link, go to the advanced settings and make the public form available',
  },
  drivingsHeaders: {
    drivingNumber: 'Driving order number',
    drivingType: 'Booking type',
    drivingStatus: 'Status',
    locationFrom: 'Starting point',
    locationTo: 'Destination',
    vehicleClass: 'Vehicle class',
    vehicle: 'Vehicle',
    driversName: 'Driver',
    startTime: 'Pickup time',
    actions: 'Actions',
    exportExcel: 'Export excel',
    price: 'Price',
    totalPrice: 'Total price',
    confirmed: 'Confirmed',
    paid: 'Paid',
    numberOfDrivings: 'Number of driving orders',
    templateName: 'Template name',
    visibleToClients: 'Visible to clients',
  },
  drivingTemplatesPage: {
    newItem: 'New entry of reservation template ',
    editItem: 'Change of reservation template',
    deleteDrivingTemplate: 'Delete reservation template',
    deleteMessage: 'Are you sure you want to delete this reservation template?',
  },
  vehiclesHeaders: {
    plateNumber: 'Registration number',
    brand: ' Vehicle Brand',
    vehicleClass: 'Vehicle class',
    passengersNumber: 'Passengers number',
    fuelConsumption: 'Car fuel consumption',
    yearOfManufacture: 'Year of manufacture',
    pricePerKm: 'Price per km',
    pricePerMi: 'Price per mile',
    pricePerHour: 'Price per hour',
    pricePerWaitingHour: 'Price per waiting hour',
    active: 'Active',
    actions: 'Actions',
  },
  vehiclesPage: {
    id: 'Id',
    newItem: 'New vehicle entry',
    editItem: 'Change of vehicle',
    vehiclePlates: 'Registration marks',
    vehicleBrand: 'Vehicle brand',
    vehicleClass: 'Vehicle class',
    passengersNumber: 'Passenger number',
    fuelConsumption: 'Car fuel consumption (100km)',
    fuelConsumptionMi: 'Car fuel consumption (100mi)',
    manufactureYear: 'Year of manufacture',
    price: 'Price per km',
    priceMi: 'Price per mile',
    hourPrice: 'Price per hour',
    image: 'Image',
    hourWaitingPrice: 'Price per waiting hour',
    deleteMessage: 'Are you sure you want to delete this vehicle?',
    tabs: {
      general: 'General',
      transfers: 'Transfers',
      costs: 'Costs',
      damages: 'Damages',
    },
    licence: 'Licence number',
    color: 'Color',
    colorPicker:
      'You can select a unique color for each vehicle. It will be used on the availability page for easier identification.',
  },
  vehicleClassesHeaders: {
    vehicleClass: 'Vehicle class',
    numberOfPassengers: 'Number of passengers',
    numberOfSuitcases: 'Number of suitcases',
    pricePerKm: 'Price per km',
    pricePerMi: 'Price per mile',
    pricePerHour: 'Price per hour',
    pricePerWaitingHour: 'Price per waiting hour',
    availableForPublicBookingForm: 'Available for public booking form',
    active: 'Active',
    actions: 'Actions',
  },
  vehicleClassesPage: {
    newItem: 'New vehicle class entry',
    editItem: 'Vehicle class change',
    id: 'Id',
    vehicleClass: 'Vehicle class',
    numberOfPassengers: 'Number of passengers',
    numberOfSuitcases: 'Number of suitcases',
    availableForPublicBookingForm: 'Available for public booking form',
    price: 'Price per km',
    priceMi: 'Price per mile',
    hourPrice: 'Price per hour',
    image: 'Image',
    hourWaitingPrice: 'Price per waiting hour',
    deleteMessage: 'Are you sure you want to delete this vehicle class?',
  },
  expensesHeaders: {
    name: 'Name',
    vehicle: 'Vehicle',
    type: 'Type',
    description: 'Description',
    price: 'Price',
    expenseDate: 'Date',
    actions: 'Action',
    driving: 'Driving',
    exportExcel: 'Export excel',
  },
  vehicleExpensesTypes: {
    minorService: 'Minor service',
    majorService: 'Major service',
    fuel: 'Fuel',
    tires: 'Tires',
    parking: 'Parking',
    other: 'Other',
  },
  expensesPage: {
    newItem: 'New cost entry ',
    editItem: 'Cost Change',
    id: 'Id',
    name: 'Name',
    vehicle: 'Vehicle',
    costType: 'Cost type',
    price: 'Price',
    currency: 'Currency',
    costTime: 'Cost time',
    description: 'Description',
    deleteMessage: 'Are you sure you want to delete this cost?',
    costTypes: {
      'Mali servis': 'Minor service',
      'Veliki servis': 'Major service',
      Gorivo: 'Fuel',
      Gume: 'Tires',
      Parking: 'Parking',
      Ostalo: 'Other',
    },
  },
  vehicleMaintenanceHeaders: {
    name: 'Vehicle',
    details: 'Details',
    dateFrom: 'Date from',
    dateTo: 'Date to',
    actions: 'Actions',
  },
  vehicleMaintenancePage: {
    newItem: 'New entry of vehicle breakdown ',
    editItem: 'Change of vehicle breakdown',
    id: 'Id',
    vehicle: 'Vehicle',
    description: 'Description',
    dateFrom: 'Time from',
    dateTo: 'Time to',
    deleteMessage: 'Are you sure you want to delete this vehicle breakdown?',
  },
  customerCardsHeaders: {
    cardholderName: 'Cardholder name',
    cardNumber: 'Card number',
    expiryDate: 'Expiry date',
    cvv: 'CVV',
    actions: 'Actions',
  },
  customerCardsPage: {
    newItem: 'New entry of card',
    editItem: 'Change of card',
    cardholderName: 'Cardholder name',
    cardNumber: 'Card number',
    expiryDate: 'Expiry date',
    cvv: 'CVV',
    deleteCard: 'Delete card',
    deleteMessage: 'Are you sure you want to delete this card?',
  },
  pricingRegionsHeaders: {
    name: 'Name',
    actions: 'Actions',
  },
  pricingRegionsPage: {
    newItem: 'New entry of pricing region',
    editItem: 'Change of pricing region',
    name: 'Name',
    chooseCurrency: 'Choose currency',
    soloPrice: 'Solo price - Year',
    soloPaddlePriceId: 'Solo paddle price id - Year',
    team5Price: 'Team 5 price - Year',
    team5PaddlePriceId: 'Team 5 paddle price id - Year',
    team10Price: 'Team 10 price - Year',
    team10PaddlePriceId: 'Team 10 paddle price id - Year',
    team25Price: 'Team 25 price - Year',
    team25PaddlePriceId: 'Team 25 paddle price id - Year',
    team50Price: 'Team 50 price - Year',
    team50PaddlePriceId: 'Team 50 paddle price id - Year',
    soloPriceMonthly: 'Solo price - Month',
    soloPaddlePriceIdMonthly: 'Solo paddle price id - Month',
    team5PriceMonthly: 'Team 5 price - Month',
    team5PaddlePriceIdMonthly: 'Team 5 paddle price id - Month',
    team10PriceMonthly: 'Team 10 price - Month',
    team10PaddlePriceIdMonthly: 'Team 10 paddle price id - Month',
    team25PriceMonthly: 'Team 25 price - Month',
    team25PaddlePriceIdMonthly: 'Team 25 paddle price id - Month',
    team50PriceMonthly: 'Team 50 price - Month',
    team50PaddlePriceIdMonthly: 'Team 50 paddle price id - Month',
    chooseCountries: 'Choose countries',
    description: 'Description',
    deletePricingRegion: 'Delete pricing region',
    deleteMessage: 'Are you sure you want to delete this pricing region?',
  },
  notificationsHeaders: {
    time: 'Time',
    notification: 'Notification',
  },
  notificationsPage: {
    showUnseenOnly: 'Show unseen only',
    markAllAsRead: 'Mark all as read',
  },
  organisationsHeaders: {
    name: 'Organization',
    phoneNumber: 'Phone number',
    email: 'Email',
    address: 'Address',
    companyId: 'Company ID',
    vat: 'VAT',
    active: 'Active',
    createdAt: 'Registered',
    lastActivityAt: 'Last activity at',
    actions: 'Actions',
    billingDate: 'Billing date',
    referral: 'Referrer',
    subscriptionType: 'Subscription type',
    subscriptionStatus: 'Subscription status',
    country: 'Country',
  },
  organisationsPage: {
    newItem: 'New user entry ',
    editItem: 'Edit user',
    id: 'Id',
    name: 'Organization',
    address: 'Address',
    phoneNumber: 'Phone number',
    email: 'Email',
    vat: 'VAT',
    companyId: 'Company ID',
    warrantNumber: 'Number of decision',
    image: 'Image',
    signature: 'Signature',
    seal: 'Stamp',
    deactivateMessage: 'Are you sure you want to ',
    deactivate: 'DEACTIVATE',
    activate: 'ACTIVATE',
    organisation: 'organization',
    deleteOrganisation: 'Delete organization',
    deleteMessage: 'Are you sure you want to delete this organization?',
    cancelSubscrMessage:
      "Are you sure you want to cancel your subscription? Please be aware that by confirming this action: 1. Your organization's account and all associated data will be permanently deleted. This action cannot be undone. 2. You will be automatically logged out of the system immediately after cancellation. 3. You will no longer have access to log in or retrieve any data related to your organization in the future. We highly recommend reviewing any necessary information or exporting any critical data before proceeding with this irreversible action. If you have any questions or need assistance, please do not hesitate to contact our support team. Do you wish to proceed with the cancellation?",
    allDrivings: 'Total number of driving orders',
    allDrivingsAccepted: 'Total accepted driving orders',
    allDrivingsCanceled: 'Total rejected driving orders',
    allVehicles: 'Total number of vehicles',
    allUsers: 'Total number of users',
    areYouSure: 'Are you sure?',
    makeRoutesAvailable: 'Make integration routes available',
    percentage: 'Percentage',
    deleteImageMessage: 'Are you sure you want to delete this image?',
    deleteImageTitle: 'Delete Image',
    cancelSubscriptionTitle: 'Cancel Subscription',
    subscriptionType: 'Subscription type',
    country: 'Country',
    billingDate: 'Billing date',
    trialEndsAtDate: 'Trial ends at date',
    articlesOfLaw: 'Articles of law',
    referent: 'Referent',
    language: 'Language',
    gnetId: 'GNET ID',
    affiliateId: 'Affiliate ID',
    trialEndsAt: 'Trial ends at',
    languageTooltip:
      'Default fallback language for new users and the language in which all company emails (not individual user emails) will be sent.',
    organisationType: 'Organisation type',
    regular: 'Regular',
    affiliateSender: 'Affiliate sender',
    affiliateReceiver: 'Affiliate receiver',
    dispatcher: 'Dispatcher',
    referral: 'Referrer',
    selectLocation: 'Select location',
    changeLocation: 'Change location',
    timeZone: 'Time zone',
    onlyActive: 'Only active',
  },
  subscriptionsHeaders: {
    invoiceNumber: 'Invoice number',
    status: 'Status',
    total: 'Total',
    tax: 'Tax',
    currency: 'Currency',
    billedAt: 'Billed at',
    actions: 'Actions',
  },
  subscriptionsPage: {
    newItem: 'New transaction entry ',
    editItem: 'Edit transaction',
    id: 'Id',
    invoiceNumber: 'Invoice number',
    status: 'Status',
    sent: 'Sent',
    completed: 'Completed',
    total: 'Total',
    currency: 'Currency',
    billedAt: 'Billed at',
    actions: 'Actions',
    deleteSubscription: 'Delete transaction',
    deleteMessage: 'Are you sure you want to delete this transaction?',
    currentSubscription: 'Current package',
    nextBillingDate: 'Next billing date',
    numberOfSeatsTaken: 'Number of seats taken',
    numberOfSeatsAvailable: 'Number of seats available',
    pastDue: 'Past due',
  },
  currenciesHeaders: {
    name: 'Name',
    code: 'Code',
    symbol: 'Symbol',
    active: 'Active',
    default: 'Primary',
    actions: 'Actions',
  },
  currenciesPage: {
    newItem: 'New currency entry ',
    editItem: 'Currency change',
    id: 'Id',
    currency: 'Currency',
    code: 'Code',
    symbol: 'Symbol',
    deleteMessage: 'Are you sure you want to delete this currency?',
  },
  paymentMethodsHeaders: {
    name: 'Name',
    active: 'Active',
    default: 'Primary',
    priceVisible: 'Price visible',
    actions: 'Actions',
  },
  paymentMethodsPage: {
    newItem: 'New payment method entry',
    editItem: 'Change of payment method',
    id: 'Id',
    paymentMethod: 'Payment method',
    deleteMessage: 'Are you sure you want to delete this payment method?',
  },
  feedbacksHeaders: {
    subject: 'Name',
    organisation: 'Organization',
    user: 'User',
    actions: 'Actions',
  },
  checkpointsHeaders: {
    checkpointLocation: "Stop's location",
    arrivalTime: 'Arrival time',
    actions: 'Actions',
  },
  tables: {
    noDataText: 'No data',
    search: 'Search',
    drivings: 'Driving orders',
    drivingsByPage: 'Driving orders by page',
    drivingTemplates: 'Reservation templates',
    drivingTemplatesByPage: 'Templates by page',
    vehicles: 'Vehicles',
    vehiclesByPage: 'Vehicles by page',
    vehiclesClasses: 'Vehicle classes',
    vehiclesClassesByPage: 'Vehicle classes by page',
    expenses: 'Costs',
    expensesByPage: 'Costs by page',
    vehiclesMaintenance: 'Vehicle malfunctions',
    vehiclesMaintenanceByPage: 'Vehicle malfunctions by page',
    reportsByPage: 'Reports by page',
    currencies: 'Currencies',
    currencieseByPage: 'Currencies by page',
    payments: 'Payment methods',
    paymentsByPage: 'Payment methods by page',
    saasAdministrators: 'All administrators',
    users: 'All users',
    usersByPage: 'Users by page',
    clients: 'All clients',
    clientsByPage: 'Clients by page',
    organisations: 'All organizations',
    organisationsByPage: 'Organizations by page',
    cards: 'Cards',
    cardsByPage: 'Cards by page',
    transactions: 'Transactions',
    transactionsByPage: 'Transactions by page',
    subscriptions: 'Subscriptions',
    subscriptionsByPage: 'Subscriptions by page',
    pricingRegions: 'Pricing Regions',
    pricingRegionsByPage: 'Pricing Regions by page',
    feedback: 'Feedbacks',
    feedbacksByPage: 'Feedbacks by page',
    notifications: 'Notifications',
    notificationsByPage: 'Notifications by page',
    all: 'All',
    passengers: 'Passengers',
    passengersByPage: 'Passengers by page',
    of: 'of',
    checkpoints: 'Stops',
    checkpointsByPage: 'Stops by page',
    invoices: 'Invoices',
    invoicesByPage: 'Invoices by page',
    drivingOffers: 'Driving offers',
    drivingOffersByPage: 'Driving offers by page',
    resourcesUsed: 'Resources used',
    resourcesUsedByPage: 'Resources by page',
    inNext3Days: 'In next 3 days',
    notConfirmedOrPaid: 'Not confirmed or paid',
    inNext5Days: 'In next 5 days',
    latestReviews: 'Latest reviews',
    passengerReviews: 'Passenger reviews',
    driversByPage: 'Drivers by page',
    reviewsByPage: 'Reviews by page',
    partners: 'Partners',
    partnersByPage: 'Partners by page',
    sentRequests: 'Sent requests',
    receivedRequests: 'Received requests',
    requestsByPage: 'Requests by page',
    pricingZones: 'Zones and areas',
    pricingZonesByPage: 'Zones and areas by page',
    pricingPerZones: 'Pricing per zones',
    pricingPerZonesByPage: 'Pricing by page',
    pricingPerHour: 'Pricing per hour',
    pricingPerHourByPage: 'Pricing by page',
    workLogs: 'Activity logs',
    workLogsByPage: 'Activity logs by page',
    loading: 'Loading',
  },
  statistics: {
    numberOfAcceptedDrivings: 'Number of accepted driving orders',
    numberOfRejectedDrivings: 'Number of rejected driving orders per driver',
    vehicleDrivingsNumber: 'Number of driving orders per vehicle',
    vehicleProfit: 'Earnings per vehicle',
    monthDrivingsNumber: 'Number of driving orders per month',
    driverProfit: 'Earnings per driver',
  },
  reportsHeaders: {
    vehicle: 'Vehicle',
    expenses: 'Expenses',
    profit: 'Profit',
    earnings: 'Earnings',
    daysWorking: 'Working days',
    usageIndex: 'Usage index',
    client: 'Client',
    numberOfDrivings: 'Number of Drivings',
    percentForClient: 'Price for the client',
    sumOfPrice: 'Amount',
  },
  packages: {
    active: 'Active',
    starterTitle: 'Starter',
    monthlyPackagesMessage: 'Monthly subscription per organization.',
    starterText: 'Ideal for individuals or small teams who only need basic functionality.',
    starterListItemOne: 'Mobile applications',
    starterListItemTwo: 'Push notifications',
    starterListItemThree: 'Access to administration',
    starterListItemFour: 'Website integration',
    standardTitle: 'Standard',
    standardText: 'Perfect for organizations with over 5 drivers.',
    standardListItemOne: 'STARTER package included',
    standardListItemTwo: 'There is no limit on the number of drivers',
    standardListItemThree: '99.9% Uptime',
    standardListItemFour: 'Reports & Vehicle Management',
    premiumTitle: 'Premium',
    premiumText: 'Perfect for limo service organizations that operate on accurate data.',
    premiumListItemOne: 'STANDARD package included',
    premiumListItemTwo: 'BI & Cost Forecasting',
    premiumListItemThree: 'Export data',
    premiumListItemFour: 'Weekly and Monthly Reports',
    warningOne: '* The prices shown are monthly subscription per user',
    warningTwo:
      '* After switching to a new package, it is not possible to switch to a smaller package for the following 6 months',
    warningThree: '* Users of the demo plan have an insight into the functionalities provided by the premium package',
    warningOneAnnualPackage: 'Need more? Do you have a larger organization? We have an ENTERPRISE package for you.',
    warningTwoAnnualPackage: 'and our sales team will contact you shortly.',
    packageChange: 'Package change',
    changePackage: 'Are you sure you want to switch to',
    package: 'package',
    annualPricing: 'Annual pricing',
    monthlyPricing: 'Monthly pricing',
    packageType5: 'Team 5',
    packageType10: 'Team 10',
    packageType25: 'Team 25',
    packageType50: 'Team 50',
    'Solo - Yearly': 'Solo - Yearly',
    'Team 5 - Yearly': 'Team 5 - Yearly',
    'Team 10 - Yearly': 'Team 10 - Yearly',
    'Team 25 - Yearly': 'Team 25 - Yearly',
    'Team 50 - Yearly': 'Team 50 - Yearly',
    'Solo - Monthly': 'Solo - Monthly',
    'Team 5 - Monthly': 'Team 5 - Monthly',
    'Team 10 - Monthly': 'Team 10 - Monthly',
    'Team 25 - Monthly': 'Team 25 - Monthly',
    'Team 50 - Monthly': 'Team 50 - Monthly',
    annualPackagesMessage: 'Annual subscription per organization.',
    packageTypeSoloMainMessage: '1 user account only.',
    packageType5MainMessage: 'Up to 5 user accounts.',
    packageType10MainMessage: 'Up to 10 user accounts.',
    packageType25MainMessage: 'Up to 25 user accounts.',
    packageType50MainMessage: 'Up to 50 user accounts.',
    allFeaturesIncluded: 'All features included.',
    unlimitedRides: 'Unlimited num. of rides and vehicles.',
    trialPeriod: 'Trial period included.',
    onboardingFeeOptional: 'Onboarding fee optional.',
    noHiddenCosts: 'No hidden costs.',
    freeSmsFlights10: 'Free 15 SMSs and 50 flight checks monthly.',
    freeSmsFlights25: 'Free 35 SMSs and 100 flight checks monthly.',
    freeSmsFlights50: 'Free 50 SMSs and 200 flight checks monthly.',
  },
  successPaymentPage: {
    message: 'You have paid successfully. Thank you.',
  },
  demoExpiring: {
    notification: 'Notification',
    expiredSubscriptionMessage:
      'We inform you that your subscription to this platform has expired. To continue using our services, please renew your subscription.',
  },
  login: {
    username: 'Username',
    password: 'Password',
    rememberMe: 'Remember me',
    login: 'Log in',
    noAccount: "Don't have an account yet? You can register",
    forgetPassword: 'Forgot your password? You can reset it',
    registerHere: 'Here',
    email: 'Email',
    enterEmail: 'Please enter your email',
    enterPassword: 'Please enter your new password',
    signInWithGoogle: 'Sign in with Google',
    passwordChangeError: 'Something went wrong with setting your password. Please try again or contact support.',
  },
  registration: {
    register: 'Register',
    name: 'Organization name',
    address: 'Address',
    phoneNumber: 'Phone number',
    email: 'Email',
    companyId: 'Company ID',
    vat: 'VAT',
    language: 'Language',
    package: 'Package',
    accept: 'I accept',
    terms: 'the terms of use',
    successfullyRegistered:
      'The user has been successfully registered. You will receive an email with a verification link.',
    haveAccount: 'Already have an account? You can login',
    loginHere: 'Here',
    trialPeriodInfo: '14 days free trial period included. Billing will follow after the end of the trial period.',
  },
  driving: {
    drivingType: 'Booking type',
    general: 'General info',
    newDriving: 'Schedule a transfer',
    newDailyRent: 'Schedule a daily rental',
    areYouSure: 'Are you sure?',
    vehicleIsUnavailable: 'The vehicle is occupied',
    vehicleIsDamaged: 'The vehicle is broken',
    sureAboutThisVehicle: 'Are you sure you want to choose this vehicle?',
    driverUnavaliable: 'The driver is busy',
    sureAboutThisDriver: 'Are you sure you want to select this driver?',
    checkVehicleSeatAvailability: 'This vehicle cannot hold this many passengers',
    cancelDriving: 'Are you sure you want to cancel the driving order?',
    finishDriving: 'Are you sure you want to end the driving order?',
    noShowDriving: 'Are you sure you want to mark driving order as no show?',
    markDrivingAsStarted: 'Are you sure you want to mark driving order as started?',
    deleteDriving: 'Are you sure you want to delete the driving order?',
    newTransfer: 'New transfer',
    driving: 'Driving order',
    comments: 'Comments',
    id: 'Id',
    locationFrom: 'Starting point',
    locationTo: 'Destination',
    vehicleClass: 'Vehicle class',
    vehicle: 'Vehicle',
    driver: 'Driver',
    pickupTime: 'Pickup time',
    clientConfirmed: 'Client confimed',
    roundTrip: 'Round trip',
    description: 'Description',
    extraInfo: 'Additional info',
    drivingNotAccepted: 'They did not accept the driving order:',
    currency: 'Currency',
    paymentMethod: 'Payment methods',
    price: 'Price',
    charged: 'Charged',
    paid: 'Paid',
    distance: 'Distance (km)',
    distanceMi: 'Distance (miles)',
    expectedTime: 'Expected duration',
    waitingTime: 'Waiting time (h)',
    EnterTheNumberOfHoursWaiting: 'Enter the number of hours waiting',
    dropOffTime: 'Arrival time',
    comebackTime: 'Return time',
    acceptUntil: 'Accept until',
    drivingRequired: 'Is driving order mandatory',
    comment: 'Comment',
    attachments: 'Attachments',
    addFiles: '+ Add files',
    deleteFiles: 'Are you sure you want to delete this file?',
    passengers: 'Passengers',
    addPassenger: '+ Add passenger',
    deletePassenger: 'Are you sure you want to delete this passenger?',
    chooseClient: 'Choose client',
    costs: 'Costs',
    checkpoints: 'Stops',
    addCheckpoint: 'Add a stop',
    deleteCheckpoint: 'Are you sure you want to delete this stop?',
    waitingBoardText: 'Waiting board text',
    flightNumber: 'Flight number',
    flightInfo: 'Flight info',
    createdBy: 'Reservation created at <b>{time}</b> by <b>{user}</b>',
    client: 'Client',
    gnet: 'GNET',
    note: 'Note',
    noteDriver: 'Note for driver',
    noteDispatcher: 'Note for dispatcher',
    passenger: 'Passenger',
    fullName: 'Full name',
    phoneNumber: 'Phone number',
    email: 'Email',
    passengersNumber: 'Passengers number',
    babySeatsNumber: 'Baby seats number',
    suitcasesNumber: 'Suitcases number',
    stopsNumber: 'Stops number',
    steps: {
      mainInfo: 'Main Info',
      additionalInfo: 'Additional Info',
      review: 'Review',
    },
    commissionAmount: 'Commission',
    sendSms: 'Send SMS',
    message: 'Message',
    acceptDriving: 'Are you sure you want to accept the driving order?',
    rejectDriving: 'Are you sure you want to reject the driving order?',
    calculatePriceViaZonePricing: 'Calculate price via zone pricing',
    disableCalculatePriceWithoutHours: 'Cannot calculate price without number of waiting hours',
    disableCalculatePriceViaZonePricing:
      'Cannot calculate price via zone pricing without location, currency and vehicle class',
    calculatePriceViaDistanceScales: 'Calculate price via distance',
    disableCalculatePriceViaDistanceScales:
      'Cannot calculate price via distance without distance, currency and vehicle class',
    numberOfPassengers: 'Number of passengers',
    numberOfSuitcases: 'Number of suitcases',
    vat: 'VAT',
    vatPercentage: 'VAT percentage',
    priceType: 'Price type',
    accessibleForWheelchair: 'Accessible for wheelchair',
    priceForWaiting: 'Price for waiting',
    numberOfHours: 'Number of hours',
    getAQuote: 'Reach out for a quote',
    selectPassenger: 'Select passenger',
    clientId: 'Client id',
    perDistance: '(per distance)',
    perHour: '(per hour)',
    templateName: 'Template name',
    visibleToClients: 'Visible to clients',
  },
  drivingStatus: {
    pending: 'Pending',
    accepted: 'Accepted',
    rejected: 'Rejected',
    expired: 'Expired',
    canceled: 'Canceled',
    draft: 'Draft',
    done: 'Done',
    noShow: 'No Show',
    ongoing: 'Started',
  },
  drivingOrderType: {
    dailyRent: 'Daily rent',
    transfer: 'Transfer',
    hourlyDaily: 'Hourly/Daily',
    oneWayTransfer: 'One-Way Transfer',
    fromAirport: 'From Airport',
    toAirport: 'To Airport',
  },
  flightInfo: {
    airlineName: 'Airline name',
    flightNumber: 'Flight number',
    departureAirport: 'Departure airport',
    departureCountry: 'Departure country',
    departureTime: 'Departure time',
    arrivalAirport: 'Arrival airport',
    arrivalCountry: 'Arrival country',
    arrivalTime: 'Arrival time',
    departureDelayed: 'Departure delayed',
    flightStatus: 'Flight status',
    percentageOfFlight: 'Percentage of flight',
  },
  customValidations: {
    fileType: `The file type .{extension} is not supported`,
    fileSize: 'Files cannot be larger than {size}',
    fieldRequired: 'Field {field} is required',
    fieldType: 'Field {field} must be of type {type}',
    fieldLengthMin: 'The {field} field must be {length} or more',
    fieldLengthMax: 'The {field} field must be {length} or less',
    fieldUnique: 'Field {field} must be unique',
    fieldValid: 'Field {field} must be valid',
    noActiveSubscription: 'No active subscription found',
    usersLimitExceeded: 'User limit reached',
    fieldBefore: 'The {field1} must be before {field2}',
    flightNotExist: 'The flight {field} does not exist',
    gnetKeyNotValid: 'Gnet key provided is not valid.',
    gnetPassengersRequired: 'Driving order cannot be sent to GNET without passengers.',
    priceAndCurrencyRequired: 'Payment link cannot be generated without price and currency',
    fieldValueNotSupported: 'The {field} field value is not supported',
    noCountryCode: 'You have no country selected',
    noStripeForYourCountry: 'Stripe is not available in your country',
    stripeAlreadyEnabled: 'Stripe is already activated',
    fieldValueMin: 'The {field} field must be {min} or more',
    fieldValueMax: 'The {field} field must be {max} or less',
    markAsDoneDate: 'Pickup time cannot be in the future',
    phoneNumberNotValidForSms:
      'The phone number is invalid for sending SMS. A valid phone number must include the country code.',
    affiliateIdDoesNotExist: 'The organization with the given affiliate ID does not exist.',
    numericDecimal: 'The {field} field may only contain numeric characters (decimals allowed)',
  },
  fieldTypes: {
    string: 'string',
    integer: 'number',
    date: 'date',
  },
  names: {
    username: 'username',
    password: 'password',
    choose_client: 'choose client',
    choose_month: 'choose month',
    currency: 'currency',
    payment_method: 'payment method',
    vehicle_class: 'vehicle class',
    first_name: 'first name',
    last_name: 'last name',
    passport: 'passport',
    nationality: 'nationality',
    plate_number: 'plate number',
    brand: 'car brand',
    number_of_passengers: 'number of passengers',
    fuel_consumption: 'fuel consumption',
    year_of_manufacture: 'year of manufacture',
    price_per_km: 'price per km',
    price_per_mi: 'price per mile',
    price_per_hour: 'price per hour',
    price_per_waiting_hour: 'price per waiting hour',
    vehicle: 'vehicle',
    description: 'description',
    time_from: 'time from',
    time_to: 'time to',
    name: 'name',
    cost_type: 'cost type',
    cost_time: 'cost time',
    price: 'price',
    phone_number: 'phone number',
    organisation: 'organization',
    role: 'role',
    image: 'image',
    location_from: 'location from',
    location_to: 'location to',
    pickup_time: 'Pickup time',
    distance: 'distance',
    waiting_time: 'waiting time',
    comment: 'comment',
    address: 'address',
    vat: 'VAT',
    company_id: 'company id',
    company_personal_id: 'company id/personal id',
    licence: 'licence',
    warrant_number: 'warrant number',
    seal: 'seal',
    signature: 'signature',
    email: 'email',
    percentage: 'percentage',
    organizationName: 'organization name',
    package: 'package',
    choose_currency: 'choose currency',
    team_10_price: 'team 10 price',
    team_10_paddle_price_id: 'team 10 paddle price id',
    team_25_price: 'team 25 price',
    team_25_paddle_price_id: 'team 25 paddle price id',
    team_50_price: 'team 50 price',
    team_50_paddle_price_id: 'team 50 paddle price id',
    team_10_price_monthly: 'team 10 price',
    team_10_paddle_price_id_monthly: 'team 10 paddle price id',
    team_25_price_monthly: 'team 25 price',
    team_25_paddle_price_id_monthly: 'team 25 paddle price id',
    team_50_price_monthly: 'team 50 price',
    team_50_paddle_price_id_monthly: 'team 50 paddle price id',
    countries: 'countries',
    invoice_number: 'invoice number',
    status: 'status',
    total: 'total',
    billed_at: 'billed at',
    articles_of_law: 'articles of law',
    country: 'country',
    referent: 'referent',
    language: 'language',
    location: 'location',
    arrival_time: 'arrival time',
    waiting_board_text: 'waiting board text',
    flight_number: 'flight number',
    gnet_id: 'GNET ID',
    receiver_id: 'receiver ID',
    preferred_vehicle_type: 'preferred vehicle type',
    reservation_type: 'reservation type',
    run_type: 'run type',
    trip_duration_minutes: 'trip duration',
    client: 'client',
    client_type: 'client type',
    card_holder_name: 'cardholder name',
    card_number: 'card number',
    expiry_date: 'expiry date',
    cvv: 'cvv',
    code: 'code',
    symbol: 'symbol',
    quantity: 'quantity',
    discount: 'discount',
    driving_type: 'reservation type',
    full_name: 'full name',
    suitcases_number: 'suitcases number',
    passengers_number: 'suitcases number',
    baby_seats_number: 'suitcases number',
    stops_number: 'suitcases number',
    commission_amount: 'commission',
    message: 'message',
    affiliate_id: 'affiliate id',
    coordinates: 'coordinates',
    zone_one: 'zone one',
    zone_two: 'zone two',
    price_type: 'price type',
    price_for_waiting: 'price for waiting',
    number_of_hours: 'number of hours',
    zones: 'Zones',
    client_id: 'client id',
    minutes: 'minutes',
    started_at: 'started at',
    finished_at: 'finished at',
    driving: 'driving',
    user: 'user',
    time_zone: 'time zone',
    charged: 'charged',
    offer_number: 'driving offer number',
    drivings: 'drivings',
  },
  weekDays: {
    monday: 'Monday',
    tuesday: 'Tuesday',
    wednesday: 'Wednesday',
    thursday: 'Thursday',
    friday: 'Friday',
    saturday: 'Saturday',
    sunday: 'Sunday',
  },
  select: {
    noDataAvailable: 'No data available',
    selected: 'selected',
  },
  excelExport: {
    to_date_range: 'The report cannot be generated for future dates. Please enter valid dates',
    overlap_range: 'End time cannot be greater than the start time.',
  },
  setPassword: {
    success: 'Email sent',
    checkEmail: 'Check your email for further instructions.',
    already_changed: 'Already changed',
    send_new_reset_request: 'You have already changed password with this link. Send new reset password request.',
  },
  statisticsPage: {
    reportType: 'Report type',
    reportTime: 'Report time',
    vehicleUtilization: 'Vehicle utilization',
    costEffectiveness: 'Cost effectiveness',
    generalReport: 'General report',
    clientsReport: 'Report by Clients',
  },
  profile: {
    yourProfile: 'Your Profile',
    name: 'Name',
    role: 'Role',
    username: 'Username',
    password: 'Password',
    phoneNumber: 'Phone Number',
    email: 'Email',
    percentage: 'Percentage',
  },
  gpsMaps: {
    noTransfers: 'There are no ongoing or upcoming transfers.',
    map: 'Map',
    transfers: 'Transfers',
    drivers: 'Drivers',
    ongoing: 'Ongoing',
    upcoming: 'Upcoming',
    driving: 'Driving',
    driver: 'Driver',
    vehicle: 'Vehicle',
    requestDriverLocation: 'Request driver’s location',
    lastRecordedLocation: 'Last recorded location',
  },
  driverStatus: {
    available: 'Available',
    offline: 'Offline',
    inTransfer: 'In transfer',
  },
  onboardingTutorialMain: {
    languageStep: 'Here, you can set your preferred language for the platform.',
    notificationsStep: 'Here, you can find your notifications.',
    addTransferStep: 'Click this button to create a new reservation, one-way transfers and daily rents.',
    sideMenuButtonStep: 'Click this icon to show or hide the menu bar.',
    sideMenuExpStep:
      'From here, you can navigate to the main sections of the platform: Reservations, Fleet, Costs, Administration, Invoices, Statistics, etc.',
    sideMenuButtonCloseStep: 'Click the same icon to hide the menu bar.',
    supportStep: 'If you have any issues or questions, don’t hesitate to contact Customer Support by clicking here.',
  },
  onboardingTutorialVehicles: {
    tableStep: 'Vehicles will be listed in this table after you add them.',
    searchStep: 'You can easily find vehicles by searching directly by brand, licence plate, year, etc.',
    addStep:
      'Add new vehicles by clicking the “Add” button. Please note that all “Vehicle Classes” should be added before entering vehicles.',
  },
  onboardingTutorialStatistics: {
    typeStep: `In this section, you can find all your statistical data shown in graphs. Select the type of statistics here.`,
    intervalStep: 'Choose an interval to view statistics for.',
    downloadStep: `You can also download and print these tables.`,
  },
  onboardingTutorialGps: {
    tabOneStep: `In the GPS module, you can follow up on your drivers and transfers in one place. Choose this tab to view ongoing and upcoming (next 24h) transfers.`,
    tabTwoStep: `Select vehicles to see them on the map. You can assign available vehicles to transfers directly from here.`,
    mapStep: `Select or deselect vehicles by clicking on their pin on the map.`,
  },
  onboardingTutorialInvoices: {
    tableStep: 'All invoices will be listed in this table, from newest to oldest.',
    searchStep: 'Easily find invoices by searching by number, amount, client name, create date, etc.',
    addStep: 'Create new invoices clicking the “Add” button.',
  },
  onboardingTutorialOffers: {
    tableStep: 'Offers will be listed in this table after adding them, from the newest to the oldest.',
    searchStep: 'Easily find offers by searching by number, amount or description.',
    addStep: 'Create new offers clicking the “Add” button.',
  },
  onboardingTutorialResources: {
    infoStep: 'View remaining resources, and purchase more SMS messages on demand.',
    tableStep: 'In this table you can find audit and history of all used resources (sms sent and flight checked).',
    searchStep: 'Easily find resources by searching by content, user or creation date.',
  },
  onboardingTutorialPassengerReviews: {
    tableStep: 'Passenger reviews will be listed in this table from newest to the oldest.',
    searchStep: 'Easily find passenger reviews by searching by number or passenger comment.',
  },
  onboardingTutorialPricingZones: {
    tableStep: 'Pricing zones will be listed in this table after you add them.',
    searchStep: 'Easily find pricing zones by searching directly.',
    addStep: 'Create new pricing zones by clicking the “Add” button.',
  },
  onboardingTutorialPricingZoneRelations: {
    tableStep: 'Pricing zone relations (prices) will be listed in this table after you add them.',
    searchStep: 'Easily find pricing zone relations by searching directly.',
    addStep:
      'Create new pricing zone relations (prices) by clicking the “Add” button. Please note that “Pricing zones” should be added first.',
  },
  onboardingTutorialPricingZoneHourlyRelations: {
    tableStep:
      'All pricing zones are listed in this table. By opening a specific zone, you can set prices for that zone.',
    searchStep: 'Easily find pricing zones by searching directly.',
  },
  onboardingTutorialDashboard: {
    infoStep: "View a summary of your organization's statistics for the last 30 days.",
    next3DaysStep: 'Reservations for the next 3 days will be listed in this table.',
    notPaidOrConfirmedStep:
      'Reservations for the next 7 days that are not confirmed or paid will be listed in this table.',
    next5DaysStep: 'Busy drivers for the next 5 days will be listed in this table.',
    latestReviewsStep: 'Your latest ride reviews will be listed in this table.',
  },
  onboardingTutorialAffiliate: {
    tabStep: 'Switch between tabs to view your partners, sent requests, or received requests.',
    idStep: 'Find your affiliate ID here, which you can share with partners.',
    addStep: 'Create a new affiliate partner request by clicking the “Add” button.',
  },
  onboardingTutorialAdvancedSettings: {
    advancedSettings:
      "Click here to access your organization's Advanced Settings, where you can configure all properties and integrations.",
  },
  onboardingTutorialDistanceScales: {
    distanceScales:
      'Here you can set and manage distance price scales for each vehicle class and currency. Easily define pricing for specific distance ranges (e.g., 0-5 km, 5-15 km) to customize your fare calculations.',
  },
  advancedSettings: {
    advancedSettings: 'Advanced settings',
    distanceUnit: 'Distance unit',
    distanceUnitDescription: 'Select the unit for measurements of distance.',
    kilometers: 'Kilometers',
    miles: 'Miles',
    dateFormat: 'Date format',
    dateFormatDescription: 'Select the date format you prefer to use.',
    ddmmyyyy: 'DD-MM-YYYY',
    mmddyyyy: 'MM-DD-YYYY',
    mandatoryDrivings: 'Mandatory driving orders',
    mandatoryDrivingsDescription: 'Check to make all driving orders mandatory by default.',
    mandatoryDrivingsDefault: 'Mandatory Driving Orders (Default)',
    paidDrivings: 'Paid driving orders',
    paidDrivingsDescription: 'Check to make all drivingmakeRoutesAvailable orders paid by default.',
    paidDrivingsDefault: 'Paid Driving Orders (Default)',
    notificationsEmail: 'Email for notifications',
    notificationsEmailDescription:
      'Change the default email to receive notifications (if you don’t want to use the email address of your organization).',
    email: 'Email',
    articlesOfLaw: 'Articles of law',
    integrations: 'Integrations',
    gnetId: 'GNET ID',
    gnetDescription:
      'GNET is a comprehensive platform designed to optimize real-time tracking, fleet management, and dispatch operations for the transportation industry.',
    makeRoutesAvailable: 'Make integration routes available',
    makeRoutesAvailableDescription:
      'Activate your API key to integrate LimoExpress with your website, ERP system, and more.',
    makePublicFormAvailable: 'Make public form available',
    makePublicFormAvailableDescription:
      'Activate this option to access the public booking page so your clients can access the advanced booking form and easily create accounts. You can use the link in newsletters, on the website, flyers; it is a unique link only for your company. Alternatively, you can also get a code snippet to embed the booking form directly on your own website.',
    emailsEnabled: 'Emails enabled',
    emailsEnabledDefault: 'Emails enabled',
    emailsEnabledDescription: 'Check to receive email notifications.',
    passengerReviewsEnabled: 'Passenger reviews enabled',
    passengerReviewsEnabledDefault: 'Passenger reviews enabled',
    passengerReviewsEnabledDescription:
      'By activating this feature, your passengers (if an email is entered) will receive a confirmation email after the ride is completed (marked as Finished) and will have the opportunity to provide ratings (feedback) for your services.',
    vat: 'VAT',
    vatPercentage: 'VAT percentage',
    vatPercentageDescription: 'Enter vat percentage.',
    invoicePaymentInstructions: 'Invoice payment instructions',
    stripe: 'Stripe',
    stripeDescription:
      'Activate Stripe to be able to charge your clients for your services. LimoExpress does not take any commission.',
    dataExport: 'Data export',
    dataExportDescription: 'You can export all your data, and it will be sent to your email.',
    sureAboutDataExport:
      'Are you sure you want to export all your data? If yes, all data will be sent to your email within the next 2 hours.',
    defaultPriceType: 'Default price type',
    defaultPriceTypeDescription: 'Set the default price type to determine how prices will be displayed.',
    NET: 'NET',
    GROSS: 'GROSS',
    link: 'Link',
    code: 'Code',
    language: 'Language',
    chooseSize: 'Choose size',
    small: 'Small',
    medium: 'Medium',
    large: 'Large',
    zapier: 'Zapier',
    zapierDescription:
      'Activate Zapier and gain access to integrations with over 7,000 of the most popular systems worldwide. This integration allows you to create workflows that fully support your business processes. You can automate messages, invoices, and even trigger chain actions based on specific activities within the LimoExpress platform.',
    zapierId: 'Zapier ID',
    publicBookingColor: 'Public booking form color',
    useSystemColors: 'Use default color',
    choseCustomColor: 'Choose custom color',
    location: 'Location',
    selectLocation: 'Select location',
    changeLocation: 'Change location',
    mapRadius: 'Map radius',
    mapRadiusDescription:
      'Set a location and define a radius to limit available locations when creating a new reservation. Only addresses within this radius will be available for selection.',
    publicMapRadius: 'Public booking form map radius',
    publicMapRadiusDescription:
      'Set a location and define a radius for the public booking form. Users booking a ride can only select pickup and drop-off locations within this radius.',
    radiusDistance: 'Radius distance (km)',
    radiusDistanceMi: 'Radius distance (miles)',
  },
  drivingExcelCheckboxes: {
    drivingNumber: 'Driving order number',
    drivingType: 'Booking type',
    drivingStatus: 'Status',
    locationFrom: 'Starting point',
    locationTo: 'Destination',
    driver: 'Driver',
    client: 'Client',
    plateNumber: 'Registration number',
    note: 'Description',
    fuelConsumption: 'Car fuel consumption',
    pickupTime: 'Pickup time',
    numberOfPassengers: 'Number of passengers',
    passengers: 'Passengers',
    price: 'Price',
    paid: 'Paid',
    paymentMethod: 'Payment method',
    waitingTime: 'Waiting time (h)',
    distance: 'Distance (km)',
    distanceMi: 'Distance (miles)',
    selectAll: 'Select all',
    roundTrip: 'Round trip',
    babySeatsNumber: 'Baby seats number',
    suitcasesNumber: 'Suitcases number',
    charged: 'Charged',
  },
  gnet: {
    gnetInfo: 'GNET info',
    requesterId: 'Requester id',
    receiverId: 'Receiver id',
    preferredVehicleType: 'Preferred vehicle type',
    reservationType: 'Reservation type',
    runType: 'Run type',
    price: 'Price',
    currency: 'Currency',
    noteDriver: 'Note for driver',
    noteDispatcher: 'Note for dispatcher',
    tripDurationMinutes: 'Trip duration (minutes)',
  },
  invoicesHeaders: {
    invoiceNumber: 'Invoice number',
    client: 'Client',
    totalPrice: 'Total price',
    drivings: 'Drivings',
    paid: 'Paid',
    currency: 'Currency',
    createdAt: 'Created at',
    actions: 'Actions',
  },
  invoicesPage: {
    newItem: 'New invoice entry',
    chooseClient: 'Choose client',
    chooseDrivings: 'Choose drivings',
    allDrivings: 'All drivings',
    invoiceNumber: 'Invoice number',
    payUntil: 'Pay until',
    deleteMessage: 'Are you sure you want to delete this invoice?',
    markInvoiceAsPaid: 'Are you sure you want to mark this invoice as paid?',
  },
  drivingOffersHeaders: {
    number: 'Driving offer number',
    totalPrice: 'Total price',
    description: 'Description',
    paid: 'Paid',
    currency: 'Currency',
    createdAt: 'Created at',
    actions: 'Actions',
  },
  drivingOffersPage: {
    newItem: 'New driving offer entry',
    item: 'Item',
    description: 'Description',
    title: 'Title',
    vehicleClass: 'Vehicle class',
    quantity: 'Quantity',
    price: 'Price',
    discount: 'Discount',
    number: 'Driving offer number',
    vat: 'VAT',
    deleteMessage: 'Are you sure you want to delete this driving offer?',
    markDrivingOfferAsPaid: 'Are you sure you want to mark this driving offer as paid?',
  },
  modal: {
    reservationSuccessTitle: 'Success',
    reservationSuccessMessage: 'Reservation request sent successfully. Someone will get in touch with you shortly.',
    reservationErrorTitle: 'Form disabled',
    reservationErrorMessage:
      'Form is currently disabled by the organization. Please contact support or try again later.',
  },
  dashboardPage: {
    drivingCountToday: 'Number of driving orders (today)',
    drivingCountThisMonth: 'Number of driving orders (last 30 days)',
    dailyRentsAndTransfers: 'Number of driving orders (daily rents/transfers)',
    drivingTotalAmount: 'Total amount',
    driversCount: 'Active drivers (±7 days)',
    passengerCount: 'Number of passengers (last 30 days)',
  },
  reviewsHeaders: {
    rating: 'Rating',
    comment: 'Comment',
    createdAt: 'Created at',
  },
  resourceOverviewHeaders: {
    actionType: 'Action type',
    user: 'User',
    action: 'Action',
    createdAt: 'Created at',
  },
  resourceOverviewPage: {
    smsMessagesLeft: 'Number of SMS messages left',
    flightsCheckLeft: 'Number of flight checks left',
    sms: 'SMS',
    flightCheck: 'Flight check',
  },
  affiliateHeaders: {
    organisation: 'Organisation',
    createdAt: 'Created at',
    actions: 'Actions',
  },
  affiliatePage: {
    partners: 'Partners',
    sentRequests: 'Sent requests',
    receivedRequests: 'Received requests',
    acceptRequest: 'Are you sure you want to accept this request?',
    rejectRequest: 'Are you sure you want to reject this request?',
    deletePartner: 'Are you sure you want to delete this partner?',
    deleteRequest: 'Are you sure you want to delete the request?',
    newItem: 'New request',
    affiliateId: 'Affiliate ID',
    registerNewPartner: 'Register a new partner',
    registrationSuccessfull: 'Registration successfull',
    registrationSuccessfullDescription:
      'Affiliate partner successfully registered! Please inform the partner that they will receive an email to verify their account and set a password. Once completed, they can start using the platform.',
  },
  affiliate: {
    affiliateInfo: 'Affiliate info',
    affiliate: 'Affiliate',
    partner: 'Partner',
    preferredVehicleType: 'Preferred vehicle type',
    driverInfo: 'Driver info',
    vehicleInfo: 'Vehicle info',
    price: 'Price',
    currency: 'Currency',
  },
  pricingZonesHeaders: {
    name: 'Zone name',
    code: 'Code',
    postalCode: 'ZIP/Postal Code',
    actions: 'Actions',
  },
  pricingZonesPage: {
    newItem: 'New pricing zone entry',
    editItem: 'Change of pricing zone',
    id: 'Id',
    name: 'Name',
    code: 'Code',
    deleteMessage: 'Are you sure you want to delete this pricing zone?',
    postalCode: 'ZIP/Postal Code',
    postalCodeDescription: 'Define a map zone using a ZIP/postal code',
    postalCodeDisabled: 'Please enter a ZIP/postal code first',
  },
  pricingZoneRelationsHeaders: {
    zoneOne: 'Zone one',
    zoneTwo: 'Zone two',
    client: 'Client',
    actions: 'Actions',
  },
  pricingZoneRelationsPage: {
    newItem: 'New pricing zone relation entry',
    editItem: 'Change of pricing zone relation',
    zone: 'Zone',
    zoneOne: 'Zone one',
    zoneTwo: 'Zone two',
    chooseClient: 'Choose client',
    chooseClientFrom: 'Clone from client',
    chooseClientTo: 'Clone to client',
    cloneItem: 'Clone pricing zone relation',
    zoneRelations: 'Zone relations',
    cloneItemHourly: 'Clone hourly pricing',
    zones: 'Zones',
    fromTo: 'From - To',
    discountPercentage: 'Discount percentage (%)',
    deleteMessage: 'Are you sure you want to delete this pricing zone relation?',
  },
  referralPrize: {
    referToAFriend: 'Refer to a friend',
    referToAFriendDescription:
      "Invite your friends to join the platform by entering their email address below. They will receive a personalized invitation to register and start using the platform. Alternatively, you can share your unique registration link directly with others! When someone registers using your link or invitation, you'll earn a referral prize as our way of saying thank you. Start inviting and sharing today!",
  },
  distanceScalesPage: {
    distanceScales: 'Distance scales',
  },
  passengersPage: {
    newItem: 'New passenger entry',
    deletePassenger: 'Delete passenger',
    deleteMessage: 'Are you sure you want to delete the passenger ',
  },
  formsAndTables: {
    actions: 'Actions',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    phoneNumber: 'Phone number',
    passport: 'Passport',
    country: 'Country',
    nationality: 'Nationality',

    // work logs
    logType: 'Type',
    minutes: 'Minutes',
    startedAt: 'Started at',
    finishedAt: 'Finished at',
    driving: 'Driving',
    user: 'User',
    createdAt: 'Created at',
    exportExcel: 'Export excel',
    description: 'Description',
  },

  workLogsPage: {
    newItem: 'New activity log entry ',
    deleteMessage: 'Are you sure you want to delete this activity log?',
    regular: 'Regular',
  },
};
