import i18n from '@/i18n/i18n';
import store from '@/store';

export function mainLayoutSteps() {
  const steps = [
    {
      target: '#v-step-0',
      content: i18n.t('onboardingTutorialMain.languageStep'),
      params: {
        disable: true,
      },
      targetId: '0',
    },
    {
      target: '#v-step-1',
      content: i18n.t('onboardingTutorialMain.notificationsStep'),
      targetId: '1',
    },
    {
      target: '#v-step-2',
      content: i18n.t('onboardingTutorialMain.addTransferStep'),
      targetId: '2',
    },
    {
      target: '#v-step-3',
      content: i18n.t('onboardingTutorialMain.supportStep'),
      targetId: '3',
      params: {
        placement: 'top',
        highlight: false,
      },
    },
    {
      target: '#v-step-4',
      content: i18n.t('onboardingTutorialMain.sideMenuButtonStep'),
      params: {
        placement: 'right',
      },
      targetId: '4',
    },
    {
      target: '#v-step-5',
      content: i18n.t('onboardingTutorialMain.sideMenuExpStep'),
      params: {
        placement: 'right',
      },
      targetId: '5',
    },
    {
      target: '#v-step-4',
      content: i18n.t('onboardingTutorialMain.sideMenuButtonCloseStep'),
      params: {
        placement: 'top',
      },
      targetId: '4',
    },
  ];

  // Remove v-step-3 for a customer role
  if (store.state.auth.role === 11) {
    return steps.filter((step) => step.target !== '#v-step-3');
  }

  return steps;
}

export function mainLayoutStepsSmallDevices() {
  const steps = [
    {
      target: '#v-step-0',
      content: i18n.t('onboardingTutorialMain.languageStep'),
      params: {
        disable: true,
      },
      targetId: '0',
    },
    {
      target: '#v-step-1',
      content: i18n.t('onboardingTutorialMain.notificationsStep'),
      targetId: '1',
    },
    {
      target: '#v-step-2',
      content: i18n.t('onboardingTutorialMain.addTransferStep'),
      targetId: '2',
    },
    {
      target: '#v-step-3',
      content: i18n.t('onboardingTutorialMain.supportStep'),
      targetId: '3',
      params: {
        placement: 'top',
        highlight: false,
      },
    },
    {
      target: '#v-step-4',
      content: i18n.t('onboardingTutorialMain.sideMenuButtonStep'),
      params: {
        placement: 'left',
      },
      targetId: '4',
    },
    {
      target: '#v-step-5',
      content: i18n.t('onboardingTutorialMain.sideMenuExpStep'),
      params: {
        placement: 'bottom',
      },
      targetId: '5',
    },
  ];

  // Remove v-step-3 for a customer role
  if (store.state.auth.role === 11) {
    return steps.filter((step) => step.target !== '#v-step-3');
  }

  return steps;
}

export function vehiclesPageSteps() {
  return [
    {
      target: '#vehicle-page-step-0',
      content: i18n.t('onboardingTutorialVehicles.tableStep'),
      params: {
        disable: true,
      },
      targetId: '0',
    },
    {
      target: '#vehicle-page-step-1',
      content: i18n.t('onboardingTutorialVehicles.searchStep'),
      params: {
        disable: true,
      },
      targetId: '1',
    },
    {
      target: '#vehicle-page-step-2',
      content: i18n.t('onboardingTutorialVehicles.addStep'),
      params: {
        disable: true,
      },
      targetId: '2',
    },
  ];
}

export function statisticsPageSteps() {
  return [
    {
      target: '#statistics-page-step-0',
      content: i18n.t('onboardingTutorialStatistics.typeStep'),
      params: {
        disable: true,
        enableScrolling: false,
        placement: 'bottom',
      },
      targetId: '0',
    },
    {
      target: '#statistics-page-step-1',
      content: i18n.t('onboardingTutorialStatistics.intervalStep'),
      params: {
        disable: true,
        enableScrolling: false,
      },
      targetId: '1',
    },
    {
      target: '#statistics-page-step-2',
      content: i18n.t('onboardingTutorialStatistics.downloadStep'),
      params: {
        disable: true,
        enableScrolling: false,
      },
      targetId: '2',
    },
  ];
}

export function gpsPageSteps() {
  const steps = [
    {
      target: '#gps-page-step-0',
      content: i18n.t('onboardingTutorialGps.tabOneStep'),
      params: {
        disable: true,
        enableScrolling: false,
        placement: 'bottom',
      },
      targetId: '0',
    },
    {
      target: '#gps-page-step-1',
      content: i18n.t('onboardingTutorialGps.tabTwoStep'),
      params: {
        disable: true,
        enableScrolling: false,
      },
      targetId: '1',
    },
    {
      target: '#gps-page-step-2',
      content: i18n.t('onboardingTutorialGps.mapStep'),
      params: {
        placement: 'bottom',
        disable: true,
        enableScrolling: false,
      },
      targetId: '2',
    },
  ];

  // Remove #gps-page-step-1 for a customer role
  if (store.state.auth.role === 11) {
    return steps.filter((step) => step.target !== '#gps-page-step-1');
  }

  return steps;
}

export function invoicesPageSteps() {
  const steps = [
    {
      target: '#invoices-page-step-0',
      content: i18n.t('onboardingTutorialInvoices.tableStep'),
      params: {
        disable: true,
      },
      targetId: '0',
    },
    {
      target: '#invoices-page-step-1',
      content: i18n.t('onboardingTutorialInvoices.searchStep'),
      params: {
        disable: true,
      },
      targetId: '1',
    },
    {
      target: '#invoices-page-step-2',
      content: i18n.t('onboardingTutorialInvoices.addStep'),
      params: {
        disable: true,
      },
      targetId: '2',
    },
  ];

  // Remove #invoices-page-step-2 for a customer role
  if (store.state.auth.role === 11) {
    return steps.filter((step) => step.target !== '#invoices-page-step-2');
  }

  return steps;
}

export function offersPageSteps() {
  return [
    {
      target: '#offers-page-step-0',
      content: i18n.t('onboardingTutorialOffers.tableStep'),
      params: {
        disable: true,
      },
      targetId: '0',
    },
    {
      target: '#offers-page-step-1',
      content: i18n.t('onboardingTutorialOffers.searchStep'),
      params: {
        disable: true,
      },
      targetId: '1',
    },
    {
      target: '#offers-page-step-2',
      content: i18n.t('onboardingTutorialOffers.addStep'),
      params: {
        disable: true,
      },
      targetId: '2',
    },
  ];
}

export function resourcesPageSteps() {
  return [
    {
      target: '#resources-page-step-0',
      content: i18n.t('onboardingTutorialResources.infoStep'),
      params: {
        disable: true,
      },
      targetId: '0',
    },
    {
      target: '#resources-page-step-1',
      content: i18n.t('onboardingTutorialResources.tableStep'),
      params: {
        disable: true,
        placement: 'bottom',
      },
      targetId: '1',
    },
    {
      target: '#resources-page-step-2',
      content: i18n.t('onboardingTutorialResources.searchStep'),
      params: {
        disable: true,
      },
      targetId: '2',
    },
  ];
}

export function passengerReviewsPageSteps() {
  return [
    {
      target: '#passenger-reviews-page-step-0',
      content: i18n.t('onboardingTutorialPassengerReviews.tableStep'),
      params: {
        disable: true,
        placement: 'bottom',
      },
      targetId: '0',
    },
    {
      target: '#passenger-reviews-page-step-1',
      content: i18n.t('onboardingTutorialPassengerReviews.searchStep'),
      params: {
        disable: true,
      },
      targetId: '1',
    },
  ];
}

export function pricingZonesPageSteps() {
  return [
    {
      target: '#pricing-zones-page-step-0',
      content: i18n.t('onboardingTutorialPricingZones.tableStep'),
      params: {
        disable: true,
      },
      targetId: '0',
    },
    {
      target: '#pricing-zones-page-step-1',
      content: i18n.t('onboardingTutorialPricingZones.searchStep'),
      params: {
        disable: true,
      },
      targetId: '1',
    },
    {
      target: '#pricing-zones-page-step-2',
      content: i18n.t('onboardingTutorialPricingZones.addStep'),
      params: {
        disable: true,
      },
      targetId: '2',
    },
  ];
}

export function pricingZoneRelationsPageSteps() {
  return [
    {
      target: '#pricing-zone-relations-page-step-0',
      content: i18n.t('onboardingTutorialPricingZoneRelations.tableStep'),
      params: {
        disable: true,
      },
      targetId: '0',
    },
    {
      target: '#pricing-zone-relations-page-step-1',
      content: i18n.t('onboardingTutorialPricingZoneRelations.searchStep'),
      params: {
        disable: true,
      },
      targetId: '1',
    },
    {
      target: '#pricing-zone-relations-page-step-2',
      content: i18n.t('onboardingTutorialPricingZoneRelations.addStep'),
      params: {
        disable: true,
      },
      targetId: '2',
    },
  ];
}

export function pricingZoneHourlyRelationsPageSteps() {
  return [
    {
      target: '#pricing-zone-hourly-relations-page-step-0',
      content: i18n.t('onboardingTutorialPricingZoneHourlyRelations.tableStep'),
      params: {
        disable: true,
      },
      targetId: '0',
    },
    {
      target: '#pricing-zone-hourly-relations-page-step-1',
      content: i18n.t('onboardingTutorialPricingZoneHourlyRelations.searchStep'),
      params: {
        disable: true,
      },
      targetId: '1',
    },
  ];
}

export function dashboardSteps() {
  return [
    {
      target: '#dashboard-step-0',
      content: i18n.t('onboardingTutorialDashboard.infoStep'),
      params: {
        disable: true,
      },
      targetId: '0',
    },
    {
      target: '#dashboard-step-1',
      content: i18n.t('onboardingTutorialDashboard.next3DaysStep'),
      params: {
        disable: true,
      },
      targetId: '1',
    },
    {
      target: '#dashboard-step-2',
      content: i18n.t('onboardingTutorialDashboard.notPaidOrConfirmedStep'),
      params: {
        disable: true,
      },
      targetId: '2',
    },
    {
      target: '#dashboard-step-3',
      content: i18n.t('onboardingTutorialDashboard.next5DaysStep'),
      params: {
        disable: true,
      },
      targetId: '3',
    },
    {
      target: '#dashboard-step-4',
      content: i18n.t('onboardingTutorialDashboard.latestReviewsStep'),
      params: {
        disable: true,
      },
      targetId: '4',
    },
  ];
}

export function affiliatePageSteps() {
  return [
    {
      target: '#affiliate-page-step-0',
      content: i18n.t('onboardingTutorialAffiliate.tabStep'),
      params: {
        disable: true,
      },
      targetId: '0',
    },
    {
      target: '#affiliate-page-step-1',
      content: i18n.t('onboardingTutorialAffiliate.idStep'),
      params: {
        disable: true,
      },
      targetId: '1',
    },
    {
      target: '#affiliate-page-step-2',
      content: i18n.t('onboardingTutorialAffiliate.addStep'),
      params: {
        disable: true,
      },
      targetId: '2',
    },
  ];
}

export function advancedSettingsPageSteps() {
  return [
    {
      target: '#advanced-settings-step-0',
      content: i18n.t('onboardingTutorialAdvancedSettings.advancedSettings'),
      params: {
        disable: true,
      },
      targetId: '0',
    },
  ];
}

export function distanceScalesPageSteps() {
  return [
    {
      target: '#distance-scales-step-0',
      content: i18n.t('onboardingTutorialDistanceScales.distanceScales'),
      params: {
        disable: true,
        placement: 'right',
      },
      targetId: '0',
    },
  ];
}

export function getAllTourSteps() {
  return [
    {
      component_id: '1',
      step_id: '#v-step-0',
    },
    {
      component_id: '1',
      step_id: '#v-step-1',
    },
    {
      component_id: '1',
      step_id: '#v-step-2',
    },
    {
      component_id: '1',
      step_id: '#v-step-3',
    },
    {
      component_id: '1',
      step_id: '#v-step-4',
    },
    {
      component_id: '1',
      step_id: '#v-step-5',
    },
    {
      component_id: '1',
      step_id: '#v-step-5',
    },
    {
      component_id: '1',
      step_id: '#v-step-4',
    },
    // Vehicles page
    {
      component_id: '2',
      step_id: '#vehicle-page-step-0',
    },
    {
      component_id: '2',
      step_id: '#vehicle-page-step-1',
    },
    {
      component_id: '2',
      step_id: '#vehicle-page-step-2',
    },
    // Statistics page
    {
      component_id: '3',
      step_id: '#statistics-page-step-0',
    },
    {
      component_id: '3',
      step_id: '#statistics-page-step-1',
    },
    {
      component_id: '3',
      step_id: '#statistics-page-step-2',
    },
    // GPS page
    {
      component_id: '4',
      step_id: '#gps-page-step-0',
    },
    {
      component_id: '4',
      step_id: '#gps-page-step-1',
    },
    {
      component_id: '4',
      step_id: '#gps-page-step-2',
    },
    // Invoices page
    {
      component_id: '5',
      step_id: '#invoices-page-step-0',
    },
    {
      component_id: '5',
      step_id: '#invoices-page-step-1',
    },
    {
      component_id: '5',
      step_id: '#invoices-page-step-2',
    },
    // Offers page
    {
      component_id: '6',
      step_id: '#offers-page-step-0',
    },
    {
      component_id: '6',
      step_id: '#offers-page-step-1',
    },
    {
      component_id: '6',
      step_id: '#offers-page-step-2',
    },
    // Resources page
    {
      component_id: '7',
      step_id: '#resources-page-step-0',
    },
    {
      component_id: '7',
      step_id: '#resources-page-step-1',
    },
    {
      component_id: '7',
      step_id: '#resources-page-step-2',
    },
    // Passenger reviews
    {
      component_id: '8',
      step_id: '#passenger-reviews-page-step-0',
    },
    {
      component_id: '8',
      step_id: '#passenger-reviews-page-step-0',
    },
    // Pricing zones
    {
      component_id: '9',
      step_id: '#pricing-zones-page-step-0',
    },
    {
      component_id: '9',
      step_id: '#pricing-zones-page-step-1',
    },
    {
      component_id: '9',
      step_id: '#pricing-zones-page-step-2',
    },
    // Pricing zone relations
    {
      component_id: '10',
      step_id: '#pricing-zone-relations-page-step-0',
    },
    {
      component_id: '10',
      step_id: '#pricing-zone-relations-page-step-1',
    },
    {
      component_id: '10',
      step_id: '#pricing-zone-relations-page-step-2',
    },
    // Advanced settings
    {
      component_id: '11',
      step_id: '#advanced-settings-step-0',
    },
    // Dashboard page
    {
      component_id: '12',
      step_id: '#dashboard-step-0',
    },
    {
      component_id: '12',
      step_id: '#dashboard-step-1',
    },
    {
      component_id: '12',
      step_id: '#dashboard-step-2',
    },
    {
      component_id: '12',
      step_id: '#dashboard-step-3',
    },
    {
      component_id: '12',
      step_id: '#dashboard-step-4',
    },
    // Affiliate page
    {
      component_id: '13',
      step_id: '#affiliate-page-step-0',
    },
    {
      component_id: '13',
      step_id: '#affiliate-page-step-1',
    },
    {
      component_id: '13',
      step_id: '#affiliate-page-step-2',
    },
    // Pricing zone hourly relations
    {
      component_id: '14',
      step_id: '#pricing-zone-hourly-relations-page-step-0',
    },
    {
      component_id: '14',
      step_id: '#pricing-zone-hourly-relations-page-step-1',
    },
    // Advanced settings
    {
      component_id: '15',
      step_id: '#distance-scales-step-0',
    },
  ];
}
