import { deleteClient, fetchAllClients, saveClient, updateClient, changeClientActiveStatus } from '@/api/clients';
import store from '@/store';
import i18n from '@/i18n/i18n';

export default {
  namespaced: true,
  state: { clients: [] },
  mutations: {
    SET_CLIENTS(state, clients) {
      state.clients = clients;
    },
  },
  actions: {
    async fetchAllClients({ commit }) {
      try {
        const response = await fetchAllClients();
        commit('SET_CLIENTS', response.data);
      } catch (error) {
        store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
      }
    },
    async saveClient(state, item) {
      return saveClient(item);
    },
    async updateClient(state, item) {
      return updateClient(item);
    },
    async deleteClient(state, item) {
      return deleteClient(item);
    },
    async changeClientActiveStatus(state, item) {
      return changeClientActiveStatus(item);
    },
  },
  getters: {
    getClients: (state) => state.clients,
  },
};
