import { getHttpClient } from './client';

export const fetchAllVehicleClasses = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/vehicle-class';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getVehicleClass = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/vehicle-class/' + data).then((response) => response.data);
};

export const saveVehicleClass = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/vehicle-class', 'POST', data).then(
    (response) => response.data
  );
};

export const updateVehicleClass = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/vehicle-class', 'POST', data).then(
    (response) => response.data
  );
};

export const deleteVehicleClass = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/vehicle-class/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const changeVehicleClassActiveStatus = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/vehicle-class-status/' + data.id, 'POST', data).then(
    (response) => response.data
  );
};
