import {
  fetchAllDrivingTemplates,
  saveDrivingTemplate,
  updateDrivingTemplate,
  deleteDrivingTemplate,
} from '@/api/drivingTemplates';
import store from '@/store';
import i18n from '@/i18n/i18n';

export default {
  namespaced: true,
  state: { drivingTemplates: [] },
  mutations: {
    SET_DRIVING_TEMPLATES(state, drivingTemplates) {
      state.drivingTemplates = drivingTemplates;
    },
  },
  actions: {
    async fetchAllDrivingTemplates({ commit }) {
      try {
        const response = await fetchAllDrivingTemplates();
        commit('SET_DRIVING_TEMPLATES', response.data);
      } catch (error) {
        store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
      }
    },
    async deleteDrivingTemplate(state, item) {
      return deleteDrivingTemplate(item);
    },
    async saveDrivingTemplate(state, item) {
      return saveDrivingTemplate(item);
    },
    async updateDrivingTemplate(state, item) {
      return updateDrivingTemplate(item);
    },
  },
  getters: {
    getDrivingTemplates: (state) => state.drivingTemplates,
  },
};
