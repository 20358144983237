import {
  getAllUsers,
  fetchAllDrivers,
  getAllSaasAdmins,
  getAllCustomers,
  saveUser,
  updateUser,
  deleteUser,
  changeUserActiveStatus,
  checkIfDriverIsAvailable,
} from '@/api/users';
import store from '@/store';
import i18n from '@/i18n/i18n';

export default {
  namespaced: true,
  state: { drivers: [] },
  mutations: {
    SET_DRIVERS(state, drivers) {
      state.drivers = drivers;
    },
  },
  actions: {
    async getAllUsers(state, item) {
      return getAllUsers(item);
    },
    async fetchAllDrivers({ commit }) {
      try {
        const response = await fetchAllDrivers();
        commit('SET_DRIVERS', response.data);
      } catch (error) {
        store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
      }
    },
    async getAllSaasAdmins() {
      return getAllSaasAdmins();
    },
    async getAllCustomers(state, item) {
      return getAllCustomers(item);
    },
    async saveUser(state, item) {
      return saveUser(item);
    },
    async updateUser(state, item) {
      return updateUser(item);
    },
    async deleteUser(state, item) {
      return deleteUser(item);
    },
    async changeUserActiveStatus(state, item) {
      return changeUserActiveStatus(item);
    },
    async checkIfDriverIsAvailable(state, item) {
      return checkIfDriverIsAvailable(item);
    },
  },
  getters: {
    getDrivers: (state) => state.drivers,
  },
};
