export default {
  sidebar: {
    fleet: 'Vloot',
    vehicles: 'Voertuigen',
    vehiclesClasses: 'Voertuigklassen',
    administration: 'Administratie',
    currencies: 'Valuta',
    currenciesAdministration: 'Valutabeheer',
    paymentTypes: 'Betalingsmethoden',
    paymentTypesAdministration: 'Beheer van betalingsmethoden',
    users: 'Gebruikers',
    usersAdministration: 'Gebruikersbeheer',
    clients: 'Klanten',
    clientsAdministration: 'Klantenbeheer',
    organizations: 'Organisaties',
    organizationsAdministration: 'Organisatiebeheer',
    saasAdmin: 'Saas-beheerder',
    administrators: 'Beheerders',
    saasAdminAdministration: 'Beheer van Saas-beheerder',
    statistics: 'Statistieken',
    generalOverview: 'Algemeen rapport',
    payment: 'Betaling',
    pricingRegions: "Prijzenregio's",
    pricingRegionsAdministration: "Beheer van prijsregio's",
    help: 'Help',
    drivings: 'Rijbestellingen',
    calendar: 'Kalender',
    tabularView: 'Tabellarisch overzicht',
    drivingTemplates: 'Reserveringssjablonen',
    expenses: 'Kosten',
    vehicleExpensesAdministration: 'Beheer van voertuigkosten',
    vehicleMaintenance: 'Voertuigonderhoud',
    vehicleMaintenanceAdministration: 'Beheer van voertuigonderhoud',
    organization: 'Organisatie',
    organizationAdminstration: 'Beheer van organisatie',
    packages: 'Pakketten',
    packagesChange: 'Wijzig pakketten',
    logout: 'Uitloggen',
    logoutText: 'Weet u zeker dat u wilt uitloggen?',
    unaccessibleRoutes: 'U kunt deze pagina niet openen. Upgrade het pakket voor volledige toegang.',
    transactions: 'Transacties',
    subscription: 'Abonnement',
    gpsMap: 'GPS kaart',
    team: 'Team',
    teamAdministration: 'Teambeheer',
    invoices: 'Facturen',
    invoicesAdministration: 'Factuurbeheer',
    drivingOffers: 'Aanbiedingen',
    drivingOffersAdministration: 'Beheer van aanbiedingen',
    dashboard: 'Dashboard',
    dashboardAdministration: 'Dashboardbeheer',
    resourceOverview: 'Overzicht van middelen',
    resourceOverviewAdministration: 'Beheer van middelenoverzicht',
    rides: 'Ritten',
    passengerReviews: 'Passagiersbeoordelingen',
    passengerReviewsAdministration: 'Beheer van passagiersbeoordelingen',
    pricingAndRates: 'Prijzen en tarieven',
    pricingZones: 'Zones en gebieden',
    pricingZonesAdministration: 'Beheer van zones en gebieden',
    pricingPerZones: 'Prijzen per zones',
    pricingPerZonesAdministration: 'Beheer van prijzen per zones',
    pricingPerHour: 'Prijzen per uur',
    pricingPerHourAdministration: 'Beheer van prijzen per uur',
    availability: 'Beschikbaarheid',
    availabilityAdministration: 'Beschikbaarheid van chauffeurs en voertuigen',
    distanceScales: 'Afstandsschalen',
    distanceScalesAdministration: 'Beheer van afstandsschalen',
    passengers: 'Passagiers',
    passengersAdministration: 'Passagiersbeheer',
    workLogs: 'Activiteitenlogboeken',
    workLogsAdministration: 'Beheer van activiteitenlogboeken',
    advancedSettings: 'Geavanceerde instellingen',
    advancedSettingsAdministration: 'Beheer van geavanceerde instellingen',
  },
  header: {
    organization: 'Organisatie',
    newDriving: 'Rijbestelling',
    dailyRent: 'Dagverhuur',
    transfer: 'Overdracht',
    notifications: 'Meldingen',
    changeLanguage: 'Taal wijzigen',
    help: 'Help',
    fromTemplate: 'Uit sjabloon',
    back: 'Terug',
  },
  footer: {
    poweredBy: 'Mogelijk gemaakt door',
    subscriptionTo: 'Uw abonnement op',
    trialEndsAt: 'Uw proefperiode voor',
    packageExpiresIn: 'abonnement verloopt over',
    packagePastDue: 'is verlopen.',
    clickToPay: 'Klik om nu te betalen.',
    affiliateUser: 'Als u alle functies van het platform wilt ontgrendelen, moet u zich abonneren ',
    here: 'HIER',
  },
  buttons: {
    ok: 'Oké',
    yes: 'Ja',
    no: 'Nee',
    save: 'Opslaan',
    send: 'Verzenden',
    sendMessage: 'Bericht verzenden',
    edit: 'Bewerken',
    add: 'Toevoegen',
    confirm: 'Bevestigen',
    cancel: 'Annuleren',
    cancelDriving: 'Rijbestelling annuleren',
    markAsDone: 'Markeer als voltooid',
    markAsNoShow: 'Markeren als niet verschenen',
    deleteDriving: 'Rijden verwijderen',
    newOrganisation: 'Nieuwe organisatie',
    changePackage: 'Pakketten wijzigen',
    downloadStats: 'Statistieken downloaden',
    downloadPDF: 'PDF downloaden',
    today: 'Vandaag',
    choose: 'Kiezen',
    login: 'Inloggen',
    generateFile: 'Contract genereren',
    generateDrivingOrder: 'Rijbestelling afdrukken',
    downloadFile: 'Bestand downloaden',
    activate: 'Activeren',
    deactivate: 'Deactiveren',
    showImage: 'Afbeelding weergeven',
    showDetails: 'Details weergeven',
    actions: 'Acties',
    delete: 'Verwijderen',
    open: 'Openen',
    show: 'Weergeven',
    options: 'Opties',
    prev: 'Vorige',
    next: 'Volgende',
    skip: 'Overslaan',
    finish: 'Voltooien',
    settings: 'Instellingen',
    cancelSubscription: 'Abonnement annuleren',
    backToEditOrganization: 'Terug naar organisatie bewerken',
    payNow: 'Betaal nu',
    back: 'Terug',
    integrationRoutes: 'Integratieroutes',
    transactions: 'Transacties',
    articlesOfLaw: 'Artikelen van wet',
    export: 'Exporteer',
    duplicate: 'Maak een kopie',
    makeReturnTrip: 'Maak een retourreis',
    selectOrganisation: 'Selecteer organisatie',
    advancedSettings: 'Geavanceerde instellingen',
    accept: 'Accepteren',
    reject: 'Weigeren',
    sendViaGnet: 'Versturen via GNET',
    printInvoice: 'Factuur afdrukken',
    enable: 'Inschakelen',
    disable: 'Uitschakelen',
    generatePaymentLink: 'Betaallink genereren',
    addItem: '+ Item toevoegen',
    visitWebsite: 'Bezoek onze website',
    buyMoreSms: 'Koop meer SMS',
    forward: 'Doorsturen',
    sendViaAffiliate: 'Verzend via Affiliate',
    markInvoiceAsPaid: 'Factuur als betaald markeren',
    markDrivingOfferAsPaid: 'Ritaanbod als betaald markeren',
    selectAll: 'Alles selecteren',
    viaAffiliateId: 'Toevoegen via affiliate-ID',
    registerPartner: 'Partner registreren',
    createNew: 'Nieuw maken',
    clone: 'Klonen',
    cloneExisting: 'Bestaande klonen',
    copyLink: 'Link kopiëren',
    markAsStarted: 'Markeren als gestart',
    copyReferenceLink: 'Kopieer referentielink',
  },
  tooltips: {
    save: 'Opslaan',
    edit: 'Bewerken',
    delete: 'Verwijderen',
    showImage: 'Afbeelding weergeven',
    open: 'Openen',
    show: 'Weergeven',
    showDetails: 'Details weergeven',
    downloadFile: 'Bestand downloaden',
    generateFile: 'Contract genereren',
    generateDrivingOrder: 'Rijbestelling afdrukken',
    activate: 'Activeren',
    deactivate: 'Deactiveren',
    cancel: 'Annuleren',
    cancelDriving: 'Rijbestelling annuleren',
    cannotMarkAsCanceled: 'De bestelling kan niet als geannuleerd worden gemarkeerd omdat deze al is geaccepteerd',
    markAsDone: 'Markeer als voltooid',
    cannotMarkAsDone: 'De bestelling kan niet als voltooid worden gemarkeerd, omdat deze nog niet heeft plaatsgevonden',
    markAsNoShow: 'Markeren als niet verschenen',
    cannotMarkAsNoShow:
      'De bestelling kan niet worden gemarkeerd als niet verschenen, omdat deze nog niet heeft plaatsgevonden',
    deleteDriving: 'Rijden verwijderen',
    cannotDelete:
      'Het spijt ons, maar om veiligheidsredenen kunnen gebruikers niet worden verwijderd terwijl ze momenteel zijn ingelogd.',
    cannotDeactivate:
      'Het spijt ons, maar om veiligheidsredenen kunnen gebruikers niet worden gedeactiveerd terwijl ze zijn ingelogd.',
    duplicate: 'Maak een kopie',
    makeReturnTrip: 'Maak een retourreis',
    refresh: 'Vernieuwen',
    selectOrganisation: 'Selecteer organisatie',
    sendViaGnet: 'Versturen via GNET',
    receivedViaGnet: 'Ontvangen via GNET',
    sentViaGnet: 'Verzonden via GNET',
    printInvoice: 'Factuur afdrukken',

    optionsDisabled: 'U moet de bestelling weigeren of accepteren om toegang te krijgen tot het optiemenu',
    cannotMakeReturnTrip: 'U kunt geen retourreis maken voor daghuur',
    cannotSendGnetOrderViaGnet: 'U kunt de gnet-bestelling niet via gnet verzenden',
    cannotSendViaGnetWithoutGnetId: 'U kunt de bestelling niet via gnet verzenden zonder gnet-id',
    cannotPrintInvoiceWithoutClient: 'U kunt geen factuur afdrukken zonder een klant te kiezen',
    cannotMarkAsDoneStatusRejected:
      'De bestelling kan niet worden gemarkeerd als voltooid omdat deze al als afgewezen is gemarkeerd',
    cannotMarkAsDoneStatusCanceled:
      'De bestelling kan niet worden gemarkeerd als voltooid omdat deze al als geannuleerd is gemarkeerd',
    cannotMarkAsDoneStatusDone:
      'De bestelling kan niet worden gemarkeerd als voltooid omdat deze al als voltooid is gemarkeerd',
    cannotMarkAsDoneStatusNoShow:
      'De bestelling kan niet worden gemarkeerd als voltooid omdat deze al als niet verschenen is gemarkeerd',
    cannotMarkAsNoShowStatusRejected:
      'De bestelling kan niet worden gemarkeerd als niet verschenen omdat deze al als afgewezen is gemarkeerd',
    cannotMarkAsNoShowStatusCanceled:
      'De bestelling kan niet worden gemarkeerd als niet verschenen omdat deze al als geannuleerd is gemarkeerd',
    cannotMarkAsNoShowStatusDone:
      'De bestelling kan niet worden gemarkeerd als niet verschenen omdat deze al als voltooid is gemarkeerd',
    cannotMarkAsNoShowStatusNoShow:
      'De bestelling kan niet worden gemarkeerd als niet verschenen omdat deze al als niet verschenen is gemarkeerd',
    cannotMarkAsCanceledGnet:
      'De bestelling kan niet als geannuleerd worden gemarkeerd omdat deze via gnet is ontvangen',
    cannotMarkAsCanceledStatusRejected:
      'De bestelling kan niet als geannuleerd worden gemarkeerd omdat deze al als afgewezen is gemarkeerd',
    cannotMarkAsCanceledStatusExpired:
      'De bestelling kan niet als geannuleerd worden gemarkeerd omdat deze al als verlopen is gemarkeerd',
    cannotMarkAsCanceledStatusCanceled:
      'De bestelling kan niet als geannuleerd worden gemarkeerd omdat deze al als geannuleerd is gemarkeerd',
    cannotMarkAsCanceledStatusDraft:
      'De bestelling kan niet als geannuleerd worden gemarkeerd omdat deze al als concept is gemarkeerd',
    cannotMarkAsCanceledStatusCompleted:
      'De bestelling kan niet als geannuleerd worden gemarkeerd omdat deze al als voltooid is gemarkeerd',
    cannotMarkAsCanceledStatusNoShow:
      'De bestelling kan niet als geannuleerd worden gemarkeerd omdat deze al als niet verschenen is gemarkeerd',
    cannotDeleteDrivingSentViaGnet: 'De bestelling kan niet worden verwijderd omdat deze via gnet is verzonden',
    cannotDeleteDrivingReceivedViaGnet:
      'De bestelling kan niet worden verwijderd omdat deze via gnet is ontvangen en geaccepteerd',
    generatePaymentLink: 'Betaallink genereren',
    cannotGeneratePaymentLInk: 'Betaallink kan niet worden gegenereerd omdat je Stripe niet hebt geactiveerd',
    disabledExport: 'U kunt gegevens één keer in de 24 uur exporteren',
    sendSms: 'SMS versturen',
    accept: 'Accepteren',
    reject: 'Weigeren',
    sendViaAffiliate: 'Verzend via Affiliate',
    receivedViaAffiliate: 'Ontvangen via Affiliate',
    sentViaAffiliate: 'Verzonden via Affiliate',
    cannotSendViaAffiliate: 'Je kunt de rijopdracht niet via de affiliate verzenden.',
    cannotForwardDrivingOrder: 'U kunt een rijopdracht die in het verleden ligt niet doorsturen.',
    cannotMarkAsCanceledAffiliate:
      'De rijopdracht kan niet als geannuleerd worden gemarkeerd omdat deze via de affiliate is ontvangen.',
    cannotMarkAsCanceled12Hours:
      'De bestelling kan niet als geannuleerd worden gemarkeerd omdat er 12 uur zijn verstreken sinds de ophaaltijd.',
    cannotDeleteActiveDriving: 'De actieve rijopdracht kan niet worden verwijderd.',
    noImageToShow: 'Geen afbeelding om te tonen',
    disabledForAffiliateUser:
      "Uw account is van het type 'Affiliate'. Deze actie is beschikbaar voor geabonneerde [Reguliere] accounts. Ga naar de link in de footer om te abonneren.",
    markInvoiceAsPaid: 'Factuur als betaald markeren',
    markDrivingOfferAsPaid: 'Ritaanbod als betaald markeren',
    invoiceIsPaid: 'Factuur is al gemarkeerd als betaald',
    drivingOfferIsPaid: 'Ritaanbod is al gemarkeerd als betaald',
    selectAreaToDelete: 'Selecteer het gebied dat u wilt verwijderen',
    deleteSelectedArea: 'Verwijder geselecteerd gebied',
    markAsStarted: 'Markeren als gestart',
    cannotMarkAsStarted:
      'De bestelling kan niet als gestart worden gemarkeerd omdat deze nog niet heeft plaatsgevonden',
    cannotMarkAsStartedStatusStarted:
      'De bestelling kan niet als gestart worden gemarkeerd omdat deze al als gestart is gemarkeerd',
    cannotMarkAsStartedStatusRejected:
      'De bestelling kan niet als gestart worden gemarkeerd omdat deze al als afgewezen is gemarkeerd',
    cannotMarkAsStartedStatusCanceled:
      'De bestelling kan niet als gestart worden gemarkeerd omdat deze al als geannuleerd is gemarkeerd',
    cannotMarkAsStartedStatusDone:
      'De bestelling kan niet als gestart worden gemarkeerd omdat deze al als voltooid is gemarkeerd',
    cannotMarkAsStartedStatusNoShow:
      'De bestelling kan niet als gestart worden gemarkeerd omdat deze al als niet verschenen is gemarkeerd',
    copyReferenceLink: 'Kopieer referentielink',
    noTemplatesAvailable: 'Geen reserveringssjablonen beschikbaar',
  },
  snackbar: {
    close: 'Sluiten',
    savedSuccessfully: 'Succesvol opgeslagen',
    excelDownloadedSuccessfully: 'Succesvol gedownload',
    deletedSuccessfully: 'Succesvol verwijderd',
    sentSuccessfully: 'Succesvol verzonden',
    statusChanged: 'status succesvol gewijzigd',
    activeStatusChanged: 'Actieve status succesvol gewijzigd',
    defaultStatusChanged: 'Standaard status succesvol gewijzigd',
    primaryStatusChanged: 'Primaire status succesvol gewijzigd',
    anErrorOccured: 'Er is een fout opgetreden, meld dit aan de ondersteuning',
    loadingCommentsFailed: 'Laden van opmerkingen mislukt',
    drivingCanceled: 'Rijbestelling is succesvol geannuleerd',
    drivingFinished: 'Rijbestelling is succesvol voltooid',
    drivingStarted: 'Rijopdracht is succesvol gemarkeerd als gestart',
    drivingNoShow: 'Ritbestelling is succesvol gemarkeerd als niet verschenen.',
    drivingDeleted: 'de rijopdracht is succesvol verwijderd',
    drivingAccepted: 'Rijopdracht is succesvol geaccepteerd',
    drivingRejected: 'Rijopdracht is succesvol geweigerd',
    planChanged: 'Plan succesvol gewijzigd',
    planChangeFailed: 'Het is niet mogelijk om het plan te wijzigen',
    saveChangesFirst: 'Sla eerst de wijzigingen op die u eerder heeft gemaakt',
    makeRoutesPublic: 'Routes zijn openbaar toegankelijk',
    makeRoutesNotPublic: 'Routes zijn niet langer openbaar toegankelijk',
    makePublicLinkActive: 'Publieke link is nu beschikbaar',
    makePublicLinkInactive: 'Publieke link is niet langer beschikbaar',
    integrationKeyCopied: 'Integratiesleutel gekopieerd',
    copyToken: 'Kopieer de token handmatig',
    publicLinkCopied: 'Publieke link gekopieerd',
    copyPublicLink: 'Kopieer de publieke link handmatig',
    referenceLinkCopied: 'Referentielink gekopieerd',
    copyReferenceLink: 'Kopieer de referentielink handmatig',
    organisationChanged: 'Organisatie succesvol gewijzigd',
    changeFailed: 'De wijziging is mislukt, probeer het opnieuw',
    loginFailed: 'Gebruikersnaam of wachtwoord is onjuist',
    organisationSuspended: 'Uw organisatie is opgeschort',
    userEmailDoesNotExists: 'Gebruiker met dit e-mailadres bestaat niet',
    canceledSubscrSuccessfully: 'Abonnement succesvol geannuleerd',
    toManyRequests: 'Te veel verzoeken. Probeer het over een minuut opnieuw.',
    visibleStatusChanged: 'Zichtbare status succesvol gewijzigd',
    noInternetConnection: 'Geen internet. Controleer uw internetverbinding.',
    backOnline: 'U bent weer online.',
    driverNotifiedToTurnGpsOn: 'Bestuurder geïnformeerd om GPS in te schakelen',
    savedSuccessfullyWithGnetError: 'GNET-synchronisatie mislukt, ritbestelling succesvol opgeslagen.',
    logoutFromAnotherDevice: 'U bent afgemeld van een ander apparaat.',
    stripeEnabled: 'Stripe succesvol geactiveerd',
    stripeDisabled: 'Stripe succesvol gedeactiveerd',
    finishStripeConnect: 'Voltooi uw Stripe-installatie',
    requestSentSuccessfully: 'Verzoek succesvol verzonden',
    pricingZoneNoMatch: 'We kunnen geen overeenkomstige prijszones vinden om de prijs te berekenen.',
    areaAlreadyExists: 'Dit gebied bestaat al',
    noDataForThisPostalCode: 'Geen gegevens beschikbaar voor deze postcode',
    affiliateRequestSentSuccessfully: 'Organisatie bestaat al, affiliate-verzoek is verzonden',
    organizationRegisteredSuccessfully: 'Organisatie succesvol geregistreerd',
    organizationIsAlreadyAPartner: 'Organisatie is al een partner',
    recordAlreadyExists: 'Dit record bestaat al.',
    tooManyUsers: 'U heeft te veel gebruikers voor dit pakket',
    priceCalculationFailed: 'Prijsberekening mislukt',
    workLogTimeRequired: 'Je moet een begin- en eindtijd invoeren, of minuten',
    zapierEnabled: 'Zapier succesvol geactiveerd',
    zapierDisabled: 'Zapier succesvol gedeactiveerd',
    lastUserRoleChange:
      'U kunt de rol van deze gebruiker niet wijzigen, omdat u anders zonder operators of dispatchers komt te zitten.',
  },
  filters: {
    filter: 'Filter',
    currentYear: 'Huidig jaar',
    lastYear: 'Vorig jaar',
    lastQuarter: 'Laatste kwartaal',
    lastThreeYears: 'Laatste drie jaar',
    filterByDriver: 'Filteren op bestuurder',
    filterByVehicle: 'Filteren op voertuig',
    filterByClient: 'Filteren op klanten',
    day: 'Dag',
    week: 'Week',
    month: 'Maand',
    vehicle: 'Voertuig',
    driving: 'Rijden',
    user: 'Gebruiker',
    fromDate: 'Vanaf datum',
    toDate: 'Tot datum',
    threeMonths: 'Drie maanden',
    halfYear: 'Half jaar',
    year: 'Jaar',
  },
  months: {
    jan: 'januari',
    feb: 'februari',
    mar: 'maart',
    apr: 'april',
    may: 'mei',
    jun: 'juni',
    jul: 'juli',
    aug: 'augustus',
    sep: 'september',
    oct: 'oktober',
    nov: 'november',
    dec: 'december',
    January: 'januari',
    February: 'februari',
    March: 'maart',
    April: 'april',
    May: 'mei',
    June: 'juni',
    July: 'juli',
    August: 'augustus',
    September: 'september',
    October: 'oktober',
    November: 'november',
    December: 'december',
  },
  usersPageHeaders: {
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    username: 'Gebruikersnaam',
    email: 'E-mail',
    phoneNumber: 'Telefoonnummer',
    role: 'Rol',
    device: 'Apparaat',
    operatingSystem: 'Besturingssysteem',
    browser: 'Browser',
    lastActive: 'Laatst actief',
    active: 'Actief',
    actions: 'Acties',
  },
  usersRoles: {
    masterAdmin: 'Hoofdbeheerder',
    manager: 'Manager',
    driver: 'Chauffeur',
    operator: 'Operator',
    externalAssociate: 'Externe medewerker',
    sales: 'Verkoop',
    salesLead: 'Verkoopleider',
    gnet: 'GNET',
    customer: 'Portaalklant',
    publicUser: 'Publieke gebruiker',
    zapier: 'Zapier',
  },
  masterUsersPageHeaders: {
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    username: 'Gebruikersnaam',
    email: 'E-mail',
    phoneNumber: 'Telefoonnummer',
    role: 'Rol',
    organisation: 'Organisatie',
    active: 'Actief',
    actions: 'Acties',
  },
  usersPage: {
    newItem: 'Nieuwe gebruiker invoeren',
    editItem: 'Gebruiker bewerken',
    id: 'Id',
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    username: 'Gebruikersnaam',
    password: 'Wachtwoord',
    phoneNumber: 'Telefoonnummer',
    role: 'Rol',
    client: 'Klant',
    email: 'E-mail',
    organisation: 'Organisatie',
    percentage: 'Percentage',
    externalAssociate: 'Externe medewerker',
    image: 'Afbeelding',
    deleteUser: 'Gebruiker verwijderen',
    deleteMessage: 'Weet u zeker dat u de gebruiker wilt verwijderen ',
    emailChanged: 'E-mailadres wijzigen',
    verificationEmailSent:
      'Het verzoek om het e-mailadres te wijzigen is verzonden. Om het proces te voltooien en de beveiliging van het gebruikersaccount te waarborgen, moet het nieuwe e-mailadres worden geverifieerd door te klikken op de verificatielink die naar het nieuwe e-mailadres is gestuurd. Het e-mailadres wordt pas gewijzigd nadat het met succes is geverifieerd.',
    passengersInfoAvailableToDriver: 'Passagiersinformatie beschikbaar voor de chauffeur',
    colorPicker:
      'Je kunt een unieke kleur kiezen voor elke gebruiker. Dit wordt gebruikt op de beschikbaarheidspagina voor eenvoudige identificatie.',
  },
  clientsPageHeaders: {
    name: 'Naam',
    phoneNumber: 'Telefoonnummer',
    address: 'Adres',
    email: 'E-mail',
    companyId: 'Bedrijfs-ID/Persoonlijke ID',
    clientType: 'Klanttype',
    percentage: 'Percentage',
    active: 'Actief',
    actions: 'Acties',
  },
  clientsPage: {
    newItem: 'Nieuwe klant invoeren',
    editItem: 'Klant bewerken',
    id: 'Id',
    name: 'Naam',
    address: 'Adres',
    phoneNumber: 'Telefoonnummer',
    email: 'E-mail',
    companyId: 'Bedrijfs-ID/Persoonlijke ID',
    clientType: 'Klanttype',
    individual: 'Individu',
    business: 'Zakelijke entiteit',
    percentage: 'Percentage',
    deleteClient: 'Klant verwijderen',
    deleteMessage: 'Weet u zeker dat u de klant wilt verwijderen ',
    tabs: {
      general: 'Algemeen',
      transfers: 'Overdrachten',
      users: 'Gebruikers',
      invoices: 'Facturen',
      cards: 'Kaarten',
    },
    publicId: 'Publieke ID',
    referenceLink: 'Referentielink',
    publicFormNotEnabled:
      'Als je toegang wilt tot de referentielink, ga dan naar de geavanceerde instellingen en maak het openbare formulier beschikbaar',
  },
  drivingsHeaders: {
    drivingNumber: 'Rijbestelnummer',
    drivingType: 'Boekingstype',
    drivingStatus: 'Status',
    locationFrom: 'Vertrekpunt',
    locationTo: 'Bestemming',
    vehicleClass: 'Voertuigklasse',
    vehicle: 'Voertuig',
    driversName: 'Chauffeur',
    startTime: 'Ophaaltijd',
    actions: 'Acties',
    exportExcel: 'Exporteer excel',
    price: 'Prijs',
    totalPrice: 'Totale prijs',
    confirmed: 'Bevestigd',
    paid: 'Betaald',
    numberOfDrivings: 'Aantal rijopdrachten',
    templateName: 'Sjabloonnaam',
    visibleToClients: 'Zichtbaar voor klanten',
  },
  drivingTemplatesPage: {
    newItem: 'Nieuwe invoer van reserveringssjabloon',
    editItem: 'Wijziging van reserveringssjabloon',
    deleteDrivingTemplate: 'Reserveringssjabloon verwijderen',
    deleteMessage: 'Weet u zeker dat u dit reserveringssjabloon wilt verwijderen?',
  },
  vehiclesHeaders: {
    plateNumber: 'Kenteken',
    brand: 'Voertuigmerk',
    vehicleClass: 'Voertuigklasse',
    passengersNumber: 'Aantal passagiers',
    fuelConsumption: 'Brandstofverbruik',
    yearOfManufacture: 'Bouwjaar',
    pricePerKm: 'Prijs per km',
    pricePerMi: 'Prijs per mijl',
    pricePerHour: 'Prijs per uur',
    pricePerWaitingHour: 'Prijs per wachturen',
    active: 'Actief',
    actions: 'Acties',
  },
  vehiclesPage: {
    id: 'Id',
    newItem: 'Nieuwe voertuiginvoer',
    editItem: 'Voertuig bewerken',
    vehiclePlates: 'Kenteken',
    vehicleBrand: 'Voertuigmerk',
    vehicleClass: 'Voertuigklasse',
    passengersNumber: 'Aantal passagiers',
    fuelConsumption: 'Brandstofverbruik (100 km)',
    fuelConsumptionMi: 'Brandstofverbruik auto (100 mijl)',
    manufactureYear: 'Bouwjaar',
    price: 'Prijs per km',
    priceMi: 'Prijs per mijl',
    hourPrice: 'Prijs per uur',
    image: 'Afbeelding',
    hourWaitingPrice: 'Prijs per wachturen',
    deleteMessage: 'Weet u zeker dat u dit voertuig wilt verwijderen?',
    tabs: {
      general: 'Algemeen',
      transfers: 'Transfers',
      costs: 'Kosten',
      damages: 'Schades',
    },
    licence: 'Licentienummer',
    color: 'Kleur',
    colorPicker:
      'Je kunt een unieke kleur kiezen voor elke voertuiginvoer. Dit wordt gebruikt op de beschikbaarheidspagina voor eenvoudige identificatie.',
  },
  vehicleClassesHeaders: {
    vehicleClass: 'Voertuigklasse',
    numberOfPassengers: 'Aantal passagiers',
    numberOfSuitcases: 'Aantal koffers',
    pricePerKm: 'Prijs per km',
    pricePerMi: 'Prijs per mijl',
    pricePerHour: 'Prijs per uur',
    pricePerWaitingHour: 'Prijs per wachturen',
    availableForPublicBookingForm: 'Beschikbaar voor openbaar boekingsformulier',
    active: 'Actief',
    actions: 'Acties',
  },
  vehicleClassesPage: {
    newItem: 'Nieuwe voertuigklasse-invoer',
    editItem: 'Voertuigklasse bewerken',
    id: 'Id',
    vehicleClass: 'Voertuigklasse',
    numberOfPassengers: 'Aantal passagiers',
    numberOfSuitcases: 'Aantal koffers',
    availableForPublicBookingForm: 'Beschikbaar voor openbaar boekingsformulier',
    price: 'Prijs per km',
    priceMi: 'Prijs per mijl',
    hourPrice: 'Prijs per uur',
    image: 'Afbeelding',
    hourWaitingPrice: 'Prijs per wachturen',
    deleteMessage: 'Weet u zeker dat u deze voertuigklasse wilt verwijderen?',
  },
  expensesHeaders: {
    name: 'Naam',
    vehicle: 'Voertuig',
    type: 'Type',
    description: 'Beschrijving',
    price: 'Prijs',
    expenseDate: 'Datum',
    actions: 'Actie',
    driving: 'Rijden',
    exportExcel: 'Exporteer excel',
  },
  vehicleExpensesTypes: {
    minorService: 'Kleine service',
    majorService: 'Grote service',
    fuel: 'Brandstof',
    tires: 'Banden',
    parking: 'Parkeren',
    other: 'Overige',
  },
  expensesPage: {
    newItem: 'Nieuwe invoer van kosten',
    editItem: 'Wijziging van kosten',
    id: 'Id',
    name: 'Naam',
    vehicle: 'Voertuig',
    costType: 'Kostentype',
    price: 'Prijs',
    currency: 'Valuta',
    costTime: 'Kostentijd',
    description: 'Omschrijving',
    deleteMessage: 'Weet u zeker dat u deze kosten wilt verwijderen?',
    costTypes: {
      'Mali servis': 'Kleine service',
      'Veliki servis': 'Grote service',
      Gorivo: 'Brandstof',
      Gume: 'Banden',
      Parking: 'Parkeren',
      Ostalo: 'Overig',
    },
  },
  vehicleMaintenanceHeaders: {
    name: 'Voertuig',
    details: 'Details',
    dateFrom: 'Datum vanaf',
    dateTo: 'Datum tot',
    actions: 'Acties',
  },
  vehicleMaintenancePage: {
    newItem: 'Nieuwe invoer van voertuigstoring',
    editItem: 'Wijziging van voertuigstoring',
    id: 'Id',
    vehicle: 'Voertuig',
    description: 'Omschrijving',
    dateFrom: 'Tijd vanaf',
    dateTo: 'Tijd tot',
    deleteMessage: 'Weet u zeker dat u deze voertuigstoring wilt verwijderen?',
  },
  customerCardsHeaders: {
    cardholderName: 'Naam kaarthouder',
    cardNumber: 'Kaartnummer',
    expiryDate: 'Vervaldatum',
    cvv: 'CVV',
    actions: 'Acties',
  },
  customerCardsPage: {
    newItem: 'Nieuwe kaartinvoer',
    editItem: 'Kaartwijziging',
    cardholderName: 'Naam kaarthouder',
    cardNumber: 'Kaartnummer',
    expiryDate: 'Vervaldatum',
    cvv: 'CVV',
    deleteCard: 'Kaart verwijderen',
    deleteMessage: 'Weet u zeker dat u deze kaart wilt verwijderen?',
  },
  pricingRegionsHeaders: {
    name: 'Naam',
    actions: 'Acties',
  },
  pricingRegionsPage: {
    newItem: 'Nieuwe invoer van prijsregio',
    editItem: 'Wijziging van prijsregio',
    name: 'Naam',
    chooseCurrency: 'Kies valuta',
    soloPrice: 'Solo prijs - Jaar',
    soloPaddlePriceId: 'Solo paddle prijs-ID - Jaar',
    team5Price: 'Team 5 prijs - Jaar',
    team5PaddlePriceId: 'Team 5 paddle prijs ID - Jaar',
    team10Price: 'Team 10 prijs - Jaar',
    team10PaddlePriceId: 'Team 10 paddle prijs ID - Jaar',
    team25Price: 'Team 25 prijs - Jaar',
    team25PaddlePriceId: 'Team 25 paddle prijs ID - Jaar',
    team50Price: 'Team 50 prijs - Jaar',
    team50PaddlePriceId: 'Team 50 paddle prijs ID - Jaar',
    soloPriceMonthly: 'Solo prijs - Maand',
    soloPaddlePriceIdMonthly: 'Solo paddle prijs-ID - Maand',
    team5PriceMonthly: 'Team 5 prijs - Maand',
    team5PaddlePriceIdMonthly: 'Team 5 paddle prijs ID - Maand',
    team10PriceMonthly: 'Team 10 prijs - Maand',
    team10PaddlePriceIdMonthly: 'Team 10 paddle prijs ID - Maand',
    team25PriceMonthly: 'Team 25 prijs - Maand',
    team25PaddlePriceIdMonthly: 'Team 25 paddle prijs ID - Maand',
    team50PriceMonthly: 'Team 50 prijs - Maand',
    team50PaddlePriceIdMonthly: 'Team 50 paddle prijs ID - Maand',
    chooseCountries: 'Kies landen',
    description: 'Omschrijving',
    deletePricingRegion: 'Verwijder prijsregio',
    deleteMessage: 'Weet je zeker dat je deze prijsregio wilt verwijderen?',
  },
  notificationsHeaders: {
    time: 'Tijd',
    notification: 'Melding',
  },
  notificationsPage: {
    showUnseenOnly: 'Alleen ongelezen weergeven',
    markAllAsRead: 'Alles als gelezen markeren',
  },
  organisationsHeaders: {
    name: 'Organisatie',
    phoneNumber: 'Telefoonnummer',
    email: 'E-mail',
    address: 'Adres',
    companyId: 'Bedrijfs-ID',
    vat: 'BTW',
    active: 'Actief',
    createdAt: 'Geregistreerd',
    lastActivityAt: 'Laatste activiteit op',
    actions: 'Acties',
    billingDate: 'Factuurdatum',
    referral: 'Aanbeveler',
    subscriptionType: 'Abonnementstype',
    subscriptionStatus: 'Abonnementsstatus',
    country: 'Land',
  },
  organisationsPage: {
    newItem: 'Nieuwe gebruikersinvoer',
    editItem: 'Bewerk gebruiker',
    id: 'Id',
    name: 'Organisatie',
    address: 'Adres',
    phoneNumber: 'Telefoonnummer',
    email: 'E-mail',
    vat: 'BTW',
    companyId: 'Bedrijfs-ID',
    warrantNumber: 'Beslissingsnummer',
    image: 'Afbeelding',
    signature: 'Handtekening',
    seal: 'Stempel',
    deactivateMessage: 'Weet u zeker dat u ',
    deactivate: 'DEACTIVEREN',
    activate: 'ACTIVEREN',
    organisation: 'organisatie',
    deleteOrganisation: 'Verwijder organisatie',
    deleteMessage: 'Weet u zeker dat u deze organisatie wilt verwijderen?',
    cancelSubscrMessage:
      'Weet u zeker dat u uw abonnement wilt opzeggen? Wees u ervan bewust dat door het bevestigen van deze actie: 1. Het account van uw organisatie en alle bijbehorende gegevens permanent worden verwijderd. Deze actie kan niet ongedaan worden gemaakt. 2. U wordt direct na de annulering automatisch uit het systeem uitgelogd. 3. U zult in de toekomst geen toegang meer hebben om in te loggen of gegevens gerelateerd aan uw organisatie op te halen. Wij raden u ten zeerste aan om alle benodigde informatie te controleren of kritieke gegevens te exporteren voordat u doorgaat met deze onomkeerbare actie. Als u vragen heeft of hulp nodig heeft, aarzel dan niet om contact op te nemen met ons ondersteuningsteam. Wilt u doorgaan met de annulering?',
    allDrivings: 'Totaal aantal rijbestellingen',
    allDrivingsAccepted: 'Totaal geaccepteerde rijbestellingen',
    allDrivingsCanceled: 'Totaal geweigerde rijbestellingen',
    allVehicles: 'Totaal aantal voertuigen',
    allUsers: 'Totaal aantal gebruikers',
    areYouSure: 'Weet u het zeker?',
    makeRoutesAvailable: 'Maak integratieroutes beschikbaar',
    percentage: 'Percentage',
    deleteImageMessage: 'Weet u zeker dat u deze afbeelding wilt verwijderen?',
    deleteImageTitle: 'Elimina Immagine',
    cancelSubscriptionTitle: 'Abonnement annuleren',
    subscriptionType: 'Abonnementstype',
    billingDate: 'Factuurdatum',
    articlesOfLaw: 'Artikelen van wet',
    country: 'Land',
    referent: 'Referent',
    language: 'Taal',
    gnetId: 'GNET-ID',
    affiliateId: 'Affiliate ID',
    trialEndsAt: 'Proefperiode eindigt op',
    languageTooltip:
      'Standaard taal voor nieuwe gebruikers en de taal waarin alle bedrijfs-e-mails (niet individuele gebruikers-e-mails) zullen worden verzonden.',
    organisationType: 'Organisatietype',
    regular: 'Regulier',
    affiliateSender: 'Affiliate afzender',
    affiliateReceiver: 'Affiliate ontvanger',
    dispatcher: 'Dispatcher',
    referral: 'Aanbeveler',
    selectLocation: 'Selecteer locatie',
    changeLocation: 'Wijzig locatie',
    timeZone: 'Tijdzone',
    onlyActive: 'Alleen actief',
  },
  subscriptionsHeaders: {
    invoiceNumber: 'Factuurnummer',
    status: 'Status',
    total: 'Totaal',
    tax: 'Belasting',
    currency: 'Valuta',
    billedAt: 'Gefactureerd op',
    actions: 'Acties',
  },
  subscriptionsPage: {
    newItem: 'Nieuwe transactie-invoer',
    editItem: 'Transactie bewerken',
    id: 'Id',
    invoiceNumber: 'Factuurnummer',
    status: 'Status',
    sent: 'Verzonden',
    completed: 'Voltooid',
    total: 'Totaal',
    currency: 'Valuta',
    billedAt: 'Gefactureerd op',
    actions: 'Acties',
    deleteSubscription: 'Transactie verwijderen',
    deleteMessage: 'Weet u zeker dat u deze transactie wilt verwijderen?',
    currentSubscription: 'Huidig pakket',
    nextBillingDate: 'Volgende facturatiedatum',
    numberOfSeatsTaken: 'Aantal bezette plaatsen',
    numberOfSeatsAvailable: 'Aantal beschikbare plaatsen',
    pastDue: 'Achterstallig',
  },
  currenciesHeaders: {
    name: 'Naam',
    code: 'Code',
    symbol: 'Symbool',
    active: 'Actief',
    default: 'Primair',
    actions: 'Acties',
  },
  currenciesPage: {
    newItem: 'Nieuwe valutainvoer',
    editItem: 'Valutawijziging',
    id: 'Id',
    currency: 'Valuta',
    code: 'Code',
    symbol: 'Symbool',
    deleteMessage: 'Weet u zeker dat u deze valuta wilt verwijderen?',
  },
  paymentMethodsHeaders: {
    name: 'Naam',
    active: 'Actief',
    default: 'Primair',
    priceVisible: 'Prijs zichtbaar',
    actions: 'Acties',
  },
  paymentMethodsPage: {
    newItem: 'Nieuwe betaalmethode toevoegen',
    editItem: 'Bewerk betaalmethode',
    id: 'Id',
    paymentMethod: 'Betaalmethode',
    deleteMessage: 'Weet u zeker dat u deze betaalmethode wilt verwijderen?',
  },
  feedbacksHeaders: {
    subject: 'Naam',
    organisation: 'Organisatie',
    user: 'Gebruiker',
    actions: 'Acties',
  },
  checkpointsHeaders: {
    checkpointLocation: 'Locatie van tussenpunt',
    arrivalTime: 'Aankomsttijd',
    actions: 'Acties',
  },
  tables: {
    noDataText: 'Geen gegevens',
    search: 'Zoeken',
    drivings: 'Ritopdrachten',
    drivingsByPage: 'Ritopdrachten per pagina',
    drivingTemplates: 'Reserveringssjablonen',
    drivingTemplatesByPage: 'Sjablonen per pagina',
    vehicles: 'Voertuigen',
    vehiclesByPage: 'Voertuigen per pagina',
    vehiclesClasses: 'Voertuigklassen',
    vehiclesClassesByPage: 'Voertuigklassen per pagina',
    expenses: 'Kosten',
    expensesByPage: 'Kosten per pagina',
    vehiclesMaintenance: 'Voertuigstoringen',
    vehiclesMaintenanceByPage: 'Voertuigstoringen per pagina',
    reportsByPage: 'Rapporten per pagina',
    currencies: 'Valuta',
    currencieseByPage: 'Valuta per pagina',
    payments: 'Betaalmethoden',
    paymentsByPage: 'Betaalmethoden per pagina',
    saasAdministrators: 'Alle beheerders',
    users: 'Alle gebruikers',
    usersByPage: 'Gebruikers per pagina',
    clients: 'Alle klanten',
    clientsByPage: 'Klanten per pagina',
    organisations: 'Alle organisaties',
    organisationsByPage: 'Organisaties per pagina',
    cards: 'Kaarten',
    cardsByPage: 'Kaarten per pagina',
    transactions: 'Transacties',
    transactionsByPage: 'Transacties per pagina',
    subscriptions: 'Abonnementen',
    subscriptionsByPage: 'Abonnementen per pagina',
    pricingRegions: "Prijzenregio's",
    pricingRegionsByPage: "Prijzenregio's per pagina",
    feedback: 'Feedbacks',
    feedbacksByPage: 'Feedbacks per pagina',
    notifications: 'Meldingen',
    notificationsByPage: 'Meldingen per pagina',
    all: 'Alles',
    passengers: 'Passagiers',
    passengersByPage: 'Passagiers per pagina',
    of: 'van',
    checkpoints: 'Tussenpunten',
    checkpointsByPage: 'Tussenpunten per pagina',
    invoices: 'Facturen',
    invoicesByPage: 'Facturen per pagina',
    drivingOffers: 'Rijaanbiedingen',
    drivingOffersByPage: 'Rijaanbiedingen per pagina',
    smsMessages: 'SMS-berichten',
    smsMessagesByPage: 'SMS-berichten per pagina',
    resourcesUsed: 'Gebruikte middelen',
    resourcesUsedByPage: 'Middelen per pagina',
    inNext3Days: 'In de komende 3 dagen',
    notConfirmedOrPaid: 'Niet bevestigd of betaald',
    inNext5Days: 'In de komende 5 dagen',
    latestReviews: 'Laatste beoordelingen',
    passengerReviews: 'Passagiersbeoordelingen',
    driversByPage: 'Chauffeurs per pagina',
    reviewsByPage: 'Beoordelingen per pagina',
    partners: 'Partners',
    partnersByPage: 'Partners per pagina',
    sentRequests: 'Verzonden aanvragen',
    receivedRequests: 'Ontvangen aanvragen',
    requestsByPage: 'Aanvragen per pagina',
    pricingZones: 'Zones en gebieden',
    pricingZonesByPage: 'Zones en gebieden per pagina',
    pricingPerZones: 'Prijzen per zones',
    pricingPerZonesByPage: 'Prijzen per pagina',
    pricingPerHour: 'Prijzen per uur',
    pricingPerHourByPage: 'Prijzen per pagina',
    workLogs: 'Activiteitenlogboeken',
    workLogsByPage: 'Activiteitenlogboeken per pagina',
    loading: 'Laden',
  },
  statistics: {
    numberOfAcceptedDrivings: 'Aantal geaccepteerde ritopdrachten',
    numberOfRejectedDrivings: 'Aantal afgewezen ritopdrachten per chauffeur',
    vehicleDrivingsNumber: 'Aantal ritopdrachten per voertuig',
    vehicleProfit: 'Winst per voertuig',
    monthDrivingsNumber: 'Aantal ritopdrachten per maand',
    driverProfit: 'Winst per chauffeur',
  },
  reportsHeaders: {
    vehicle: 'Voertuig',
    expenses: 'Kosten',
    profit: 'Winst',
    earnings: 'Inkomsten',
    daysWorking: 'Werkdagen',
    usageIndex: 'Gebruiksindex',
    client: 'Klant',
    numberOfDrivings: 'Aantal Ritten',
    percentForClient: 'Prijs voor de klant',
    sumOfPrice: 'Hoeveelheid',
  },
  packages: {
    active: 'Actief',
    starterTitle: 'Starter',
    monthlyPackagesMessage: 'Maandelijkse abonnement per organisatie.',
    starterText: 'Ideaal voor individuen of kleine teams die alleen basisfunctionaliteit nodig hebben.',
    starterListItemOne: 'Mobiele applicaties',
    starterListItemTwo: 'Pushmeldingen',
    starterListItemThree: 'Toegang tot beheer',
    starterListItemFour: 'Website-integratie',
    standardTitle: 'Standaard',
    standardText: 'Perfect voor organisaties met meer dan 5 chauffeurs.',
    standardListItemOne: 'INBEGREPEN STARTER-pakket',
    standardListItemTwo: 'Geen limiet op het aantal chauffeurs',
    standardListItemThree: '99,9% uptime',
    standardListItemFour: 'Rapporten en voertuigbeheer',
    premiumTitle: 'Premium',
    premiumText: 'Perfect voor limousine-serviceorganisaties die werken met nauwkeurige gegevens.',
    premiumListItemOne: 'INBEGREPEN STANDAARD-pakket',
    premiumListItemTwo: 'BI & Kostenprognose',
    premiumListItemThree: 'Gegevens exporteren',
    premiumListItemFour: 'Wekelijkse en maandelijkse rapporten',
    warningOne: '* De getoonde prijzen zijn maandelijkse abonnementskosten per gebruiker',
    warningTwo:
      '* Na overschakeling naar een nieuw pakket is het niet mogelijk om binnen de volgende 6 maanden naar een kleiner pakket over te schakelen',
    warningThree:
      '* Gebruikers van het demoplan hebben inzicht in de functionaliteiten die worden geboden door het premium pakket',
    warningOneAnnualPackage: 'Meer nodig? Heeft u een grotere organisatie? We hebben een ENTERPRISE-pakket voor u.',
    warningTwoAnnualPackage: 'en ons verkoopteam zal binnenkort contact met u opnemen.',
    packageChange: 'Pakket wijzigen',
    changePackage: 'Weet u zeker dat u wilt overschakelen naar',
    package: 'pakket',
    annualPricing: 'Jaarlijkse prijzen',
    monthlyPricing: 'Maandelijkse prijzen',
    packageType5: 'Team 5',
    packageType10: 'Team 10',
    packageType25: 'Team 25',
    packageType50: 'Team 50',
    'Solo - Yearly': 'Solo - Annuale',
    'Team 5 - Yearly': 'Squadra 5 - Annuale',
    'Team 10 - Yearly': 'Squadra 10 - Annuale',
    'Team 25 - Yearly': 'Squadra 25 - Annuale',
    'Team 50 - Yearly': 'Squadra 50 - Annuale',
    'Solo - Monthly': 'Solo - Mensile',
    'Team 5 - Monthly': 'Squadra 5 - Mensile',
    'Team 10 - Monthly': 'Squadra 10 - Mensile',
    'Team 25 - Monthly': 'Squadra 25 - Mensile',
    'Team 50 - Monthly': 'Squadra 50 - Mensile',
    annualPackagesMessage: 'Jaarabonnement per organisatie.',
    packageTypeSoloMainMessage: 'Slechts 1 gebruikersaccount.',
    packageType5MainMessage: 'Maximaal 5 gebruikersaccounts.',
    packageType10MainMessage: 'Maximaal 10 gebruikersaccounts.',
    packageType25MainMessage: 'Maximaal 25 gebruikersaccounts.',
    packageType50MainMessage: 'Maximaal 50 gebruikersaccounts.',
    allFeaturesIncluded: 'Alle functies inbegrepen.',
    unlimitedRides: 'Onbeperkt aantal ritten en voertuigen.',
    trialPeriod: 'Proefperiode inbegrepen.',
    onboardingFeeOptional: 'Invoeringskosten optioneel.',
    noHiddenCosts: 'Geen verborgen kosten.',
    freeSmsFlights10: "15 gratis SMS'jes en 50 vluchtcontroles per maand.",
    freeSmsFlights25: "35 gratis SMS'jes en 100 vluchtcontroles per maand.",
    freeSmsFlights50: "50 gratis SMS'jes en 200 vluchtcontroles per maand.",
  },
  successPaymentPage: {
    message: 'Betaling succesvol. Dank je wel.',
  },
  demoExpiring: {
    notification: 'Melding',
    expiredSubscriptionMessage:
      'Wij informeren u dat uw abonnement op dit platform is verlopen. Om onze diensten te blijven gebruiken, vernieuw alstublieft uw abonnement.',
  },
  login: {
    username: 'Gebruikersnaam',
    password: 'Wachtwoord',
    rememberMe: 'Onthoud mij',
    login: 'Inloggen',
    noAccount: 'Heeft u nog geen account? U kunt zich hier registreren',
    forgetPassword: 'Wachtwoord vergeten? U kunt het hier opnieuw instellen',
    registerHere: 'Hier',
    email: 'E-mail',
    enterEmail: 'Voer uw e-mailadres in',
    enterPassword: 'Voer uw nieuw wachtwoord in',
    signInWithGoogle: 'Inloggen met Google',
  },
  registration: {
    register: 'Registreren',
    name: 'Organisatienaam',
    address: 'Adres',
    phoneNumber: 'Telefoonnummer',
    email: 'E-mail',
    companyId: 'Bedrijfs-ID',
    vat: 'BTW',
    language: 'Taal',
    package: 'Pakket',
    accept: 'Ik accepteer',
    terms: 'de gebruiksvoorwaarden',
    successfullyRegistered: 'De gebruiker is succesvol geregistreerd. Je ontvangt een e-mail met een verificatielink.',
    haveAccount: 'Heb je al een account? Je kunt inloggen',
    loginHere: 'Hier',
    trialPeriodInfo:
      'Inclusief is een gratis proefperiode van 14 dagen. Facturatie volgt na afloop van de proefperiode.',
  },
  driving: {
    drivingType: 'Boekingstype',
    general: 'Algemene informatie',
    newDriving: 'Plan een transfer',
    newDailyRent: 'Plan een dagelijkse verhuur',
    areYouSure: 'Weet je het zeker?',
    vehicleIsUnavailable: 'Het voertuig is bezet',
    vehicleIsDamaged: 'Het voertuig is beschadigd',
    sureAboutThisVehicle: 'Weet je zeker dat je dit voertuig wilt kiezen?',
    driverUnavaliable: 'De chauffeur is bezig',
    sureAboutThisDriver: 'Weet je zeker dat je deze chauffeur wilt selecteren?',
    checkVehicleSeatAvailability: 'Dit voertuig kan niet zoveel passagiers vervoeren',
    cancelDriving: 'Weet je zeker dat je de ritopdracht wilt annuleren?',
    finishDriving: 'Weet je zeker dat je de ritopdracht wilt beëindigen?',
    noShowDriving: 'Weet u zeker dat u de ritbestelling als niet verschenen wilt markeren?',
    markDrivingAsStarted: 'Weet u zeker dat u de rijopdracht als gestart wilt markeren?',
    deleteDriving: 'Weet je zeker dat je de ritopdracht wilt verwijderen?',
    newTransfer: 'Nieuwe overdracht',
    driving: 'Rijopdracht',
    comments: 'Opmerkingen',
    id: 'Id',
    locationFrom: 'Vertrekpunt',
    locationTo: 'Bestemming',
    vehicleClass: 'Voertuigklasse',
    vehicle: 'Voertuig',
    driver: 'Chauffeur',
    pickupTime: 'Ophaaltijd',
    clientConfirmed: 'Klant bevestigd',
    roundTrip: 'Retourreis',
    description: 'Beschrijving',
    extraInfo: 'Extra informatie',
    drivingNotAccepted: 'Ze hebben de rijopdracht niet geaccepteerd:',
    currency: 'Valuta',
    paymentMethod: 'Betaalmethoden',
    price: 'Prijs',
    charged: 'In rekening gebracht',
    paid: 'Betaald',
    distance: 'Afstand (km)',
    distanceMi: 'Afstand (mijl)',
    expectedTime: 'Verwachte duur',
    waitingTime: 'Wachttijd (h)',
    EnterTheNumberOfHoursWaiting: 'Voer het aantal wachturen in',
    dropOffTime: 'Aankomsttijd',
    comebackTime: 'Terugkeertijd',
    acceptUntil: 'Accepteer tot',
    drivingRequired: 'Is een rijopdracht verplicht',
    comment: 'Opmerking',
    attachments: 'Bijlagen',
    addFiles: '+ Bestanden toevoegen',
    deleteFiles: 'Weet je zeker dat je dit bestand wilt verwijderen?',
    passengers: 'Passagiers',
    addPassenger: '+ Passagier toevoegen',
    deletePassenger: 'Weet je zeker dat je deze passagier wilt verwijderen?',
    chooseClient: 'Kies klant',
    costs: 'Kosten',
    checkpoints: 'Tussenpunten',
    addCheckpoint: 'Voeg tussenpunt toe',
    deleteCheckpoint: 'Weet u zeker dat u dit tussenpunt wilt verwijderen?',
    waitingBoardText: 'Wachttekst op het bord',
    flightNumber: 'Vluchtnummer',
    flightInfo: 'Vluchtinformatie',
    createdBy: 'Rijbestelling gemaakt om <b>{time}</b> door <b>{user}</b>',
    client: 'Klant',
    gnet: 'GNET',
    note: 'Opmerking',
    noteDriver: 'Opmerking voor bestuurder',
    noteDispatcher: 'Opmerking voor dispatcher',
    passenger: 'Passagier',
    fullName: 'Volledige naam',
    phoneNumber: 'Telefoonnummer',
    email: 'E-mail',
    passengersNumber: 'Aantal passagiers',
    babySeatsNumber: 'Aantal kinderzitjes',
    suitcasesNumber: 'Aantal koffers',
    stopsNumber: 'Aantal stops',
    steps: {
      mainInfo: 'Hoofdinfo',
      additionalInfo: 'Aanvullende informatie',
      review: 'Beoordeling',
    },
    commissionAmount: 'Commissie',
    sendSms: 'SMS versturen',
    message: 'Bericht',
    acceptDriving: 'Weet je zeker dat je de rijopdracht wilt accepteren?',
    rejectDriving: 'Weet je zeker dat je de rijopdracht wilt weigeren?',
    calculatePriceViaZonePricing: 'Bereken prijs via de prijszone',
    disableCalculatePriceWithoutHours: 'Kan de prijs niet berekenen zonder aantal wachttijden',
    disableCalculatePriceViaZonePricing:
      'Kan prijs niet berekenen via de prijszone zonder locatie, valuta en voertuigklasse',
    calculatePriceViaDistanceScales: 'Bereken prijs via afstand',
    disableCalculatePriceViaDistanceScales:
      'Kan prijs niet berekenen via afstand zonder afstand, valuta en voertuigklasse',
    numberOfPassengers: 'Aantal passagiers',
    numberOfSuitcases: 'Aantal koffers',
    vat: 'BTW',
    vatPercentage: 'BTW-percentage',
    priceType: 'Prijstype',
    accessibleForWheelchair: 'Toegankelijk voor rolstoelen',
    priceForWaiting: 'Prijs voor wachten',
    numberOfHours: 'Aantal uren',
    getAQuote: 'Vraag een offerte aan',
    selectPassenger: 'Selecteer passagier',
    clientId: 'Klant-ID',
    perDistance: '(per afstand)',
    perHour: '(per uur)',
    templateName: 'Sjabloonnaam',
    visibleToClients: 'Zichtbaar voor klanten',
  },
  drivingStatus: {
    pending: 'In afwachting',
    accepted: 'Geaccepteerd',
    rejected: 'Afgewezen',
    expired: 'Verlopen',
    canceled: 'Geannuleerd',
    draft: 'Concept',
    done: 'Voltooid',
    noShow: 'Niet verschenen',
    ongoing: 'Gestart',
  },
  drivingOrderType: {
    dailyRent: 'Dagverhuur',
    transfer: 'Overdracht',
    hourlyDaily: 'Per uur/dag',
    oneWayTransfer: 'Enkele reis',
    fromAirport: 'Van luchthaven',
    toAirport: 'Naar luchthaven',
  },
  flightInfo: {
    airlineName: 'Luchtvaartmaatschappij',
    flightNumber: 'Vluchtnummer',
    departureAirport: 'Vertrekluchthaven',
    departureCountry: 'Vertrekland',
    departureTime: 'Vertrektijd',
    arrivalAirport: 'Aankomstluchthaven',
    arrivalCountry: 'Aankomstland',
    arrivalTime: 'Aankomsttijd',
    departureDelayed: 'Vertrek vertraagd',
    flightStatus: 'Vluchtstatus',
    percentageOfFlight: 'Percentage van de vlucht',
  },
  customValidations: {
    fileType: `Het bestandstype .{extension} wordt niet ondersteund`,
    fileSize: 'Bestanden mogen niet groter zijn dan {size}',
    fieldRequired: 'Veld {field} is verplicht',
    fieldType: 'Veld {field} moet van het type {type} zijn',
    fieldLengthMin: 'Het veld {field} moet {length} of meer zijn',
    fieldLengthMax: 'Het veld {field} moet {length} of minder zijn',
    fieldUnique: 'Veld {field} moet uniek zijn',
    fieldValid: 'Veld {field} moet geldig zijn',
    noActiveSubscription: 'Geen actief abonnement gevonden',
    usersLimitExceeded: 'Gebruikerslimiet bereikt',
    fieldBefore: '{field1} moet voor {field2} zijn',
    flightNotExist: 'De vlucht {field} bestaat niet',
    gnetKeyNotValid: 'De opgegeven Gnet-sleutel is niet geldig.',
    gnetPassengersRequired: 'Rijopdracht kan niet naar GNET worden gestuurd zonder passagiers.',
    priceAndCurrencyRequired: 'Betaallink kan niet worden gegenereerd zonder prijs en valuta',
    fieldValueNotSupported: 'De waarde van het veld {field} wordt niet ondersteund',
    noCountryCode: 'U heeft geen land geselecteerd',
    noStripeForYourCountry: 'Stripe is niet beschikbaar in uw land',
    stripeAlreadyEnabled: 'Stripe is al geactiveerd',
    fieldValueMin: 'Het veld {field} moet {min} of meer zijn',
    fieldValueMax: 'Het veld {field} moet {max} of minder zijn',
    markAsDoneDate: 'De ophaaltijd kan niet in de toekomst liggen',
    phoneNumberNotValidForSms:
      'Het telefoonnummer is ongeldig voor het versturen van SMS. Een geldig telefoonnummer moet de landcode bevatten.',
    affiliateIdDoesNotExist: 'De organisatie met de opgegeven affiliate-ID bestaat niet.',
    numericDecimal: 'Het veld {field} mag alleen numerieke tekens bevatten (decimalen toegestaan)',
  },
  fieldTypes: {
    string: 'tekenreeks',
    integer: 'nummer',
    date: 'datum',
  },
  names: {
    username: 'gebruikersnaam',
    password: 'wachtwoord',
    choose_client: 'kies klant',
    choose_month: 'kies maand',
    currency: 'valuta',
    payment_method: 'betaalmethode',
    vehicle_class: 'voertuigklasse',
    first_name: 'voornaam',
    last_name: 'achternaam',
    passport: 'paspoort',
    nationality: 'nationaliteit',
    plate_number: 'kenteken',
    brand: 'automerk',
    number_of_passengers: 'aantal passagiers',
    fuel_consumption: 'brandstofverbruik',
    year_of_manufacture: 'fabricagejaar',
    price_per_km: 'prijs per km',
    price_per_mi: 'prijs per mijl',
    price_per_hour: 'prijs per uur',
    price_per_waiting_hour: 'prijs per wachtuur',
    vehicle: 'voertuig',
    description: 'omschrijving',
    time_from: 'tijd van',
    time_to: 'tijd tot',
    name: 'naam',
    cost_type: 'kostentype',
    cost_time: 'kostentijd',
    price: 'prijs',
    phone_number: 'telefoonnummer',
    organisation: 'organisatie',
    role: 'rol',
    image: 'afbeelding',
    location_from: 'locatie van',
    location_to: 'locatie naar',
    pickup_time: 'ophaaltijd',
    distance: 'afstand',
    waiting_time: 'wachttijd',
    comment: 'opmerking',
    address: 'adres',
    vat: 'BTW',
    company_id: 'bedrijfsidentificatienummer',
    company_personal_id: 'bedrijfs-id/persoonlijke id',
    licence: 'vergunning',
    warrant_number: 'garantienummer',
    seal: 'zegel',
    signature: 'handtekening',
    email: 'e-mail',
    percentage: 'percentage',
    organizationName: 'nome da organização',
    package: 'paket',
    choose_currency: 'kies valuta',
    team_10_price: 'team 10 prijs',
    team_10_paddle_price_id: 'ID team 10 Paddle prijs',
    team_25_price: 'team 25 prijs',
    team_25_paddle_price_id: 'ID team 25 Paddle prijs',
    team_50_price: 'team 50 prijs',
    team_50_paddle_price_id: 'ID team 50 Paddle prijs',
    team_10_price_monthly: 'team 10 prijs',
    team_10_paddle_price_id_monthly: 'ID team 10 Paddle prijs',
    team_25_price_monthly: 'team 25 prijs',
    team_25_paddle_price_id_monthly: 'ID team 25 Paddle prijs',
    team_50_price_monthly: 'team 50 prijs',
    team_50_paddle_price_id_monthly: 'ID team 50 Paddle prijs',
    countries: 'landen',
    invoice_number: 'factuurnummer',
    status: 'status',
    total: 'totaal',
    billed_at: 'gefactureerd op',
    articles_of_law: 'artikelen van wet',
    country: 'land',
    referent: 'referent',
    language: 'taal',
    location: 'locatie',
    arrival_time: 'aankomsttijd',
    waiting_board_text: 'wachttekst op het bord',
    flight_number: 'vluchtnummer',
    gnet_id: 'GNET-ID',
    receiver_id: 'Ontvanger-ID',
    preferred_vehicle_type: 'voorkeur voertuigtype',
    reservation_type: 'reserveringstype',
    run_type: 'rittype',
    trip_duration_minutes: 'reisduur',
    client: 'klant',
    client_type: 'klanttype',
    card_holder_name: 'naam kaarthouder',
    card_number: 'kaartnummer',
    expiry_date: 'vervaldatum',
    cvv: 'cvv',
    code: 'code',
    symbol: 'symbool',
    quantity: 'hoeveelheid',
    discount: 'korting',
    driving_type: 'boekingstype',
    full_name: 'volledige naam',
    suitcases_number: 'aantal koffers',
    passengers_number: 'aantal passagiers',
    baby_seats_number: 'aantal kinderzitjes',
    stops_number: 'aantal stops',
    commission_amount: 'commissie',
    message: 'bericht',
    affiliate_id: 'affiliate id',
    coordinates: 'coördinaten',
    zone_one: 'zone één',
    zone_two: 'zone twee',
    price_type: 'prijstype',
    price_for_waiting: 'prijs voor wachten',
    number_of_hours: 'aantal uren',
    zones: 'Zones',
    client_id: 'klant-id',
    minutes: 'minuten',
    started_at: 'begonnen om',
    finished_at: 'beëindigd om',
    driving: 'rijden',
    user: 'gebruiker',
    time_zone: 'tijdzone',
    charged: 'in rekening gebracht',
    offer_number: 'rij-aanbiedingsnummer',
    drivings: 'ritten',
  },
  weekDays: {
    monday: 'maandag',
    tuesday: 'dinsdag',
    wednesday: 'woensdag',
    thursday: 'donderdag',
    friday: 'vrijdag',
    saturday: 'zaterdag',
    sunday: 'zondag',
  },
  select: {
    noDataAvailable: 'Geen gegevens beschikbaar',
    selected: 'geselecteerd',
  },
  excelExport: {
    to_date_range: 'Het rapport kan niet worden gegenereerd voor toekomstige data. Voer alstublieft geldige data in.',
    overlap_range: 'Eindtijd kan niet groter zijn dan de begintijd.',
  },
  setPassword: {
    success: 'E-mail verzonden',
    checkEmail: 'Controleer uw e-mail voor verdere instructies.',
    already_changed: 'Al gewijzigd',
    send_new_reset_request:
      'U heeft uw wachtwoord al gewijzigd met deze link. Stuur een nieuw verzoek om het wachtwoord te resetten.',
  },
  statisticsPage: {
    reportType: 'Type rapport',
    reportTime: 'Rapportagetijd',
    vehicleUtilization: 'Voertuiggebruik',
    costEffectiveness: 'Kosteneffectiviteit',
    generalReport: 'Algemeen rapport',
    clientsReport: 'Rapport per Klant',
  },
  profile: {
    yourProfile: 'Uw profiel',
    name: 'Naam',
    role: 'Rol',
    username: 'Gebruikersnaam',
    password: 'Wachtwoord',
    phoneNumber: 'Telefoonnummer',
    email: 'E-mail',
    percentage: 'Percentage',
  },
  gpsMaps: {
    noTransfers: 'Er zijn geen lopende of aankomende transfers.',
    map: 'Kaart',
    transfers: 'Overdrachten',
    drivers: 'Chauffeurs',
    ongoing: 'Aan de gang',
    upcoming: 'Komende',
    driving: 'Rijden',
    driver: 'Chauffeur',
    vehicle: 'Voertuig',
    requestDriverLocation: 'Verzoek om locatie van chauffeur',
    lastRecordedLocation: 'Laatst opgenomen locatie',
  },
  driverStatus: {
    available: 'Beschikbaar',
    offline: 'Offline',
    inTransfer: 'In overdracht',
  },
  onboardingTutorialMain: {
    languageStep: 'Hier kunt u uw voorkeurstaal voor het platform instellen.',
    notificationsStep: 'Hier vindt u uw meldingen.',
    addTransferStep: 'Klik op deze knop om een nieuwe reservering, enkele reis of dagverhuur te maken.',
    sideMenuButtonStep: 'Klik op dit pictogram om de menubalk weer te geven of te verbergen.',
    sideMenuExpStep:
      'Vanaf hier kunt u navigeren naar de belangrijkste secties van het platform: Reserveringen, Wagenpark, Kosten, Administratie, Facturen, Statistieken, enz.',
    sideMenuButtonCloseStep: 'Klik op hetzelfde pictogram om de menubalk te verbergen.',
    supportStep:
      'Als u vragen of problemen heeft, aarzel dan niet om contact op te nemen met de klantenservice door hier te klikken.',
  },
  onboardingTutorialVehicles: {
    tableStep: 'Voertuigen worden in deze tabel weergegeven nadat u ze hebt toegevoegd.',
    searchStep: 'U kunt voertuigen eenvoudig vinden door rechtstreeks te zoeken op merk, kenteken, jaar, enz.',
    addStep:
      'Voeg nieuwe voertuigen toe door op de knop "Toevoegen" te klikken. Voeg eerst alle "Voertuigklassen" toe voordat u voertuigen invoert.',
  },
  onboardingTutorialStatistics: {
    typeStep:
      'In deze sectie vindt u al uw statistische gegevens in grafieken weergegeven. Selecteer hier het type statistiek.',
    intervalStep: 'Kies een interval om de statistieken te bekijken.',
    downloadStep: 'U kunt deze tabellen ook downloaden en afdrukken.',
  },
  onboardingTutorialGps: {
    tabOneStep:
      'In de GPS-module kunt u uw chauffeurs en ritten op één plek volgen. Kies deze tab om lopende en aankomende (volgende 24 uur) ritten te bekijken.',
    tabTwoStep:
      'Selecteer voertuigen om ze op de kaart te zien. U kunt beschikbare voertuigen hier direct toewijzen aan ritten.',
    mapStep: 'Selecteer of deselecteer voertuigen door op hun pin op de kaart te klikken.',
  },
  onboardingTutorialInvoices: {
    tableStep: 'Alle facturen worden in deze tabel weergegeven, van nieuw naar oud.',
    searchStep: 'Zoek eenvoudig facturen door rechtstreeks te zoeken op nummer, bedrag, klantnaam, aanmaakdatum, enz.',
    addStep: 'Maak nieuwe facturen aan door op de knop "Toevoegen" te klikken.',
  },
  onboardingTutorialOffers: {
    tableStep: 'Aanbiedingen worden in deze tabel weergegeven nadat ze zijn toegevoegd, van nieuw naar oud.',
    searchStep: 'Zoek eenvoudig aanbiedingen door rechtstreeks te zoeken op nummer, bedrag of beschrijving.',
    addStep: 'Maak nieuwe aanbiedingen door op de knop "Toevoegen" te klikken.',
  },
  onboardingTutorialResources: {
    infoStep: 'Bekijk de resterende middelen en koop op aanvraag extra SMS-berichten.',
    tableStep:
      'In deze tabel vindt u een overzicht en geschiedenis van alle gebruikte middelen (verzonden sms-berichten en gecontroleerde vluchten).',
    searchStep: 'Zoek eenvoudig middelen door rechtstreeks te zoeken op inhoud, gebruiker of aanmaakdatum.',
  },
  onboardingTutorialPassengerReviews: {
    tableStep: 'Passagiersbeoordelingen worden in deze tabel weergegeven, van nieuw naar oud.',
    searchStep: 'Zoek eenvoudig passagiersbeoordelingen door rechtstreeks te zoeken op nummer of passagierscommentaar.',
  },
  onboardingTutorialPricingZones: {
    tableStep: 'Prijszones worden in deze tabel weergegeven nadat ze zijn toegevoegd.',
    searchStep: 'Zoek eenvoudig prijszones door rechtstreeks te zoeken.',
    addStep: 'Maak nieuwe prijszones door op de knop "Toevoegen" te klikken.',
  },
  onboardingTutorialPricingZoneRelations: {
    tableStep: 'Relaties tussen prijszones (prijzen) worden in deze tabel weergegeven nadat ze zijn toegevoegd.',
    searchStep: 'Zoek eenvoudig relaties tussen prijszones door rechtstreeks te zoeken.',
    addStep:
      'Maak nieuwe relaties tussen prijszones (prijzen) door op de knop "Toevoegen" te klikken. Let op: voeg eerst "Prijszones" toe voordat u deze functie gebruikt.',
  },
  onboardingTutorialPricingZoneHourlyRelations: {
    tableStep:
      'Alle prijszones worden in deze tabel weergegeven. Door een specifieke zone te openen, kunt u de prijzen voor die zone instellen.',
    searchStep: 'Vind eenvoudig prijszones door direct te zoeken.',
  },
  onboardingTutorialDashboard: {
    infoStep: 'Bekijk een overzicht van de statistieken van uw organisatie voor de afgelopen 30 dagen.',
    next3DaysStep: 'Reserveringen voor de komende 3 dagen worden in deze tabel weergegeven.',
    notPaidOrConfirmedStep:
      'Reserveringen voor de komende 7 dagen die niet zijn bevestigd of betaald, worden in deze tabel weergegeven.',
    next5DaysStep: 'Drukke chauffeurs voor de komende 5 dagen worden in deze tabel weergegeven.',
    latestReviewsStep: 'De laatste ritbeoordelingen worden in deze tabel weergegeven.',
  },
  onboardingTutorialAffiliate: {
    tabStep: 'Door van tabblad te wisselen, kunt u uw partners, verzonden verzoeken of ontvangen verzoeken bekijken.',
    idStep: 'Hier vindt u uw affiliate-ID die u kunt delen met uw partners.',
    addStep: 'Maak een nieuw affiliate partnerverzoek door op de knop "Toevoegen" te klikken.',
  },
  onboardingTutorialAdvancedSettings: {
    advancedSettings:
      'Klik hier om toegang te krijgen tot de Geavanceerde instellingen van uw organisatie en om alle eigenschappen en integraties te configureren.',
  },
  onboardingTutorialDistanceScales: {
    distanceScales:
      'Hier kunt u afstandsprijsschalen instellen en beheren voor elke voertuigklasse en valuta. Definieer eenvoudig prijzen voor specifieke afstanden (bijvoorbeeld 0-5 km, 5-15 km) om uw tarieven aan te passen.',
  },
  advancedSettings: {
    advancedSettings: 'Geavanceerde instellingen',
    distanceUnit: 'Afstandseenheid',
    distanceUnitDescription: 'Selecteer de eenheid voor afstandsmetingen.',
    kilometers: 'Kilometers',
    miles: 'Mijlen',
    dateFormat: 'Datumnotatie',
    dateFormatDescription: 'Selecteer de datumnotatie die u wilt gebruiken.',
    ddmmyyyy: 'DD-MM-YYYY',
    mmddyyyy: 'MM-DD-YYYY',
    mandatoryDrivings: 'Verplichte rijopdrachten',
    mandatoryDrivingsDescription: 'Kies of je alle rijopdrachten standaard verplicht wilt maken.',
    mandatoryDrivingsDefault: 'Verplichte Rijopdrachten (Standaard)',
    paidDrivings: 'Betaalde rijopdrachten',
    paidDrivingsDescription: 'Selecteer om alle rijopdrachten standaard betaald te maken.',
    paidDrivingsDefault: 'Betaalde rijopdrachten (Standaard)',
    notificationsEmail: 'E-mail voor meldingen',
    notificationsEmailDescription:
      'Wijzig het standaard e-mailadres om meldingen te ontvangen (als je niet het e-mailadres van je organisatie wilt gebruiken).',
    email: 'E-mail',
    articlesOfLaw: 'Artikelen van wet',
    integrations: 'Integraties',
    gnetId: 'GNET ID',
    gnetDescription:
      'GNET is een uitgebreid platform ontworpen om real-time tracking, vlootbeheer en dispatch-activiteiten voor de transportindustrie te optimaliseren.',
    makeRoutesAvailable: 'Maak integratieroutes beschikbaar',
    makeRoutesAvailableDescription:
      'Activeer uw API-sleutel om LimoExpress te integreren met uw website, ERP-systeem en meer.',
    makePublicFormAvailable: 'Maak het openbare formulier beschikbaar',
    makePublicFormAvailableDescription:
      'Activeer deze optie om toegang te krijgen tot de openbare boekingspagina, zodat uw klanten toegang hebben tot het geavanceerde boekingsformulier en eenvoudig accounts kunnen aanmaken. U kunt de link gebruiken in nieuwsbrieven, op de website, in flyers; het is een unieke link alleen voor uw bedrijf. U kunt ook een codefragment krijgen om het boekingsformulier direct op uw eigen website in te bedden.',
    emailsEnabled: 'E-mails ingeschakeld',
    emailsEnabledDefault: 'E-mails ingeschakeld (Standaard)',
    emailsEnabledDescription: 'Vink aan om e-mailmeldingen te ontvangen.',
    passengerReviewsEnabled: 'Passagiersbeoordelingen ingeschakeld',
    passengerReviewsEnabledDefault: 'Passagiersbeoordelingen ingeschakeld',
    passengerReviewsEnabledDescription:
      'Door deze functie in te schakelen, ontvangen uw passagiers (als er een e-mailadres is ingevoerd) een bevestigingsmail nadat de rit is voltooid (gemarkeerd als Voltooid) en krijgen ze de mogelijkheid om beoordelingen (feedback) te geven over uw diensten.',
    vat: 'BTW',
    vatPercentage: 'Btw-percentage',
    vatPercentageDescription: 'Voer het btw-percentage in.',
    invoicePaymentInstructions: 'Instructies voor factuurbetaling',
    stripe: 'Stripe',
    stripeDescription:
      'Activeer Stripe om uw klanten te kunnen factureren voor uw diensten. LimoExpress neemt geen commissie.',
    dataExport: 'Gegevens exporteren',
    dataExportDescription: 'U kunt al uw gegevens exporteren, en deze zullen naar uw e-mail worden verzonden.',
    sureAboutDataExport:
      'Weet u zeker dat u al uw gegevens wilt exporteren? Zo ja, worden alle gegevens binnen 2 uur naar uw e-mail gestuurd.',
    defaultPriceType: 'Standaard prijstype',
    defaultPriceTypeDescription: 'Stel het standaard prijstype in om te bepalen hoe prijzen worden weergegeven.',
    NET: 'NETTO',
    GROSS: 'BRUTO',
    link: 'Link',
    code: 'Code',
    language: 'Taal',
    chooseSize: 'Kies grootte',
    small: 'Klein',
    medium: 'Middel',
    large: 'Groot',
    zapier: 'Zapier',
    zapierDescription:
      'Activeer Zapier en krijg toegang tot integraties met meer dan 7.000 van de meest populaire systemen wereldwijd. Deze integratie stelt je in staat workflows te maken die je bedrijfsprocessen volledig ondersteunen. Je kunt berichten, facturen automatiseren en zelfs kettingacties activeren op basis van specifieke activiteiten binnen het LimoExpress-platform.',
    zapierId: 'Zapier ID',
    publicBookingColor: 'Kleur van het openbare boekingsformulier',
    useSystemColors: 'Standaardkleur gebruiken',
    choseCustomColor: 'Aangepaste kleur kiezen',
    location: 'Locatie',
    selectLocation: 'Selecteer locatie',
    changeLocation: 'Wijzig locatie',
    mapRadius: 'Kaartstraal',
    mapRadiusDescription:
      'Stel een locatie en een straal in om beschikbare locaties te beperken bij het maken van een nieuwe reservering. Alleen adressen binnen deze straal zijn beschikbaar.',
    publicMapRadius: 'Kaartstraal voor openbaar boekingsformulier',
    publicMapRadiusDescription:
      'Stel een locatie en een straal in voor het openbare boekingsformulier. Gebruikers kunnen alleen ophaal- en afleverlocaties binnen deze straal selecteren.',
    radiusDistance: 'Straalafstand (km)',
    radiusDistanceMi: 'Straalafstand (mijl)',
  },
  drivingExcelCheckboxes: {
    drivingNumber: 'Bestelnummer',
    drivingType: 'Boekingstype',
    drivingStatus: 'Status',
    locationFrom: 'Vertrekpunt',
    locationTo: 'Bestemming',
    driver: 'Bestuurder',
    client: 'Klant',
    plateNumber: 'Kentekennummer',
    note: 'Beschrijving',
    fuelConsumption: 'Brandstofverbruik',
    pickupTime: 'Ophaaltijd',
    numberOfPassengers: 'Aantal passagiers',
    passengers: 'Passagiers',
    price: 'Prijs',
    paid: 'Betaald',
    paymentMethod: 'Betalingsmethode',
    waitingTime: 'Wachttijd (h)',
    distance: 'Afstand (km)',
    distanceMi: 'Afstand (mijl)',
    selectAll: 'Alles selecteren',
    roundTrip: 'Retourreis',
    babySeatsNumber: 'Aantal kinderzitjes',
    suitcasesNumber: 'Aantal koffers',
    charged: 'In rekening gebracht',
  },
  gnet: {
    gnetInfo: 'GNET informatie',
    requesterId: 'Aanvrager-ID',
    receiverId: 'Ontvanger-ID',
    preferredVehicleType: 'Voorkeur voertuigtype',
    reservationType: 'Reserveringstype',
    runType: 'Rittype',
    price: 'Prijs',
    currency: 'Valuta',
    noteDriver: 'Opmerking voor bestuurder',
    noteDispatcher: 'Opmerking voor dispatcher',
    tripDurationMinutes: 'Reisduur (minuten)',
  },
  invoicesHeaders: {
    invoiceNumber: 'Factuurnummer',
    client: 'Klant',
    totalPrice: 'Totale prijs',
    drivings: 'Ritten',
    paid: 'Betaald',
    currency: 'Valuta',
    createdAt: 'Gemaakt op',
    actions: 'Acties',
  },
  invoicesPage: {
    newItem: 'Nieuwe factuurinvoer',
    chooseClient: 'Kies klant',
    chooseDrivings: 'Kies ritten',
    allDrivings: 'Alle ritten',
    invoiceNumber: 'Factuurnummer',
    payUntil: 'Betalen Vóór',
    deleteMessage: 'Weet je zeker dat je deze factuur wilt verwijderen?',
    markInvoiceAsPaid: 'Weet u zeker dat u deze factuur als betaald wilt markeren?',
  },
  drivingOffersHeaders: {
    number: 'Rij-aanbiedingsnummer',
    totalPrice: 'Totale prijs',
    description: 'Beschrijving',
    paid: 'Betaald',
    currency: 'Valuta',
    createdAt: 'Gemaakt op',
    actions: 'Acties',
  },
  drivingOffersPage: {
    newItem: 'Nieuwe rij-aanbieding invoer',
    item: 'Item',
    description: 'Beschrijving',
    title: 'Titel',
    vehicleClass: 'Voertuigklasse',
    quantity: 'Hoeveelheid',
    price: 'Prijs',
    discount: 'Korting',
    number: 'Rij-aanbiedingsnummer',
    vat: 'BTW',
    deleteMessage: 'Weet u zeker dat u deze rij-aanbieding wilt verwijderen?',
    markDrivingOfferAsPaid: 'Weet u zeker dat u dit ritaanbod als betaald wilt markeren?',
  },
  modal: {
    reservationSuccessTitle: 'Succes',
    reservationSuccessMessage: 'Reserveringsverzoek succesvol verzonden. Iemand zal binnenkort contact met u opnemen.',
    reservationErrorTitle: 'Formulier uitgeschakeld',
    reservationErrorMessage:
      'Het formulier is momenteel uitgeschakeld door de organisatie. Neem contact op met de support of probeer het later opnieuw.',
  },
  dashboardPage: {
    drivingCountToday: 'Aantal ritten (vandaag)',
    drivingCountThisMonth: 'Aantal ritten (laatste 30 dagen)',
    dailyRentsAndTransfers: 'Aantal ritten (dagelijkse verhuur/transfers)',
    drivingTotalAmount: 'Totaalbedrag',
    driversCount: 'Actieve chauffeurs (±7 dagen)',
    passengerCount: 'Aantal passagiers (laatste 30 dagen)',
  },
  reviewsHeaders: {
    rating: 'Beoordeling',
    comment: 'Opmerking',
    createdAt: 'Aangemaakt op',
  },
  resourceOverviewHeaders: {
    actionType: 'Actietype',
    user: 'Gebruiker',
    action: 'Actie',
    createdAt: 'Aangemaakt op',
  },
  resourceOverviewPage: {
    smsMessagesLeft: 'Aantal resterende SMS-berichten',
    flightsCheckLeft: 'Aantal resterende vluchtcontroles',
    sms: 'SMS',
    flightCheck: 'Vluchtcontrole',
  },
  affiliateHeaders: {
    organisation: 'Organisatie',
    createdAt: 'Gemaakt op',
    actions: 'Acties',
  },
  affiliatePage: {
    partners: 'Partners',
    sentRequests: 'Verzonden aanvragen',
    receivedRequests: 'Ontvangen aanvragen',
    acceptRequest: 'Weet u zeker dat u deze aanvraag wilt accepteren?',
    rejectRequest: 'Weet u zeker dat u deze aanvraag wilt weigeren?',
    deletePartner: 'Weet u zeker dat u deze partner wilt verwijderen?',
    deleteRequest: 'Weet u zeker dat u de aanvraag wilt verwijderen?',
    newItem: 'Nieuw verzoek',
    affiliateId: 'Affiliate ID',
    registerNewPartner: 'Een nieuwe partner registreren',
    registrationSuccessfull: 'Registratie succesvol',
    registrationSuccessfullDescription:
      'Affiliate-partner succesvol geregistreerd! Informeer de partner dat zij een e-mail ontvangen om hun account te verifiëren en een wachtwoord in te stellen. Daarna kunnen ze het platform gebruiken.',
  },
  affiliate: {
    affiliateInfo: 'Affiliate informatie',
    affiliate: 'Affiliate',
    partner: 'Partner',
    preferredVehicleType: 'Voorkeur voertuigtype',
    driverInfo: 'Bestuurdersinformatie',
    vehicleInfo: 'Voertuiginformatie',
    price: 'Prijs',
    currency: 'Valuta',
  },
  pricingZonesHeaders: {
    name: 'Zonenaam',
    code: 'Code',
    postalCode: 'Postcode',
    actions: 'Acties',
  },
  pricingZonesPage: {
    newItem: 'Nieuwe prijszone-invoer',
    editItem: 'Wijziging van prijzone',
    id: 'ID',
    name: 'Zonenaam',
    code: 'Code',
    deleteMessage: 'Weet u zeker dat u deze prijzone wilt verwijderen?',
    postalCode: 'Postcode',
    postalCodeDescription: 'Definieer een zone op de kaart met een postcode',
    postalCodeDisabled: 'Voer eerst een postcode in',
  },
  pricingZoneRelationsHeaders: {
    zoneOne: 'Zone één',
    zoneTwo: 'Zone twee',
    client: 'Klant',
    actions: 'Acties',
  },
  pricingZoneRelationsPage: {
    newItem: 'Nieuwe prijszone-relatie invoer',
    editItem: 'Wijziging van prijszone-relatie',
    zone: 'Zone',
    zoneOne: 'Zone één',
    zoneTwo: 'Zone twee',
    chooseClient: 'Kies klant',
    chooseClientFrom: 'Kloon van klant',
    chooseClientTo: 'Kloon naar klant',
    cloneItem: 'Kloon prijszone-relatie',
    zoneRelations: 'Zonerelaties',
    cloneItemHourly: 'Uurprijzen klonen',
    zones: 'Zones',
    fromTo: 'Van - Naar',
    discountPercentage: 'Kortingspercentage (%)',
    deleteMessage: 'Weet u zeker dat u deze prijszone-relatie wilt verwijderen?',
  },
  referralPrize: {
    referToAFriend: 'Aan een vriend doorverwijzen',
    referToAFriendDescription:
      'Nodig je vrienden uit om zich bij het platform aan te sluiten door hun e-mailadres hieronder in te voeren. Ze ontvangen een gepersonaliseerde uitnodiging om zich te registreren en het platform te gebruiken. Als alternatief kun je je unieke registratie link rechtstreeks met anderen delen! Wanneer iemand zich registreert via jouw link of uitnodiging, ontvang je een beloning voor de aanbeveling als bedankje. Begin vandaag nog met uitnodigen en delen!',
  },
  distanceScalesPage: {
    distanceScales: 'Afstandsschalen',
  },
  passengersPage: {
    newItem: 'Nieuwe passagiersinvoer',
    deletePassenger: 'Passagier verwijderen',
    deleteMessage: 'Weet u zeker dat u de passagier wilt verwijderen ',
  },
  formsAndTables: {
    actions: 'Acties',
    firstName: 'Voornaam',
    lastName: 'Achternaam',
    email: 'E-mail',
    phoneNumber: 'Telefoonnummer',
    passport: 'Paspoort',
    country: 'Land',
    nationality: 'Nationaliteit',

    logType: 'Type',
    minutes: 'Minuten',
    startedAt: 'Gestart op',
    finishedAt: 'Voltooid op',
    driving: 'Rijden',
    user: 'Gebruiker',
    createdAt: 'Aangemaakt op',
    exportExcel: 'Exporteer naar Excel',
    description: 'Beschrijving',
  },

  workLogsPage: {
    newItem: 'Nieuwe activiteit logboekinvoer',
    deleteMessage: 'Weet u zeker dat u dit activiteitenlogboek wilt verwijderen?',
    regular: 'Regulier',
  },
};
