import {
  loadDrivingDetails,
  fetchAllVehicleClasses,
  fetchAllOrganisationCurrencies,
  fetchAllDrivingTemplates,
  saveDriving,
  cancelDriving,
  getAllInvoices,
  getDriversGpsData,
  requestDriverLocation,
  searchPassengers,
} from '@/api/customers';
import store from '@/store';
import i18n from '@/i18n/i18n';

export default {
  namespaced: true,
  state: { drivingTemplates: [] },
  mutations: {
    SET_DRIVING_TEMPLATES(state, drivingTemplates) {
      state.drivingTemplates = drivingTemplates;
    },
  },
  actions: {
    async loadDrivingDetails(state, item) {
      return loadDrivingDetails(item);
    },
    async fetchAllVehicleClasses(state, item) {
      return fetchAllVehicleClasses(item);
    },
    async fetchAllOrganisationCurrencies(state, item) {
      return fetchAllOrganisationCurrencies(item);
    },
    async fetchAllDrivingTemplates({ commit }) {
      try {
        const response = await fetchAllDrivingTemplates();
        commit('SET_DRIVING_TEMPLATES', response.data);
      } catch (error) {
        store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
      }
    },
    async saveDriving(state, item) {
      return saveDriving(item);
    },
    async cancelDriving(state, item) {
      return cancelDriving(item);
    },
    async getAllInvoices(state, item) {
      return getAllInvoices(item);
    },
    async getDriversGpsData(state, item) {
      return getDriversGpsData(item);
    },
    async requestDriverLocation(state, item) {
      return requestDriverLocation(item);
    },
    async searchPassengers(state, item) {
      return searchPassengers(item);
    },
  },
  getters: {
    getDrivingTemplates: (state) => state.drivingTemplates,
  },
};
