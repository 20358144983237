export default {
  sidebar: {
    fleet: 'Filom',
    vehicles: 'Araçlar',
    vehiclesClasses: 'Araç Sınıfları',
    administration: 'Yönetim',
    currencies: 'Para Birimleri',
    currenciesAdministration: 'Para Birimi Yönetimi',
    paymentTypes: 'Ödeme Yöntemleri',
    paymentTypesAdministration: 'Ödeme Yöntemi Yönetimi',
    users: 'Kullanıcılar',
    usersAdministration: 'Kullanıcı Yönetimi',
    clients: 'Müşteriler',
    clientsAdministration: 'Müşteri Yönetimi',
    organizations: 'Organizasyonlar',
    organizationsAdministration: 'Organizasyon Yönetimi',
    saasAdmin: 'Saas Yönetici',
    administrators: 'Yöneticiler',
    saasAdminAdministration: 'Saas Yönetici Yönetimi',
    statistics: 'İstatistikler',
    generalOverview: 'Genel Rapor',
    payment: 'Ödeme',
    pricingRegions: 'Fiyat bölgeleri',
    pricingRegionsAdministration: 'Fiyat bölgeleri yönetimi',
    help: 'Yardım',
    drivings: 'Sürüş Siparişleri',
    calendar: 'Takvim',
    tabularView: 'Tablo Görünümü',
    drivingTemplates: 'Rezervasyon şablonları',
    expenses: 'Maliyetler',
    vehicleExpensesAdministration: 'Araç Maliyetleri Yönetimi',
    vehicleMaintenance: 'Araç Arızaları',
    vehicleMaintenanceAdministration: 'Araç Arızaları Yönetimi',
    organization: 'Organizasyon',
    organizationAdminstration: 'Organizasyon Yönetimi',
    packages: 'Paketler',
    packagesChange: 'Paket Değişikliği',
    logout: 'Çıkış',
    logoutText: 'Çıkış yapmak istediğinizden emin misiniz?',
    unaccessibleRoutes: 'Bu sayfaya erişemezsiniz. Tam erişim için paketi yükseltin.',
    transactions: 'İşlemler',
    subscription: 'Abonelik',
    gpsMap: 'GPS haritası',
    team: 'Takım',
    teamAdministration: 'Takım yönetimi',
    invoices: 'Faturalar',
    invoicesAdministration: 'Fatura yönetimi',
    drivingOffers: 'Teklifleri',
    drivingOffersAdministration: 'Teklifleri yönetimi',
    dashboard: 'Kontrol Paneli',
    dashboardAdministration: 'Kontrol Paneli Yönetimi',
    resourceOverview: 'Kaynak genel görünümü',
    resourceOverviewAdministration: 'Kaynak genel görünümü yönetimi',
    rides: 'Yolculuklar',
    passengerReviews: 'Yolcu yorumları',
    passengerReviewsAdministration: 'Yolcu yorumları yönetimi',
    pricingAndRates: 'Fiyatlandırma ve oranlar',
    pricingZones: 'Bölgeler ve alanlar',
    pricingZonesAdministration: 'Bölgeler ve alanlar yönetimi',
    pricingPerZones: 'Bölgelere göre fiyatlandırma',
    pricingPerZonesAdministration: 'Bölgelere göre fiyat yönetimi',
    pricingPerHour: 'Saatlik fiyatlandırma',
    pricingPerHourAdministration: 'Saatlik fiyat yönetimi',
    availability: 'Uygunluk',
    availabilityAdministration: 'Sürücülerin ve araçların uygunluğu',
    distanceScales: 'Mesafe ölçekleri',
    distanceScalesAdministration: 'Mesafe ölçekleri yönetimi',
    passengers: 'Yolcular',
    passengersAdministration: 'Yolcu yönetimi',
    workLogs: 'Etkinlik günlükleri',
    workLogsAdministration: 'Etkinlik günlükleri yönetimi',
    advancedSettings: 'Gelişmiş ayarlar',
    advancedSettingsAdministration: 'Gelişmiş ayarlar yönetimi',
  },
  header: {
    organization: 'Organizasyon',
    newDriving: 'Sürüş Siparişi',
    dailyRent: 'Günlük Kiralama',
    transfer: 'Transfer',
    notifications: 'Bildirimler',
    changeLanguage: 'Dil Değiştir',
    help: 'Yardım',
    fromTemplate: 'Şablondan',
    back: 'Geri',
  },
  footer: {
    poweredBy: 'Tarafından Desteklenmektedir',
    subscriptionTo: 'Aboneliğiniz',
    trialEndsAt: 'Deneme süreniz için',
    packageExpiresIn: 'paketinin sona erme süresi',
    packagePastDue: 'vadesi geçti.',
    clickToPay: 'Şimdi ödemek için tıklayın.',
    affiliateUser: 'Platformun tüm özelliklerini açmak istiyorsanız, abone olmanız gerekir ',
    here: 'BURADA',
  },
  buttons: {
    ok: 'Tamam',
    yes: 'Evet',
    no: 'Hayır',
    save: 'Kaydet',
    send: 'Gönder',
    sendMessage: 'Mesaj Gönder',
    edit: 'Düzenle',
    add: 'Ekle',
    confirm: 'Onayla',
    cancel: 'İptal',
    cancelDriving: 'Sürüşü İptal Et',
    markAsDone: 'Tamamlandı Olarak İşaretle',
    markAsNoShow: 'Gösterilmeyen olarak işaretle',
    deleteDriving: 'Sürüşü sil',
    newOrganisation: 'Yeni Organizasyon',
    changePackage: 'Paket Değişikliği',
    downloadStats: 'İstatistikleri İndir',
    downloadPDF: 'PDF İndir',
    today: 'Bugün',
    choose: 'Seç',
    login: 'Giriş Yap',
    generateFile: 'Sözleşme Oluştur',
    generateDrivingOrder: 'Sürüş Siparişi Yazdır',
    downloadFile: 'Dosyayı İndir',
    activate: 'Etkinleştir',
    deactivate: 'Devre Dışı Bırak',
    showImage: 'Resmi Göster',
    showDetails: 'Detayları Göster',
    actions: 'Eylemler',
    delete: 'Sil',
    open: 'Aç',
    show: 'Göster',
    options: 'Seçenekler',
    prev: 'Önceki',
    next: 'Sonraki',
    skip: 'Atla',
    finish: 'Bitir',
    settings: 'Ayarlar',
    cancelSubscription: 'Aboneliği iptal et',
    backToEditOrganization: 'Organizasyonu düzenlemeye geri dön',
    payNow: 'Şimdi Öde',
    back: 'Geri git',
    integrationRoutes: 'Entegrasyon yolları',
    transactions: 'İşlemler',
    articlesOfLaw: 'Yasa maddeleri',
    export: 'Dışa Aktar',
    duplicate: 'Bir kopya yap',
    makeReturnTrip: 'Dönüş yolculuğu yapın',
    selectOrganisation: 'Organizasyon seç',
    advancedSettings: 'Gelişmiş ayarlar',
    accept: 'Kabul et',
    reject: 'Reddet',
    sendViaGnet: 'GNET üzerinden gönder',
    printInvoice: 'Fatura yazdır',
    enable: 'Etkinleştir',
    disable: 'Devre Dışı Bırak',
    generatePaymentLink: 'Ödeme bağlantısı oluştur',
    addItem: '+ Öğeyi ekle',
    visitWebsite: 'Web sitemizi ziyaret edin',
    buyMoreSms: 'Daha fazla SMS satın alın',
    forward: 'Yönlendir',
    sendViaAffiliate: 'Affiliate aracılığıyla gönder',
    markInvoiceAsPaid: 'Faturayı ödenmiş olarak işaretle',
    markDrivingOfferAsPaid: 'Sürüş teklifini ödenmiş olarak işaretle',
    selectAll: 'Hepsini seç',
    viaAffiliateId: 'Affiliate kimliğiyle ekle',
    registerPartner: 'Ortak kaydet',
    createNew: 'Yeni oluştur',
    clone: 'Kopyala',
    cloneExisting: 'Mevcut olanı kopyala',
    copyLink: 'Bağlantıyı kopyala',
    markAsStarted: 'Başladı olarak işaretle',
    copyReferenceLink: 'Referans bağlantısını kopyala',
  },
  tooltips: {
    save: 'Kaydet',
    edit: 'Düzenle',
    delete: 'Sil',
    showImage: 'Resmi Göster',
    open: 'Aç',
    show: 'Göster',
    showDetails: 'Detayları Göster ',
    downloadFile: 'Dosyayı İndir',
    generateFile: 'Sözleşme Oluştur',
    generateDrivingOrder: 'Sürüş Siparişi Yazdır',
    activate: 'Etkinleştir',
    deactivate: 'Devre Dışı Bırak',
    cancel: 'İptal',
    cancelDriving: 'Sürüşü İptal Et',
    cannotMarkAsCanceled: 'Sipariş, zaten kabul edildiği için iptal olarak işaretlenemez',
    markAsDone: 'Tamamlandı Olarak İşaretle',
    cannotMarkAsDone: 'Sipariş henüz gerçekleşmediği için tamamlandı olarak işaretlenemez',
    markAsNoShow: 'Gösterilmeyen olarak işaretle',
    cannotMarkAsNoShow: 'Sipariş gösterilmeyen olarak işaretlenemez, çünkü henüz gerçekleşmedi',
    deleteDriving: 'Sürüşü sil',
    cannotDelete: 'Üzgünüz, ancak güvenlik nedenleriyle, kullanıcılar şu anda oturum açıkken silinemez.',
    cannotDeactivate: 'Üzgünüz, ancak güvenlik nedenleriyle kullanıcılar oturumları açıkken devre dışı bırakılamaz.',
    duplicate: 'Bir kopya yap',
    makeReturnTrip: 'Dönüş yolculuğu yapın',
    refresh: 'Yenile',
    selectOrganisation: 'Organizasyon seç',
    sendViaGnet: 'GNET üzerinden gönder',
    receivedViaGnet: 'GNET üzerinden alındı',
    sentViaGnet: 'GNET üzerinden gönderildi',
    printInvoice: 'Fatura yazdır',
    optionsDisabled: 'Seçenek menüsüne erişmek için siparişi reddetmeli veya kabul etmelisiniz',
    cannotMakeReturnTrip: 'Günlük kiralama için geri dönüş yolculuğu yapamazsınız',
    cannotSendGnetOrderViaGnet: 'Gnet siparişini gnet üzerinden gönderemezsiniz',
    cannotSendViaGnetWithoutGnetId: "Gnet ID'si olmadan siparişi gnet üzerinden gönderemezsiniz",
    cannotPrintInvoiceWithoutClient: 'Müşteri seçmeden faturayı yazdıramazsınız',
    cannotMarkAsDoneStatusRejected: 'Sipariş zaten reddedildiği için tamamlanmış olarak işaretlenemez',
    cannotMarkAsDoneStatusCanceled: 'Sipariş zaten iptal edildiği için tamamlanmış olarak işaretlenemez',
    cannotMarkAsDoneStatusDone: 'Sipariş zaten tamamlandığı için tamamlanmış olarak işaretlenemez',
    cannotMarkAsDoneStatusNoShow: 'Sipariş zaten gelmediği için tamamlanmış olarak işaretlenemez',
    cannotMarkAsNoShowStatusRejected: 'Sipariş zaten reddedildiği için gelmedi olarak işaretlenemez',
    cannotMarkAsNoShowStatusCanceled: 'Sipariş zaten iptal edildiği için gelmedi olarak işaretlenemez',
    cannotMarkAsNoShowStatusDone: 'Sipariş zaten tamamlandığı için gelmedi olarak işaretlenemez',
    cannotMarkAsNoShowStatusNoShow: 'Sipariş zaten gelmedi olarak işaretlendiği için gelmedi olarak işaretlenemez',
    cannotMarkAsCanceledGnet: 'Sipariş gnet üzerinden alındığı için iptal edilemez',
    cannotMarkAsCanceledStatusRejected: 'Sipariş zaten reddedildiği için iptal edilemez',
    cannotMarkAsCanceledStatusExpired: 'Sipariş zaten süresi dolmuş olduğu için iptal edilemez',
    cannotMarkAsCanceledStatusCanceled: 'Sipariş zaten iptal edildiği için iptal edilemez',
    cannotMarkAsCanceledStatusDraft: 'Sipariş zaten taslak olarak işaretlendiği için iptal edilemez',
    cannotMarkAsCanceledStatusCompleted: 'Sipariş zaten tamamlandığı için iptal edilemez',
    cannotMarkAsCanceledStatusNoShow: 'Sipariş zaten gelmedi olarak işaretlendiği için iptal edilemez',
    cannotDeleteDrivingSentViaGnet: 'Sipariş gnet üzerinden gönderildiği için silinemez',
    cannotDeleteDrivingReceivedViaGnet: 'Sipariş gnet üzerinden alındığı ve kabul edildiği için silinemez',
    generatePaymentLink: 'Ödeme bağlantısı oluştur',
    cannotGeneratePaymentLInk: 'Stripe etkinleştirmediğiniz için ödeme bağlantısı oluşturulamıyor',
    disabledExport: 'Verileri 24 saat içinde bir kez dışa aktarabilirsiniz',
    sendSms: 'SMS Gönder',
    accept: 'Kabul et',
    reject: 'Reddet',
    sendViaAffiliate: 'Affiliate aracılığıyla gönder',
    receivedViaAffiliate: 'Affiliate aracılığıyla alındı',
    sentViaAffiliate: 'Affiliate aracılığıyla gönderildi',
    cannotSendViaAffiliate: 'Affiliate üzerinden sürüş emri gönderemezsiniz.',
    cannotForwardDrivingOrder: 'Geçmişteki bir sürüş siparişini iletemezsiniz.',
    cannotMarkAsCanceledAffiliate: 'Sürüş emri, affiliate üzerinden alındığı için iptal olarak işaretlenemez.',
    cannotMarkAsCanceled12Hours: 'Sipariş, alma saatinden itibaren 12 saat geçtiği için iptal olarak işaretlenemez.',
    cannotDeleteActiveDriving: 'Aktif sürüş emri silinemez.',
    noImageToShow: 'Gösterilecek resim yok',
    disabledForAffiliateUser:
      "Hesabınız 'Ortak' türündedir. Bu işlem, abone olunan [Normal] hesaplar için kullanılabilir. Abone olmak için altbilgideki bağlantıya gidin.",
    markInvoiceAsPaid: 'Faturayı ödenmiş olarak işaretle',
    markDrivingOfferAsPaid: 'Sürüş teklifini ödenmiş olarak işaretle',
    invoiceIsPaid: 'Fatura zaten ödenmiş olarak işaretlenmiş',
    drivingOfferIsPaid: 'Sürüş teklifi zaten ödenmiş olarak işaretlenmiş',
    selectAreaToDelete: 'Silmek istediğiniz alanı seçin',
    deleteSelectedArea: 'Seçilen alanı sil',
    markAsStarted: 'Başladı olarak işaretle',
    cannotMarkAsStarted: 'Sipariş henüz gerçekleşmediği için başlatılamaz olarak işaretlenemez',
    cannotMarkAsStartedStatusStarted: 'Sipariş zaten başlatıldı olarak işaretlendiği için başlatılamaz',
    cannotMarkAsStartedStatusRejected: 'Sipariş zaten reddedildi olarak işaretlendiği için başlatılamaz',
    cannotMarkAsStartedStatusCanceled: 'Sipariş zaten iptal edildi olarak işaretlendiği için başlatılamaz',
    cannotMarkAsStartedStatusDone: 'Sipariş zaten tamamlandı olarak işaretlendiği için başlatılamaz',
    cannotMarkAsStartedStatusNoShow: 'Sipariş zaten gelinmedi olarak işaretlendiği için başlatılamaz',
    copyReferenceLink: 'Referans bağlantısını kopyala',
    noTemplatesAvailable: 'Mevcut rezervasyon şablonu yok',
  },
  snackbar: {
    close: 'Kapat',
    savedSuccessfully: 'Başarıyla Kaydedildi',
    excelDownloadedSuccessfully: 'Başarıyla İndirildi',
    deletedSuccessfully: 'Başarıyla Silindi',
    sentSuccessfully: 'Başarıyla Gönderildi',
    statusChanged: 'Durum Başarıyla Değiştirildi',
    activeStatusChanged: 'Aktif Durum Başarıyla Değiştirildi',
    defaultStatusChanged: 'Varsayılan Durum Başarıyla Değiştirildi',
    primaryStatusChanged: 'Birincil Durum Başarıyla Değiştirildi',
    anErrorOccured: 'Bir hata oluştu, destek ekibine bildirin',
    loadingCommentsFailed: 'Yorumları Yükleme Başarısız',
    drivingCanceled: 'Sürüş siparişi başarıyla iptal edildi',
    drivingFinished: 'Sürüş siparişi başarıyla tamamlandı',
    drivingStarted: 'Sürüş siparişi başarıyla başlatıldı olarak işaretlendi',
    drivingNoShow: 'Sürüş siparişi başarıyla gösterilmeyen olarak işaretlendi.',
    drivingDeleted: 'sürüş emri başarıyla silindi',
    drivingAccepted: 'Sürüş siparişi başarıyla kabul edildi',
    drivingRejected: 'Sürüş siparişi başarıyla reddedildi',
    planChanged: 'Plan başarıyla değiştirildi',
    planChangeFailed: 'Plan değiştirme mümkün değil',
    saveChangesFirst: 'Önce yaptığınız değişiklikleri kaydedin',
    makeRoutesPublic: 'Rotalar kamu erişimine açık',
    makeRoutesNotPublic: 'Rotalar artık kamu erişimine kapalı',
    makePublicLinkActive: 'Genel bağlantı artık kullanılabilir',
    makePublicLinkInactive: 'Genel bağlantı artık kullanılamıyor',
    integrationKeyCopied: 'Entegrasyon anahtarı kopyalandı',
    copyToken: "Token'ı manuel olarak kopyalayın",
    publicLinkCopied: 'Genel bağlantı kopyalandı',
    copyPublicLink: 'Genel bağlantıyı manuel olarak kopyalayın',
    referenceLinkCopied: 'Referans bağlantısı kopyalandı',
    copyReferenceLink: 'Referans bağlantısını manuel olarak kopyalayın',
    organisationChanged: 'Şirket başarıyla değiştirildi',
    changeFailed: 'Değişiklik başarısız oldu, lütfen tekrar deneyin',
    loginFailed: 'Kullanıcı adı veya şifre yanlış',
    organisationSuspended: 'Organizasyonunuz askıya alındı',
    userEmailDoesNotExists: 'Bu e-posta ile kullanıcı bulunmuyor',
    canceledSubscrSuccessfully: 'Abonelik başarıyla iptal edildi',
    toManyRequests: 'Çok fazla istek. Bir dakika sonra tekrar deneyin.',
    visibleStatusChanged: 'Görünür durum başarıyla değiştirildi',
    noInternetConnection: 'İnternet yok. Lütfen internet bağlantınızı kontrol edin.',
    backOnline: 'Tekrar çevrimiçisiniz.',
    driverNotifiedToTurnGpsOn: "Sürücüye GPS'i açması bildirildi",
    savedSuccessfullyWithGnetError: 'GNET senkronizasyonu başarısız oldu, sürüş siparişi başarıyla kaydedildi.',
    logoutFromAnotherDevice: 'Başka bir cihazdan çıkış yaptınız.',
    stripeEnabled: 'Stripe başarıyla etkinleştirildi',
    stripeDisabled: 'Stripe başarıyla devre dışı bırakıldı',
    finishStripeConnect: 'Lütfen Stripe kurulumunuzu tamamlayın',
    requestSentSuccessfully: 'İstek başarıyla gönderildi',
    pricingZoneNoMatch: 'Fiyatı hesaplamak için uygun fiyat bölgelerini bulamıyoruz.',
    areaAlreadyExists: 'Bu alan zaten mevcut',
    noDataForThisPostalCode: 'Bu posta kodu için veri yok',
    affiliateRequestSentSuccessfully: 'Organizasyon zaten mevcut, ortaklık isteği gönderildi',
    organizationRegisteredSuccessfully: 'Organizasyon başarıyla kaydedildi',
    organizationIsAlreadyAPartner: 'Organizasyon zaten bir ortak',
    recordAlreadyExists: 'Bu kayıt zaten mevcut.',
    tooManyUsers: 'Bu paket için çok fazla kullanıcınız var',
    priceCalculationFailed: 'Fiyat hesaplanamadı',
    workLogTimeRequired: 'Başlangıç ve bitiş saatini veya dakikaları girmelisiniz',
    zapierEnabled: 'Zapier başarıyla etkinleştirildi',
    zapierDisabled: 'Zapier başarıyla devre dışı bırakıldı',
    lastUserRoleChange:
      'Bu kullanıcının rolünü değiştiremezsiniz çünkü operatörler veya dağıtıcılar olmadan kalırsınız.',
  },
  filters: {
    filter: 'Filtrele',
    currentYear: 'Geçerli yıl',
    lastYear: 'Geçen yıl',
    lastQuarter: 'Son çeyrek',
    lastThreeYears: 'Son üç yıl',
    filterByDriver: 'Sürücüye göre filtrele',
    filterByVehicle: 'Araça göre filtrele',
    filterByClient: 'Müşterilere göre filtrele',
    day: 'Gün',
    week: 'Hafta',
    month: 'Ay',
    vehicle: 'Araç',
    driving: 'Sürüş',
    user: 'Kullanıcı',
    fromDate: 'Başlangıç tarihi',
    toDate: 'Bitiş tarihi',
    threeMonths: 'Üç ay',
    halfYear: 'Yarım yıl',
    year: 'Yıl',
  },
  months: {
    jan: 'Ocak',
    feb: 'Şubat',
    mar: 'Mart',
    apr: 'Nisan',
    may: 'Mayıs',
    jun: 'Haziran',
    jul: 'Temmuz',
    aug: 'Ağustos',
    sep: 'Eylül',
    oct: 'Ekim',
    nov: 'Kasım',
    dec: 'Aralık',
    January: 'Ocak',
    February: 'Şubat',
    March: 'Mart',
    April: 'Nisan',
    May: 'Mayıs',
    June: 'Haziran',
    July: 'Temmuz',
    August: 'Ağustos',
    September: 'Eylül',
    October: 'Ekim',
    November: 'Kasım',
    December: 'Aralık',
  },
  usersPageHeaders: {
    firstName: 'Adı',
    lastName: 'Soyadı',
    username: 'Kullanıcı Adı',
    email: 'E-posta',
    phoneNumber: 'Telefon Numarası',
    role: 'Rol',
    device: 'Cihaz',
    operatingSystem: 'İşletim sistemi',
    browser: 'Tarayıcı',
    lastActive: 'Son etkinlik',
    active: 'Aktif',
    actions: 'Eylemler',
  },
  usersRoles: {
    masterAdmin: 'Ana Yönetici',
    manager: 'Yönetici',
    driver: 'Sürücü',
    operator: 'Operatör',
    externalAssociate: 'Harici İş Ortakları',
    sales: 'Satış',
    salesLead: 'Satış Lideri',
    gnet: 'GNET',
    customer: 'Portal kullanıcısı',
    publicUser: 'Genel kullanıcı',
    zapier: 'Zapier',
  },
  masterUsersPageHeaders: {
    firstName: 'Adı',
    lastName: 'Soyadı',
    username: 'Kullanıcı Adı',
    email: 'E-posta',
    phoneNumber: 'Telefon Numarası',
    role: 'Rol',
    organisation: 'Organizasyon',
    active: 'Aktif',
    actions: 'Eylemler',
  },
  usersPage: {
    newItem: 'Yeni kullanıcı girişi',
    editItem: 'Kullanıcıyı Düzenle',
    id: 'Kimlik',
    firstName: 'Adı',
    lastName: 'Soyadı',
    username: 'Kullanıcı Adı',
    password: 'Şifre',
    phoneNumber: 'Telefon Numarası',
    role: 'Rol',
    client: 'Müşteri',
    email: 'E-posta',
    organisation: 'Organizasyon',
    percentage: 'Yüzde',
    externalAssociate: 'Harici İş Ortakları',
    image: 'Resim',
    deleteUser: 'Kullanıcıyı Sil',
    deleteMessage: 'Kullanıcıyı silmek istediğinizden emin misiniz ',
    emailChanged: 'E-posta değişikliği',
    verificationEmailSent:
      'E-posta adresini değiştirme talebi gönderildi. İşlemi tamamlamak ve kullanıcı hesabının güvenliğini sağlamak için yeni e-postaya gönderilen doğrulama bağlantısına tıklayarak yeni e-postanın doğrulanması gerekmektedir. E-posta adresi başarıyla doğrulanana kadar değiştirilmeyecektir.',
    passengersInfoAvailableToDriver: 'Yolcu bilgileri sürücüye açık',
    colorPicker:
      'Her kullanıcı için benzersiz bir renk seçebilirsiniz. Bu, kullanılabilirlik sayfasında daha kolay tanımlama için kullanılacaktır.',
  },
  clientsPageHeaders: {
    name: 'Adı',
    phoneNumber: 'Telefon Numarası',
    address: 'Adres',
    email: 'E-posta',
    companyId: 'Şirket Kimliği/Kişisel Kimlik',
    clientType: 'Müşteri türü',
    percentage: 'Yüzde',
    active: 'Aktif',
    actions: 'Eylemler',
  },
  clientsPage: {
    newItem: 'Yeni müşteri girişi',
    editItem: 'Müşteriyi Düzenle',
    id: 'Kimlik',
    name: 'Adı',
    address: 'Adres',
    phoneNumber: 'Telefon Numarası',
    email: 'E-posta',
    companyId: 'Şirket Kimliği/Kişisel Kimlik',
    clientType: 'Müşteri türü',
    individual: 'Bireysel',
    business: 'Ticari varlık',
    percentage: 'Yüzde',
    deleteClient: 'Müşteriyi Sil',
    deleteMessage: 'Müşteriyi silmek istediğinizden emin misiniz ',
    tabs: {
      general: 'Genel',
      transfers: 'Transferler',
      users: 'Kullanıcılar',
      invoices: 'Faturalar',
      cards: 'Kartlar',
    },
    publicId: 'Genel ID',
    referenceLink: 'Referans bağlantısı',
    publicFormNotEnabled:
      'Referans bağlantısına erişmek istiyorsanız, gelişmiş ayarlara gidin ve genel formu kullanılabilir hale getirin',
  },
  drivingsHeaders: {
    drivingNumber: 'Sürüş Siparişi Numarası',
    drivingType: 'Rezervasyon türü',
    drivingStatus: 'Durum',
    locationFrom: 'Başlangıç Noktası',
    locationTo: 'Varış Noktası',
    vehicleClass: 'Araç Sınıfı',
    vehicle: 'Araç',
    driversName: 'Sürücü',
    startTime: 'Alım Zamanı',
    actions: 'Eylemler',
    exportExcel: "Excel'i Dışa Aktar",
    price: 'Fiyat',
    totalPrice: 'Toplam fiyat',
    confirmed: 'Onaylandı',
    paid: 'Ödendi',
    numberOfDrivings: 'Sürüş siparişlerinin sayısı',
    templateName: 'Şablon adı',
    visibleToClients: 'Müşterilere görünür',
  },
  drivingTemplatesPage: {
    newItem: 'Yeni rezervasyon şablonu girişi',
    editItem: 'Rezervasyon şablonunu değiştirme',
    deleteDrivingTemplate: 'Rezervasyon şablonunu sil',
    deleteMessage: 'Bu rezervasyon şablonunu silmek istediğinizden emin misiniz?',
  },
  vehiclesHeaders: {
    plateNumber: 'Araç Plakası',
    brand: 'Araç Markası',
    vehicleClass: 'Araç Sınıfı',
    passengersNumber: 'Yolcu Sayısı',
    fuelConsumption: 'Araç Yakıt Tüketimi',
    yearOfManufacture: 'Üretim Yılı',
    pricePerKm: 'Km Başına Fiyat',
    pricePerMi: 'Mil başına Fiyat',
    pricePerHour: 'Saat Başına Fiyat',
    pricePerWaitingHour: 'Bekleme Saati Başına Fiyat',
    active: 'Aktif',
    actions: 'Eylemler',
  },
  vehiclesPage: {
    id: 'Kimlik',
    newItem: 'Yeni araç girişi',
    editItem: 'Araç Değişikliği',
    vehiclePlates: 'Araç Plakaları',
    vehicleBrand: 'Araç Markası',
    vehicleClass: 'Araç Sınıfı',
    passengersNumber: 'Yolcu Sayısı',
    fuelConsumption: 'Araç Yakıt Tüketimi (100 km)',
    fuelConsumptionMi: 'Araba yakıt tüketimi (100 mil)',
    manufactureYear: 'Üretim Yılı',
    price: 'Km Başına Fiyat',
    priceMi: 'Mil başına fiyat',
    hourPrice: 'Saat Başına Fiyat',
    image: 'Resim',
    hourWaitingPrice: 'Bekleme Saati Başına Fiyat',
    deleteMessage: 'Bu aracı silmek istediğinizden emin misiniz?',
    tabs: {
      general: 'Genel',
      transfers: 'Transferler',
      costs: 'Maliyetler',
      damages: 'Hasarlar',
    },
    licence: 'Lisans Numarası',
    color: 'Renk',
    colorPicker:
      'Her kullanıcı için benzersiz bir renk seçebilirsiniz. Bu, kullanılabilirlik sayfasında daha kolay tanımlama için kullanılacaktır.',
  },
  vehicleClassesHeaders: {
    vehicleClass: 'Araç Sınıfı',
    numberOfPassengers: 'Yolcu sayısı',
    numberOfSuitcases: 'Bavul sayısı',
    pricePerKm: 'Km Başına Fiyat',
    pricePerMi: 'Mil başına Fiyat',
    pricePerHour: 'Saat Başına Fiyat',
    pricePerWaitingHour: 'Bekleme Saati Başına Fiyat',
    availableForPublicBookingForm: 'Genel rezervasyon formu için uygun',
    active: 'Aktif',
    actions: 'Eylemler',
  },
  vehicleClassesPage: {
    newItem: 'Yeni araç sınıfı girişi',
    editItem: 'Araç sınıfı değişikliği',
    id: 'Kimlik',
    vehicleClass: 'Araç sınıfı',
    numberOfPassengers: 'Yolcu sayısı',
    numberOfSuitcases: 'Bavul sayısı',
    availableForPublicBookingForm: 'Genel rezervasyon formu için uygun',
    price: 'Km Başına Fiyat',
    priceMi: 'Mil başına fiyat',
    hourPrice: 'Saat Başına Fiyat',
    image: 'Resim',
    hourWaitingPrice: 'Bekleme Saati Başına Fiyat',
    deleteMessage: 'Bu araç sınıfını silmek istediğinizden emin misiniz?',
  },
  expensesHeaders: {
    name: 'Ad',
    vehicle: 'Araç',
    type: 'Tür',
    description: 'Açıklama',
    price: 'Fiyat',
    expenseDate: 'Tarih',
    actions: 'Eylem',
    driving: 'Sürüş',
    exportExcel: "Excel'i Dışa Aktar",
  },
  vehicleExpensesTypes: {
    minorService: 'Küçük bakım',
    majorService: 'Büyük bakım',
    fuel: 'Yakıt',
    tires: 'Lastikler',
    parking: 'Otopark',
    other: 'Diğer',
  },
  expensesPage: {
    newItem: 'Yeni maliyet girişi ',
    editItem: 'Maliyet değişikliği',
    id: 'Kimlik',
    name: 'Ad',
    vehicle: 'Araç',
    costType: 'Maliyet türü',
    price: 'Fiyat',
    currency: 'Para Birimi',
    costTime: 'Maliyet zamanı',
    description: 'Açıklama',
    deleteMessage: 'Bu maliyeti silmek istediğinizden emin misiniz?',
    costTypes: {
      'Küçük bakım': 'Küçük bakım',
      'Büyük bakım': 'Büyük bakım',
      Yakıt: 'Yakıt',
      Lastikler: 'Lastikler',
      Otopark: 'Otopark',
      Diğer: 'Diğer',
    },
  },
  vehicleMaintenanceHeaders: {
    name: 'Araç',
    details: 'Detaylar',
    dateFrom: 'Başlangıç tarihi',
    dateTo: 'Bitiş tarihi',
    actions: 'Eylemler',
  },
  vehicleMaintenancePage: {
    newItem: 'Yeni arıza girişi ',
    editItem: 'Araç Arızası Değişikliği',
    id: 'Kimlik',
    vehicle: 'Araç',
    description: 'Açıklama',
    dateFrom: 'Başlama zamanı',
    dateTo: 'Bitiş zamanı',
    deleteMessage: 'Bu arızayı silmek istediğinizden emin misiniz?',
  },
  customerCardsHeaders: {
    cardholderName: 'Kart Sahibi Adı',
    cardNumber: 'Kart Numarası',
    expiryDate: 'Son Kullanma Tarihi',
    cvv: 'CVV',
    actions: 'Eylemler',
  },
  customerCardsPage: {
    newItem: 'Yeni kart girişi',
    editItem: 'Kart değişikliği',
    cardholderName: 'Kart Sahibi Adı',
    cardNumber: 'Kart Numarası',
    expiryDate: 'Son Kullanma Tarihi',
    cvv: 'CVV',
    deleteCard: 'Kartı sil',
    deleteMessage: 'Bu kartı silmek istediğinizden emin misiniz?',
  },
  pricingRegionsHeaders: {
    name: 'Adı',
    actions: 'İşlemler',
  },
  pricingRegionsPage: {
    newItem: 'Fiyat bölgesi yeni girişi',
    editItem: 'Fiyat bölgesi değişikliği',
    name: 'Adı',
    chooseCurrency: 'Para birimi seç',
    soloPrice: 'Solo fiyat - Yıl',
    soloPaddlePriceId: 'Solo paddle fiyat ID - Yıl',
    team5Price: 'Takım 5 fiyatı - Yıl',
    team5PaddlePriceId: 'Takım 5 paddle fiyatı ID - Yıl',
    team10Price: 'Takım 10 fiyatı - Yıl',
    team10PaddlePriceId: 'Takım 10 paddle fiyatı ID - Yıl',
    team25Price: 'Takım 25 fiyatı - Yıl',
    team25PaddlePriceId: 'Takım 25 paddle fiyatı ID - Yıl',
    team50Price: 'Takım 50 fiyatı - Yıl',
    team50PaddlePriceId: 'Takım 50 paddle fiyatı ID - Yıl',
    soloPriceMonthly: 'Solo fiyat - Ay',
    soloPaddlePriceIdMonthly: 'Solo paddle fiyat ID - Ay',
    team5PriceMonthly: 'Takım 5 fiyatı - Ay',
    team5PaddlePriceIdMonthly: 'Takım 5 paddle fiyatı ID - Ay',
    team10PriceMonthly: 'Takım 10 fiyatı - Ay',
    team10PaddlePriceIdMonthly: 'Takım 10 paddle fiyatı ID - Ay',
    team25PriceMonthly: 'Takım 25 fiyatı - Ay',
    team25PaddlePriceIdMonthly: 'Takım 25 paddle fiyatı ID - Ay',
    team50PriceMonthly: 'Takım 50 fiyatı - Ay',
    team50PaddlePriceIdMonthly: 'Takım 50 paddle fiyatı ID - Ay',
    chooseCountries: 'Ülkeleri seç',
    description: 'Açıklama',
    deletePricingRegion: 'Fiyat bölgesini sil',
    deleteMessage: 'Bu fiyat bölgesini silmek istediğinizden emin misiniz?',
  },
  notificationsHeaders: {
    time: 'Zaman',
    notification: 'Bildirim',
  },
  notificationsPage: {
    showUnseenOnly: 'Sadece görülmeyenleri göster',
    markAllAsRead: 'Tümünü okundu olarak işaretle',
  },
  organisationsHeaders: {
    name: 'Organizasyon',
    phoneNumber: 'Telefon numarası',
    email: 'E-posta',
    address: 'Adres',
    companyId: 'Şirket Kimliği',
    vat: 'KDV',
    active: 'Aktif',
    createdAt: 'Kayıt Tarihi',
    lastActivityAt: 'Son etkinlik',
    actions: 'Eylemler',
    billingDate: 'Fatura Tarihi',
    referral: 'Yönlendiren',
    subscriptionType: 'Abonelik Türü',
    subscriptionStatus: 'Abonelik durumu',
    country: 'Ülke',
  },
  organisationsPage: {
    newItem: 'Yeni kullanıcı girişi ',
    editItem: 'Kullanıcıyı Düzenle',
    id: 'Kimlik',
    name: 'Organizasyon',
    address: 'Adres',
    phoneNumber: 'Telefon numarası',
    email: 'E-posta',
    vat: 'KDV',
    companyId: 'Şirket Kimliği',
    warrantNumber: 'Karar numarası',
    image: 'Resim',
    signature: 'İmza',
    seal: 'Mühür',
    deactivateMessage: 'Emin misiniz ',
    deactivate: 'DEAKTİF',
    activate: 'AKTİF',
    organisation: 'organizasyon',
    deleteOrganisation: 'Organizasyonu Sil',
    deleteMessage: 'Bu organizasyonu silmek istediğinizden emin misiniz?',
    cancelSubscrMessage:
      'Aboneliğinizi iptal etmek istediğinizden emin misiniz? Bu işlemi onaylayarak şunlara dikkat edin: 1. Kuruluşunuzun hesabı ve tüm ilişkili veriler kalıcı olarak silinecektir. Bu işlem geri alınamaz. 2. İptalden hemen sonra sistemden otomatik olarak çıkış yapılacaksınız. 3. Gelecekte kuruluşunuzla ilgili herhangi bir veriye erişmek veya giriş yapmak mümkün olmayacak. Bu geri dönüşü olmayan işlemle devam etmeden önce gerekli tüm bilgileri gözden geçirmenizi veya kritik verileri dışa aktarmanızı şiddetle tavsiye ederiz. Herhangi bir sorunuz varsa veya yardıma ihtiyacınız varsa, destek ekibimizle iletişime geçmekten çekinmeyin. İptal işlemine devam etmek istiyor musunuz?',
    allDrivings: 'Toplam sürüş siparişi sayısı',
    allDrivingsAccepted: 'Toplam kabul edilen sürüş siparişi sayısı',
    allDrivingsCanceled: 'Toplam reddedilen sürüş siparişi sayısı',
    allVehicles: 'Toplam araç sayısı',
    allUsers: 'Toplam kullanıcı sayısı',
    areYouSure: 'Emin misiniz?',
    makeRoutesAvailable: 'Entegrasyon rotalarını kullanılabilir hale getir',
    percentage: 'Yüzde',
    deleteImageMessage: 'Bu resmi silmek istediğinizden emin misiniz?',
    deleteImageTitle: 'Resmi Sil',
    cancelSubscriptionTitle: 'Abonmanı İptal Et',
    subscriptionType: 'Abonelik Türü',
    billingDate: 'Fatura Tarihi',
    articlesOfLaw: 'Yasa maddeleri',
    country: 'Ülke',
    referent: 'Referans',
    language: 'Dil',
    gnet: 'GNET',
    affiliateId: 'Affiliate ID',
    trialEndsAt: 'Deneme süresi bitiyor',
    languageTooltip:
      'Yeni kullanıcılar için varsayılan dil ve tüm şirket e-postalarının (bireysel kullanıcı e-postaları hariç) gönderileceği dil.',
    organisationType: 'Organizasyon türü',
    regular: 'Normal',
    affiliateSender: 'Ortak gönderici',
    affiliateReceiver: 'Ortak alıcı',
    dispatcher: 'Sevk görevlisi',
    referral: 'Yönlendiren',
    selectLocation: 'Konum seç',
    changeLocation: 'Konumu değiştir',
    timeZone: 'Zaman dilimi',
    onlyActive: 'Sadece aktif',
  },
  subscriptionsHeaders: {
    invoiceNumber: 'Fatura Numarası',
    status: 'Durum',
    total: 'Toplam',
    tax: 'Vergi',
    currency: 'Para Birimi',
    billedAt: 'Fatura Tarihi',
    actions: 'Eylemler',
  },
  subscriptionsPage: {
    newItem: 'Yeni işlem girişi',
    editItem: 'İşlemi düzenle',
    id: 'Id',
    invoiceNumber: 'Fatura numarası',
    status: 'Durum',
    sent: 'Gönderildi',
    completed: 'Tamamlandı',
    total: 'Toplam',
    currency: 'Para birimi',
    billedAt: 'Faturalandırma tarihi',
    actions: 'İşlemler',
    deleteSubscription: 'İşlemi sil',
    deleteMessage: 'Bu işlemi silmek istediğinizden emin misiniz?',
    currentSubscription: 'Mevcut paket',
    nextBillingDate: 'Sonraki fatura tarihi',
    numberOfSeatsTaken: 'Dolu koltuk sayısı',
    numberOfSeatsAvailable: 'Boş koltuk sayısı',
    pastDue: 'Vadesi geçmiş',
  },
  currenciesHeaders: {
    name: 'Ad',
    code: 'Kod',
    symbol: 'Sembol',
    active: 'Aktif',
    default: 'Varsayılan',
    actions: 'Eylemler',
  },
  currenciesPage: {
    newItem: 'Yeni para birimi girişi ',
    editItem: 'Para Birimi Değişikliği',
    id: 'Kimlik',
    currency: 'Para Birimi',
    code: 'Kod',
    symbol: 'Sembol',
    deleteMessage: 'Bu para birimini silmek istediğinizden emin misiniz?',
  },
  paymentMethodsHeaders: {
    name: 'Ad',
    active: 'Aktif',
    default: 'Varsayılan',
    priceVisible: 'Fiyat görünür',
    actions: 'Eylemler',
  },
  paymentMethodsPage: {
    newItem: 'Yeni ödeme yöntemi girişi',
    editItem: 'Ödeme yöntemi değişikliği',
    id: 'Kimlik',
    paymentMethod: 'Ödeme yöntemi',
    deleteMessage: 'Bu ödeme yöntemini silmek istediğinizden emin misiniz?',
  },
  feedbacksHeaders: {
    subject: 'Konu',
    organisation: 'Organizasyon',
    user: 'Kullanıcı',
    actions: 'Eylemler',
  },
  checkpointsHeaders: {
    checkpointLocation: 'Kontrol noktası konumu',
    arrivalTime: 'Varış saati',
    actions: 'Eylemler',
  },
  tables: {
    noDataText: 'Veri yok',
    search: 'Ara',
    drivings: 'Sürüş siparişleri',
    drivingsByPage: 'Sayfa başına sürüş siparişleri',
    drivingTemplates: 'Rezervasyon şablonları',
    drivingTemplatesByPage: 'Sayfa başına şablon',
    vehicles: 'Araçlar',
    vehiclesByPage: 'Sayfa başına araçlar',
    vehiclesClasses: 'Araç sınıfları',
    vehiclesClassesByPage: 'Sayfa başına araç sınıfları',
    expenses: 'Maliyetler',
    expensesByPage: 'Sayfa başına maliyet',
    vehiclesMaintenance: 'Araç bakımları',
    vehiclesMaintenanceByPage: 'Sayfa başına araç bakımları',
    reportsByPage: 'Sayfa başına raporlar',
    currencies: 'Para birimleri',
    currencieseByPage: 'Sayfa başına para birimleri',
    payments: 'Ödeme yöntemleri',
    paymentsByPage: 'Sayfa başına ödeme yöntemleri',
    saasAdministrators: 'Tüm yöneticiler',
    users: 'Tüm kullanıcılar',
    usersByPage: 'Sayfa başına kullanıcılar',
    clients: 'Tüm müşteriler',
    clientsByPage: 'Sayfa başına müşteriler',
    organisations: 'Tüm organizasyonlar',
    organisationsByPage: 'Sayfa başına organizasyonlar',
    cards: 'Kartlar',
    cardsByPage: 'Sayfa başına kartlar',
    transactions: 'Transakcije',
    transactionsByPage: 'Transakcije na strani',
    subscriptions: 'Abonelikler',
    subscriptionsByPage: 'Sayfa başına abonelikler',
    pricingRegions: 'Fiyat Bölgeleri',
    pricingRegionsByPage: 'Fiyat Bölgeleri sayfa başına',
    feedback: 'Geribildirimler',
    feedbacksByPage: 'Sayfa başına geribildirimler',
    notifications: 'Bildirimler',
    notificationsByPage: 'Sayfa başına bildirimler',
    all: 'Tümü',
    passengers: 'Yolcular',
    passengersByPage: 'Sayfa başına yolcular',
    of: 'of',
    checkpoints: 'Güzergah noktaları',
    checkpointsByPage: 'Sayfa başına güzergah noktaları',
    invoices: 'Faturalar',
    invoicesByPage: 'Sayfa başına faturalar',
    drivingOffers: 'Sürüş teklifleri',
    drivingOffersByPage: 'Sayfa başına sürüş teklifleri',
    smsMessages: 'SMS mesajları',
    smsMessagesByPage: 'Sayfa başına SMS mesajı',
    resourcesUsed: 'Kullanılan kaynaklar',
    resourcesUsedByPage: 'Sayfa başına kaynaklar',
    inNext3Days: 'Önümüzdeki 3 gün içinde',
    notConfirmedOrPaid: 'Onaylanmadı veya ödenmedi',
    inNext5Days: 'Önümüzdeki 5 gün içinde',
    latestReviews: 'Son incelemeler',
    passengerReviews: 'Yolcu yorumları',
    driversByPage: 'Sayfa başına sürücüler',
    reviewsByPage: 'Sayfa başına incelemeler',
    partners: 'Ortaklar',
    partnersByPage: 'Sayfa başına ortaklar',
    sentRequests: 'Gönderilen istekler',
    receivedRequests: 'Alınan istekler',
    requestsByPage: 'Sayfa başına istekler',
    pricingZones: 'Bölgeler ve alanlar',
    pricingZonesByPage: 'Bölgeler ve alanlar sayfa başına',
    pricingPerZones: 'Bölgelere göre fiyatlandırma',
    pricingPerZonesByPage: 'Sayfa başına fiyatlandırma',
    pricingPerHour: 'Saatlik fiyatlandırma',
    pricingPerHourByPage: 'Sayfa başına fiyatlandırma',
    workLogs: 'Etkinlik günlükleri',
    workLogsByPage: 'Etkinlik günlükleri sayfaya göre',
    loading: 'Yükleniyor',
  },
  statistics: {
    numberOfAcceptedDrivings: 'Kabul edilen sürüş siparişlerinin sayısı',
    numberOfRejectedDrivings: 'Sürücü başına reddedilen sürüş siparişlerinin sayısı',
    vehicleDrivingsNumber: 'Araç başına sürüş siparişlerinin sayısı',
    vehicleProfit: 'Araç başına kazanç',
    monthDrivingsNumber: 'Aya göre sürüş siparişlerinin sayısı',
    driverProfit: 'Sürücü başına kazanç',
  },
  reportsHeaders: {
    vehicle: 'Araç',
    expenses: 'Giderler',
    profit: 'Kar',
    earnings: 'Kazanç',
    daysWorking: 'Çalışma günleri',
    usageIndex: 'Kullanım endeksi',
    client: 'Müşteri',
    numberOfDrivings: 'Sürüş Sayısı',
    percentForClient: 'Klimayı Kullan',
    sumOfPrice: 'Miktar',
  },
  packages: {
    active: 'Aktif',
    starterTitle: 'Başlangıç',
    monthlyPackagesMessage: 'Her organizasyon için aylık abonelik.',
    starterText: 'Sadece temel işlevselliğe ihtiyaç duyan bireyler veya küçük ekipler için idealdir.',
    starterListItemOne: 'Mobil uygulamalar',
    starterListItemTwo: 'Push bildirimleri',
    starterListItemThree: 'Yönetim erişimi',
    starterListItemFour: 'Website entegrasyonu',
    standardTitle: 'Standart',
    standardText: '5 sürücüden fazla sürücüye sahip organizasyonlar için mükemmeldir.',
    standardListItemOne: 'BAŞLANGIÇ paketi dahildir',
    standardListItemTwo: 'Sürücü sayısı sınırlaması yoktur',
    standardListItemThree: '99.9% Uptime',
    standardListItemFour: 'Raporlar ve Araç Yönetimi',
    premiumTitle: 'Premium',
    premiumText: 'Kesin verilere dayalı çalışan limuzin hizmeti sunan organizasyonlar için mükemmeldir.',
    premiumListItemOne: 'STANDART paketi dahildir',
    premiumListItemTwo: 'BI ve Maliyet Tahmini',
    premiumListItemThree: 'Veri dışa aktarma',
    premiumListItemFour: 'Haftalık ve Aylık Raporlar',
    warningOne: '* Gösterilen fiyatlar kullanıcı başına aylık abonelik bedelidir',
    warningTwo: '* Yeni bir pakete geçtikten sonra, bir sonraki 6 ay boyunca daha küçük bir pakete geçiş yapılamaz',
    warningThree: '* Demo planı kullanıcıları, premium paket tarafından sağlanan işlevselliklere erişime sahiptir',
    warningOneAnnualPackage:
      'Daha fazla ihtiyacınız var mı? Daha büyük bir organizasyonunuz mu var? Size bir ENTERPRISE paketimiz var.',
    warningTwoAnnualPackage: 've satış ekibimiz sizi yakında iletişime geçecektir.',
    packageChange: 'Paket değişikliği',
    changePackage: 'Şuna geçmek istediğinizden emin misiniz',
    package: 'paket',
    annualPricing: 'Yıllık fiyatlandırma',
    monthlyPricing: 'Aylık fiyatlandırma',
    packageType5: 'Ekip 5',
    packageType10: 'Ekip 10',
    packageType25: 'Ekip 25',
    packageType50: 'Ekip 50',
    'Solo - Yearly': 'Solo - Yıllık',
    'Team 5 - Yearly': 'Takım 5 - Yıllık',
    'Team 10 - Yearly': 'Takım 10 - Yıllık',
    'Team 25 - Yearly': 'Takım 25 - Yıllık',
    'Team 50 - Yearly': 'Takım 50 - Yıllık',
    'Solo - Monthly': 'Solo - Aylık',
    'Team 5 - Monthly': 'Takım 5 - Aylık',
    'Team 10 - Monthly': 'Takım 10 - Aylık',
    'Team 25 - Monthly': 'Takım 25 - Aylık',
    'Team 50 - Monthly': 'Takım 50 - Aylık',
    annualPackagesMessage: 'Yıllık abonelik per organizasyon.',
    packageTypeSoloMainMessage: 'Sadece 1 kullanıcı hesabı.',
    packageType5MainMessage: 'En fazla 5 kullanıcı hesabına kadar.',
    packageType10MainMessage: 'En fazla 10 kullanıcı hesabına kadar.',
    packageType25MainMessage: 'En fazla 25 kullanıcı hesabına kadar.',
    packageType50MainMessage: 'En fazla 50 kullanıcı hesabına kadar.',
    allFeaturesIncluded: 'Tüm özellikler dahil.',
    unlimitedRides: 'Sınırsız sürüş ve araç sayısı.',
    trialPeriod: 'Deneme süresi dahil.',
    onboardingFeeOptional: 'Kurulum ücreti isteğe bağlı.',
    noHiddenCosts: 'Gizli maliyet yok.',
    freeSmsFlights10: 'Aylık 15 ücretsiz SMS ve 50 uçuş kontrolü.',
    freeSmsFlights25: 'Aylık 35 ücretsiz SMS ve 100 uçuş kontrolü.',
    freeSmsFlights50: 'Aylık 50 ücretsiz SMS ve 200 uçuş kontrolü.',
  },
  successPaymentPage: {
    message: 'Ödeme başarılı. Teşekkür ederiz.',
  },
  demoExpiring: {
    notification: 'Bildirim',
    expiredSubscriptionMessage:
      'Size bu platforma aboneliğinizin sona erdiğini bildiriyoruz. Hizmetlerimizi kullanmaya devam etmek için lütfen aboneliğinizi yenileyin.',
  },
  login: {
    username: 'Kullanıcı adı',
    password: 'Parola',
    rememberMe: 'Beni hatırla',
    login: 'Giriş yap',
    noAccount: 'Henüz bir hesabınız yok mu? Kayıt olabilirsiniz',
    forgetPassword: 'Parolanızı mı unuttunuz? Sıfırlayabilirsiniz',
    registerHere: 'buradan',
    email: 'E-posta',
    enterEmail: 'Lütfen e-postanızı girin',
    enterPassword: 'Lütfen yeni parolanızı girin',
    signInWithGoogle: 'Google ile giriş yap',
  },
  registration: {
    register: 'Kayıt Ol',
    name: 'Organizasyon Adı',
    address: 'Adres',
    phoneNumber: 'Telefon Numarası',
    email: 'E-posta',
    companyId: 'Şirket Kimliği',
    vat: 'KDV',
    language: 'Dil',
    package: 'Paket',
    accept: 'Kabul Ediyorum',
    terms: 'kullanım koşullarını',
    successfullyRegistered: 'Kullanıcı başarıyla kaydedildi. Doğrulama bağlantısı içeren bir e-posta alacaksınız.',
    haveAccount: 'Zaten hesabınız var mı? Giriş yapabilirsiniz',
    loginHere: 'Burada',
    trialPeriodInfo: 'Dahil 14 günlük ücretsiz deneme süresi. Fatura, deneme süresinin sonunda takip edecek.',
  },
  driving: {
    drivingType: 'Rezervasyon türü',
    general: 'Genel bilgiler',
    newDriving: 'Transferi planla',
    newDailyRent: 'Günlük kiralama planla',
    areYouSure: 'Emin misiniz?',
    vehicleIsUnavailable: 'Araç kullanımda değil',
    vehicleIsDamaged: 'Araç hasarlı',
    sureAboutThisVehicle: 'Bu aracı seçmek istediğinizden emin misiniz?',
    driverUnavaliable: 'Sürücü meşgul',
    sureAboutThisDriver: 'Bu sürücüyü seçmek istediğinizden emin misiniz?',
    checkVehicleSeatAvailability: 'Bu araç bu kadar yolcu taşıyamaz',
    cancelDriving: 'Sürüş siparişini iptal etmek istediğinizden emin misiniz?',
    finishDriving: 'Sürüş siparişini sonlandırmak istediğinizden emin misiniz?',
    noShowDriving: 'Sürüş siparişini gösterilmeyen olarak işaretlemek istediğinizden emin misiniz?',
    markDrivingAsStarted: 'Sürüş siparişini başlatıldı olarak işaretlemek istediğinizden emin misiniz?',
    deleteDriving: 'Sürüş siparişini silmek istediğinizden emin misiniz?',
    newTransfer: 'Yeni transfer',
    driving: 'Sürüş siparişi',
    comments: 'Yorumlar',
    id: 'Kimlik',
    locationFrom: 'Başlangıç noktası',
    locationTo: 'Varış noktası',
    vehicleClass: 'Araç sınıfı',
    vehicle: 'Araç',
    driver: 'Sürücü',
    pickupTime: 'Alım zamanı',
    clientConfirmed: 'Müşteri onayladı',
    roundTrip: 'Gidiş-dönüş',
    description: 'Açıklama',
    extraInfo: 'Ek bilgi',
    drivingNotAccepted: 'Sürüş siparişi kabul edilmedi:',
    currency: 'Para birimi',
    paymentMethod: 'Ödeme yöntemleri',
    price: 'Fiyat',
    charged: 'Ücretlendirildi',
    paid: 'Ödenmiş',
    distance: 'Mesafe (km)',
    distanceMi: 'Mesafe (mil)',
    expectedTime: 'Tahmini süre',
    waitingTime: 'Bekleme süresi (saat)',
    EnterTheNumberOfHoursWaiting: 'Bekleme saatini girin',
    dropOffTime: 'Varış saati',
    comebackTime: 'Dönüş saati',
    acceptUntil: 'Kabul süresi',
    drivingRequired: 'Sürüş siparişi zorunlu mu',
    comment: 'Yorum',
    attachments: 'Ekler',
    addFiles: '+ Dosyaları ekle',
    deleteFiles: 'Bu dosyayı silmek istediğinizden emin misiniz?',
    passengers: 'Yolcular',
    addPassenger: '+ Yolcu ekleyin',
    deletePassenger: 'Bu yolcu kaydını silmek istediğinizden emin misiniz?',
    chooseClient: 'Müşteri seçin',
    costs: 'Maliyetler',
    checkpoints: 'Güzergah noktaları',
    addCheckpoint: 'Güzergah noktası ekle',
    deleteCheckpoint: 'Bu güzergah noktasını silmek istediğinizden emin misiniz?',
    waitingBoardText: 'Pano metninde bekleyen metin',
    flightNumber: 'Uçuş numarası',
    flightInfo: 'Uçuş bilgisi',
    createdBy: 'Sürüş emri <b>{time}</b> tarihinde <b>{user}</b> tarafından oluşturuldu',
    client: 'Müşteri',
    note: 'Için not',
    noteDriver: 'Sürücü için not',
    noteDispatcher: 'Sevk memuru için not',
    passenger: 'Yolcu',
    fullName: 'Tam adı',
    phoneNumber: 'Telefon numarası',
    email: 'E-posta',
    passengersNumber: 'Yolcu sayısı',
    babySeatsNumber: 'Bebek koltuğu sayısı',
    suitcasesNumber: 'Bavul sayısı',
    stopsNumber: 'Durak sayısı',
    steps: {
      mainInfo: 'Ana Bilgiler',
      additionalInfo: 'Ek Bilgiler',
      review: 'Gözden Geçir',
    },
    commissionAmount: 'Komisyon',
    sendSms: 'SMS Gönder',
    message: 'Mesaj',
    acceptDriving: 'Sürüş siparişini kabul etmek istediğinizden emin misiniz?',
    rejectDriving: 'Sürüş siparişini reddetmek istediğinizden emin misiniz?',
    calculatePriceViaZonePricing: 'Fiyat bölgesi üzerinden fiyatı hesapla',
    disableCalculatePriceWithoutHours: 'Bekleme saati olmadan fiyat hesaplanamaz',
    disableCalculatePriceViaZonePricing:
      'Konum, para birimi ve araç sınıfı olmadan fiyat bölgesi üzerinden fiyat hesaplanamaz',
    calculatePriceViaDistanceScales: 'Mesafeye göre fiyat hesaplayın',
    disableCalculatePriceViaDistanceScales:
      'Mesafeye göre fiyat hesaplanamaz: mesafe, para birimi ve araç sınıfı olmadan',
    numberOfPassengers: 'Yolcu sayısı',
    numberOfSuitcases: 'Bavul sayısı',
    vat: 'KDV',
    vatPercentage: 'KDV yüzdesi',
    priceType: 'Fiyat türü',
    accessibleForWheelchair: 'Tekerlekli sandalye için uygun',
    priceForWaiting: 'Bekleme ücreti',
    numberOfHours: 'Saat sayısı',
    getAQuote: 'Teklif için ulaşın',
    selectPassenger: 'Yolcu seç',
    clientId: 'Müşteri kimliği',
    perDistance: '(mesafeye göre)',
    perHour: '(saate göre)',
    templateName: 'Şablon adı',
    visibleToClients: 'Müşterilere görünür',
  },
  drivingStatus: {
    pending: 'Beklemede',
    accepted: 'Kabul edildi',
    rejected: 'Reddedildi',
    expired: 'Süresi doldu',
    canceled: 'İptal edildi',
    draft: 'Taslak',
    done: 'Tamamlandı',
    noShow: 'Gösterilmeyen',
    ongoing: 'Başladı',
  },
  drivingOrderType: {
    dailyRent: 'Günlük Kiralama',
    transfer: 'Transfer',
    hourlyDaily: 'Saatlik/Günlük',
    oneWayTransfer: 'Tek Yön Transfer',
    fromAirport: 'Havaalanından',
    toAirport: 'Havaalanına',
  },
  flightInfo: {
    airlineName: 'Havayolu Adı',
    flightNumber: 'Uçuş Numarası',
    departureAirport: 'Kalkış Havalimanı',
    departureCountry: 'Kalkış Ülkesi',
    departureTime: 'Kalkış Saati',
    arrivalAirport: 'Varış Havalimanı',
    arrivalCountry: 'Varış Ülkesi',
    arrivalTime: 'Varış Saati',
    departureDelayed: 'Kalkışta Gecikme',
    flightStatus: 'Uçuş Durumu',
    percentageOfFlight: 'Uçuşun Yüzdesi',
  },
  customValidations: {
    fileType: `Dosya türü .{extension} desteklenmiyor`,
    fileSize: 'Dosyalar {size} boyutundan büyük olamaz',
    fieldRequired: '{field} alanı zorunludur',
    fieldType: '{field} alanı {type} türünde olmalıdır',
    fieldLengthMin: '{field} alanı en az {length} karakter olmalıdır',
    fieldLengthMax: '{field} alanı en fazla {length} karakter olmalıdır',
    fieldUnique: '{field} alanı benzersiz olmalıdır',
    fieldValid: '{field} alanı geçerli olmalı',
    noActiveSubscription: 'Aktif abonelik bulunamadı',
    usersLimitExceeded: 'Kullanıcı sınırlamasına ulaşıldı',
    fieldBefore: "{field1}, {field2}'dan önce olmalıdır",
    flightNotExist: 'Uçuş {field} mevcut değil',
    gnetKeyNotValid: 'Sağlanan Gnet anahtarı geçerli değil.',
    gnetPassengersRequired: "Sürücü talimatı, yolcular olmadan GNET'e gönderilemez.",
    priceAndCurrencyRequired: 'Ödeme bağlantısı, fiyat ve para birimi olmadan oluşturulamaz',
    fieldValueNotSupported: '{field} alanının değeri desteklenmiyor',
    noCountryCode: 'Hiçbir ülke seçmediniz',
    noStripeForYourCountry: 'Stripe ülkenizde mevcut değil',
    stripeAlreadyEnabled: 'Stripe zaten etkinleştirildi',
    fieldValueMin: '{field} alanı {min} veya daha fazla olmalı',
    fieldValueMax: '{field} alanı {max} veya daha az olmalı',
    markAsDoneDate: 'Alma zamanı gelecekte olamaz',
    phoneNumberNotValidForSms:
      'SMS göndermek için telefon numarası geçersiz. Geçerli bir telefon numarası ülke kodunu içermelidir.',
    affiliateIdDoesNotExist: 'Belirtilen ortaklık kimliğine sahip organizasyon mevcut değil.',
    numericDecimal: '{field} alanı yalnızca sayısal karakterler içerebilir (ondalıklar izinli)',
  },
  fieldTypes: {
    string: 'metin',
    integer: 'sayı',
    date: 'tarih',
  },
  names: {
    username: 'kullanıcı adı',
    password: 'şifre',
    choose_client: 'müşteri seçin',
    choose_month: 'ay seçin',
    currency: 'para birimi',
    payment_method: 'ödeme yöntemi',
    vehicle_class: 'araç sınıfı',
    first_name: 'ad',
    last_name: 'soyad',
    passport: 'pasaport',
    nationality: 'milliyet',
    plate_number: 'plaka numarası',
    brand: 'araç markası',
    number_of_passengers: 'yolcu sayısı',
    fuel_consumption: 'yakıt tüketimi',
    year_of_manufacture: 'üretim yılı',
    price_per_km: 'km başına fiyat',
    price_per_mi: 'mil başına fiyat',
    price_per_hour: 'saat başına fiyat',
    price_per_waiting_hour: 'bekleme saati başına fiyat',
    vehicle: 'araç',
    description: 'açıklama',
    time_from: 'başlangıç zamanı',
    time_to: 'bitiş zamanı',
    name: 'ad',
    cost_type: 'maliyet türü',
    cost_time: 'maliyet zamanı',
    price: 'fiyat',
    phone_number: 'telefon numarası',
    organisation: 'organizasyon',
    role: 'rol',
    image: 'resim',
    location_from: 'başlangıç konumu',
    location_to: 'varış konumu',
    pickup_time: 'Alım zamanı',
    distance: 'mesafe',
    waiting_time: 'bekleme süresi',
    comment: 'yorum',
    address: 'adres',
    vat: 'KDV',
    company_id: 'şirket kimliği',
    company_personal_id: 'şirket kimliği/kişisel kimlik',
    licence: 'lisans',
    warrant_number: 'karar numarası',
    seal: 'mühür',
    signature: 'imza',
    email: 'e-posta',
    percentage: 'yüzde',
    organizationName: 'organizasyon adı',
    package: 'paket',
    choose_currency: 'para birimi seç',
    team_10_price: 'ekip 10 fiyatı',
    team_10_paddle_price_id: 'ekip 10 paddle fiyatı ID',
    team_25_price: 'ekip 25 fiyatı',
    team_25_paddle_price_id: 'ekip 25 paddle fiyatı ID',
    team_50_price: 'ekip 50 fiyatı',
    team_50_paddle_price_id: 'ekip 50 paddle fiyatı ID',
    team_10_price_monthly: 'ekip 10 fiyatı',
    team_10_paddle_price_id_monthly: 'ekip 10 paddle fiyatı ID',
    team_25_price_monthly: 'ekip 25 fiyatı',
    team_25_paddle_price_id_monthly: 'ekip 25 paddle fiyatı ID',
    team_50_price_monthly: 'ekip 50 fiyatı',
    team_50_paddle_price_id_monthly: 'ekip 50 paddle fiyatı ID',
    countries: 'ülkeler',
    invoice_number: 'fatura numarası',
    status: 'durum',
    total: 'toplam',
    billed_at: 'fatura tarihi',
    articles_of_law: 'yasa maddeleri',
    country: 'ülke',
    referent: 'referans',
    language: 'dil',
    location: 'konum',
    arrival_time: 'varış zamanı',
    waiting_board_text: 'pano metninde bekleyen metin',
    flight_number: 'uçuş numarası',
    gnet_id: 'GNET Kimliği',
    receiver_id: 'Alıcı kimliği',
    preferred_vehicle_type: 'tercih edilen araç tipi',
    reservation_type: 'rezervasyon türü',
    run_type: 'çalışma türü',
    trip_duration_minutes: 'seyahat süresi',
    client: 'müşteri',
    client_type: 'müşteri türü',
    card_holder_name: 'kart sahibi adı',
    card_number: 'kart numarası',
    expiry_date: 'son kullanma tarihi',
    cvv: 'cvv',
    code: 'kod',
    symbol: 'sembol',
    quantity: 'miktar',
    discount: 'indirim',
    driving_type: 'rezervasyon türü',
    full_name: 'tam ad',
    suitcases_number: 'valiz sayısı',
    passengers_number: 'yolcu sayısı',
    baby_seats_number: 'bebek koltuğu sayısı',
    stops_number: 'durak sayısı',
    commission_amount: 'komisyon',
    message: 'mesaj',
    affiliate_id: 'affiliate id',
    coordinates: 'koordinatları',
    zone_one: 'bölge bir',
    zone_two: 'bölge iki',
    price_type: 'fiyat türü',
    price_for_waiting: 'bekleme ücreti',
    number_of_hours: 'saat sayısı',
    zones: 'Bölgeler',
    client_id: 'müşteri kimliği',
    minutes: 'dakika',
    started_at: 'başladı',
    finished_at: 'bitti',
    driving: 'sürüş',
    user: 'kullanıcı',
    time_zone: 'zaman dilimi',
    charged: 'ücretlendirildi',
    offer_number: 'sürüş teklifi numarası',
    drivings: 'sürümler',
  },
  weekDays: {
    monday: 'Pazartesi',
    tuesday: 'Salı',
    wednesday: 'Çarşamba',
    thursday: 'Perşembe',
    friday: 'Cuma',
    saturday: 'Cumartesi',
    sunday: 'Pazar',
  },
  select: {
    noDataAvailable: 'Veri yok',
    selected: 'Seçildi',
  },
  excelExport: {
    to_date_range: 'Rapor gelecekteki tarihler için oluşturulamaz. Lütfen geçerli tarihler girin',
    overlap_range: 'Bitiş saati başlangıç saatinden büyük olamaz.',
  },
  setPassword: {
    success: 'E-posta gönderildi',
    checkEmail: 'Daha fazla talimat için e-postanızı kontrol edin.',
    already_changed: 'Zaten değiştirildi',
    send_new_reset_request: 'Bu bağlantıyla şifrenizi zaten değiştirdiniz. Yeni şifre sıfırlama isteği gönderin.',
  },
  statisticsPage: {
    reportType: 'Rapor türü',
    reportTime: 'Rapor zamanı',
    vehicleUtilization: 'Araç kullanımı',
    costEffectiveness: 'Maliyet etkinliği',
    generalReport: 'Genel rapor',
    clientsReport: 'Müşterilere Göre Rapor',
  },
  profile: {
    yourProfile: 'Profiliniz',
    name: 'Ad',
    role: 'Rol',
    username: 'Kullanıcı Adı',
    password: 'Şifre',
    phoneNumber: 'Telefon Numarası',
    email: 'E-posta',
    percentage: 'Yüzde',
  },
  gpsMaps: {
    noTransfers: 'Devam eden veya yaklaşan transfer yok.',
    map: 'Harita',
    transfers: 'Transferler',
    drivers: 'Sürücüler',
    ongoing: 'Devam Eden',
    upcoming: 'Yaklaşan',
    driving: 'Sürüş',
    driver: 'Sürücü',
    vehicle: 'Araç',
    requestDriverLocation: 'Sürücü konumunu iste',
    lastRecordedLocation: 'Son kaydedilen konum',
  },
  driverStatus: {
    available: 'Uygun',
    offline: 'Çevrimdışı',
    inTransfer: 'Transferde',
  },
  onboardingTutorialMain: {
    languageStep: 'Burada platform için tercih ettiğiniz dili ayarlayabilirsiniz.',
    notificationsStep: 'Burada bildirimlerinizi bulabilirsiniz.',
    addTransferStep: 'Yeni bir rezervasyon, tek yön transfer veya günlük kiralama oluşturmak için bu düğmeye tıklayın.',
    sideMenuButtonStep: 'Menü çubuğunu göstermek veya gizlemek için bu simgeye tıklayın.',
    sideMenuExpStep:
      'Buradan platformun ana bölümlerine erişebilirsiniz: Rezervasyonlar, Filo, Maliyetler, Yönetim, Faturalar, İstatistikler vb.',
    sideMenuButtonCloseStep: 'Menü çubuğunu gizlemek için aynı simgeye tıklayın.',
    supportStep:
      'Herhangi bir sorun veya sorunuz varsa, buraya tıklayarak müşteri desteğiyle iletişime geçmekten çekinmeyin.',
  },
  onboardingTutorialVehicles: {
    tableStep: 'Araçlar ekledikten sonra bu tabloda listelenecektir.',
    searchStep: 'Araçları kolayca doğrudan marka, plaka, yıl vb. ile arayarak bulabilirsiniz.',
    addStep:
      '“Ekle” düğmesine tıklayarak yeni araçlar ekleyin. Araçları eklemeden önce tüm “Araç Sınıfları” eklenmelidir.',
  },
  onboardingTutorialStatistics: {
    typeStep:
      'Bu bölümde grafiklerle gösterilen tüm istatistiksel verilerinizi bulabilirsiniz. Burada istatistik türünü seçin.',
    intervalStep: 'İstatistikleri görüntülemek için bir aralık seçin.',
    downloadStep: 'Ayrıca bu tabloları indirip yazdırabilirsiniz.',
  },
  onboardingTutorialGps: {
    tabOneStep:
      'GPS modülünde sürücülerinizi ve transferlerinizi tek bir yerden takip edebilirsiniz. Devam eden ve yaklaşan (önümüzdeki 24 saat) transferleri görmek için bu sekmeyi seçin.',
    tabTwoStep: 'Araçları görmek için seçin. Transferlere doğrudan buradan uygun araçları atayabilirsiniz.',
    mapStep: 'Haritadaki araç simgesine tıklayarak araçları seçin veya seçimi kaldırın.',
  },
  onboardingTutorialInvoices: {
    tableStep: 'Tüm faturalar, yenilerden eskiye doğru bu tabloda listelenecektir.',
    searchStep:
      'Fatura numarası, miktar, müşteri adı, oluşturma tarihi vb. ile doğrudan arayarak faturaları kolayca bulun.',
    addStep: '“Ekle” düğmesine tıklayarak yeni faturalar oluşturun.',
  },
  onboardingTutorialOffers: {
    tableStep: 'Ekledikten sonra teklifler bu tabloda, yenilerden eskiye doğru listelenecektir.',
    searchStep: 'Doğrudan numara, miktar veya açıklama ile arama yaparak teklifleri kolayca bulun.',
    addStep: '“Ekle” düğmesine tıklayarak yeni teklifler oluşturun.',
  },
  onboardingTutorialResources: {
    infoStep: 'Kalan kaynakları görüntüleyin ve talep üzerine daha fazla SMS satın alın.',
    tableStep:
      'Bu tabloda kullanılan tüm kaynakların (gönderilen SMS ve kontrol edilen uçuşlar) denetim ve geçmişini bulabilirsiniz.',
    searchStep: 'İçerik, kullanıcı veya oluşturma tarihine göre doğrudan arayarak kaynakları kolayca bulun.',
  },
  onboardingTutorialPassengerReviews: {
    tableStep: 'Yolcu değerlendirmeleri bu tabloda, yenilerden eskiye doğru listelenecektir.',
    searchStep: 'Numara veya yolcu yorumu ile doğrudan arayarak yolcu değerlendirmelerini kolayca bulun.',
  },
  onboardingTutorialPricingZones: {
    tableStep: 'Fiyatlandırma bölgeleri ekledikten sonra bu tabloda listelenecektir.',
    searchStep: 'Doğrudan arama yaparak fiyatlandırma bölgelerini kolayca bulun.',
    addStep: '“Ekle” düğmesine tıklayarak yeni fiyatlandırma bölgeleri oluşturun.',
  },
  onboardingTutorialPricingZoneRelations: {
    tableStep: 'Fiyatlandırma bölgesi ilişkileri (fiyatlar) ekledikten sonra bu tabloda listelenecektir.',
    searchStep: 'Doğrudan arama yaparak fiyatlandırma bölgesi ilişkilerini kolayca bulun.',
    addStep:
      '“Ekle” düğmesine tıklayarak yeni fiyatlandırma bölgesi ilişkileri (fiyatlar) oluşturun. Bu özelliği kullanmadan önce “Fiyatlandırma bölgeleri” eklenmelidir.',
  },
  onboardingTutorialPricingZoneHourlyRelations: {
    tableStep:
      'Tüm fiyatlandırma bölgeleri bu tabloda listelenmiştir. Belirli bir bölgeyi açarak o bölge için fiyatlar belirleyebilirsiniz.',
    searchStep: 'Doğrudan arama yaparak fiyatlandırma bölgelerini kolayca bulun.',
  },
  onboardingTutorialDashboard: {
    infoStep: 'Son 30 gün için organizasyonunuzun istatistik özetini görüntüleyin.',
    next3DaysStep: 'Önümüzdeki 3 gün için rezervasyonlar bu tabloda listelenecektir.',
    notPaidOrConfirmedStep:
      'Önümüzdeki 7 gün için henüz onaylanmamış veya ödenmemiş rezervasyonlar bu tabloda listelenecektir.',
    next5DaysStep: 'Önümüzdeki 5 gün için meşgul olan sürücüler bu tabloda listelenecektir.',
    latestReviewsStep: 'En son sürüş değerlendirmeleriniz bu tabloda listelenecektir.',
  },
  onboardingTutorialAffiliate: {
    tabStep: 'Sekmeler arasında geçiş yaparak ortaklarınızı, gönderilen veya alınan talepleri görebilirsiniz.',
    idStep: 'Ortaklarınızla paylaşabileceğiniz affiliate ID’nizi burada bulabilirsiniz.',
    addStep: '“Ekle” düğmesine tıklayarak yeni bir affiliate ortaklık talebi oluşturun.',
  },
  onboardingTutorialAdvancedSettings: {
    advancedSettings:
      'Buraya tıklayarak organizasyonunuzun Gelişmiş Ayarlarına erişebilir ve tüm özellikleri ve entegrasyonları yapılandırabilirsiniz.',
  },
  onboardingTutorialDistanceScales: {
    distanceScales:
      'Burada her araç sınıfı ve para birimi için mesafe fiyat ölçeklerini ayarlayabilir ve yönetebilirsiniz. Belirli mesafe aralıkları (örneğin, 0-5 km, 5-15 km) için fiyatları kolayca tanımlayarak ücret hesaplamalarınızı özelleştirin.',
  },
  advancedSettings: {
    advancedSettings: 'Gelişmiş ayarlar',
    distanceUnit: 'Mesafe birimi',
    distanceUnitDescription: 'Mesafe ölçümleri için birimi seçin.',
    kilometers: 'Kilometre',
    miles: 'Mil',
    dateFormat: 'Tarih formatı',
    dateFormatDescription: 'Kullanmak istediğiniz tarih formatını seçin.',
    ddmmyyyy: 'DD-MM-YYYY',
    mmddyyyy: 'MM-DD-YYYY',
    mandatoryDrivings: 'Zorunlu sürüş emirleri',
    mandatoryDrivingsDescription:
      'Tüm sürüş emirlerini varsayılan olarak zorunlu hale getirmek isteyip istemediğinizi seçin.',
    mandatoryDrivingsDefault: 'Zorunlu Sürüş Emirleri (Varsayılan)',
    paidDrivings: 'Ücretli sürüş siparişleri',
    paidDrivingsDescription: 'Tüm sürüş siparişlerinin varsayılan olarak ücretli olmasını sağlamak için işaretleyin.',
    paidDrivingsDefault: 'Ücretli Sürüş Siparişleri (Varsayılan)',
    notificationsEmail: 'Bildirimler için e-posta',
    notificationsEmailDescription:
      'Bildirim almak için varsayılan e-postayı değiştirin (kuruluşunuzun e-posta adresini kullanmak istemiyorsanız).',
    email: 'E-posta',
    articlesOfLaw: 'Yasa maddeleri',
    integrations: 'Entegrasyonlar',
    gnetId: 'GNET ID',
    gnetDescription:
      'GNET, taşımacılık sektörü için gerçek zamanlı izleme, filo yönetimi ve sevk operasyonlarını optimize etmek amacıyla tasarlanmış kapsamlı bir platformdur.',
    makeRoutesAvailable: 'Entegrasyon rotalarını kullanılabilir hale getir',
    makeRoutesAvailableDescription:
      "LimoExpress'i web siteniz, ERP sisteminiz ve daha fazlasıyla entegre etmek için API anahtarınızı etkinleştirin.",
    makePublicFormAvailable: 'Genel formu kullanılabilir yap',
    makePublicFormAvailableDescription:
      'Müşterilerinizin gelişmiş rezervasyon formuna erişmesi ve kolayca hesap oluşturabilmesi için bu seçeneği etkinleştirin. Bağlantıyı bültenlerde, web sitesinde, el ilanlarında kullanabilirsiniz; bu, yalnızca şirketiniz için benzersiz bir bağlantıdır. Alternatif olarak, rezervasyon formunu doğrudan kendi web sitenize yerleştirmek için bir kod parçası da alabilirsiniz.',
    emailsEnabled: 'E-postalar etkin',
    emailsEnabledDefault: 'E-postalar etkin (Varsayılan)',
    emailsEnabledDescription: 'E-posta bildirimleri almak için işaretleyin.',
    passengerReviewsEnabled: 'Yolcu yorumları etkinleştirildi',
    passengerReviewsEnabledDefault: 'Yolcu yorumları etkinleştirildi',
    passengerReviewsEnabledDescription:
      'Bu özelliği etkinleştirdiğinizde, yolcularınız (e-posta girilmişse) yolculuk tamamlandıktan (Tamamlandı olarak işaretlendikten) sonra bir onay e-postası alacaklar ve hizmetlerinizle ilgili değerlendirmeler (geri bildirim) yapma fırsatına sahip olacaklar.',
    vat: 'KDV',
    vatPercentage: 'KDV yüzdesi',
    vatPercentageDescription: 'KDV yüzdesini girin.',
    invoicePaymentInstructions: 'Fatura ödeme talimatları',
    stripe: 'Stripe',
    stripeDescription:
      'Müşterilerinizden hizmetleriniz için ödeme alabilmek için Stripe’i etkinleştirin. LimoExpress komisyon almaz.',
    dataExport: 'Veri dışa aktarımı',
    dataExportDescription: 'Tüm verilerinizi dışa aktarabilirsiniz ve veriler e-posta adresinize gönderilecektir.',
    sureAboutDataExport:
      'Tüm verilerinizi dışa aktarmak istediğinizden emin misiniz? Eğer evet, tüm veriler önümüzdeki 2 saat içinde e-posta adresinize gönderilecektir.',
    defaultPriceType: 'Varsayılan fiyat türü',
    defaultPriceTypeDescription:
      'Fiyatların nasıl görüntüleneceğini belirlemek için varsayılan fiyat türünü ayarlayın.',
    NET: 'NET',
    GROSS: 'BRÜT',
    link: 'Bağlantı',
    code: 'Kod',
    language: 'Dil',
    chooseSize: 'Boyut seçin',
    small: 'Küçük',
    medium: 'Orta',
    large: 'Büyük',
    zapier: 'Zapier',
    zapierDescription:
      'Zapier’i etkinleştirerek dünya çapında en popüler 7.000’den fazla sistemle entegrasyon erişimi elde edin. Bu entegrasyon, iş süreçlerinizi tam olarak destekleyen iş akışları oluşturmanıza olanak tanır. Mesajları, faturaları otomatikleştirebilir ve LimoExpress platformundaki belirli etkinliklere bağlı olarak zincirleme eylemleri tetikleyebilirsiniz.',
    zapierId: 'Zapier ID',
    publicBookingColor: 'Genel rezervasyon formu rengi',
    useSystemColors: 'Varsayılan rengi kullan',
    choseCustomColor: 'Özel renk seç',
    location: 'Konum',
    selectLocation: 'Konum seç',
    changeLocation: 'Konumu değiştir',
    mapRadius: 'Harita yarıçapı',
    mapRadiusDescription:
      'Yeni bir rezervasyon oluştururken kullanılabilir konumları sınırlamak için bir konum belirleyin ve bir yarıçap tanımlayın. Sadece bu yarıçap içindeki adresler seçilebilir.',
    publicMapRadius: 'Genel rezervasyon formu harita yarıçapı',
    publicMapRadiusDescription:
      'Genel rezervasyon formu için bir konum belirleyin ve bir yarıçap tanımlayın. Yolculuk rezervasyonu yapan kullanıcılar yalnızca bu yarıçap içindeki konumları seçebilir.',
    radiusDistance: 'Yarıçap mesafesi (km)',
    radiusDistanceMi: 'Yarıçap mesafesi (mil)',
  },
  drivingExcelCheckboxes: {
    drivingNumber: 'Sürüş sipariş numarası',
    drivingType: 'Rezervasyon türü',
    drivingStatus: 'Durum',
    locationFrom: 'Başlangıç noktası',
    locationTo: 'Hedef',
    driver: 'Sürücü',
    client: 'Müşteri',
    plateNumber: 'Plaka numarası',
    note: 'Açıklama',
    fuelConsumption: 'Araç yakıt tüketimi',
    pickupTime: 'Alış zamanı',
    numberOfPassengers: 'Yolcu sayısı',
    passengers: 'Yolcular',
    price: 'Fiyat',
    paid: 'Ödenmiş',
    paymentMethod: 'Ödeme yöntemi',
    waitingTime: 'Bekleme süresi (saat)',
    distance: 'Mesafe (km)',
    distanceMi: 'Mesafe (mil)',
    selectAll: 'Hepsini seç',
    roundTrip: 'Gidiş-dönüş',
    babySeatsNumber: 'Bebek koltuğu sayısı',
    suitcasesNumber: 'Bavul sayısı',
    charged: 'Ücretlendirildi',
  },
  gnet: {
    gnetInfo: 'GNET bilgileri',
    requesterId: "İstek sahibinin ID'si",
    receiverId: 'Alıcı kimliği',
    preferredVehicleType: 'Tercih edilen araç tipi',
    reservationType: 'Rezervasyon türü',
    runType: 'Sürüş türü',
    price: 'Fiyat',
    currency: 'Para birimi',
    noteDriver: 'Sürücü için not',
    noteDispatcher: 'Sevk memuru için not',
    tripDurationMinutes: 'Seyahat süresi (dakika)',
  },
  invoicesHeaders: {
    invoiceNumber: 'Fatura numarası',
    client: 'Müşteri',
    totalPrice: 'Toplam fiyat',
    drivings: 'Sürümler',
    paid: 'Ödenmiş',
    currency: 'Para birimi',
    createdAt: 'Oluşturulma tarihi',
    actions: 'Eylemler',
  },
  invoicesPage: {
    newItem: 'Yeni fatura girişi',
    chooseClient: 'Müşteri seç',
    chooseDrivings: 'Sürüşleri seç',
    allDrivings: 'Tüm sürüşler',
    invoiceNumber: 'Fatura Numarası',
    payUntil: 'Ödeme Tarihi',
    deleteMessage: 'Bu faturayı silmek istediğinizden emin misiniz?',
    markInvoiceAsPaid: 'Bu faturayı ödenmiş olarak işaretlemek istediğinizden emin misiniz?',
  },
  drivingOffersHeaders: {
    number: 'Sürüş teklifi numarası',
    totalPrice: 'Toplam fiyat',
    description: 'Açıklama',
    paid: 'Ödenmiş',
    currency: 'Para birimi',
    createdAt: 'Oluşturulma tarihi',
    actions: 'Eylemler',
  },
  drivingOffersPage: {
    newItem: 'Yeni sürüş teklifi girişi',
    item: 'Öğe',
    description: 'Açıklama',
    title: 'Başlık',
    vehicleClass: 'Araç sınıfı',
    quantity: 'Miktar',
    price: 'Fiyat',
    discount: 'İndirim',
    number: 'Sürüş teklifi numarası',
    vat: 'KDV',
    deleteMessage: 'Bu sürüş teklifini silmek istediğinizden emin misiniz?',
    markDrivingOfferAsPaid: 'Bu sürüş teklifini ödenmiş olarak işaretlemek istediğinizden emin misiniz?',
  },
  modal: {
    reservationSuccessTitle: 'Başarı',
    reservationSuccessMessage:
      'Rezervasyon talebi başarıyla gönderildi. Kısa süre içinde biri sizinle iletişime geçecektir.',
    reservationErrorTitle: 'Form devre dışı',
    reservationErrorMessage:
      'Form, organizasyon tarafından şu anda devre dışı bırakılmıştır. Lütfen destek ile iletişime geçin veya daha sonra tekrar deneyin.',
  },
  dashboardPage: {
    drivingCountToday: 'Bugünkü sürüş sayısı',
    drivingCountThisMonth: 'Son 30 gündeki sürüş sayısı',
    dailyRentsAndTransfers: 'Günlük kiralama/transfer sürüş sayısı',
    drivingTotalAmount: 'Toplam tutar',
    driversCount: 'Aktif sürücüler (±7 gün)',
    passengerCount: 'Son 30 gündeki yolcu sayısı',
  },
  reviewsHeaders: {
    rating: 'Puan',
    comment: 'Yorum',
    createdAt: 'Oluşturulma tarihi',
  },
  resourceOverviewHeaders: {
    actionType: 'Eylem türü',
    user: 'Kullanıcı',
    action: 'Eylem',
    createdAt: 'Oluşturulma tarihi',
  },
  resourceOverviewPage: {
    smsMessagesLeft: 'Kalan SMS mesajı sayısı',
    flightsCheckLeft: 'Kalan uçuş kontrolü sayısı',
    sms: 'SMS',
    flightCheck: 'Uçuş kontrolü',
  },
  affiliateHeaders: {
    organisation: 'Organizasyon',
    createdAt: 'Oluşturulma tarihi',
    actions: 'Eylemler',
  },
  affiliatePage: {
    partners: 'Ortaklar',
    sentRequests: 'Gönderilen istekler',
    receivedRequests: 'Alınan istekler',
    acceptRequest: 'Bu isteği kabul etmek istediğinizden emin misiniz?',
    rejectRequest: 'Bu isteği reddetmek istediğinizden emin misiniz?',
    deletePartner: 'Bu ortağı silmek istediğinizden emin misiniz?',
    deleteRequest: 'Bu isteği silmek istediğinizden emin misiniz?',
    newItem: 'Yeni istek',
    affiliateId: 'Affiliate ID',
    registerNewPartner: 'Yeni bir ortak kaydet',
    registrationSuccessfull: 'Kayıt başarılı',
    registrationSuccessfullDescription:
      'Bağlı ortak başarıyla kaydedildi! Lütfen ortağı bir doğrulama e-postası alacağı ve bir şifre belirleyeceği konusunda bilgilendirin. İşlem tamamlandıktan sonra platformu kullanmaya başlayabilirler.',
  },
  affiliate: {
    affiliateInfo: 'Affiliate bilgi',
    affiliate: 'Affiliate',
    partner: 'Ortak',
    preferredVehicleType: 'Tercih edilen araç tipi',
    driverInfo: 'Sürücü bilgisi',
    vehicleInfo: 'Araç bilgisi',
    price: 'Fiyat',
    currency: 'Para birimi',
  },
  pricingZonesHeaders: {
    name: 'Bölge adı',
    code: 'Kod',
    postalCode: 'Posta Kodu',
    actions: 'İşlemler',
  },
  pricingZonesPage: {
    newItem: 'Yeni fiyat bölgesi girişi',
    editItem: 'Fiyat bölgesi değişikliği',
    id: 'ID',
    name: 'Bölge adı',
    code: 'Kod',
    deleteMessage: 'Bu fiyat bölgesini silmek istediğinizden emin misiniz?',
    postalCode: 'Posta Kodu',
    postalCodeDescription: 'Bir posta kodu kullanarak harita bölgesi tanımlayın',
    postalCodeDisabled: 'Lütfen önce bir posta kodu girin',
  },
  pricingZoneRelationsHeaders: {
    zoneOne: 'Bölge bir',
    zoneTwo: 'Bölge iki',
    client: 'Müşteri',
    actions: 'İşlemler',
  },
  pricingZoneRelationsPage: {
    newItem: 'Yeni fiyat bölgesi ilişki kaydı',
    editItem: 'Fiyat bölgesi ilişki değişikliği',
    zone: 'Bölge',
    zoneOne: 'Bölge bir',
    zoneTwo: 'Bölge iki',
    chooseClient: 'Müşteri seç',
    chooseClientFrom: 'Müşteriden kopyala',
    chooseClientTo: 'Müşteriye kopyala',
    cloneItem: 'Fiyat bölgesi ilişkisini kopyala',
    zoneRelations: 'Bölge ilişkileri',
    cloneItemHourly: 'Saatlik fiyatları kopyala',
    zones: 'Bölgeler',
    fromTo: 'Kimden - Kime',
    discountPercentage: 'İndirim yüzdesi (%)',
    deleteMessage: 'Bu fiyat bölgesi ilişkisini silmek istediğinizden emin misiniz?',
  },
  referralPrize: {
    referToAFriend: 'Bir arkadaşa yönlendir',
    referToAFriendDescription:
      'Aşağıya arkadaşlarınızın e-posta adresini girerek onları platforma katılmaya davet edin. Kişiselleştirilmiş bir kayıt daveti alacaklar ve platformu kullanmaya başlayabilecekler. Alternatif olarak, benzersiz kayıt bağlantınızı doğrudan başkalarıyla paylaşabilirsiniz! Birisi bağlantınız veya davetinizle kaydolduğunda, teşekkürümüz olarak bir yönlendirme ödülü kazanacaksınız. Bugün davet etmeye ve paylaşmaya başlayın!',
  },
  distanceScalesPage: {
    distanceScales: 'Mesafe ölçekleri',
  },
  passengersPage: {
    newItem: 'Yeni yolcu girişi',
    deletePassenger: 'Yolcuyu sil',
    deleteMessage: 'Yolcuyu silmek istediğinizden emin misiniz ',
  },
  formsAndTables: {
    actions: 'Eylemler',
    firstName: 'Ad',
    lastName: 'Soyad',
    email: 'E-posta',
    phoneNumber: 'Telefon numarası',
    passport: 'Pasaport',
    nationality: 'Milliyet',
    country: 'Ülke',

    logType: 'Tür',
    minutes: 'Dakika',
    startedAt: 'Başladı',
    finishedAt: 'Bitti',
    driving: 'Sürüş',
    user: 'Kullanıcı',
    createdAt: 'Oluşturulma tarihi',
    exportExcel: "Excel'e aktar",
    description: 'Açıklama',
  },

  workLogsPage: {
    newItem: 'Yeni etkinlik günlüğü girişi',
    deleteMessage: 'Bu etkinlik günlüğünü silmek istediğinizden emin misiniz?',
    regular: 'Düzenli',
  },
};
