<template>
  <div>
    <v-dialog v-model="showNotificationModal" width="500" persistent>
      <v-card>
        <v-card-title class="info title white--text font-weight-bold"
          >{{ $t('demoExpiring.notification') }}
        </v-card-title>
        <v-spacer />
        <v-card-text>
          <v-container>
            {{ $t('demoExpiring.expiredSubscriptionMessage') }}
          </v-container>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer />
          <v-btn @click="openPaddleCheckoutModal" color="primary"> {{ $t('buttons.payNow') }} </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-bottom-navigation v-if="this.$store.state.auth.trialBannerDisplay" class="expiring-demo yellow black--text d-flex align-center" fixed padless>
      <p class="mb-0">
        <span v-if="expirationHours >= 0">
          {{ $t('footer.trialEndsAt') }} {{ packageName }} {{ $t('footer.packageExpiresIn') }} {{ message }}.
        </span>
        <span v-else> {{ $t('footer.subscriptionTo') }} {{ packageName }} {{ $t('footer.packagePastDue') }} </span>
        <strong>
          <a @click="openPaddleCheckoutModal" class="black--text text-decoration-none cursor-pointer">
            {{ $t('footer.clickToPay') }}
          </a>
        </strong>
      </p>
    </v-bottom-navigation>
  </div>
</template>

<script>
import { initializePaddle } from '@paddle/paddle-js';
import { checkoutSuccessCallBack } from '@/mixins/paddle-functions';

export default {
  name: 'DemoExpiring',
  data: () => ({
    organisationEmail: '',
    expirationHours: null,
    packageName: '',
    expirationTime: null,
    message: '',
    showNotificationModal: false,
    user: {
      name: '',
      email: '',
      role_id: null,
    },
  }),
  created() {
    this.loadUser();
    this.checkSubscription();
    setInterval(() => {
      this.checkSubscription();
    }, 60 * 60 * 1000); // every hour

    let config = {
      token: process.env.VUE_APP_PADDLE_CLIENT_SIDE_TOKEN,
    };

    if (process.env.VUE_APP_ENV !== 'production') {
      config.environment = 'sandbox';
    }

    initializePaddle({
      ...config,
      eventCallback: (data) => {
          checkoutSuccessCallBack(data, this.$store)
      }
    }).then((paddleInstance) => {
      if (paddleInstance) {
        this.paddle = paddleInstance;
      }
    });
  },

  methods: {
    loadUser() {
      this.user = this.$store.getters['auth/user'];
    },
    calculateRemainingHours(date) {
      let difference = new Date(date) - new Date();
      let days = Math.floor(difference / (1000 * 60 * 60 * 24));
      let hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
      this.message = `${days ? days + 'd' : ''} ${hours ? hours + 'h' : ''}`;
      return Math.floor(difference / (1000 * 60 * 60));
    },
    checkSubscription() {
      if (this.$store.state.auth.customer) {
        this.packageName = this.$store.state.auth.user.organisation.package_name;

        if(this.$store.state.auth.chargeDateBody)
          this.doTheCalculation(this.$store.state.auth.chargeDateBody)

        var licenceLastCheck = localStorage.getItem('licenceLastCheck');

        var last_visit = new Date(licenceLastCheck ? licenceLastCheck : new Date());
        if(last_visit.getMinutes() < 45){
          last_visit.setMinutes(last_visit.getMinutes() + 15); // add 15 minutes
        }
        else{
          // if minutes are in the next hour
          last_visit.setHours(last_visit.getHours() + 1);
          last_visit.setMinutes(15 - (60 - last_visit.getMinutes()));
        }

        if(this.$store.state.auth.chargeDateBody === null || licenceLastCheck == null || last_visit < new Date()){
          localStorage.setItem('licenceLastCheck', new Date());

          this.$store.dispatch('subscriptionPackages/getChargeDate').then((res) => {
            this.doTheCalculation(res)
            this.$store.dispatch('auth/setChargeDateBody', res);
            
            this.user = {
              ...this.user,
              organisation: {
                ...this.user.organisation,
                package_name: res?.subscriptionType?.name,
              },
            };

            this.$store.dispatch('auth/setUser', this.user);
            localStorage.setItem('LimoExpressUser', JSON.stringify(this.user));
          });
        }
      }
    },
    doTheCalculation(res){
      this.expirationHours = this.calculateRemainingHours(res.date);
      if (res.trialEnded === false) {
        if(this.calculateRemainingHours(res.trialEndDate) > 0 || this.user.role_id === 1){
          this.$store.dispatch("auth/setTrialBannerDisplay", true);
        }
        else if (this.user.role_id !== 1) { // do not block saas admin
          this.showNotificationModal = true;
          this.$store.dispatch("auth/setTrialBannerDisplay", false);
        }
      } else if (this.expirationHours < - 25 * 24 && res.date != null && this.user.role_id !== 1) {  // do not block saas admin, let's give 25 days warning
        this.showNotificationModal = true;
        this.$store.dispatch("auth/setTrialBannerDisplay", false);
      }  else {
        if(this.expirationHours < 0)
          this.$store.dispatch("auth/setTrialBannerDisplay", true);
        else
          this.$store.dispatch("auth/setTrialBannerDisplay", false);
      }
    },
    async openPaddleCheckoutModal() {
      this.organisationEmail = this.$store.state.auth.user.organisation.email;
      if (this.paddle) {
        try {
          const { product } = await this.$store.dispatch('subscriptionPackages/getCalculatedPrice');
          this.paddle.Checkout.open({
            settings: {
              displayMode: 'overlay',
              theme: 'light',
              allowLogout: false,
            },
            items: [
              {
                priceId: product.price_id,
              },
            ],
            customer: {
              email: this.organisationEmail,
            },
          });
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error('Error fetching data:', error);
        }
      }
    },
  },
  watch: {
    '$store.state.auth.customer': {
      handler() {
        this.checkSubscription();
      },
    },
    '$store.state.auth.user.organisation': {
      handler() {
        this.packageName = this.$store.state.auth.user.organisation.package_name;
      },
    },
  },
};
</script>

<style>
.expiring-demo {
  bottom: 56px !important;
}
</style>
