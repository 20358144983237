import { getAllWorkLogs, saveWorkLog, updateWorkLog, deleteWorkLog, exportExcel } from '@/api/workLogs';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllWorkLogs(state, item) {
      return getAllWorkLogs(item);
    },
    async deleteWorkLog(state, item) {
      return deleteWorkLog(item);
    },
    async saveWorkLog(state, item) {
      return saveWorkLog(item);
    },
    async updateWorkLog(state, item) {
      return updateWorkLog(item);
    },
    async exportExcel(state, item) {
      return exportExcel(item);
    },
  },
  getters: {},
};
