import {
  getAllSubscriptions,
  getSubscription,
  saveSubscription,
  updateSubscription,
  deleteSubscription,
  getCurrentSubscription,
} from '@/api/subscriptions';

export default {
  namespaced: true,
  mutations: {},
  actions: {
    async getAllSubscriptions(state, item) {
      return getAllSubscriptions(item);
    },
    async getSubscription(state, item) {
      return getSubscription(item);
    },
    async saveSubscription(state, item) {
      return saveSubscription(item);
    },
    async updateSubscription(state, item) {
      return updateSubscription(item);
    },
    async deleteSubscription(state, item) {
      return deleteSubscription(item);
    },
    async getCurrentSubscription(state, item) {
      return getCurrentSubscription(item);
    },
  },
  getters: {},
};
