import { getHttpClient } from './client';

export const calculatePriceViaZonePricing = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/get-prices-by-coordinates';

  let queryString = '?';
  if (data) {
    if (data.from_lat) {
      queryString += `from_lat=${data.from_lat}`;
    }
    if (data.from_lng) {
      queryString += `&from_lng=${data.from_lng}`;
    }
    if (data.to_lat) {
      queryString += `&to_lat=${data.to_lat}`;
    }
    if (data.to_lng) {
      queryString += `&to_lng=${data.to_lng}`;
    }
    if (data.currency_id) {
      queryString += `&currency_id=${data.currency_id}`;
    }
    if (data.vehicle_class_id) {
      queryString += `&vehicle_class_id=${data.vehicle_class_id}`;
    }
    if (data.client_id) {
      queryString += `&client_id=${data.client_id}`;
    }
    if (data.type) {
      queryString += `&type=${data.type}`;
    }
  }

  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const calculatePriceViaDistanceScales = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/calculate-distance-scale-price';

  let queryString = '?';
  if (data) {
    if (data.distance) {
      queryString += `distance=${data.distance}`;
    }
    if (data.currency_id) {
      queryString += `&currency_id=${data.currency_id}`;
    }
    if (data.vehicle_class_id) {
      queryString += `&vehicle_class_id=${data.vehicle_class_id}`;
    }
    if (data.client_id) {
      queryString += `&client_id=${data.client_id}`;
    }
  }

  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const calculatePriceViaZonePricingCustomer = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/customer/get-prices-by-coordinates';

  let queryString = '?';
  if (data) {
    if (data.from_lat) {
      queryString += `from_lat=${data.from_lat}`;
    }
    if (data.from_lng) {
      queryString += `&from_lng=${data.from_lng}`;
    }
    if (data.to_lat) {
      queryString += `&to_lat=${data.to_lat}`;
    }
    if (data.to_lng) {
      queryString += `&to_lng=${data.to_lng}`;
    }
    if (data.currency_id) {
      queryString += `&currency_id=${data.currency_id}`;
    }
    if (data.vehicle_class_id) {
      queryString += `&vehicle_class_id=${data.vehicle_class_id}`;
    }
  }

  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};
