<template>
  <div>
    <div>
      <v-navigation-drawer v-model="drawer" app>
        <div class="d-flex flex-column justify-space-between">
          <div>
            <profile-dialog :user="user"></profile-dialog>

            <v-divider></v-divider>

            <v-list dense nav :disabled="tourActive" class="sidebar-menu">
              <div id="v-step-5">
                <template v-if="user.role_id === 1">
                  <side-menu-item
                    :link="routePrefix.concat('/dashboard')"
                    icon="mdi-view-dashboard"
                    :title="$t('sidebar.dashboard')"
                    :tooltip="$t('sidebar.dashboardAdministration')" />

                  <v-list-group
                    :value="mainTourActive && window.width > 1263"
                    prepend-icon="mdi-file-document-multiple">
                    <template v-slot:activator>
                      <v-list-item-content class="text-left">
                        <v-list-item-title>{{ $t('sidebar.rides') }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/drivings/tabular-view')"
                      icon="mdi-table"
                      :title="$t('sidebar.tabularView')"
                      :tooltip="$t('sidebar.tabularView')" />
                  </v-list-group>

                  <side-menu-item
                    :link="routePrefix.concat('/availability')"
                    icon="mdi-calendar-check"
                    :title="$t('sidebar.availability')"
                    :tooltip="$t('sidebar.availabilityAdministration')" />

                  <side-menu-item
                    :link="routePrefix.concat('/gps-map')"
                    icon="mdi-crosshairs-gps"
                    :title="$t('sidebar.gpsMap')"
                    :tooltip="$t('sidebar.gpsMap')" />

                  <v-list-group :value="mainTourActive && window.width > 1263" prepend-icon="mdi-car-multiple">
                    <template v-slot:activator>
                      <v-list-item-content class="text-left">
                        <v-list-item-title>{{ $t('sidebar.fleet') }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/vehicles')"
                      icon="mdi-car"
                      :title="$t('sidebar.vehicles')"
                      :tooltip="$t('sidebar.vehicles')" />

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/vehicle-classes')"
                      icon="mdi-car-select"
                      :title="$t('sidebar.vehiclesClasses')"
                      :tooltip="$t('sidebar.vehiclesClasses')" />

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/vehicle-breakdowns')"
                      icon="mdi-car-wrench"
                      :title="$t('sidebar.vehicleMaintenance')"
                      :tooltip="$t('sidebar.vehicleMaintenanceAdministration')" />
                  </v-list-group>

                  <side-menu-item
                    :link="routePrefix.concat('/costs')"
                    icon="mdi-car-cog"
                    :title="$t('sidebar.expenses')"
                    :tooltip="$t('sidebar.expenses')" />

                  <side-menu-item
                    :link="routePrefix.concat('/work-logs')"
                    icon="mdi-history"
                    :title="$t('sidebar.workLogs')"
                    :tooltip="$t('sidebar.workLogs')" />

                  <side-menu-item
                    :link="routePrefix.concat('/passengers')"
                    icon="mdi-account-group"
                    :title="$t('sidebar.passengers')"
                    :tooltip="$t('sidebar.passengersAdministration')" />

                  <side-menu-item
                    :link="routePrefix.concat('/invoices')"
                    icon="mdi-file-document"
                    :title="$t('sidebar.invoices')"
                    :tooltip="$t('sidebar.invoicesAdministration')" />

                  <side-menu-item
                    :link="routePrefix.concat('/driving-offers')"
                    icon="mdi-clipboard-text"
                    :title="$t('sidebar.drivingOffers')"
                    :tooltip="$t('sidebar.drivingOffersAdministration')"
                    :disabled="disabledForAffiliate"
                    :tooltipDisabled="$t('tooltips.disabledForAffiliateUser')" />

                  <side-menu-item
                    :link="routePrefix.concat('/resources-overview')"
                    icon="mdi-chart-box-outline"
                    :title="$t('sidebar.resourceOverview')"
                    :tooltip="$t('sidebar.resourceOverviewAdministration')" />

                  <side-menu-item
                    :link="routePrefix.concat('/passenger-reviews')"
                    icon="mdi-comment-account"
                    :title="$t('sidebar.passengerReviews')"
                    :tooltip="$t('sidebar.passengerReviewsAdministration')" />

                  <side-menu-item
                    :link="routePrefix.concat('/affiliate')"
                    icon="mdi-handshake"
                    :title="$t('sidebar.affiliate')"
                    :tooltip="$t('sidebar.affiliateAdministration')" />

                  <v-list-group prepend-icon="mdi-cash-marker">
                    <template v-slot:activator>
                      <v-list-item-content class="text-left">
                        <v-list-item-title>{{ $t('sidebar.pricingAndRates') }}</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/zones-and-areas')"
                      icon="mdi-map-marker-multiple"
                      :title="$t('sidebar.pricingZones')"
                      :tooltip="$t('sidebar.pricingZonesAdministration')" />

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/pricing-per-zones')"
                      icon="mdi-arrow-expand-horizontal"
                      :title="$t('sidebar.pricingPerZones')"
                      :tooltip="$t('sidebar.pricingPerZonesAdministration')" />

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/pricing-per-hour')"
                      icon="mdi-clock-outline"
                      :title="$t('sidebar.pricingPerHour')"
                      :tooltip="$t('sidebar.pricingPerHourAdministration')" />

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/distance-scales')"
                      icon="mdi-map-marker-distance"
                      :title="$t('sidebar.distanceScales')"
                      :tooltip="$t('sidebar.distanceScalesAdministration')" />
                  </v-list-group>

                  <v-list-group :value="mainTourActive && window.width > 1263" prepend-icon="mdi-cogs">
                    <template v-slot:activator>
                      <v-list-item-content class="text-left">
                        <v-list-item-title>{{ $t('sidebar.administration') }}</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/organisation-currencies')"
                      icon="mdi-currency-eur"
                      :title="$t('sidebar.currencies')"
                      :tooltip="$t('sidebar.currenciesAdministration')" />

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/payment')"
                      icon="mdi-cash-multiple"
                      :title="$t('sidebar.paymentTypes')"
                      :tooltip="$t('sidebar.paymentTypesAdministration')" />

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/team')"
                      icon="mdi-account-group"
                      :title="$t('sidebar.team')"
                      :tooltip="$t('sidebar.teamAdministration')" />

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/clients')"
                      icon="mdi-account-group"
                      :title="$t('sidebar.clients')"
                      :tooltip="$t('sidebar.clientsAdministration')" />

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/drivings/templates')"
                      icon="mdi-file-document-outline"
                      :title="$t('sidebar.drivingTemplates')"
                      :tooltip="$t('sidebar.drivingTemplates')" />
                  </v-list-group>

                  <v-list-group :value="mainTourActive && window.width > 1263" prepend-icon="mdi-account">
                    <template v-slot:activator>
                      <v-list-item-content class="text-left">
                        <v-list-item-title>{{ $t('sidebar.saasAdmin') }}</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/saas-admins')"
                      icon="mdi-account-group"
                      :title="$t('sidebar.administrators')"
                      :tooltip="$t('sidebar.saasAdminAdministration')" />

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/organisations')"
                      icon="mdi-office-building"
                      :title="$t('sidebar.organizations')"
                      :tooltip="$t('sidebar.organizationsAdministration')" />

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/advanced-settings')"
                      icon="mdi-cogs"
                      :title="$t('sidebar.advancedSettings')"
                      :tooltip="$t('sidebar.advancedSettingsAdministration')" />

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/currencies')"
                      icon="mdi-currency-eur"
                      :title="$t('sidebar.currencies')"
                      :tooltip="$t('sidebar.currenciesAdministration')" />

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/pricing-regions')"
                      icon="mdi-currency-usd"
                      :title="$t('sidebar.pricingRegions')"
                      :tooltip="$t('sidebar.pricingRegionsAdministration')" />
                  </v-list-group>

                  <side-menu-item
                    :link="routePrefix.concat('/statistics')"
                    icon="mdi-chart-bar"
                    :title="$t('sidebar.statistics')"
                    :tooltip="$t('sidebar.statistics')" />

                  <v-list-group :value="mainTourActive && window.width > 1263" prepend-icon="mdi-account-credit-card">
                    <template v-slot:activator>
                      <v-list-item-content class="text-left">
                        <v-list-item-title>{{ $t('sidebar.subscription') }}</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/subscription')"
                      icon="mdi mdi-format-list-bulleted"
                      :title="$t('sidebar.subscription')"
                      :tooltip="$t('sidebar.subscription')" />
                  </v-list-group>

                  <side-menu-item
                    :link="routePrefix.concat('/feedback')"
                    icon="mdi-help"
                    :title="$t('sidebar.help')"
                    :tooltip="$t('sidebar.help')" />
                </template>

                <template v-else-if="user.role_id === 2 || user.role_id === 4">
                  <side-menu-item
                    :link="routePrefix.concat('/dashboard')"
                    icon="mdi-view-dashboard"
                    :title="$t('sidebar.dashboard')"
                    :tooltip="$t('sidebar.dashboardAdministration')" />

                  <v-list-group
                    :value="mainTourActive && window.width > 1263"
                    prepend-icon="mdi-file-document-multiple">
                    <template v-slot:activator>
                      <v-list-item-content class="text-left">
                        <v-list-item-title>{{ $t('sidebar.rides') }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/drivings/calendar-view')"
                      icon="mdi-calendar"
                      :title="$t('sidebar.calendar')"
                      :tooltip="$t('sidebar.calendar')" />
                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/drivings/tabular-view')"
                      icon="mdi-table"
                      :title="$t('sidebar.tabularView')"
                      :tooltip="$t('sidebar.tabularView')" />
                  </v-list-group>

                  <side-menu-item
                    :link="routePrefix.concat('/availability')"
                    icon="mdi-calendar-check"
                    :title="$t('sidebar.availability')"
                    :tooltip="$t('sidebar.availabilityAdministration')" />

                  <side-menu-item
                    :link="routePrefix.concat('/gps-map')"
                    icon="mdi-crosshairs-gps"
                    :title="$t('sidebar.gpsMap')"
                    :tooltip="$t('sidebar.gpsMap')" />

                  <v-list-group :value="mainTourActive && window.width > 1263" prepend-icon="mdi-car-multiple">
                    <template v-slot:activator>
                      <v-list-item-content class="text-left">
                        <v-list-item-title>{{ $t('sidebar.fleet') }}</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/vehicles')"
                      icon="mdi-car"
                      :title="$t('sidebar.vehicles')"
                      :tooltip="$t('sidebar.vehicles')" />

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/vehicle-classes')"
                      icon="mdi-car-select"
                      :title="$t('sidebar.vehiclesClasses')"
                      :tooltip="$t('sidebar.vehiclesClasses')" />

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/vehicle-breakdowns')"
                      icon="mdi-car-wrench"
                      :title="$t('sidebar.vehicleMaintenance')"
                      :tooltip="$t('sidebar.vehicleMaintenanceAdministration')" />
                  </v-list-group>

                  <side-menu-item
                    :link="routePrefix.concat('/costs')"
                    icon="mdi-car-cog"
                    :title="$t('sidebar.expenses')"
                    :tooltip="$t('sidebar.expenses')" />

                  <side-menu-item
                    :link="routePrefix.concat('/work-logs')"
                    icon="mdi-history"
                    :title="$t('sidebar.workLogs')"
                    :tooltip="$t('sidebar.workLogs')" />

                  <side-menu-item
                    :link="routePrefix.concat('/passengers')"
                    icon="mdi-account-group"
                    :title="$t('sidebar.passengers')"
                    :tooltip="$t('sidebar.passengersAdministration')" />

                  <side-menu-item
                    :link="routePrefix.concat('/invoices')"
                    icon="mdi-file-document"
                    :title="$t('sidebar.invoices')"
                    :tooltip="$t('sidebar.invoicesAdministration')" />

                  <side-menu-item
                    :link="routePrefix.concat('/driving-offers')"
                    icon="mdi-clipboard-text"
                    :title="$t('sidebar.drivingOffers')"
                    :tooltip="$t('sidebar.drivingOffersAdministration')"
                    :disabled="disabledForAffiliate"
                    :tooltipDisabled="$t('tooltips.disabledForAffiliateUser')" />

                  <side-menu-item
                    :link="routePrefix.concat('/resources-overview')"
                    icon="mdi-chart-box-outline"
                    :title="$t('sidebar.resourceOverview')"
                    :tooltip="$t('sidebar.resourceOverviewAdministration')" />

                  <side-menu-item
                    :link="routePrefix.concat('/passenger-reviews')"
                    icon="mdi-comment-account"
                    :title="$t('sidebar.passengerReviews')"
                    :tooltip="$t('sidebar.passengerReviewsAdministration')" />

                  <side-menu-item
                    :link="routePrefix.concat('/affiliate')"
                    icon="mdi-handshake"
                    :title="$t('sidebar.affiliate')"
                    :tooltip="$t('sidebar.affiliateAdministration')" />

                  <v-list-group prepend-icon="mdi-cash-marker">
                    <template v-slot:activator>
                      <v-list-item-content class="text-left">
                        <v-list-item-title>{{ $t('sidebar.pricingAndRates') }}</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/zones-and-areas')"
                      icon="mdi-map-marker-multiple"
                      :title="$t('sidebar.pricingZones')"
                      :tooltip="$t('sidebar.pricingZonesAdministration')" />

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/pricing-per-zones')"
                      icon="mdi-swap-horizontal"
                      :title="$t('sidebar.pricingPerZones')"
                      :tooltip="$t('sidebar.pricingPerZonesAdministration')" />

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/pricing-per-hour')"
                      icon="mdi-clock-outline"
                      :title="$t('sidebar.pricingPerHour')"
                      :tooltip="$t('sidebar.pricingPerHourAdministration')" />

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/distance-scales')"
                      icon="mdi-map-marker-distance"
                      :title="$t('sidebar.distanceScales')"
                      :tooltip="$t('sidebar.distanceScalesAdministration')" />
                  </v-list-group>

                  <v-list-group :value="mainTourActive && window.width > 1263" prepend-icon="mdi-cogs">
                    <template v-slot:activator>
                      <v-list-item-content class="text-left">
                        <v-list-item-title>{{ $t('sidebar.administration') }}</v-list-item-title>
                      </v-list-item-content>
                    </template>

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/organisation-currencies')"
                      icon="mdi-currency-eur"
                      :title="$t('sidebar.currencies')"
                      :tooltip="$t('sidebar.currenciesAdministration')" />

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/payment')"
                      icon="mdi-cash-multiple"
                      :title="$t('sidebar.paymentTypes')"
                      :tooltip="$t('sidebar.paymentTypesAdministration')" />

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/users-organisation')"
                      icon="mdi-office-building"
                      :title="$t('sidebar.organization')"
                      :tooltip="$t('sidebar.organizationAdminstration')" />

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/team')"
                      icon="mdi-account-group"
                      :title="$t('sidebar.team')"
                      :tooltip="$t('sidebar.teamAdministration')" />

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/clients')"
                      icon="mdi-account-group"
                      :title="$t('sidebar.clients')"
                      :tooltip="$t('sidebar.clientsAdministration')" />

                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/drivings/templates')"
                      icon="mdi-file-document-outline"
                      :title="$t('sidebar.drivingTemplates')"
                      :tooltip="$t('sidebar.drivingTemplates')" />
                  </v-list-group>

                  <side-menu-item
                    :link="routePrefix.concat('/statistics')"
                    icon="mdi-chart-bar"
                    :title="$t('sidebar.statistics')"
                    :tooltip="$t('sidebar.statistics')" />

                  <side-menu-item
                    :link="routePrefix.concat('/subscription')"
                    icon="mdi mdi-account-credit-card"
                    :title="$t('sidebar.subscription')"
                    :tooltip="$t('sidebar.subscription')" />
                </template>

                <template v-if="user.role_id === 8">
                  <side-menu-item
                    :link="routePrefix.concat('/organisations')"
                    icon="mdi-office-building"
                    :title="$t('sidebar.organizations')"
                    :tooltip="$t('sidebar.organizationsAdministration')" />
                </template>

                <template v-else-if="user.role_id === 11">
                  <v-list-group
                    :value="mainTourActive && window.width > 1263"
                    prepend-icon="mdi-file-document-multiple">
                    <template v-slot:activator>
                      <v-list-item-content class="text-left">
                        <v-list-item-title>{{ $t('sidebar.rides') }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/drivings/calendar-view')"
                      icon="mdi-calendar"
                      :title="$t('sidebar.calendar')"
                      :tooltip="$t('sidebar.calendar')" />
                    <side-menu-item
                      class_name="ml-4"
                      :link="routePrefix.concat('/drivings/tabular-view')"
                      icon="mdi-table"
                      :title="$t('sidebar.tabularView')"
                      :tooltip="$t('sidebar.tabularView')" />
                  </v-list-group>

                  <side-menu-item
                    :link="routePrefix.concat('/gps-map')"
                    icon="mdi-crosshairs-gps"
                    :title="$t('sidebar.gpsMap')"
                    :tooltip="$t('sidebar.gpsMap')" />

                  <side-menu-item
                    :link="routePrefix.concat('/invoices')"
                    icon="mdi-file-document"
                    :title="$t('sidebar.invoices')"
                    :tooltip="$t('sidebar.invoicesAdministration')" />

                  <!-- <side-menu-item
                    :link="routePrefix.concat('/cards')"
                    icon="mdi-cards"
                    :title="$t('sidebar.cards')"
                    :tooltip="$t('sidebar.cardsAdministration')" /> -->
                </template>
              </div>
            </v-list>
          </div>

          <div>
            <v-divider></v-divider>
            <v-list dense nav :disabled="tourActive">
              <v-dialog v-model="dialog" max-width="450" persistent>
                <template v-slot:activator="{ on: dialog }">
                  <v-tooltip right>
                    <template v-slot:activator="{ on: tooltip, attrs }">
                      <v-list-item v-bind="attrs" v-on="{ ...tooltip, ...dialog }" class="mb-0">
                        <v-list-item-icon>
                          <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="text-left">
                          <v-list-item-title>{{ $t('sidebar.logout') }}</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                    <span>{{ $t('sidebar.logout') }}</span>
                  </v-tooltip>
                </template>

                <v-card>
                  <v-card-title class="blue darken-1 title white--text font-weight-bold">
                    {{ $t('sidebar.logout') }}
                    <v-spacer />
                    <v-icon class="mdi mdi-close" style="color: white" @click="dialog = false"></v-icon>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      {{ $t('sidebar.logoutText') }}
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn class="primary" text @click="logout">
                      {{ $t('buttons.yes') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-list>
          </div>
        </div>
      </v-navigation-drawer>

      <v-app-bar app class="primary" dark>
        <template>
          <v-btn
            class="d-none d-md-block navbar-icon menu-icon-left px-2 px-sm-4"
            text
            @click="drawer = !drawer"
            :id="window.width > 599 ? 'v-step-4' : ''">
            <v-icon> mdi-menu </v-icon>
          </v-btn>
        </template>

        <router-link to="/" class="d-none d-md-flex align-center black--text text-decoration-none">
          <v-img
            :class="{ displayLogo: user.role_id === 1 }"
            alt="Logo"
            class="shrink mr-2"
            contain
            height="60"
            src="../assets/logo2.svg"
            transition="scale-transition"
            width="240" />
        </router-link>

        <router-link to="/" class="d-flex d-md-none align-center black--text text-decoration-none">
          <v-img
            :class="{ displayLogo: user.role_id === 1 }"
            alt="Logo"
            class="shrink mr-2"
            contain
            height="32"
            src="../assets/Logo mark.svg"
            transition="scale-transition"
            width="32" />
        </router-link>

        <v-row v-if="this.user.role_id === 1">
          <v-col xl="4" lg="6" md="8" sm="12">
            <v-autocomplete
              v-model="organisationId"
              :items="organisations"
              item-text="name"
              item-value="id"
              :label="$t('header.organization')"
              name="organisation_id"
              required
              style="margin-top: 30px"
              @change="saasAdminCompanyUpdate"
              :no-data-text="$t('select.noDataAvailable')"
              @focus="$event.target.click()"></v-autocomplete>
          </v-col>
        </v-row>

        <v-spacer />

        <claim-referral-prize v-if="[2, 4].includes(user.role_id)" :key="user.referral_token" />

        <template
          v-if="
            this.$store.state.auth.authenticated &&
            (this.$store.state.auth.role === 2 || this.$store.state.auth.role === 4)
          ">
          <div class="text-center">
            <v-tooltip left :disabled="!disabledForAffiliate">
              <template v-slot:activator="{ on, attrs }">
                <div style="display: block" v-bind="attrs" v-on="on">
                  <!-- Main Menu -->
                  <v-menu
                    open-on-hover
                    open-on-click
                    bottom
                    offset-y
                    :close-on-content-click="false"
                    @input="menuStateChanged">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="primary add mr-2 mr-sm-8 ml-2 ml-sm-8"
                        v-bind="attrs"
                        v-on="on"
                        ref="submitBtn"
                        id="v-step-2"
                        :disabled="disabledForAffiliate">
                        + {{ $t('header.newDriving') }}
                      </v-btn>
                    </template>

                    <v-list class="driving-menu">
                      <template v-if="!showSubmenu">
                        <v-list-item @click="displayFormAndDisableOptions(2)">
                          <v-list-item-title>
                            {{ $t('header.dailyRent') }}
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="displayFormAndDisableOptions(1)">
                          <v-list-item-title>
                            {{ $t('header.transfer') }}
                          </v-list-item-title>
                        </v-list-item>

                        <!-- Fake Submenu -->
                        <v-tooltip left :disabled="drivingTemplates.length > 0">
                          <template v-slot:activator="{ on, attrs }">
                            <div class="d-block" v-bind="attrs" v-on="on">
                              <v-list-item
                                @click="showSubmenu = true"
                                class="from-template-item"
                                :disabled="drivingTemplates.length === 0">
                                <v-list-item-title class="d-flex align-center">
                                  {{ $t('header.fromTemplate') }}
                                  <v-icon left>mdi-chevron-right</v-icon>
                                </v-list-item-title>
                              </v-list-item>
                            </div>
                          </template>
                          <span v-if="drivingTemplates.length === 0">{{ $t('tooltips.noTemplatesAvailable') }}</span>
                        </v-tooltip>
                      </template>

                      <!-- Submenu -->
                      <template v-else>
                        <v-list-item @click="showSubmenu = false" class="back-item">
                          <v-list-item-title class="d-flex align-center">
                            <v-icon left>mdi-chevron-left</v-icon>
                            {{ $t('header.back') }}
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                          v-for="(item, index) in drivingTemplates"
                          :key="'template' + index"
                          @click="handleSubOption(item)">
                          <v-list-item-title>
                            {{ item.name }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </div>
              </template>
            </v-tooltip>
          </div>
        </template>

        <template v-if="this.$store.state.auth.authenticated && this.$store.state.auth.role === 11">
          <div class="text-center">
            <v-tooltip left :disabled="!disabledForAffiliate">
              <template v-slot:activator="{ on, attrs }">
                <div style="display: block" v-bind="attrs" v-on="on">
                  <!-- Main Menu -->
                  <v-menu
                    open-on-hover
                    open-on-click
                    bottom
                    offset-y
                    :close-on-content-click="false"
                    @input="menuStateChanged">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="primary add mr-2 mr-sm-8 ml-2 ml-sm-8"
                        v-bind="attrs"
                        v-on="on"
                        ref="submitBtn"
                        id="v-step-2"
                        :disabled="disabledForAffiliate">
                        + {{ $t('header.newDriving') }}
                      </v-btn>
                    </template>

                    <v-list class="driving-menu">
                      <template v-if="!showSubmenu">
                        <v-list-item @click="displayCustomerDrivingModal(2)">
                          <v-list-item-title>
                            {{ $t('header.dailyRent') }}
                          </v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="displayCustomerDrivingModal(1)">
                          <v-list-item-title>
                            {{ $t('header.transfer') }}
                          </v-list-item-title>
                        </v-list-item>

                        <!-- Fake Submenu -->
                        <v-tooltip left :disabled="drivingTemplates.length > 0">
                          <template v-slot:activator="{ on, attrs }">
                            <div class="d-block" v-bind="attrs" v-on="on">
                              <v-list-item
                                @click="showSubmenu = true"
                                class="from-template-item"
                                :disabled="drivingTemplates.length === 0">
                                <v-list-item-title class="d-flex align-center">
                                  {{ $t('header.fromTemplate') }}
                                  <v-icon left>mdi-chevron-right</v-icon>
                                </v-list-item-title>
                              </v-list-item>
                            </div>
                          </template>
                          <span v-if="drivingTemplates.length === 0">{{ $t('tooltips.noTemplatesAvailable') }}</span>
                        </v-tooltip>
                      </template>

                      <!-- Submenu -->
                      <template v-else>
                        <v-list-item @click="showSubmenu = false" class="back-item">
                          <v-list-item-title class="d-flex align-center">
                            <v-icon left>mdi-chevron-left</v-icon>
                            {{ $t('header.back') }}
                          </v-list-item-title>
                        </v-list-item>

                        <v-list-item
                          v-for="(item, index) in drivingTemplates"
                          :key="'template' + index"
                          @click="handleSubOption(item)">
                          <v-list-item-title>
                            {{ item.name }}
                          </v-list-item-title>
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </div>
              </template>
            </v-tooltip>
          </div>
        </template>

        <driving-modal
          v-if="
            this.$store.state.auth.role === 1 || this.$store.state.auth.role === 2 || this.$store.state.auth.role === 4
          "
          :selectedElement="selectedElement"
          :transferOrDaily="transferOrDaily" />

        <customer-driving-modal
          v-if="this.$store.state.auth.role === 11"
          :selectedElement="selectedElement"
          :transferOrDaily="transferOrDaily" />

        <template>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <v-tooltip left>
                <template v-slot:activator="{ on: tooltipOn }">
                  <v-btn class="navbar-icon px-2 px-sm-4" text v-on="{ ...on, ...tooltipOn }" id="v-step-0">
                    <v-icon> mdi-web </v-icon>
                  </v-btn>
                </template>
                <span>
                  {{ $t('header.changeLanguage') }}
                </span>
              </v-tooltip>
            </template>
            <v-list class="language-menu">
              <v-list-item v-for="(item, index) in languageOptions" :key="index" @click="changeLocale(item.abbr)">
                {{ item.lang }}
              </v-list-item>
            </v-list>
          </v-menu>
        </template>

        <template v-if="this.$store.state.auth.role !== 8">
          <v-tooltip left>
            <template v-slot:activator="{ on: tooltip }">
              <router-link to="/notifications" class="black--text text-decoration-none">
                <v-btn class="navbar-icon px-2 px-sm-4" text v-on="{ ...tooltip }">
                  <v-icon id="v-step-1"> mdi-bell </v-icon>
                </v-btn>
              </router-link>
            </template>
            <span>
              {{ $t('header.notifications') }}
            </span>
          </v-tooltip>
        </template>

        <reset-tour :role="this.$store.state.auth.role" />

        <template>
          <v-btn class="d-md-none navbar-icon menu-icon-right px-2 px-sm-4" text @click="drawer = !drawer">
            <v-icon :id="window.width < 600 ? 'v-step-4' : ''"> mdi-menu </v-icon>
          </v-btn>
        </template>
      </v-app-bar>
    </div>
    <div class="tourStyle">
      <v-tour name="mainTour" :steps="stepsTour" :callbacks="myCallbacks" :options="options"></v-tour>
    </div>
    <p class="under-support-icon" id="v-step-3"></p>
    <all-transfers-map
      v-if="
        this.$store.state.auth.role === 1 || this.$store.state.auth.role === 2 || this.$store.state.auth.role === 4
      " />
  </div>
</template>

<script>
import i18n from '@/i18n/i18n';
import SideMenuItem from '@/components/SideMenuItem';
import state from '@/store';
import ProfileDialog from '@/components/ProfileDialog.vue';
import DrivingModal from '@/views/pages/drivings/DrivingModal.vue';
import CustomerDrivingModal from '@/components/customers/drivings/CustomersDrivingModal.vue';
import AllTransfersMap from '@/components/gps-map/AllTransfersMap.vue';
import ResetTour from '@/components/ResetTour.vue';
import { defaultDriving } from '@/mixins/default-items';
import { languageOptions } from '@/mixins/language-options';
import 'vue-tour/dist/vue-tour.css';
import { mapState } from 'vuex';
import { getAllTourSteps, mainLayoutSteps, mainLayoutStepsSmallDevices } from '@/mixins/onboarding-tutorial-steps';
import ClaimReferralPrize from '@/components/ClaimReferralPrize.vue';

export default {
  name: 'MainLayout',
  data() {
    return {
      myCallbacks: {
        onStop: this.onComplete,
        onNextStep: this.onNextStep,
        onSkip: this.onSkip,
      },
      user: {
        name: '',
        email: '',
        role_id: null,
      },
      organisationId: null,
      organisations: [],
      userRoleId: null,
      drawer: false,
      dialog: false,
      preview: true,
      menu: false,
      routePrefix: process.env.VUE_APP_ROUTE_PREFIX,
      languageOptions: languageOptions,
      selectedElement: null,
      transferOrDaily: 1,
      visitedSteps: [],
      visitedStepsForThisTour: [],
      visitedStepsArray: [],
      stepsTour: [],
      window: {
        width: 0,
      },
      options: {
        labels: {
          buttonSkip: i18n.t('buttons.skip'),
          buttonPrevious: '<< ' + i18n.t('buttons.prev'),
          buttonNext: i18n.t('buttons.next') + ' >>',
          buttonStop: i18n.t('buttons.finish'),
        },
        highlight: true,
      },
      mainTourActive: false,
      showSubmenu: false,
    };
  },
  mounted() {
    if (parseInt(this.user.role_id) !== 1) {
      this.setStepsAndStartTour();
    }
  },

  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },

  computed: {
    ...mapState('onboardingTutorial', ['tourActive']),
    item_length: function (array) {
      return array.length;
    },
    steps() {
      if (this.window.width < 1264) {
        return mainLayoutStepsSmallDevices();
      } else {
        return mainLayoutSteps();
      }
    },
    drivingTemplates() {
      let gettersPath = '';
      if ([1, 2, 4].includes(this.$store?.getters['auth/user']?.role_id)) {
        gettersPath = 'drivingTemplates/getDrivingTemplates';
      } else if ([11].includes(this.$store?.getters['auth/user']?.role_id)) {
        gettersPath = 'customers/getDrivingTemplates';
      }
      return this.$store.getters[gettersPath].map((item) => {
        return {
          ...item,
          active: parseInt(item.active), // Convert to integer
          driving_type_id: item.drivingType?.id,
          vehicle_class_id: item.vehicleClass?.id,
          vehicle_id: item.vehicle?.id,
          driver_id: item.driver?.id,
          currency_id: item.currency?.id,
          location_from: JSON.parse(item.location_from),
          location_from_name: JSON.parse(item.location_from)?.name,
          location_to: JSON.parse(item.location_to),
          location_to_name: JSON.parse(item.location_to)?.name,
        };
      });
    },
  },
  components: {
    SideMenuItem,
    DrivingModal,
    CustomerDrivingModal,
    ProfileDialog,
    AllTransfersMap,
    ResetTour,
    ClaimReferralPrize,
  },
  created() {
    this.loadUser();

    if (parseInt(this.user.role_id) === 1) {
      this.loadAllOrganisations();
      this.organisationId = parseInt(this.user.organisation?.id);
    }

    this.selectedElement = Object.assign({}, defaultDriving);

    window.addEventListener('resize', this.handleResize);

    this.handleResize();

    if ([1, 2, 4].includes(this.$store?.getters['auth/user']?.role_id)) {
      this.$store.dispatch('clients/fetchAllClients');
      this.$store.dispatch('vehicleClasses/fetchAllVehicleClasses');
      this.$store.dispatch('users/fetchAllDrivers');
      this.$store.dispatch('vehicles/fetchAllVehicles');
      this.$store.dispatch('currencies/fetchAllOrganisationCurrencies');
      this.$store.dispatch('paymentMethods/fetchAllPaymentMethods');
      this.$store.dispatch('drivingTemplates/fetchAllDrivingTemplates');
    }
    if ([11].includes(this.$store?.getters['auth/user']?.role_id)) {
      this.$store.dispatch('customers/fetchAllDrivingTemplates');
    }
  },
  methods: {
    async setStepsAndStartTour() {
      if (this.$store.state.auth.role !== 1 && !this.tourActive) {
        await this.setSteps();
        if (this.stepsTour?.length > 0) {
          this.startTour();
        }
      }
    },
    setSteps() {
      return new Promise((resolve) => {
        const visitedStepsParsed = JSON.parse(localStorage.getItem('LimoExpressUser'))?.user_tour_logs;

        if (visitedStepsParsed) {
          this.visitedStepsArray = visitedStepsParsed
            .filter((item) => item.component_id === '1')
            ?.map((item) => item.step_id);
          if (this.visitedStepsArray?.length > 0) {
            this.stepsTour = this.steps.filter((step) => !this.visitedStepsArray.includes(step.target));
          } else {
            this.stepsTour = this.steps;
          }
        } else {
          this.stepsTour = [];
          let user = this.$store.getters['auth/user'];
          user.user_tour_logs = getAllTourSteps();

          this.$store.dispatch('auth/setUser', user);
          localStorage.setItem('LimoExpressUser', JSON.stringify(user));
        }
        resolve();
      });
    },

    startTour() {
      this.$tours['mainTour'].start();
      this.$store.dispatch('onboardingTutorial/toggleTour', true);
      this.mainTourActive = true;
    },

    handleResize() {
      this.window.width = window.innerWidth;
    },

    loadUser() {
      this.user = this.$store.getters['auth/user'];
    },

    logout() {
      let path = 'auth/logout';

      if (this.user.role_id === 11) {
        path = 'auth/customerLogout';
      }
      state
        .dispatch(path)
        .then(() => {
          state.dispatch('auth/removeUserData');
          this.dialog = false;
          window.location.href = '/login';
          this.$store.dispatch('onboardingTutorial/toggleTour', false);
          this.mainTourActive = false;
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
        });
    },

    color(active) {
      return active ? 'green' : 'red';
    },
    async loadAllOrganisations() {
      let data = await this.$store.dispatch('organisations/getAllOrganisationsForSaasAdmin');
      this.organisations = data.data.map((item) => {
        if (parseInt(item.active) === 1 || item.id === this.user.organisation?.id) {
          return item;
        }
      });

      this.organisationId = this.organisationId
        ? this.organisationId
        : this.user.organisation?.id
        ? parseInt(this.user.organisation?.id)
        : data.data[0].id;

      if (!this.user.organisation?.id) {
        this.saasAdminCompanyUpdate();
      }
    },
    saasAdminCompanyUpdate() {
      localStorage.removeItem('licenceLastCheck');
      this.$store
        .dispatch('organisations/assignSaasAdminOrganisation', { organisation_id: this.organisationId })
        .then((res) => {
          if (res.success) {
            this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.organisationChanged'), color: 'green' });
            res.user.role_id = parseInt(res.user.role.id);
            this.$store.dispatch('auth/setUser', res.user);
            localStorage.setItem('LimoExpressUser', JSON.stringify(res.user));
            this.$router.go(0);
          }
        })
        .catch(() => {
          this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.changeFailed'), color: 'red' });
        });
    },
    changeLocale(lang) {
      this.$store.dispatch('language/changeLanguage', lang);
      localStorage.setItem('currentLanguage', lang);
    },
    displayFormAndDisableOptions(data) {
      // data is integer passed to the function, 1 representing Transfer and 2 representing Dnevni najam
      this.transferOrDaily = data;

      this.selectedElement = Object.assign({}, defaultDriving);
    },

    handleSubOption(data) {
      this.transferOrDaily = data.drivingType.id;

      this.selectedElement = Object.assign(
        {},
        { ...data, id: '', from_template: true, from_location: data.location_from, to_location: data.location_to }
      );
    },

    displayCustomerDrivingModal(data) {
      // data is integer passed to the function, 1 representing Transfer and 2 representing Dnevni najam
      this.transferOrDaily = data;

      this.selectedElement = Object.assign({}, defaultDriving);
    },
    onComplete() {
      this.$store.dispatch('onboardingTutorial/toggleTour', false);
      this.mainTourActive = false;
      this.drawer = false;
      this.setStepAsVisited(this.steps.pop()?.target?.split('-').pop());
    },
    onSkip() {
      this.$store.dispatch('onboardingTutorial/toggleTour', false);
      this.mainTourActive = false;
      const body = {
        objects: this.steps.map((item) => ({
          component_id: '1',
          step_id: item.target,
        })),
      };

      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      const lastCharsOfTarget = this.steps.map((step) => step.target.slice(-1));
      lastCharsOfTarget.forEach((step) => this.updateStorage(step));
    },
    onNextStep(currentStep) {
      const currentStepId = this.stepsTour[currentStep]?.targetId;
      this.setStepAsVisited(currentStepId);

      if (currentStepId == 4) {
        this.drawer = true;
      }
    },

    updateStorage(currentStep) {
      const newVisitedStep = {
        component_id: '1',
        step_id: `#v-step-${currentStep}`,
      };

      let user = this.$store.getters['auth/user'];

      if (user) {
        if (user.user_tour_logs?.length > 0) {
          user.user_tour_logs.push(newVisitedStep);
        } else {
          user.user_tour_logs = [newVisitedStep];
        }
      }

      this.$store.dispatch('auth/setUser', user);
      localStorage.setItem('LimoExpressUser', JSON.stringify(user));
    },

    setStepAsVisited(step) {
      const body = {
        objects: [
          {
            component_id: '1',
            step_id: `#v-step-${step}`,
          },
        ],
      };

      this.$store.dispatch('onboardingTutorial/sendVisitedStep', body);
      this.updateStorage(step);
    },

    menuStateChanged(isOpen) {
      if (isOpen) {
        this.showSubmenu = false; // Reset submenu when menu closes
      }
    },
  },
};
</script>

<style>
@media only screen and (max-width: 500px) {
  .displayLogo {
    display: none;
  }
}

.v-list-item--active {
  color: #040b91 !important;
}

.navbar-icon {
  min-width: unset !important;
}

.menu-icon-left {
  margin-left: -16px;
}

.menu-icon-right {
  margin-right: -8px;
}

.under-support-icon {
  position: fixed;
  bottom: 5px !important;
  right: 20px !important;
  z-index: 9999;
  width: 57px !important;
  height: 57px !important;
  min-width: unset !important;
  border-radius: 50% !important;
}

.sidebar-menu {
  height: calc(100dvh - 128px);
  overflow-y: auto;
}

.driving-menu {
  max-height: 60dvh;
  overflow-y: auto;
}

.back-item {
  border-bottom: 1px solid #e0e0e0; /* Add bottom border */
}

.from-template-item {
  border-top: 1px solid #e0e0e0; /* Add bottom border */
}
</style>
