import { getHttpClient } from './client';

export const fetchAllVehicles = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/vehicle';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const getVehicle = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/vehicle/' + data).then((response) => response.data);
};

export const saveVehicle = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/vehicle', 'POST', data).then((response) => response.data);
};

export const updateVehicle = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/vehicle', 'POST', data).then((response) => response.data);
};

export const deleteVehicle = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/vehicle/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const changeVehicleActiveStatus = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/vehicle-status/' + data.id, 'POST', data).then(
    (response) => response.data
  );
};

export const checkIfVehicleIsAvailable = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/available-vehicle/' + data.id, 'POST', data).then(
    (response) => response.data
  );
};

export const checkVehicleSeatAvailability = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/seat-available/' + data.id, 'POST', data).then(
    (response) => response.data
  );
};
