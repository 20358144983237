import { getHttpClient } from './client';

export const getAllUsers = async (data) => {
  let baseUrl = process.env.VUE_APP_API_URL + '/user';
  let queryString = data ? '?' + new URLSearchParams(data).toString() : '';
  return await getHttpClient(baseUrl + queryString).then((response) => response.data);
};

export const fetchAllDrivers = async () => {
  let baseUrl = process.env.VUE_APP_API_URL + '/user?drivers=true';
  return await getHttpClient(baseUrl).then((response) => response.data);
};

export const getAllSaasAdmins = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/saas-admins').then((response) => response.data);
};

export const getAllCustomers = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/get-all-customers?client_id=' + data).then(
    (response) => response.data
  );
};

export const saveUser = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/user-profile', 'POST', data).then(
    (response) => response.data
  );
};

export const updateUser = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/user-profile', 'POST', data).then(
    (response) => response.data
  );
};

export const deleteUser = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/user/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};

export const changeUserActiveStatus = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/user-active/' + data.id, 'POST', data).then(
    (response) => response.data
  );
};

export const checkIfDriverIsAvailable = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/available-user/' + data.id, 'POST', data).then(
    (response) => response.data
  );
};
