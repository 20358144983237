import {
  fetchAllPaymentMethods,
  getPaymentMethod,
  savePaymentMethod,
  updatePaymentMethod,
  deletePaymentMethod,
  changePaymentMethodActiveStatus,
  changePaymentMethodDefaultStatus,
  changePaymentMethodVisibleStatus,
} from '@/api/paymentMethods';
import store from '@/store';
import i18n from '@/i18n/i18n';

export default {
  namespaced: true,
  state: { paymentMethods: [] },
  mutations: {
    SET_PAYMENT_METHODS(state, paymentMethods) {
      state.paymentMethods = paymentMethods;
    },
  },
  actions: {
    async fetchAllPaymentMethods({ commit }) {
      try {
        const response = await fetchAllPaymentMethods();
        commit('SET_PAYMENT_METHODS', response.data);
      } catch (error) {
        store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
      }
    },
    async getPaymentMethod(state, item) {
      return getPaymentMethod(item);
    },
    async savePaymentMethod(state, item) {
      return savePaymentMethod(item);
    },
    async updatePaymentMethod(state, item) {
      return updatePaymentMethod(item);
    },
    async deletePaymentMethod(state, item) {
      return deletePaymentMethod(item);
    },
    async changePaymentMethodActiveStatus(state, item) {
      return changePaymentMethodActiveStatus(item);
    },
    async changePaymentMethodDefaultStatus(state, item) {
      return changePaymentMethodDefaultStatus(item);
    },
    async changePaymentMethodVisibleStatus(state, item) {
      return changePaymentMethodVisibleStatus(item);
    },
  },
  getters: {
    getPaymentMethods: (state) => state.paymentMethods,
  },
};
