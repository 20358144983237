import {
  fetchAllVehicles,
  getVehicle,
  saveVehicle,
  updateVehicle,
  deleteVehicle,
  changeVehicleActiveStatus,
  checkIfVehicleIsAvailable,
  checkVehicleSeatAvailability,
} from '@/api/vehicles';
import store from '@/store';
import i18n from '@/i18n/i18n';

export default {
  namespaced: true,
  state: { vehicles: [] },
  mutations: {
    SET_VEHICLES(state, vehicles) {
      state.vehicles = vehicles;
    },
  },
  actions: {
    async fetchAllVehicles({ commit }) {
      try {
        const response = await fetchAllVehicles();
        commit('SET_VEHICLES', response.data);
      } catch (error) {
        store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
      }
    },
    async getAllVehiclesByClass(state, item) {
      return fetchAllVehicles(item);
    },
    async getVehicle(state, item) {
      return getVehicle(item);
    },
    async saveVehicle(state, item) {
      return saveVehicle(item);
    },
    async updateVehicle(state, item) {
      return updateVehicle(item);
    },
    async deleteVehicle(state, item) {
      return deleteVehicle(item);
    },
    async changeVehicleActiveStatus(state, item) {
      return changeVehicleActiveStatus(item);
    },
    async checkIfVehicleIsAvailable(state, item) {
      return checkIfVehicleIsAvailable(item);
    },
    async checkVehicleSeatAvailability(state, item) {
      return checkVehicleSeatAvailability(item);
    },
  },
  getters: {
    getVehicles: (state) => state.vehicles,
  },
};
