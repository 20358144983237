import { getHttpClient } from './client';

export const fetchAllDrivingTemplates = async () => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/driving-template').then((response) => response.data);
};

export const saveDrivingTemplate = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/driving-template', 'POST', data).then(
    (response) => response.data
  );
};

export const updateDrivingTemplate = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/driving-template', 'POST', data).then(
    (response) => response.data
  );
};

export const deleteDrivingTemplate = async (data) => {
  return await getHttpClient(process.env.VUE_APP_API_URL + '/driving-template/' + data.id, 'DELETE', data).then(
    (response) => response.data
  );
};
