import {
  changeCurrencyActiveStatus,
  changeCurrencyDefaultStatus,
  deleteCurrency,
  getAllCurrencies,
  getCurrency,
  saveCurrency,
  updateCurrency,
  fetchAllOrganisationCurrencies,
  getOrganisationAvailableCurrencies,
  saveOrganisationCurrencies,
  deleteOrganisationCurrency,
} from '@/api/currencies';
import store from '@/store';
import i18n from '@/i18n/i18n';

export default {
  namespaced: true,
  state: { currencies: [] },
  mutations: {
    SET_CURRENCIES(state, currencies) {
      state.currencies = currencies;
    },
  },
  actions: {
    async getAllCurrencies(state, item) {
      return getAllCurrencies(item);
    },
    async fetchAllOrganisationCurrencies({ commit }) {
      try {
        const response = await fetchAllOrganisationCurrencies();
        commit('SET_CURRENCIES', response.data);
      } catch (error) {
        store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
      }
    },
    async getOrganisationAvailableCurrencies(state, item) {
      return getOrganisationAvailableCurrencies(item);
    },
    async getCurrency(state, item) {
      return getCurrency(item);
    },
    async saveCurrency(state, item) {
      return saveCurrency(item);
    },
    async updateCurrency(state, item) {
      return updateCurrency(item);
    },
    async deleteCurrency(state, item) {
      return deleteCurrency(item);
    },
    async changeCurrencyActiveStatus(state, item) {
      return changeCurrencyActiveStatus(item);
    },
    async changeCurrencyDefaultStatus(state, item) {
      return changeCurrencyDefaultStatus(item);
    },
    async saveOrganisationCurrencies(state, item) {
      return saveOrganisationCurrencies(item);
    },
    async deleteOrganisationCurrency(state, item) {
      return deleteOrganisationCurrency(item);
    },
  },
  getters: {
    getOrganisationCurrencies: (state) => state.currencies,
  },
};
