import {
  changeVehicleClassActiveStatus,
  deleteVehicleClass,
  fetchAllVehicleClasses,
  getVehicleClass,
  saveVehicleClass,
  updateVehicleClass,
} from '@/api/vehicleClasses';
import store from '@/store';
import i18n from '@/i18n/i18n';

export default {
  namespaced: true,
  state: { vehicleClasses: [] },
  mutations: {
    SET_VEHICLE_CLASSES(state, vehicleClasses) {
      state.vehicleClasses = vehicleClasses;
    },
  },
  actions: {
    async fetchAllVehicleClasses({ commit }) {
      try {
        const response = await fetchAllVehicleClasses();
        commit('SET_VEHICLE_CLASSES', response.data);
      } catch (error) {
        store.dispatch('showSnackbar', { text: i18n.t('snackbar.anErrorOccured'), color: 'red' });
      }
    },
    async getVehicleClass(state, item) {
      return getVehicleClass(item);
    },
    async saveVehicleClass(state, item) {
      return saveVehicleClass(item);
    },
    async updateVehicleClass(state, item) {
      return updateVehicleClass(item);
    },
    async deleteVehicleClass(state, item) {
      return deleteVehicleClass(item);
    },
    async changeVehicleClassActiveStatus(state, item) {
      return changeVehicleClassActiveStatus(item);
    },
  },
  getters: {
    getVehicleClasses: (state) => state.vehicleClasses,
  },
};
