<template>
  <!-- <v-app :class="{ 'disabled-content': tourActive && $store.state.auth.authenticated && $store.state.auth.role !== 1 }"> -->
  <v-app
    :style="themeStyles"
    :class="[
      pageClass,
      { 'disabled-content': tourActive && $store.state.auth.authenticated && $store.state.auth.role !== 1 },
    ]">
    <v-layout fill-height>
      <v-overlay class="app-loader" :value="$store.state.loader.loading || $store.state.loader.permanentLoading">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-overlay>

      <main-layout v-if="this.$store.state.auth.authenticated && this.$store.state.auth.user" />

      <HeaderEmpty v-else />

      <v-main
        :class="
          this.$store.state.auth.trialBannerDisplay || this.disabledForAffiliate ? 'margin-large' : 'margin-small'
        ">
        <router-view :key="$route.fullPath" class="mb-5" />
      </v-main>
      <template v-if="footerVisible()">
        <AffiliateBanner v-if="disabledForAffiliate" />
        <DemoExpiring v-if="$store.state.auth.user" />
        <Footer />
      </template>
    </v-layout>

    <v-snackbar
      v-model="snackbar.active"
      :class="snackbar.textColor ? 'snackbar-warning' : ''"
      :color="snackbar.color"
      :timeout="snackbar.duration || '2000'"
      bottom>
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar.active = false">
          {{ $t('snackbar.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import HeaderEmpty from './components/HeaderEmpty';
import DemoExpiring from './components/DemoExpiring';
import AffiliateBanner from './components/AffiliateBanner';
import Footer from './components/Footer';
import MainLayout from './components/MainLayout';
import { mapState } from 'vuex';
import state from '@/store';
import i18n from '@/i18n/i18n';
import router from '@/router';

export default {
  name: 'App',
  components: {
    HeaderEmpty,
    DemoExpiring,
    AffiliateBanner,
    Footer,
    MainLayout,
  },
  data: () => ({
    customColor: null,
  }),
  computed: {
    ...mapState(['snackbar']),
    ...mapState('onboardingTutorial', ['tourActive']),

    pageClass() {
      if (
        (this.$route.name === 'PublicReservationPage' || this.$route.name === 'PublicReservationEmbeddedPage') &&
        this.customColor
      ) {
        return 'custom-theme'; // Add a custom class for a specific page
      }
      return '';
    },
    themeStyles() {
      return {
        '--custom-primary': this.customColor || '#040b91',
      };
    },
  },
  created() {
    let limoExpressUser = localStorage.getItem('LimoExpressUser');
    let parsedUser = limoExpressUser ? JSON.parse(limoExpressUser) : null;
    let paddleCustomer = localStorage.getItem('customer');
    let parsedCustomer = paddleCustomer ? JSON.parse(paddleCustomer) : null;
    let organisationTypeStorage = localStorage.getItem('organisation_type');
    let organisationType = organisationTypeStorage ? organisationTypeStorage : null;
    if (
      localStorage.getItem('LimoExpressToken') &&
      parsedUser &&
      localStorage.getItem('loggedIn') &&
      localStorage.getItem('saveToken')
    ) {
      state.dispatch('auth/setUser', parsedUser);
      state.dispatch('auth/setRole', parsedUser);
      state.dispatch('auth/setCustomer', parsedCustomer);
      state.dispatch('auth/setAuthToken', localStorage.getItem('LimoExpressToken'));
      state.dispatch('auth/setAuthenticated', true);
      state.dispatch('auth/setOrganisationType', organisationType);
      if (this.$route.name === 'Login' || window.location.pathname === '/login' || window.location.pathname === '/') {
        this.$router.push({ name: 'Dashboard' });
      }
    }

    if (!localStorage.getItem('loggedIn')) {
      state.dispatch('auth/removeUserData');

      if (!this.isAllowedRoute()) {
        router.push({ name: 'Login' });
      }
    }

    this.snackbar.active = false;

    this.$store.dispatch('onboardingTutorial/toggleTour', false);
  },
  mounted() {
    window.addEventListener('online', this.handleOnline);
    window.addEventListener('offline', this.handleOffline);
  },
  metaInfo() {
    return {
      title: process.env.VUE_APP_BROWSER_TITLE,
      meta: [
        { name: 'description', content: 'LimoExpress - Limo|Livery Dispatch & Management Software' },
        { property: 'og:title', content: 'LimoExpress - Limo|Livery Dispatch & Management Software' },
        { property: 'og:site_name', content: 'LimoExpress - Limo|Livery Dispatch & Management Software' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
  methods: {
    async getPublicBookingData() {
      if (this.$route.query.param) {
        await this.$store
          .dispatch('drivings/getPublicBookingData', {
            id: this.$route.query.param,
          })
          .then((res) => {
            this.customColor = res.data.organisation.public_booking_color;
          });
      }
    },
    isAllowedRoute: function () {
      const allowedRoutes = new Set([
        'SocialLogin',
        'ForgetPassword',
        'SetPasswordPage',
        'EnterEmailPage',
        'Registration',
        'PublicReservationPage',
        'PublicReservationEmbeddedPage',
      ]);
      const allowedPaths = new Set([
        '/social-login',
        '/forget-password',
        '/set-password',
        '/change-password',
        '/registration',
        '/public/reservation-form',
        '/public/reservation-embedded-form',
      ]);

      return allowedRoutes.has(this.$route.name) || allowedPaths.has(window.location.pathname);
    },

    handleOnline() {
      this.$store.dispatch('showSnackbar', { text: i18n.t('snackbar.backOnline'), color: 'green', duration: 3000 });
    },
    handleOffline() {
      this.$store.dispatch('showSnackbar', {
        text: i18n.t('snackbar.noInternetConnection'),
        color: 'red',
        duration: 3000,
      });
    },
    footerVisible() {
      if (this.$route.name !== 'PublicReservationEmbeddedPage') {
        return true;
      }
    },
  },
  watch: {
    $route() {
      this.getPublicBookingData();
    },
  },
  beforeDestroy() {
    window.removeEventListener('online', this.handleOnline);
    window.removeEventListener('offline', this.handleOffline);
  },
};
</script>

<style lang="scss">
.v-card__actions {
  padding: 16px;
}

.v-dialog > .v-card > .v-card__actions {
  padding: 16px;
}

tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.03);
}

tbody tr.active-row,
tbody tr.inactive-row {
  background-color: #fff;
}

.active-row {
  cursor: pointer;
}

.inactive-row td:not(:last-child) {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}

.date-picker button {
  width: 50% !important;
}

.add-button {
  z-index: 22;
  position: fixed;
  bottom: 70px;
  right: 10px;
  width: 60px;
  height: 60px;
  font-size: 40px;
  font-weight: 300;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1976d2;
  border-radius: 50%;
  color: white;
}

.add-driving-options {
  z-index: 22;
  position: fixed;
  bottom: 75px;
  right: 75px;
  width: 140px;
  height: 60px;
}

.add-driving-options > .v-btn {
  margin: 0;
  padding: 0;
  width: 100%;
}

.add-button > .rotate-right {
  transform: rotate(45deg);
  transition-duration: 0.5s;
}

.add-button > .rotate-left {
  transform: rotate(0deg);
  transition-duration: 1s;
}

.margin-large {
  margin-bottom: 112px;
}

.margin-small {
  margin-bottom: 56px;
}

.disabled-content {
  /* pointer-events: none; */
  /* position: fixed; */
  /* width: 100%; */
  /* height: 100%; */
}

.snackbar-warning .v-snack__content,
.snackbar-warning .v-snack__action .v-btn__content {
  color: #000;
}

.app-loader {
  z-index: 9999 !important;
}

.v-application.custom-theme .primary {
  background-color: var(--custom-primary) !important;
  border-color: var(--custom-primary) !important;
}

.v-application.custom-theme {
  button.v-btn {
    &.primary {
      background-color: var(--custom-primary) !important;

      &:hover {
        background-color: var(--custom-primary) !important;
      }

      &:focus {
        outline: 2px solid var(--custom-primary);
      }

      &:active {
        color: #fff !important;
        background-color: var(--custom-primary) !important;
      }

      &:disabled.v-btn--disabled {
        background-color: var(--custom-primary) !important;
        opacity: 0.5;
      }

      &.cancel {
        color: var(--custom-primary) !important;
        background-color: #fff !important;

        &:hover {
          color: var(--custom-primary) !important;
          background-color: #fff !important;
        }

        &:focus {
          outline: 2px solid var(--custom-primary);
          background-color: #fff !important;
        }

        &:active {
          color: var(--custom-primary) !important;
          background-color: #fff !important;
        }

        &:disabled.v-btn--disabled {
          color: var(--custom-primary) !important;
          background-color: #fff !important;
          opacity: 0.5;
        }
      }
    }
  }
}
</style>
